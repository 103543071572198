import { Box, Link, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/hooks';
import { useGetUserGroupsQuery, useGetUserTypesQuery } from '../../../../app/redux-fetch/apiQuery';
import AddButton from '../../../../components/Buttons/AddButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import SideModalWrapper from '../../../AssetsManagement/modals/SideModalWrapper';
import { fetchLoggedInUserSelector } from '../../../selectors';
import { ILoggedInUser } from '../../../types';
import CreateUserGroupModal from './CreateUserGroupModal';
import { default as UserGroupModal, default as UserModal } from './UserGroupModal';
import messages from './messages';
import { IUserGroup } from './types';

const UserGroupTable = () => {
    const { formatMessage } = useIntl()

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const tenantId = loggedInUser.tenantId

    const { data: fetchedUserGroups, isSuccess: userGroupsLoaded } = useGetUserGroupsQuery({
        tenantId,
    });

    const { isSuccess: userTypeLoaded } = useGetUserTypesQuery({
        tenantId,
    })


    const userGroups = useMemo<IUserGroup[]>(() => {
        return fetchedUserGroups?.map((userGroup: IUserGroup) => {
            return {
                ...userGroup
            }
        }) ?? [];
    }, [fetchedUserGroups]);


    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'name',
                header: formatMessage(messages.nameLabel),
            },
            {
                accessorFn: (row: any) => {
                    return row.description
                },
                header: formatMessage(messages.descriptionLabel),
            },
            {
                accessorFn: (row: any) => {
                    return row.users.length
                },
                header: formatMessage(messages.tabUsers),
            },
            {
                accessorFn: (row: any) => {
                    return row.roles.length
                },
                header: formatMessage(messages.tabRoles),
            },
            {
                header: formatMessage(messages.activeLabel),
                accessorFn: (row: any) => {
                    return !row.disabled ? (
                        <Typography sx={{ fontWeight: 700, color: '#0A9D33' }}>{formatMessage(messages.yesLabel)}</Typography>
                    ) : (
                        <Typography sx={{ fontWeight: 700, color: '#D40808' }}>{formatMessage(messages.noLabel)}</Typography>
                    );
                },
            },
        ];
    }, []);



    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);

    const [selectedUserGroupId, setSelectedUserGroupId] = useState<string | undefined>();

    return (<>
        <SideModalWrapper
            open={createOpen}
            key={'create'}
            headerLabel={formatMessage(messages.createUserGroupLabel)}
            onClose={() => {
                setCreateOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <CreateUserGroupModal />
        </SideModalWrapper>
        <SideModalWrapper
            open={editOpen}
            key={'edit'}
            headerLabel={formatMessage(messages.editUserGroupLabel)}
            onClose={() => {
                setEditOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <UserGroupModal userGroupId={selectedUserGroupId} />
        </SideModalWrapper>
        <SideModalWrapper
            open={viewOpen}
            key={'view'}
            headerLabel={formatMessage(messages.viewUserGroupLabel)}
            onClose={() => {
                setViewOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <UserModal userGroupId={selectedUserGroupId} isDisabled={true} />
        </SideModalWrapper>
        <MRTDataGridv2
            initialState={{
                    sorting: [
                        {
                            id: 'name',
                            desc: false,
                        }]
                }
            }
            state={{
                isLoading: !userGroupsLoaded || !userTypeLoaded,
            }}
            rightHeadingComponent={
                <AddButton
                    text={formatMessage(messages.newUserGroupLabel)}
                    onClick={() => setCreateOpen(true)}
                />
            }
            columns={columns}
            data={userGroups || []}
            getRowId={(row) => row.id as any}
            enableEditing
            enableRowActions
            positionActionsColumn='last'
            renderRowActions={({ row, table }: any) => {
                return (
                    <>
                        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <Link
                                underline='none'
                                color='secondary'
                                onClick={() => {
                                    setSelectedUserGroupId(row.id)
                                    setEditOpen(true)
                                }}
                                sx={{
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                }}
                            >
                                {formatMessage(messages.editButton)}
                            </Link>
                            <Typography color='secondary'>/</Typography>
                            <Link
                                underline='none'
                                color='secondary'
                                onClick={() => {
                                    setSelectedUserGroupId(row.id)
                                    setViewOpen(true)
                                }}
                                sx={{
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                }}
                            >
                                {formatMessage(messages.viewButton)}
                            </Link>
                        </Box>
                    </>
                )
            }}
        />
    </>
    );
};

export default UserGroupTable;