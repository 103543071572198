import { Box, createTheme, Grid, Tab, Tabs, ThemeProvider, useTheme } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import TabPanel from '../../../components/TabPanel/TabPanel'
import ContactCategoryTab from './ContactCategory/ContactCategoryTab'
import ContactTypeTab from './ContactType/ContactTypeTab'
import messages from './messages'

const ContactTabs = () => {
  const { formatMessage } = useIntl()

  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  let theme = useTheme()

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={theme}>
          <Tabs
            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
            value={activeTab}
            onChange={handleChange}
            centered
            variant='standard'
            scrollButtons={false}
            aria-label='Contacts settings scrollable tabs'
          >
            <Tab label={formatMessage(messages.contacts)} />
            <Tab label={formatMessage(messages.contactCategories)} />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Grid item xs={12}>
          <ContactTypeTab />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Grid item xs={12}>
          <ContactCategoryTab />
        </Grid>
      </TabPanel>
    </>
  )
}

export default ContactTabs
