import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import {
  useGetCustomersQuery,
  useGetProductCategoriesQuery,
  useGetProductsQuery,
} from '../../../app/redux-fetch/apiQuery'
import { IDropDownItem } from '../../../app/types'
import AddButton from '../../../components/Buttons/AddButton'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import YesNoTypography from '../../../components/YesNoTypograpphy/YesNoTypography'
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper'
import { fetchAllCountriesSelector, fetchAllCurrenciesSelector } from '../../Shipments/selectors'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { useProductTabContext } from '../ProductTabContext'
import { IProduct } from '../types'
import CreateProductModal from './CreateProductModal'
import ProductModal from './ProductModal'
import messages from './messages'

const ProductTable = () => {
  const { formatMessage } = useIntl()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
  const { customerId, setCustomerId } = useProductTabContext()
  const currencies = useAppSelector(fetchAllCurrenciesSelector.data) ?? []
  const currenciesIsFetching = useAppSelector(fetchAllCurrenciesSelector.isFetching)
  const currenciesIsLoaded = useAppSelector(fetchAllCurrenciesSelector.isFinished)
  const isCustomerUser = loggedInUser.customerId !== undefined

  const { data: fetchedProducts, isSuccess: productLoaded } = useGetProductsQuery(customerId)

  const {
    data: productCategories,
    isSuccess: productCategoryLoaded,
    refetch,
  } = useGetProductCategoriesQuery(customerId, {
    skip: customerId === undefined || customerId < 1,
  })

  const { data: customers } = useGetCustomersQuery(loggedInUser.tenantId ?? -1)

  const products = useMemo<IProduct[]>(() => {
    return (
      fetchedProducts?.map((p) => {
        const currency = currencies.find((c) => c.id === p.currencyId)
        return { ...p, currencyName: `${currency?.iso3}` }
      }) ?? []
    )
  }, [fetchedProducts, currencies])

  const columns = useMemo<MRTColumnDef<IProduct>[]>(() => {
    return [
      {
        accessorKey: 'name',
        Cell: ({ cell }: any) => {
          return <Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>
        },
        header: formatMessage(messages.productName),
      },
      {
        accessorKey: 'sku',
        header: formatMessage(messages.productCode),
      },
      {
        accessorKey: 'barcode',
        header: formatMessage(messages.barcode),
      },
      {
        accessorFn: (row) => {
          const name =
            productCategories?.find(
              (productCategory) => productCategory.id === row.productCategoryId
            )?.name ?? ''
          return name
        },
        Cell: ({ cell }: any) => {
          return <Typography>{cell.getValue()}</Typography>
        },
        header: formatMessage(messages.productCategory),
      },
      {
        accessorFn: (row) => {
          return countries.find((country) => country.id === row.countryOfOriginId)?.name ?? ''
        },
        header: formatMessage(messages.countryOfOrigin),
      },
      {
        accessorFn: (row) => {
          const currency = row.currencyName ?? '-'
          const price =
            row.price != null ? row.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '-'
          return `${currency} ${price}`
        },
        header: formatMessage(messages.productPrice),
        Cell: ({ cell }: any) => {
          return <Typography sx={{ textAlign: 'right' }}>{cell.getValue()}</Typography>
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
      },
      {
        accessorFn: (row) => {
          return row.enabled ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.enabled),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
    ]
  }, [
    productCategories,
    currencies,
    countries,
    formatMessage,
    currenciesIsFetching,
    currenciesIsLoaded,
  ])

  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState(-1)

  const isLoading = countries.length === 0 || !productLoaded || !productCategoryLoaded

  return (
    <>
      <SideModalWrapper
        open={createOpen}
        key={'create'}
        headerLabel={formatMessage(messages.createEditProduct)}
        onClose={() => {
          setCreateOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <CreateProductModal />
      </SideModalWrapper>
      <SideModalWrapper
        open={editOpen}
        key={'edit'}
        headerLabel={formatMessage(messages.createEditProduct)}
        onClose={() => {
          setEditOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <ProductModal productId={selectedProductId} customerId={loggedInUser.customerId ?? -1} />
      </SideModalWrapper>
      <SideModalWrapper
        open={viewOpen}
        key={'view'}
        headerLabel={formatMessage(messages.viewProduct)}
        onClose={() => {
          setViewOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <ProductModal
          productId={selectedProductId}
          customerId={loggedInUser.customerId ?? -1}
          isDisabled={true}
        />
      </SideModalWrapper>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          density: 'comfortable',
          sorting: [
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
        state={{
          isLoading: customerId > 0 && (isLoading || currenciesIsFetching || !currenciesIsLoaded),
        }}
        leftHeadingComponent={
          isCustomerUser ? (
            <></>
          ) : (
            <>
              <FormDropdown
                id='customerId'
                items={customers as IDropDownItem[]}
                onChange={(e: any, name: any, newValue: any) => {
                  setCustomerId && setCustomerId(newValue)
                  refetch()
                }}
                sx={{ width: '400px', mt: '12px' }}
                value={customerId}
                label='Customer'
              />
            </>
          )
        }
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newProductBtn)}
            onClick={() => setCreateOpen(true)}
            disabled={!customerId || customerId < 1}
          />
        }
        columns={columns}
        data={products || []}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedProductId(row.id)
                    setEditOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editBtn)}
                </Link>
                <Typography color='secondary'>/</Typography>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedProductId(row.id)
                    setViewOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.viewBtn)}
                </Link>
              </Box>
            </>
          )
        }}
      />
    </>
  )
}

export default ProductTable
