import dayjs from 'dayjs'
import { IJourneyLegLoad, IJourneyLegRoutesLoad, ITransportJourney } from '../Transport/types'
import { Load } from '../TransportJob/types'
import { ILocation } from '../types'
import { Carrier, Journey, Leg, Route } from './types'

export const countRoutes = (journey: Journey): number => {
  return journey.legs.reduce((totalRoutes, leg) => totalRoutes + leg.routes.length, 0)
}

export const countUniqueLoadIds = (journey: Journey): number => {
  const uniqueLoadIds = new Set<number>()
  for (const leg of journey.legs) {
    for (const route of leg.routes) {
      for (const destination of route.destinations) {
        for (const loadAction of destination.loadActions) {
          uniqueLoadIds.add(loadAction.loadId)
        }
      }
    }
  }
  return uniqueLoadIds.size
}

export const getAllRoutes = (journey: Journey): Route[] => {
  const result = journey.legs.reduce<Route[]>((acc, leg) => [...acc, ...leg.routes], [])
  if (journey.id === 200) {
    console.log('result', result)
    console.log('result', journey)
  }
  return result
}

export const getAllUniqueLoads = (journey: Journey): Load[] => {
  const loadsMap = new Map<number, Load>()
  journey.legs.forEach((leg) => {
    leg.routes.forEach((route) => {
      route.destinations.forEach((destination) => {
        destination.loadActions.forEach((loadAction) => {
          if (!loadsMap.has(loadAction.loadId)) {
            loadsMap.set(loadAction.loadId, loadAction.load)
          }
        })
      })
    })
  })
  return Array.from(loadsMap.values())
}

export const getAllUniqueLoadsForLeg = (leg: Leg): Load[] => {
  const uniqueLoadsMap = new Map<number, Load>()

  leg.routes.forEach((route) => {
    route.destinations.forEach((destination) => {
      destination.loadActions.forEach((loadAction) => {
        if (!uniqueLoadsMap.has(loadAction.loadId)) {
          uniqueLoadsMap.set(loadAction.loadId, loadAction.load)
        }
      })
    })
  })

  return Array.from(uniqueLoadsMap.values())
}

export const getUniqueCarriersForRoute = (journey: Journey, routeId: number): Carrier => {
  let carrier = { id: 0, name: '' }

  journey.legs.forEach((leg) => {
    const routeFound = leg.routes.some((route) => route.id === routeId)
    if (routeFound) {
      carrier = leg.carrier
    }
  })

  return carrier
}

export const getLocationByDestinationActionTypeId = (
  route: Route,
  destinationActionTypeId: number
): ILocation | undefined => {
  for (const destination of route.destinations) {
    if (destination.loadActions.length > 0) {
      const hasPickupLocation = destination.loadActions.some(
        (loadAction) => loadAction.destinationActionTypeId === destinationActionTypeId
      )
      if (hasPickupLocation) {
        return destination.location
      }
    }
  }
  return undefined
}

export const formatDate = (date: string): string => {
  const formattedDate = dayjs(date).format('DD MMM YYYY hh:mm A')
  return formattedDate
}

export const getLoadsForRoute = (route: Route): Load[] => {
  const loadsMap = new Map<number, Load>()
  route.destinations.forEach((destination) => {
    destination.loadActions.forEach((loadAction) => {
      if (!loadsMap.has(loadAction.loadId)) {
        loadsMap.set(loadAction.loadId, loadAction.load)
      }
    })
  })
  return Array.from(loadsMap.values())
}

export const getLegsForRoute = (journey: Journey, routeId: number): Leg[] => {
  return journey.legs.filter((leg) => leg.routes.some((route) => route.id === routeId))
}

export const getUniqueRoutesForLoad = (journey: Journey, loadId: number): Route[] => {
  const uniqueRoutesMap = new Map<number, Route>()
  journey.legs.forEach((leg) => {
    leg.routes.forEach((route) => {
      route.destinations.forEach((destination) => {
        destination.loadActions.forEach((loadAction) => {
          if (loadAction.loadId === loadId) {
            uniqueRoutesMap.set(route.id, route)
          }
        })
      })
    })
  })
  return Array.from(uniqueRoutesMap.values())
}

export const getUniqueLegsForLoad = (journey: Journey, loadId: number): Leg[] => {
  const uniqueLegsMap = new Map<number, Leg>()
  journey.legs.forEach((leg) => {
    leg.routes.forEach((route) => {
      route.destinations.forEach((destination) => {
        destination.loadActions.forEach((loadAction) => {
          if (loadAction.loadId === loadId) {
            uniqueLegsMap.set(leg.id, leg)
          }
        })
      })
    })
  })
  return Array.from(uniqueLegsMap.values())
}

export const transformAndAppendLoads = (originalJourney: ITransportJourney) => {
  const journey: ITransportJourney = JSON.parse(JSON.stringify(originalJourney))

  if (journey.legs) {
    journey.legs.forEach((leg) => {
      leg.routes.forEach((route) => {
        const routeLoads: IJourneyLegRoutesLoad[] = []
        const uniqueLoadIds = new Set()

        if (route.destinations) {
          route.destinations.forEach((destination) => {
            if (destination.loadActions) {
              destination.loadActions.forEach((action) => {
                const load = action.load

                if (load && !uniqueLoadIds.has(load.id)) {
                  const transformedLoad: IJourneyLegRoutesLoad = {
                    id: load.id,
                    name: load.description,
                    isDefined: load.isDefined,
                  }
                  if (action.destinationActionTypeId === 1) {
                    transformedLoad.pickupRouteLocationId = destination.locationId
                  } else if (action.destinationActionTypeId === 2) {
                    transformedLoad.dropoffRouteLocationId = destination.locationId
                  }
                  routeLoads.push(transformedLoad)
                  uniqueLoadIds.add(load.id)
                } else {
                  const addedLoad = routeLoads.find((rl) => rl.id === load.id)
                  if (addedLoad) {
                    if (action.destinationActionTypeId === 1) {
                      addedLoad.pickupRouteLocationId = destination.locationId
                    } else if (action.destinationActionTypeId === 2) {
                      addedLoad.dropoffRouteLocationId = destination.locationId
                    }
                  }
                }
              })
              destination.name = destination.location?.name ?? ''
            }
          })
        }
        route.loads = routeLoads
      })
    })
  }

  journey.loads = getAllUniqueLoads(journey as unknown as Journey) as unknown as IJourneyLegLoad[]
  return journey
}
