import { AssetAvailabilityReport } from '../../features/Dashboard/type'
import { IAssetCategory } from '../../features/Shipments/types'
import {
  AssetCategory,
  AssetClass,
  AssetConfiguration,
  AssetType,
} from '../../features/TenantSettings/AssetSettings/types'
import {
  IAssetConfiguration
} from '../../features/TenantSettings/Components/AssetSettings/types'
import { IAssetGroupAllocationDetails } from '../../features/Transport/types'
import { IAssetBrand, IAssetManagement } from '../../features/types'
import { apiSlice } from './apiQuery'

export const apiAssets = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // useGetAssetTypesByCategoryQuery
    getAssetTypesByCategory: build.query<
      IAssetCategory,
      { tenantId: number; assetCategoryId: number }
    >({
      query: ({ tenantId, assetCategoryId }) => {
        return {
          url: `/tenant/${tenantId}/asset-category/${assetCategoryId}/asset-type`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { assetCategoryId }) => [{ type: 'Assets', assetCategoryId }],
    }),
    getAssetClassesByCategory: build.query<
      IAssetCategory,
      { tenantId: number; assetCategoryId: number }
    >({
      query: ({ tenantId, assetCategoryId }) => {
        return {
          url: `/tenant/${tenantId}/asset-category/${assetCategoryId}/asset-class`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { assetCategoryId }) => [{ type: 'Assets', assetCategoryId }],
    }),
    assetCategoriesByTransportModeId: build.query<
      AssetCategory[],
      { tenantId: number; transportModeId: number }
    >({
      query: ({ tenantId, transportModeId }) => {
        return {
          url: `/tenant/${tenantId}/transport-mode/${transportModeId}/asset-category?hideGlobals=true`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { transportModeId }) => [{ type: 'Assets', transportModeId }],
    }),
    assetCategories: build.query<AssetCategory[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/asset-category`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    createAssetCategory: build.mutation<any, { tenantId: number; body: AssetCategory }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-category`,
        method: 'POST',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    updateAssetCategory: build.mutation<any, { tenantId: number; body: AssetCategory }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-category`,
        method: 'PUT',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    // useGetAssetConfigurationsQuery
    getAssetConfigurations: build.query<IAssetConfiguration[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/asset-configuration`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [
        { type: 'AssetConfigurations', id: tenantId },
      ],
    }),
    // useGetAssetConfigurationByIdQuery
    getAssetConfigurationById: build.query<
      IAssetCategory,
      { tenantId: number; assetConfigurationId: number }
    >({
      query: ({ tenantId, assetConfigurationId }) => {
        return {
          url: `/tenant/${tenantId}/asset-configuration/${assetConfigurationId}`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [
        { type: 'AssetConfigurations', id: tenantId },
      ],
    }),
    // useCreateAssetConfigurationMutation
    createAssetConfiguration: build.mutation<any, { tenantId: number; body: AssetConfiguration }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-configuration`,
        method: 'POST',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (_result, _error, { tenantId }) => {
        return [{ type: 'AssetConfigurations', id: tenantId }]
      },
    }),
    // useDeleteAssetConfigurationMutation
    deleteAssetConfiguration: build.mutation<
      any,
      { tenantId: number; assetConfigurationId: number }
    >({
      query: ({ tenantId, assetConfigurationId }) => ({
        url: `/tenant/${tenantId}/asset-configuration/${assetConfigurationId}`,
        method: 'DELETE',
        domain: 'Asset',
      }),
      invalidatesTags: (_result, _error, { tenantId }) => {
        return [{ type: 'AssetConfigurations', id: tenantId }]
      },
    }),
    // useUpdateAssetConfigurationMutation
    updateAssetConfiguration: build.mutation<any, { tenantId: number; body: AssetConfiguration }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-configuration`,
        method: 'PUT',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (_result, _error, { tenantId }) => {
        return [{ type: 'AssetConfigurations', id: tenantId }]
      },
    }),
    // useGetAssetsQuery
    getAssets: build.query<IAssetManagement[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/asset`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
    }),
    // useGetAssetQuery
    getAsset: build.query<IAssetManagement, { tenantId: number; assetId: number }>({
      query: ({ tenantId, assetId }) => {
        return {
          url: `/tenant/${tenantId}/asset/${assetId}`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
    }),
    // useUpdateAssetMutation
    updateAsset: build.mutation<any, { tenantId: number; body: IAssetManagement }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset`,
        method: 'PUT',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (_result, _error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
    }),
    // useCreateAssetMutation
    createAsset: build.mutation<any, { tenantId: number; body: IAssetManagement }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset`,
        method: 'POST',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (_result, _error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
    }),
    // useDeleteAssetMutation
    deleteAsset: build.mutation<any, { tenantId: number; assetId: number }>({
      query: ({ tenantId, assetId }) => ({
        url: `/tenant/${tenantId}/asset/${assetId}`,
        method: 'DELETE',
        domain: 'Asset',
      }),
      invalidatesTags: (_result, _error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
    }),
    // useGetBrandQuery
    getBrand: build.query<IAssetBrand[], void>({
      query: () => {
        return {
          url: '/brand',
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: () => [{ type: 'Brands' }],
    }),
    getAssetAvailability: build.query<AssetAvailabilityReport, { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `tenant/${tenantId}/reports/asset-availability`,
          method: 'GET',
          domain: 'Asset',
        }
      },
    }),
    createAssetGroup: build.mutation<any, { tenantId: number; body: IAssetGroupAllocationDetails }>(
      {
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/asset-group`,
          method: 'POST',
          domain: 'Asset',
          body: body,
        }),
        invalidatesTags: [
          { type: 'AssetGroup', id: 'LIST' },
          { type: 'Loads', id: 'Unassigned Routes' },
        ],
      }
    ),
    useGetAssetTypesByTenantAndCategoryId: build.query<
      AssetType[],
      { tenantId: number; assetCategoryId: number }
    >({
      query: ({ tenantId, assetCategoryId }) => {
        return {
          url: `/tenant/${tenantId}/asset-category/${assetCategoryId}/asset-type`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    useGetAssetTypesByTenantId: build.query<AssetType[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/asset-type`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    createAssetType: build.mutation<any, { tenantId: number; body: any }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-type`,
        method: 'POST',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    updateAssetType: build.mutation<any, { tenantId: number; body: any }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-type`,
        method: 'PUT',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    deleteAssetType: build.mutation<any, { tenantId: number; assetTypeId: number }>({
      query: ({ tenantId, assetTypeId }) => ({
        url: `/tenant/${tenantId}/asset-type/${assetTypeId}`,
        method: 'DELETE',
        domain: 'Asset',
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    getAssetClassByTenantId: build.query<AssetClass[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/asset-class`,
          method: 'GET',
          domain: 'Asset',
        }
      },
      providesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    createAssetClass: build.mutation<any, { tenantId: number; body: AssetClass }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-class`,
        method: 'POST',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    updateAssetClass: build.mutation<any, { tenantId: number; body: AssetClass }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/asset-class`,
        method: 'PUT',
        domain: 'Asset',
        body: body,
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
    deleteAssetClass: build.mutation<any, { tenantId: number; assetClassId: number }>({
      query: ({ tenantId, assetClassId }) => ({
        url: `/tenant/${tenantId}/asset-class/${assetClassId}`,
        method: 'DELETE',
        domain: 'Asset',
      }),
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Assets', tenantId }],
    }),
  }),

  overrideExisting: false,
})

export const {
  // Queries
  useGetAssetTypesByCategoryQuery,
  useGetAssetClassesByCategoryQuery,
  useAssetCategoriesByTransportModeIdQuery,
  useCreateAssetCategoryMutation,
  useUpdateAssetCategoryMutation,
  useGetAssetConfigurationsQuery,
  useGetAssetConfigurationByIdQuery,
  useCreateAssetConfigurationMutation,
  useDeleteAssetConfigurationMutation,
  useUpdateAssetConfigurationMutation,
  useGetAssetsQuery,
  useGetAssetQuery,
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useCreateAssetGroupMutation,
  useGetBrandQuery,
  useGetAssetAvailabilityQuery,
  useAssetCategoriesQuery,
  useUseGetAssetTypesByTenantIdQuery,
  useGetAssetClassByTenantIdQuery,
  // Mutations
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useDeleteAssetTypeMutation,
  useCreateAssetClassMutation,
  useUpdateAssetClassMutation,
  useDeleteAssetClassMutation,
} = apiAssets
