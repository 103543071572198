import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface DatePickerProps {
  disabled?: boolean
  label?: string
  errorText?: string
  name: string
  value?: Date | null
  disablePast?: boolean
  onChange?: (date?: Date) => void
  maxDate?: Date
  minDate?: Date
}

export const DatePicker = ({
  disabled,
  label,
  value = null,
  name,
  disablePast = true,
  onChange,
  errorText,
  minDate,
  maxDate,
}: DatePickerProps) => {

  return (
    <DesktopDatePicker
      disablePast={disablePast}
      disabled={disabled}
      format='DD/MM/YYYY'
      label={label}
      value={dayjs(value)}
      minDate={dayjs(minDate)}
      maxDate={dayjs(maxDate ?? new Date('2100-01-01'))}
      onChange={(date: any) => {
        if (date?.$d !== 'Invalid Date') {
          const localDate = date?.toDate()
          if (onChange) {
            onChange(new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000))
          }
        }
      }}
      slotProps={{
        textField: {
          id: name,
          name: name,
          error: Boolean(errorText),
          InputLabelProps: { shrink: true },
          helperText: errorText,
          disabled: disabled
        },
      }}
    />
  )
}
