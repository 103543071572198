import {
  Autocomplete,
  TextField,
} from '@mui/material';
import { differenceBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../app/hooks';
import { useFormContext } from '../../../../../../app/hooks/useFormContext';
import { useGetUserTypesQuery } from '../../../../../../app/redux-fetch/apiQuery';
import { fetchLoggedInUserSelector } from '../../../../../selectors';
import { ILoggedInUser } from '../../../../../types';
import { IWorkforceTypes } from '../../types';

interface UserTypesAutocompleteProps {
  label: string;
  defaultValue?: IWorkforceTypes[];
  onChange?: (userTypes: IWorkforceTypes[]) => void;
  disabled?: boolean;
}

const UserTypesAutocomplete = ({
  label,
  defaultValue = [],
  onChange,
  disabled,
}: UserTypesAutocompleteProps) => {

  const { values } = useFormContext();

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: userTypes = [] } = useGetUserTypesQuery({
    tenantId,
  })

  const [userTypeOptions, setUserTypeOptions] = useState<IWorkforceTypes[]>([]);

  useEffect(() => {
    setUserTypeOptions(differenceBy(userTypes, values.userTypes as IWorkforceTypes[], 'name'));
  }, [userTypes, values.userTypes])


  return (
    <Autocomplete
      multiple
      options={userTypeOptions}
      getOptionLabel={(option) => option.name}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="types" />
      )}
      sx={{ maxWidth: '400px', mb: '16px' }}
      onChange={(_, value) => {
        if (onChange) {
          onChange(value);
        }
      }}
      disabled={disabled}
    />
  )
}

export default UserTypesAutocomplete