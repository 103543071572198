import dayjs from 'dayjs'
import { FormikConfig, FormikProps, FormikValues } from 'formik'
import { IAssetRequestAllocation, IShipment } from './types'

export const handleDateChanges = <Values extends FormikValues = FormikValues>(
  date: any,
  name: string,
  formik: FormikConfig<Values> | any
): void => {
  if (date && date.$d !== 'Invalid Date') {
    const localDate = date?.toDate()
    void formik.setFieldValue(
      name,
      new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)
    )
  }
  if (date === null) {
    void formik.setFieldValue(name, '')
  }
}

export const handleRemoveLoadMap = (
  formik: FormikProps<IShipment>,
  loadId: number,
  assetRequestId: number | null | undefined,
  containerId: number | null | undefined
): void => {
  const incomingLoadIndex = formik.values.loads.findIndex((soughtLoad) => soughtLoad.id === loadId)
  const incomingLoad = formik.values.loads[incomingLoadIndex]

  if (assetRequestId != null) {
    const incomingAssetRequestIndex = formik.values.assetRequests.findIndex(
      (c) => c.id === assetRequestId
    )
    const incomingAssetRequest = formik.values.assetRequests[incomingAssetRequestIndex]
    const mappedAssetRequestIndex = incomingLoad.assetRequestAllocations.findIndex(
      (assetRequestAllocation) => assetRequestAllocation.assetRequestId === assetRequestId
    )
    const mappedLoadIndex = incomingAssetRequest.loadsId.findIndex((cLoadId) => cLoadId === loadId)

    const amendedMappedLoads = [...incomingAssetRequest.loadsId]
    const amendedMappedAssetRequests = [...incomingLoad.assetRequestAllocations]
    amendedMappedLoads.splice(mappedLoadIndex, 1)
    amendedMappedAssetRequests.splice(mappedAssetRequestIndex, 1)

    if (amendedMappedLoads.length === 0) {
      const loadLastId = formik.values.loads.slice(-1)[0]?.id ?? 0
      amendedMappedLoads.push(
        CreateVirtualLoadForAssetRequest(
          loadLastId,
          incomingAssetRequest.id,
          formik,
          incomingLoadIndex,
          amendedMappedAssetRequests
        )
      )
    } else {
      formik.setFieldValue(
        `loads.${incomingLoadIndex}.assetRequestAllocations`,
        amendedMappedAssetRequests
      )
    }

    formik.setFieldValue(`assetRequests.${incomingAssetRequestIndex}.loadsId`, amendedMappedLoads)
  }

  if (containerId != null) {
    const incomingContainerIndex = formik.values.containerDetails.findIndex(
      (c) => c.id === containerId
    )
    const incomingContainer = formik.values.containerDetails[incomingContainerIndex]
    const mappedContainerIndex = incomingLoad.containerAllocations.findIndex(
      (containerAllocation) => containerAllocation.containerDetailId === containerId
    )
    const mappedLoadIndex = incomingContainer.loadsId.findIndex((cLoadId) => cLoadId === loadId)

    const amendedMappedLoadsId = [...incomingContainer.loadsId]
    const amendedMappedContainers = [...incomingLoad.containerAllocations]
    amendedMappedLoadsId.splice(mappedLoadIndex, 1)
    amendedMappedContainers.splice(mappedContainerIndex, 1)

    formik.setFieldValue(`loads.${incomingLoadIndex}.containerAllocations`, amendedMappedContainers)
    formik.setFieldValue(`containerDetails.${incomingContainerIndex}.loadsId`, amendedMappedLoadsId)
  }
}

export const handleMapLoad = (
  formik: FormikProps<IShipment>,
  loadId: number,
  assetRequestId: number | null | undefined,
  containerId: number | null | undefined
): void => {
  const incomingLoadIndex = formik.values.loads.findIndex((soughtLoad) => soughtLoad.id === loadId)
  const incomingLoad = formik.values.loads[incomingLoadIndex]

  if (assetRequestId != null) {
    const incomingAssetRequestIndex = formik.values.assetRequests.findIndex(
      (soughtAssetRequest) => soughtAssetRequest.id === assetRequestId
    )
    const incomingAssetRequest = formik.values.assetRequests[incomingAssetRequestIndex]
    const assetRequestAllocations = incomingLoad.assetRequestAllocations ?? []
    const mappedAssetRequestIndex = assetRequestAllocations.findIndex(
      (assetRequestAllocation) => assetRequestAllocation.assetRequestId === assetRequestId
    )

    // If the asset is already in the load, do not add it again.
    if (mappedAssetRequestIndex === -1) {
      const amendedMappedAssetRequests = [
        ...assetRequestAllocations,
        { assetRequestId: incomingAssetRequest.id, loadId: incomingLoad.id },
      ]
      if (incomingAssetRequest.loadsId && incomingAssetRequest.loadsId.length === 1) {
        const load = formik.values.loads.find((l) => l.id === incomingAssetRequest.loadsId[0])
        if (load && !load?.isDefined) {
          const loadIndex = formik.values.loads.findIndex((l) => l.id === load.id)
          const loads = [...formik.values.loads]
          loads[incomingLoadIndex].assetRequestAllocations = amendedMappedAssetRequests
          loads.splice(loadIndex, 1)
          incomingAssetRequest.loadsId = []
          void formik.setFieldValue('loads', loads)
        } else {
          formik.setFieldValue(
            `loads.${incomingLoadIndex}.assetRequestAllocations`,
            amendedMappedAssetRequests
          )
        }
      } else {
        formik.setFieldValue(
          `loads.${incomingLoadIndex}.assetRequestAllocations`,
          amendedMappedAssetRequests
        )
      }
      // Assume that if the asset is not in the load, the load is not in the asset and it is safe to add.

      const mappedLoads = incomingAssetRequest.loadsId ?? []
      const amendedMappedLoads = [...mappedLoads, incomingLoad.id]
      formik.setFieldValue(`assetRequests.${incomingAssetRequestIndex}.loadsId`, amendedMappedLoads)
    }
  }

  if (containerId != null) {
    const incomingContainerIndex = formik.values.containerDetails.findIndex(
      (soughtContainer) => soughtContainer.id === containerId
    )
    const incomingContainer = formik.values.containerDetails[incomingContainerIndex]
    const mappedContainers = incomingLoad.containerAllocations ?? []
    const mappedContainerIndex = mappedContainers.findIndex(
      (containerAllocation) => containerAllocation.containerDetailId === containerId
    )

    // If the container is already in the load, do not add it again.
    if (mappedContainerIndex === -1) {
      const amendedMappedContainers = [
        ...mappedContainers,
        { containerDetailId: incomingContainer.id, loadId: incomingLoad.id },
      ]
      formik.setFieldValue(
        `loads.${incomingLoadIndex}.containerAllocations`,
        amendedMappedContainers
      )

      // Assume that if the asset is not in the load, the load is not in the asset and it is safe to add.
      const mappedLoads = incomingContainer.loadsId ?? []
      const amendedMappedLoads = [...mappedLoads, incomingLoad.id]
      formik.setFieldValue(`containerDetails.${incomingContainerIndex}.loadsId`, amendedMappedLoads)
    }
  }
}

export const sortByDisplayOrder = (a: any, b: any): number => {
  if (a.displayOrder < b.displayOrder) {
    return -1
  }
  if (a.displayOrder > b.displayOrder) {
    return 1
  }
  return 0
}

export const sortById = (a: any, b: any): number => {
  if (a.id < b.id) {
    return 1
  }
  if (a.id > b.id) {
    return -1
  }
  return 0
}

export const CreateVirtualLoadForAssetRequest = (
  loadLastId: number,
  id: number,
  formik: FormikProps<IShipment>,
  definedLoadIndex?: number,
  amendedMappedAssetRequests?: IAssetRequestAllocation[]
): number => {
  const initialLoadPaylaod = {
    id: loadLastId - 1,
    value: 0,
    palletDetails: [],
    isDefined: false,
    quantity: 1,
    displayOrder: -1,
    description: `Virtual load for asset request ${id}`,
    currencyId: formik.values.currencyId,
    assetRequestAllocations: [{ assetRequestId: id, loadId: loadLastId - 1 }],
  }
  const loads = formik.values.loads
  if (definedLoadIndex !== undefined && amendedMappedAssetRequests) {
    loads[definedLoadIndex].assetRequestAllocations = amendedMappedAssetRequests
  }
  void formik.setFieldValue('loads', [...loads, initialLoadPaylaod])
  return initialLoadPaylaod.id
}

export const getTimeDiff = (featureTime: string) => {
  const localLockedTime = dayjs.utc(featureTime)
  const currentTime = dayjs(new Date())
  const diffTime = localLockedTime.diff(currentTime, 'second')
  return diffTime
}
