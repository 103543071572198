import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { mergeAll } from 'ramda'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useGetDocumentCategoryQuery } from '../../app/redux-fetch/apiCoreQuery'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import Dropzone from '../../components/Dropzone/Dropzone'
import FormDropdown from '../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../components/Typographies/styles'
import { useDocumentHandle } from '../AssetsManagement/hooks/useDocumentHandle'
import { getDocumentValidationSchema } from './DocumentValidationSchema'
import { messages } from './messages'
import { IDocument } from './types'

type DocumentFormModalProps = {
  isOpen: boolean
  categoryId: number
  documentToEdit?: IDocument
  handleSubmit?: (document: IDocument) => void
  handleCancel: () => void
}

const DocumentFormModal = ({
  documentToEdit,
  isOpen,
  categoryId,
  handleSubmit: handleClose,
  handleCancel,
}: DocumentFormModalProps) => {
  const { formatMessage } = useIntl()
  const [document, setDocument] = useState(documentToEdit ?? {})

  const isDisabled = !handleClose
  const [errors, setErrors] = useState<any>([])
  const [shouldValidate, setShouldValidate] = useState(false)
  const { data: documentCategory } = useGetDocumentCategoryQuery(categoryId)
  const { uploadDocument, viewDocument, removeDocument } = useDocumentHandle()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocument({ ...document, [event.target.name]: event.target.value })
  }

  const handleSubmit = async () => {
    setShouldValidate(true)
    validateDocument(document, true)
    const isValid = await documentSchema.isValid(document)
    if (isValid) {
      setDocument({})
      setErrors({})
      handleClose && handleClose(document)
    }
  }
  const handleCancelBtn = () => {
    setErrors({})
    setDocument({})
    handleCancel()
  }

  useEffect(() => {
    validateDocument(document, shouldValidate)
  }, [document, shouldValidate])

  const documentSchema = getDocumentValidationSchema()
  const validateDocument = (document: IDocument, shouldValidate: boolean) => {
    try {
      shouldValidate && documentSchema.validateSync(document, { abortEarly: false })
      setErrors({})
    } catch (err: any) {
      if (err.name === 'ValidationError') {
        const errs = mergeAll(err.inner.flatMap((e: any) => ({ [e.path]: e.errors })))
        setErrors(errs)
      }
    }
  }
  const handleDateChange = (date: Date) => {
    setDocument({ ...document, expiryDate: date })
  }

  const handleFileChange = (fileId?: string) => {
    setDocument({ ...document, fileId })
  }

  useEffect(() => {
    if (documentToEdit) {
      setDocument(documentToEdit)
    } else {
      setDocument({})
    }
  }, [documentToEdit])

  return (
    <>
      <Dialog open={isOpen} onClose={handleCancel}>
        <DialogTitle>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '20px',
            }}
          >
            {formatMessage(messages.newDocument)}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <Box sx={{ mb: '16px' }}>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.documentName)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <TextField
                fullWidth
                id={'name'}
                name={'name'}
                value={document?.name ?? ''}
                onChange={handleChange}
                disabled={isDisabled}
                helperText={errors?.name}
                error={Boolean(errors?.name)}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.expiryDate)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <DatePicker
                name={'expiryDate'}
                value={document?.expiryDate}
                disabled={isDisabled}
                disablePast={false}
                onChange={(date) => handleDateChange(date ?? new Date())}
                errorText={errors?.expiryDate}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.documentType)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <FormDropdown
                id={'documentTypeId'}
                items={documentCategory?.documentTypes ?? []}
                disabled={isDisabled}
                onChange={(e, name, newValue) => {
                  setDocument({ ...document, documentTypeId: newValue })
                }}
                value={documentCategory?.documentTypes?.find((x) => x.id === document?.documentTypeId ?? -1)?.id}
                error={Boolean(errors?.documentTypeId)}
                errorText={errors?.documentTypeId}
              />
            </Box>
            <Box>
              <Dropzone
                isDisabled={isDisabled}
                fileId={document?.fileId}
                removeHandle={(fileId: string) => {
                  removeDocument(fileId)
                  handleFileChange(undefined)
                }}
                viewHandle={(fileId: string) => {
                  viewDocument(fileId)
                }}
                uploadHandle={async (file: File): Promise<string> => {
                  const documentType = documentCategory?.documentTypes?.find((x) => x.id === document?.documentTypeId)?.name
                  const fileId = await uploadDocument(file, documentType ?? 'document')
                  handleFileChange(fileId)
                  return fileId
                }}
                isRequired={errors?.fileId}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' variant='outlined' onClick={handleCancelBtn}>
            {formatMessage(messages.cancelBtn)}
          </Button>
          {!isDisabled && (
            <Button color='secondary' variant='contained' onClick={handleSubmit}>
              {documentToEdit ? 'Update' : 'Create'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DocumentFormModal
