import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect } from 'react';
import { useGetContainerDetailsMutation } from '../../../../app/redux-fetch/apiQuery';
import ContainerDetailIcon from '../../../../assets/icons/containerDetailsIcon.svg';
import DeliveryIcon from '../../../../assets/icons/delivery.svg';
import ShippingDetailIcon from '../../../../assets/icons/shippingDetails.svg';
import TerminalIcon from '../../../../assets/icons/terminalIcon.svg';
import SpinnerBoxBlock from '../../../../components/Spinner/SpinnerBoxBlock';

dayjs.extend(customParseFormat);

const Section: React.FC<{
    title: string;
    icon: any;
    details: { label: string; value: string | number | undefined }[];
}> = ({ title, icon, details }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', my: 5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                <img
                    src={icon}
                    alt='datasets'
                    style={{ width: 32, height: 32, color: 'white' }}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                <Divider orientation="vertical" flexItem sx={{ mx: 2, height: '60px' }} />
            </Box>

            <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" gutterBottom sx={{ color: '#636363', fontSize: '14px', fontWeight: 700 }}>
                    {title}
                </Typography>
                <Grid container spacing={2}>
                    {details.map((detail, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Typography variant="body2">
                                {detail.label}: <strong>{detail.value}</strong>
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

const TerminalInfo = () => {

    const [getContainerDetails, { data, error, isLoading }] = useGetContainerDetailsMutation();

    useEffect(() => {
        getContainerDetails({
            body: {
                containerNumber: 'MRKU7950396',
                bayanNumber: '104180',
                bayanYear: 2023
            }
        });
    }, [getContainerDetails]);

    if (isLoading) {
        return (<Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh" // for the full viewport height, or use a specific value
            width="90vw" // for the full viewport width, or use a specific value
        >
            <SpinnerBoxBlock />
        </Box>
        )
    }

    if (error || !data) {
        return (<Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
            width="90vw"
        >
            <Typography variant="h5" color="error">
                No Terminal Info Found
            </Typography>
        </Box>)
    }

    return (
        <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        order: -1, // Move the expand icon to the left side
                    },
                    '& .MuiAccordionSummary-content': {
                        marginLeft: 'auto', // Adjust the content alignment
                    },
                }}
            >
                <Typography color='secondary' variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 700, fontSize: '18px' }}>
                    Container Details ({data.source === 2 ? 'RSGT' : 'DpWorld'} Terminal) {/* Return type of integration in brakets*/}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Paper elevation={0} sx={{ width: '100%', padding: 2 }}>
                    <Section
                        title="Container Details"
                        icon={ContainerDetailIcon}
                        details={[
                            { label: 'Container No', value: data.containerNo },
                            { label: 'ISO', value: data.iso },
                            { label: 'Weight in KG', value: data.weight },
                            { label: 'Damages', value: data.damages },
                        ]}
                    />
                    <Section
                        title="Shipping Details"
                        icon={ShippingDetailIcon}
                        details={[
                            { label: 'Bill of landing', value: data.billOfLadingNo },
                            { label: 'Customs Dec. No', value: data.customsDeclarationNo },
                            { label: 'Customs Dec. Date', value: dayjs(data.customsDeclarationDate).format('DD-MMM-YY HHmm') },
                            { label: 'Line ID', value: data.lineId },
                            { label: 'Consignee Id', value: data.consignee },
                            { label: 'Consignee Name', value: data.consigneeName },
                        ]}
                    />
                    <Section
                        title="Terminal Details (Pullout)"
                        icon={TerminalIcon}
                        details={[
                            { label: 'Status', value: data.status },
                            { label: 'Status Description', value: data.statusDescription },
                            { label: 'Freight Kind', value: data.freightKind },
                            { label: 'Examination Type', value: data.examinationType },
                            { label: 'Bundles Payload', value: data.bundlesPayload },
                            { label: 'Transaction Type', value: data.transactionType },
                            { label: 'Insp Flag', value: data.inspFlag },
                        ]}
                    />
                    <Section
                        title="Terminal Details (Pullout)"
                        icon={TerminalIcon}
                        details={[
                            { label: 'Empty Return Terminal', value: data.emptyReturn },
                            { label: 'Empty Return Date', value: dayjs(data.emptyReturnDate).format('DD-MMM-YY HH:mm') },
                        ]}
                    />
                    <Section
                        title="Truck Details"
                        icon={DeliveryIcon}
                        details={[
                            { label: 'Truck License', value: data.truckLicense },
                        ]}
                    />
                </Paper>
            </AccordionDetails>
        </Accordion>
    );
}

export default TerminalInfo
