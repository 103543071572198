import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import dayjs from 'dayjs'
import { useGetProductsQuery } from '../../../../../app/redux-fetch/apiQuery'
import { ILoad } from '../../../types'
import { messages } from './messages'
import { LoadSummaryHeaderBox } from './styles'

interface ILoadSummaryHeaderProps {
  load: ILoad
  customerId?: number
}

const LoadSummaryHeader: FunctionComponent<ILoadSummaryHeaderProps> = ({ load, customerId }) => {
  const { formatMessage } = useIntl()

  const { data: products } = useGetProductsQuery(customerId ?? -1, { skip: !customerId })

  const qty = load.palletDetails
    ? load.palletDetails.reduce((sum, c) => {
      return sum + (c.quantity ? c.quantity : 0)
    }, 0)
    : 0

  return (
    <LoadSummaryHeaderBox sx={{ display: 'flex', flexDirection: 'row' }}>
      <LoadSummaryHeaderBox>
        {formatMessage(messages.quantity, { qty: load.quantity })}{' '}
      </LoadSummaryHeaderBox>
      <LoadSummaryHeaderBox sx={{ width: '432px' }}>
        {formatMessage(messages.description, {
          desc: load.isProduct
            ? products?.find((p) => p.id === load.productId)?.name
            : load.description,
        })}{' '}
      </LoadSummaryHeaderBox>
      <LoadSummaryHeaderBox>
        {formatMessage(messages.palletsQuantity, { count: qty })}
      </LoadSummaryHeaderBox>
      {load.transportDetail?.pickupDate && (
        <LoadSummaryHeaderBox>
          {formatMessage(messages.pickupDate, {
            date: dayjs(load.transportDetail?.pickupDate).format('DD/MM/YYYY'),
          })}
        </LoadSummaryHeaderBox>
      )}
    </LoadSummaryHeaderBox>
  )
}

export default LoadSummaryHeader
