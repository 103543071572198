import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useMemo, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { useAppSelector } from '../../../../app/hooks'
import { useGetProductsQuery } from '../../../../app/redux-fetch/apiQuery'
import { IDropDownItem } from '../../../../app/types'
import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import ValueUnitControl from '../../../../components/ValueUnitControl/ValueUnitControl'
import { fetchAllCountriesSelector, fetchSortedCurrenciesSelector } from '../../selectors'
import { ICountry, ILoad } from '../../types'
import messages from './messages'
import { type ILoadDetailsProps } from './types'

export type LoadProps = ILoadDetailsProps

const LoadInfo = (props: LoadProps): ReactElement => {
  const { formatMessage } = useIntl()
  const { formik, index, disabled, load, handleSelectLoadChanges } = props
  const { data: products } = useGetProductsQuery(formik.values.customerId ?? -1)

  const [isShowChangeProductConfirmReset, setIsShowChangeProductConfirmReset] = useState(false)
  const [isShowSwitchUseProductConfirmReset, setIsShowSwitchUseProductConfirmReset] =
    useState(false)

  const { handleChange } = formik

  const [productValue, setProductValue] = useState()
  const [isProduct, setIsProduct] = useState(load.isProduct)

  const countries = useAppSelector(fetchAllCountriesSelector.data)

  const getCountries = useMemo<ICountry[]>(() => {
    return countries ? countries : []
  }, [countries])
  const currencies = useAppSelector(fetchSortedCurrenciesSelector)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleSelectProductDialogCancel = () => {
    setIsShowChangeProductConfirmReset(false)
  }

  const handleSwitchUseProductDialogCancel = () => {
    formik.setFieldValue(`loads.${index}.isProduct`, !isProduct)
    setIsShowSwitchUseProductConfirmReset(false)
  }
  const clearLoad = () => {
    formik.setFieldValue(`loads.${index}.quantity`, '')
    formik.setFieldValue(`loads.${index}.description`, '')
    formik.setFieldValue(`loads.${index}.value`, 0)
    formik.setFieldValue(`loads.${index}.countryOfOriginId`, undefined)
    formik.setFieldValue(`loads.${index}.currencyId`, undefined)
    formik.setFieldValue(`loads.${index}.physicalProperties`, undefined)
    formik.setFieldValue(`loads.${index}.palletDetails`, undefined)
    formik.setFieldValue(`loads.${index}.temperatureSetting`, undefined)
    formik.setFieldValue(`loads.${index}.transportDetail`, undefined)
    formik.setFieldValue(`loads.${index}.isContainer`, undefined)
    formik.setFieldValue(`loads.${index}.isTemperatureControlled`, false)
  }
  const handleSelectProductConfirmDialog = () => {
    clearLoad()
    formik.setFieldValue(`loads.${index}.productId`, productValue)
    setIsShowChangeProductConfirmReset(false)
  }

  const handleSwitchUseProductConfirmDialog = () => {
    clearLoad()
    void formik.setFieldValue(`loads.${index}.isProduct`, isProduct)
    formik.setFieldValue(`loads.${index}.productId`, undefined)
    setIsShowSwitchUseProductConfirmReset(false)
  }

  const handleChangeProduct = (
    e: string | React.ChangeEvent<any>,
    name: string,
    value: any
  ): any => {
    setProductValue(value)
    if (formik.values.loads[index].productId !== undefined) {
      setIsShowChangeProductConfirmReset(true)
    } else {
      formik.setFieldValue(`loads.${index}.productId`, value)
    }
  }

  const getError = (propertyName: string): boolean => {
    const touched =
      formik.touched.loads &&
      formik.touched.loads[index] &&
      getProperty(formik.touched.loads[index], propertyName)
    const errorMessage =
      formik.errors.loads &&
      formik.errors.loads[index] &&
      getProperty(formik.errors.loads[index] as unknown as ILoad, propertyName)
    return touched && errorMessage
  }
  const getResetSwitchBodyText = () => {
    if (!load.isProduct) {
      return formatMessage(messages.resetSwitchProductAuto)
    } else {
      return formatMessage(messages.resetSwitchProductManual)
    }
  }
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isShowChangeProductConfirmReset}
        onClose={handleSelectProductDialogCancel}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changing the selected product will clear all data entered for this Load. Are you sure
            you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSelectProductDialogCancel}>
            CANCEL
          </Button>
          <Button onClick={handleSelectProductConfirmDialog} autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={isShowSwitchUseProductConfirmReset}
        onClose={handleSelectProductDialogCancel}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{getResetSwitchBodyText()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSwitchUseProductDialogCancel}>
            CANCEL
          </Button>
          <Button onClick={handleSwitchUseProductConfirmDialog} autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
          {formatMessage(messages.createLoadTitle)}
        </Divider>
      </Grid>
      <Grid item xs={4}>
        <NumericFormat
          customInput={TextField}
          fullWidth={true}
          decimalScale={3}
          id={`loads.${index}.quantity`}
          name={`loads.${index}.quantity`}
          thousandSeparator={true}
          type='text'
          label={formatMessage(messages.quantity)}
          rows={4}
          allowNegative={false}
          onValueChange={(vals) => {
            formik.setFieldValue(`loads.${index}.quantity`, vals.floatValue)
          }}
          value={formik.values.loads[index]?.quantity}
          InputLabelProps={{ shrink: true }}
          onBlur={formik.handleBlur}
          inputProps={{ maxLength: 11 }}
          helperText={getError('quantity')}
          error={Boolean(getError('quantity'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropdown
          id={`loads.${index}.countryOfOriginId`}
          items={getCountries}
          label={formatMessage(messages.countryOfOrigin)}
          onChange={(e, name, newValue) => {
            handleSelectLoadChanges(e, name, newValue)
          }}
          value={formik.values.loads[index].countryOfOriginId}
          handleOnBlur={formik.handleBlur}
          errorText={getError('countryOfOriginId')}
          error={Boolean(getError('countryOfOriginId'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          disabled={disabled}
          control={
            <Switch
              size='small'
              id={`loads.${index}.isProduct`}
              name={`loads.${index}.isProduct`}
              color='secondary'
              checked={formik.values.loads[index].isProduct || false}
              value={formik.values.loads[index].isProduct || false}
              onChange={(e, value) => {
                handleChange(e)
                setIsProduct(value)
                setIsShowSwitchUseProductConfirmReset(true)
              }}
            />
          }
          label={formatMessage(messages.useProduct)}
        />
      </Grid>
      {(formik.values.loads[index].isProduct ?? false) && (
        <Grid item xs={8}>
          <FormDropdown
            id={`loads.${index}.productId`}
            label={formatMessage(messages.product)}
            onChange={handleChangeProduct}
            value={formik.values.loads[index].productId}
            items={(products ?? []) as IDropDownItem[]}
            errorText={getError('productId')}
            error={Boolean(getError('productId'))}
            disabled={disabled}
          />
        </Grid>
      )}
      {!(formik.values.loads[index].isProduct ?? false) && (
        <Grid item xs={8}>
          <TextField
            fullWidth={true}
            id={`loads.${index}.description`}
            name={`loads.${index}.description`}
            label={formatMessage(messages.description)}
            multiline
            onChange={formik.handleChange}
            value={formik.values.loads[index]?.description}
            onBlur={formik.handleBlur}
            helperText={getError('description')}
            error={Boolean(getError('description'))}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Grid>
      )}
      <ValueUnitControl
        unitId={`loads.${index}.currencyId`}
        valueId={`loads.${index}.value`}
        handleOnBlur={formik.handleBlur}
        errorUnit={getError('currencyId')}
        errorValue={getError('value')}
        handleChangeOfUnit={(name, newValue) => {
          formik.setFieldValue(`loads.${index}.currencyId`, newValue)
        }}
        handleChangeOfValue={(name, newValue) => {
          formik.setFieldValue(`loads.${index}.value`, newValue)
        }}
        isCurrency
        labelUnit={formatMessage(messages.currencyLabel)}
        labelValue={formatMessage(messages.loadValue)}
        name={`currencyValueControl_load_main_${index}`}
        selectedUnitId={formik.values.loads[index].currencyId ?? 0}
        unitList={currencies}
        value={formik.values.loads[index].value ?? 0}
        disabled={disabled}
      />
    </>
  )
}

export default LoadInfo
