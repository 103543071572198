import { Box, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useAppSelector } from '../../app/hooks'
import { useGetUserTypesQuery } from '../../app/redux-fetch/apiQuery'
import FormDropdown, { DropdownItem } from '../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import { useWorkforceFormContext } from './hooks/useDriverFormContext'

type DriverInfoProps = {
    isDisabled?: boolean
    isNew: boolean
}

const UserInfo = ({ isDisabled = false, isNew }: DriverInfoProps) => {

    const { values, setFieldValue, errors, touched } = useWorkforceFormContext()

    const handleTextFieldChange = ({
        target,
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(target.id, target.value)
    }

    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { data: userTypes } = useGetUserTypesQuery({
        tenantId
    })

    const {
        firstName,
        lastName,
        email,
        phone,
        userType,
    } = values

    return (
        <Box >
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography>Workforce Info</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    Workforce Type
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <FormDropdown
                    id='userTypeId'
                    items={userTypes ? userTypes.filter(x => x.id > 1) as DropdownItem[] : []}
                    onChange={(_e: any, name: string, value: number) => {
                        setFieldValue('driverDetail', undefined)
                        setFieldValue('userType', {
                            id: value,
                            name: userTypes?.find(x => x.id === value)?.name ?? ''
                        })
                    }}
                    value={userType?.id}
                    defaultValue={userType?.id}
                    disabled={isDisabled || !isNew}
                    error={(touched.userType as any)?.id && !!(errors.userType as any)?.id}
                    errorText={(touched.userType as any)?.id && (errors.userType as any)?.id}
                    required
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    First Name
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='firstName'
                    value={firstName}
                    defaultValue={firstName}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    Last Name
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='lastName'
                    value={lastName}
                    defaultValue={lastName}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    Email
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id={'email'}
                    value={email ?? ''}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    Phone
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <MuiTelInput
                    fullWidth
                    id={'phone'}
                    onChange={(value, i) => {
                        setFieldValue('phone', i.numberValue ?? '')
                    }}
                    value={phone ?? ''}
                    helperText={touched.phone && errors?.phone}
                    disabled={isDisabled}
                    error={touched.phone && Boolean(errors?.phone)}
                />
            </Box>

        </Box>
    )
}

export default UserInfo
