import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Fab, Grid, Paper, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiShipment'
import { IDropDownItem } from '../../../app/types'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import { IJourneyLegRoutesLocation, IJourneyRoute } from '../../Transport/types'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { useJourneyFormContext } from '../hooks/useJourneyFormContext'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import { messages } from '../messages'
import LocationLoad from './LocationLoad'

interface IRouteLocation {
  route: IJourneyRoute
  routeIdx: number
  locationIdx: number
  location: IJourneyLegRoutesLocation
  legIdx: number
  loadRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>
}

const RouteLocation = ({
  routeIdx: rIdx,
  locationIdx: lIdx,
  location: l,
  route,
  legIdx,
  loadRefs,
}: IRouteLocation) => {
  const routeLoads = route.loads
  const { formatMessage } = useIntl()

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { values, setFieldValue, handleBlur } = useJourneyFormContext()
  const { mode } = useJourneyStepperContext()

  const handleDateChanges = (date: any, name: string): void => {
    if (date && date.$d !== 'Invalid Date') {
      const localDate = date?.toDate()
      void setFieldValue(
        name,
        new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)
      )
    }
    if (date === null) {
      void setFieldValue(name, '')
    }
  }

  const { data: locations = [] } = useGetLocationsQuery({
    tenantId: tenantId ?? -1,
    includeCustomerLocations: false,
  })

  const handleChangeLocation = (e: any, name: string, newValue: any) => {
    const newLocation = locations.find((l) => l.id === newValue)

    routeLoads.forEach((rl, rlIdx) => {
      const load = rl

      if (load.pickupRouteLocationId === l.locationId) {
        load.pickupRouteLocationId = newValue
        setFieldValue(
          `legs.${legIdx}.routes.${rIdx}.loads.${rlIdx}.pickupRouteLocationId`,
          newValue
        )
      }
      if (load.dropoffRouteLocationId === l.locationId) {
        load.dropoffRouteLocationId = newValue
        setFieldValue(
          `legs.${legIdx}.routes.${rIdx}.loads.${rlIdx}.dropoffRouteLocationId`,
          newValue
        )
      }
    })
    setFieldValue(`${name}.locationId`, newValue)
    setFieldValue(`${name}.name`, newLocation?.name)
    setFieldValue(`${name}.contactId`, undefined)
  }

  const getLocations = () => {
    const usedLocationIds = route.destinations.map((rl) => rl.locationId)
    return locations.filter(
      (loc) => !usedLocationIds.some((uloc) => uloc === loc.id) || l.locationId === loc.id
    )
  }

  const getLocationTitle = (lIdx: number) => {
    let title = ''
    if (lIdx === 0) {
      title = formatMessage(messages.start)
    } else if (lIdx === values.legs[legIdx].routes[rIdx].destinations.length - 1) {
      title = formatMessage(messages.end)
    } else {
      title = formatMessage(messages.stop, { stopNumber: lIdx })
    }
    return title
  }
  const handleRemoveLocation = useCallback(() => {
    const currLocations = values.legs[legIdx].routes[rIdx].destinations
    const loadIdPickupIndxs: number[] = []
    const loadIdDropoffIndxs: number[] = []
    routeLoads.forEach((load, loadIdx) => {
      if (load.dropoffRouteLocationId === l.locationId) {
        loadIdDropoffIndxs.push(loadIdx)
      }
      if (load.pickupRouteLocationId === l.locationId) {
        loadIdPickupIndxs.push(loadIdx)
      }
    })
    loadIdPickupIndxs.forEach((loadIdx) => {
      setFieldValue(
        `legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}.pickupRouteLocationId`,
        undefined
      )
    })
    loadIdDropoffIndxs.forEach((loadIdx) => {
      setFieldValue(
        `legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}.dropoffRouteLocationId`,
        undefined
      )
    })
    currLocations.splice(lIdx, 1)
    setFieldValue(`legs.${legIdx}.routes.${rIdx}.locations`, currLocations)
  }, [routeLoads, values.legs[legIdx].routes[rIdx].destinations])

  const getScheduleDateValue = (): dayjs.Dayjs | null => {
    return values.legs[legIdx].routes[rIdx].destinations[lIdx].scheduledDate != null
      ? dayjs(values.legs[legIdx].routes[rIdx].destinations[lIdx].scheduledDate)
      : null
  }

  return (
    <>
      <Paper
        id={`routes.${rIdx}.locations.${lIdx}`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '6px',
          minWidth: '378px',
          maxWidth: '378px',
        }}
      >
        <Grid container sx={{ p: '12px' }}>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-start' alignItems={'center'}>
              <Typography sx={{ pr: '16px' }}>{getLocationTitle(lIdx)}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              margin: '-10px 0 19px 0',
            }}
          >
            <Box display='flex' justifyContent='flex-end'>
              <Fab
                aria-label='remove'
                color='info'
                disabled={mode === 'view'}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleRemoveLocation()
                }}
                size='small'
                sx={{ margin: '0 2px' }}
              >
                <RemoveIcon />
              </Fab>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mb: '21px',
            }}
          >
            <FormDropdown
              id={`legs.${legIdx}.routes.${rIdx}.destinations.${lIdx}`}
              label={formatMessage(messages.selectLocation)}
              disabled={mode === 'view'}
              onChange={handleChangeLocation}
              value={l.locationId}
              items={getLocations() as IDropDownItem[]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              margin: '-10px 0 19px 0',
            }}
          >
            <DesktopDatePicker
              disablePast={true}
              format='DD/MM/YYYY'
              label={''}
              value={getScheduleDateValue()}
              onChange={(e: any) => {
                handleDateChanges(
                  e,
                  `legs.${legIdx}.routes.${rIdx}.destinations.${lIdx}.scheduledDate`
                )
              }}
              slotProps={{
                textField: {
                  id: `legs.${legIdx}.routes.${rIdx}.destinations.${lIdx}.scheduledDate`,
                  name: `legs.${legIdx}.routes.${rIdx}.destinations.${lIdx}.scheduledDate`,
                  fullWidth: true,
                  disabled: mode === 'view',
                  InputLabelProps: {
                    shrink: true,
                  },
                  onBlur: handleBlur,
                },
              }}
            />
          </Grid>
          <Grid />
          <Grid item xs={12}>
            <LocationLoad
              loadRefs={loadRefs}
              loads={routeLoads}
              location={l}
              rIdx={rIdx}
              legIdx={legIdx}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default RouteLocation
