import { Box, Button, Typography, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { type ReactElement, useState, useEffect, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useIntl } from 'react-intl'
import { getProperty } from '../../../app/utils'
import { postAsset } from '../../Shipments/api'
import messages from './messages'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'

import {
  fetchAllAssetCategoriesByTransportModeThunk,
  fetchAssetClassesByCategoryThunk,
  fetchAssetTypesByCategoryThunk,
} from '../slice'
import { fetchAllTransportModesSelector } from '../../Shipments/selectors'
import { fetchAllTransportModesThunk } from '../../Shipments/slice'
import {
  fetchAllCategoriesByTransportModeSelector,
  fetchAssetClassesByCategorySelector,
  fetchAssetTypesByCategorySelector,
  fetchSortedAssetCategoriesByTransportModeSelector,
  fetchSortedAssetClassByCategorySelector,
  fetchSortedAssetTypesByCategorySelector,
} from '../Components/AssetSettings/selectors'
import { IAssetFormValues } from './types'
import logger from '../../../app/middleware/log'

const AssetForm = (props: any): ReactElement<any, any> => {
  const { loggedInUser, defaultValues, disabled = false, type = 'create' } = props

  const dispatch = useAppDispatch()
  const tenantId = loggedInUser?.tenantId
  const transportModes = useAppSelector(fetchAllTransportModesSelector.data) ?? []

  const isFetchingCategories = useAppSelector(fetchAllCategoriesByTransportModeSelector.isFetching)
  const isFetchingClasses = useAppSelector(fetchAssetClassesByCategorySelector.isFetching)
  const isFetchingTypes = useAppSelector(fetchAssetTypesByCategorySelector.isFetching)

  const assetCategories = useAppSelector(fetchSortedAssetCategoriesByTransportModeSelector)
  const assetClasses = useAppSelector(fetchSortedAssetClassByCategorySelector)
  const assetTypes = useAppSelector(fetchSortedAssetTypesByCategorySelector)

  const fetchAssetCategoriesByTransportId = useCallback(
    async (tenantId: number, transportModeId: number) => {
      dispatch(fetchAllAssetCategoriesByTransportModeThunk(tenantId, transportModeId))
    },
    [dispatch]
  )

  useEffect(() => {
    void fetchAssetCategoriesByTransportId(tenantId, 2)
  }, [fetchAssetCategoriesByTransportId])

  const fetchTransportModes = useCallback(async () => {
    dispatch(fetchAllTransportModesThunk())
  }, [dispatch])

  useEffect(() => {
    void fetchTransportModes()
  }, [fetchTransportModes])

  const fetchClassesAndTypesByCategoryId = useCallback(
    async (assetCategoryId: number) => {
      if (!assetCategoryId) return
      dispatch(fetchAssetClassesByCategoryThunk(tenantId, assetCategoryId))
      dispatch(fetchAssetTypesByCategoryThunk(tenantId, assetCategoryId))
    },
    [dispatch, assetCategories]
  )

  const create = type === 'create'
  const edit = type === 'edit'

  const { formatMessage } = useIntl()

  const initialValues = () => {
    const initialValues: IAssetFormValues = {
      transportMode: 0,
      assetCategories: assetCategories,
      assetClasses: assetClasses,
      assetTypes: assetTypes,
      assetClassId: defaultValues?.assetClassId || null,
      assetTypeId: defaultValues?.assetTypeId || null,
      identification: defaultValues?.identification || '',
      tenantId: tenantId,
    }

    return initialValues
  }

  const formik = useFormik({
    initialValues: initialValues(),
    onSubmit: async ({ assetClassId, assetTypeId, identification, tenantId }) => {
      const asset = {
        ...(edit && { assetClassId: defaultValues?.assetClassId }),
        assetClassId,
        assetTypeId,
        identification,
        tenantId,
      }

      if (create) {
        try {
          await postAsset(tenantId, asset)
        } catch (e) {
          logger.error(e)
        }
      }
    },
  })

  const getError = (propertyName: string): boolean => {
    const touched = getProperty(formik.touched, propertyName)
    const errorMessage = getProperty(formik.errors as unknown, propertyName)
    return touched && (errorMessage as any)
  }

  const handleTransportModeChange = (e: any, name: string, newValue: number) => {
    formik.setFieldValue(name, newValue)
  }

  const handleCategoriesChange = (e: any, name: string, newValue: number) => {
    formik.setFieldValue('assetCategoryId', newValue)
    fetchClassesAndTypesByCategoryId(newValue)
  }

  function handleAssetClassChange(event: any, name: string, newValue: number): void {
    formik.setFieldValue('assetClassId', newValue)
    fetchClassesAndTypesByCategoryId(newValue)
  }

  function handleAssetTypeChange(event: any, name: string, newValue: number): void {
    formik.setFieldValue('assetTypeId', newValue)
    fetchClassesAndTypesByCategoryId(newValue)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box padding={3}>
        <Box>
          <FormDropdown
            id='transportMode'
            items={transportModes}
            label={formatMessage(messages.assetsTransportMode)}
            onChange={(e, name, newValue) => {
              handleTransportModeChange(e, name, newValue)
            }}
            sx={{ margin: '10px 0' }}
            error={false}
            value={formik.values.transportMode}
          />
        </Box>

        <Box>
          <FormDropdown
            id='assetCategoryId'
            items={assetCategories}
            disabled={isFetchingCategories}
            label={formatMessage(messages.assetsCategories)}
            onChange={(e, name, newValue) => {
              handleCategoriesChange(e, name, newValue)
            }}
            sx={{ margin: '10px 0' }}
            error={false}
            value={formik.values.assetCategories}
          />
        </Box>
        <Box>
          <FormDropdown
            id='assetClassId'
            items={assetClasses}
            disabled={isFetchingClasses}
            label={formatMessage(messages.assetsClass)}
            onChange={handleAssetClassChange}
            sx={{ margin: '10px 0' }}
            error={false}
            value={formik.values.assetClasses}
          />
        </Box>

        <Box>
          <FormDropdown
            id='assetTypeId'
            items={assetTypes}
            disabled={isFetchingTypes}
            label={formatMessage(messages.assetsType)}
            onChange={handleAssetTypeChange}
            sx={{ margin: '10px 0' }}
            error={false}
            value={formik.values.assetTypes}
          />
        </Box>

        <Box marginBottom={5}>
          <TextField
            id='identification'
            name='identification'
            label={'Asset Identification'}
            sx={{ width: '100%', borderColor: 'red', borderBlockColor: 'red' }}
            onChange={formik.handleChange}
            value={formik.values.identification}
            onBlur={formik.handleBlur}
            error={getError('identification')}
            helperText={getError('identification')}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        </Box>

        {!disabled && (
          <Button color='secondary' variant='contained' type='submit'>
            {create ? formatMessage(messages.submit) : formatMessage(messages.update)}
          </Button>
        )}
      </Box>
    </form>
  )
}

export default AssetForm
