import { type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../assets/loading.gif';
import { MutationSpinnerBoxCenter } from './styles';

const MutationSpinner = (): ReactElement<any, any> => {
    const isLoading = useSelector((state: any) => state.loading.isLoading);

    if (!isLoading) return <></>;

    return (
        <MutationSpinnerBoxCenter>
            <img src={Loading} alt="Loading..." />
        </MutationSpinnerBoxCenter>
    );
}

export default MutationSpinner
