import { defineMessages } from 'react-intl'

const messages = defineMessages({
  contacts: {
    id: 'contacts.contacts',
    defaultMessage: 'Contact Types',
    description: 'The label for the contact type tab',
  },
  contactCategories: {
    id: 'contacts.contactCategories',
    defaultMessage: 'Contact Categories',
    description: 'The label for the contact categories tab',
  },
  contactCategory: {
    id: 'contacts.contactCategory',
    defaultMessage: 'Contact Category',
    description: 'The label for the contact category',
  },
  newContactBtn: {
    id: 'contacts.newContactBtn',
    defaultMessage: 'New Contact Type',
    description: 'The label for the new contact button',
  },
  editBtn: {
    id: 'contacts.editBtn',
    defaultMessage: 'Edit',
    description: 'The label for the edit button',
  },
  viewBtn: {
    id: 'contacts.viewBtn',
    defaultMessage: 'View',
    description: 'The label for the view button',
  },
  newContactCategoryBtn: {
    id: 'contacts.newContactCategoryBtn',
    defaultMessage: 'New Contact Category',
    description: 'The label for the new contact category button',
  },
  contactTypeCreated: {
    id: 'contacts.contactTypeCreated',
    defaultMessage: 'Contact Type Created',
    description: 'The message displayed when a contact type is created',
  },
  contactTypeNotCreated: {
    id: 'contacts.contactTypeNotCreated',
    defaultMessage: 'Contact Type Not Created',
    description: 'The message displayed when a contact type is not created',
  },
  contactTypeUpdated: {
    id: 'contacts.contactTypeUpdated',
    defaultMessage: 'Contact Type Updated',
    description: 'The message displayed when a contact type is updated',
  },
  contactTypeNotUpdated: {
    id: 'contacts.contactTypeNotUpdated',
    defaultMessage: 'Contact Type Not Updated',
    description: 'The message displayed when a contact type is not updated',
  },
  createEditContactType: {
    id: 'contacts.createEditContactType',
    defaultMessage: 'Create/Edit Contact Type',
    description: 'The label for the create/edit contact type form',
  },
  contactTypeName: {
    id: 'contacts.contactTypeName',
    defaultMessage: 'Contact Type Name',
    description: 'The label for the contact type name',
  },
  cancelBtn: {
    id: 'contacts.cancelBtn',
    defaultMessage: 'Cancel',
    description: 'The label for the cancel button',
  },
  inUse: {
    id: 'contacts.inUse',
    defaultMessage: 'In Use',
    description: 'The label for the in use column',
  },
  contactTypeDeleted: {
    id: 'contacts.contactTypeDeleted',
    defaultMessage: 'Contact Type Deleted',
    description: 'The message displayed when a contact type is deleted',
  },
  contactTypeNotDeleted: {
    id: 'contacts.contactTypeNotDeleted',
    defaultMessage: 'Contact Type Not Deleted',
    description: 'The message displayed when a contact type is not deleted',
  },
  contactCategoryCreated: {
    id: 'contacts.contactCategoryCreated',
    defaultMessage: 'Contact Category Created',
    description: 'The message displayed when a contact category is created',
  },
  contactCategoryNotCreated: {
    id: 'contacts.contactCategoryNotCreated',
    defaultMessage: 'Contact Category Not Created',
    description: 'The message displayed when a contact category is not created',
  },
  contactCategoryUpdated: {
    id: 'contacts.contactCategoryUpdated',
    defaultMessage: 'Contact Category Updated',
    description: 'The message displayed when a contact category is updated',
  },
  contactCategoryNotUpdated: {
    id: 'contacts.contactCategoryNotUpdated',
    defaultMessage: 'Contact Category Not Updated',
    description: 'The message displayed when a contact category is not updated',
  },
  contactCategoryDeleted: {
    id: 'contacts.contactCategoryDeleted',
    defaultMessage: 'Contact Category Deleted',
    description: 'The message displayed when a contact category is deleted',
  },
  contactCategoryNotDeleted: {
    id: 'contacts.contactCategoryNotDeleted',
    defaultMessage: 'Contact Category Not Deleted',
    description: 'The message displayed when a contact category is not deleted',
  },
})
export default messages
