import RemoveIcon from '@mui/icons-material/HighlightOff';
import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

interface RemoveButtonProps extends ButtonProps {
    text: string
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ text, ...props }) => {
    return (
        <Button {...props} startIcon={<RemoveIcon />} color='secondary' variant='outlined'>
            {text}
        </Button>
    )
}

export default RemoveButton
