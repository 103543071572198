import { useAppSelector } from '../../../../../app/hooks'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import AssetConfigurationForm from './AssetConfigurationForm'

const CreateAssetConfigurationModal = () => {
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  return (
    <AssetConfigurationForm
      isDisabled={false}
      assetConfiguration={{
        active: true,
        tenantId,
        transportModeId: 0,
        customerSelectable: false,
        allocations: [
          {
            assetCategoryId: 0,
            assetClassId: 0,
            assetTypeId: 0,
            sequence: 0,
          },
        ],
      }}
    />
  )
}

export default CreateAssetConfigurationModal
