import { Box, Button } from '@mui/material'
import { FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useSideModalContext } from '../../../AssetsManagement/modals/SideModalContext'
import { useJourneyFormContext } from '../../hooks/useJourneyFormContext'
import useJourneyStepperContext from '../../hooks/useJourneyStepperContext'
import { messages } from '../../messages'

type SubmitButtonProps = {
    disabled?: boolean

}

const SubmitButtons = ({
    disabled = false,
}: SubmitButtonProps) => {
    const { formatMessage } = useIntl()
    const { onClose } = useSideModalContext()
    const { submitForm, validateForm, setTouched, setFieldValue, isSubmitting } = useJourneyFormContext();
    const { currentStep } = useJourneyStepperContext()

    const handleSubmit = () => {
        validateForm().then((errors: FormikErrors<any>) => {
            submitForm().finally(() => {
                setTouched(setNestedObjectValues(errors, true))
            })
        })
    }

    return !disabled ? (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', zIndex: 9999 }}>
            <Button color='secondary' variant='outlined' onClick={onClose} disabled={isSubmitting}>
                {formatMessage(messages.cancel)}
            </Button>
            <Button color='secondary' variant='contained' disabled={isSubmitting} onClick={() => {
                setFieldValue('isDraft', true)
                handleSubmit()
            }}>
                {formatMessage(messages.saveDraft)}
            </Button>
            {currentStep === 3 && (<Button color='secondary' variant='contained' disabled={isSubmitting} onClick={() => {
                setFieldValue('isDraft', false)
                handleSubmit()
            }}>
                {formatMessage(messages.approveForTransport)}
            </Button>)}

        </Box>
    ) : (
        <></>
    )
}

export default SubmitButtons
