import { Box } from '@mui/material';
import { MRT_RowSelectionState as MRTRowSelectionState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/hooks';
import { useGetRolesQuery } from '../../../../app/redux-fetch/apiQuery';
import CommonButton from '../../../../components/Buttons/CommonButton';
import RemoveButton from '../../../../components/Buttons/RemoveButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography';
import { fetchLoggedInUserSelector } from '../../../selectors';
import { ILoggedInUser } from '../../../types';
import { IRole } from '../../RoleManagement/types';
import { useUserGroupFormContext } from './hooks/useUserGroupFormContext';
import messages from './messages';

type UserGroupRolesProps = {
    isDisabled: boolean
}

const UserGroupRoles = ({ isDisabled }: UserGroupRolesProps) => {

    const { values, setFieldValue } = useUserGroupFormContext()
    const { roles: groupRoles } = values
    const [isEditMembersMode, setIsEditMembersMode] = useState(false)
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId, customerId } = loggedInUser
    const { formatMessage } = useIntl()

    const { data: allRoles, isSuccess } = useGetRolesQuery({
        tenantId,
        customerId: customerId
    });

    const [selectedMembers, setSelectedMembers] = useState<MRTRowSelectionState>({})

    useEffect(() => {
        if (isEditMembersMode) {
            const selectedRoles = allRoles?.filter((role: IRole) => {
                return role.id && selectedMembers[role.id]
            })
            setFieldValue('roles', selectedRoles ?? [])

        }
    }, [selectedMembers])

    const roles = useMemo(() => {
        if (isEditMembersMode && allRoles) {
            return allRoles.map((role: IRole) => {
                return {
                    ...role,
                }
            }) ?? [];
        }
        return groupRoles?.map((role: IRole) => {
            return {
                ...role,
            }
        }) ?? [];
    }, [allRoles, isEditMembersMode, groupRoles]);

    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'name',
                header: formatMessage(messages.nameLabel)
            },
            {
                accessorFn: (row: any) => {
                    return row.description
                },
                header: formatMessage(messages.descriptionLabel)
            }
        ];
    }, []);

    return (
        <>
            <Box sx={{
                zIndex: 1,
                paddingBottom: '30px'
            }}>
                <MRTDataGridv2
                    initialState={{
                            sorting: [
                                {
                                    id: 'name',
                                    desc: false,
                                }]
                        }
                    }
                    leftHeadingComponent={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <TableTitleTypography sx={{ fontSize: '20px' }}>
                                {formatMessage(messages.tabRoles)}
                            </TableTitleTypography>
                        </Box>
                    }
                    rightHeadingComponent={
                        !isDisabled ? (<Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                            }}
                        >
                            <Box>
                                {!isEditMembersMode ? (
                                    <CommonButton
                                        text={formatMessage(messages.editButton)}
                                        onClick={() => {
                                            const predefinedRoles = groupRoles?.reduce((acc, role: IRole) => {
                                                if (role.id) acc[role.id] = true
                                                return acc;
                                            }, {} as Record<number, boolean>);
                                            setIsEditMembersMode(true)
                                            setSelectedMembers(predefinedRoles)
                                        }}
                                    />
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <RemoveButton
                                            text={formatMessage(messages.cancelButton)}
                                            onClick={
                                                () => {
                                                    setIsEditMembersMode(false)
                                                    setSelectedMembers({})
                                                }
                                            }
                                        />
                                    </Box>

                                )}

                            </Box>
                        </Box>) : <></>

                    }
                    columns={columns}
                    data={roles || []}
                    getRowId={(row) => row.id as any}
                    enableRowSelection={isEditMembersMode}
                    onRowSelectionChange={setSelectedMembers}
                    state={{
                        rowSelection: selectedMembers
                    }}
                    positionActionsColumn='last'
                />
            </Box>

        </>
    );
}

export default UserGroupRoles;