import { Box } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import EmbeddedContainer from '../components/EmbeddedContainer'
import { messages } from '../messages'
import { Journey, Route } from '../types'
import { formatDate, getLegsForRoute, getLoadsForRoute, getLocationByDestinationActionTypeId, getUniqueCarriersForRoute } from '../utils'


type EmbeddedRoutesTableProps = {
    routes: Route[]
    journey: Journey
}

const EmbeddedRoutesTable = ({ routes, journey }: EmbeddedRoutesTableProps) => {
    const { formatMessage } = useIntl()

    const columns = useMemo<MRTColumnDef<Route>[]>(() => {
        return [
            {
                accessorFn: (row) => {
                    return `Route ${row.id}`
                },
                header: formatMessage(messages.routesName)
            },
            {
                accessorFn: (row) => {
                    const carrier = getUniqueCarriersForRoute(journey, row.id)
                    return carrier?.name
                },
                header: formatMessage(messages.carrierName)
            },
            {
                accessorFn: (row) => {
                    const pickupLocation = getLocationByDestinationActionTypeId(row, 1);
                    return pickupLocation ? pickupLocation.name : ''
                },
                header: formatMessage(messages.pickupLocation)
            },
            {
                accessorFn: (row) => {
                    const pickupDate = formatDate(row.destinations[0]?.scheduledDate)
                    return pickupDate
                },
                header: formatMessage(messages.pickupDate)
            },
            {
                accessorFn: (row) => {
                    const deliveryLocation = getLocationByDestinationActionTypeId(row, 2);
                    return deliveryLocation ? deliveryLocation.name : ''
                },
                header: formatMessage(messages.deliveryLocation)
            },
            {
                accessorFn: (row) => {
                    const deliveryDate = formatDate(row.destinations[row.destinations.length - 1]?.scheduledDate)
                    return deliveryDate
                },
                header: formatMessage(messages.deliveryDate)
            },
            {
                accessorFn: (row) => {
                    const loads = getLoadsForRoute(row)
                    return loads.reduce((acc, item) => {
                        if (acc === '') {
                            return `Load ${item.id.toString()}`
                        }
                        return acc + ` / Load ${item.id}`
                    }, '')
                },
                header: formatMessage(messages.loadsName)
            },
            {
                accessorFn: (row) => {
                    const legs = getLegsForRoute(journey, row.id)
                    return legs.reduce((acc, item) => {
                        if (acc === '') {
                            return `Leg ${item.id}`
                        }
                        return acc + ` / Leg ${item.id}`
                    }, '')
                },
                header: formatMessage(messages.legName)
            }

        ]
    }, [journey])


    return (
        <EmbeddedContainer>
            <Box sx={{
                width: '100%',
                height: '100%',
            }}>
                <MRTDataGridv2
                    columns={columns}
                    data={routes || []}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableSorting={false}
                    enableBottomToolbar={false}
                    enableTopToolbar={false}
                    positionActionsColumn='last'
                />
            </Box>

        </EmbeddedContainer>
    )
}

export default EmbeddedRoutesTable
