import { Box, Grid } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useIntl } from 'react-intl'
import { useCreateRoleMutation, useUpdateRoleMutation } from '../../../app/redux-fetch/apiQuery'
import SubmitButtons from '../../../components/Buttons/SubmitButtons'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import { useNotificationStack } from '../../Shipments/hooks/useNotificationStack'
import MainInfo from './MainInfo'
import { getRoleValidationSchema } from './RoleValidationSchema'
import SecurityPrincipals from './SecurityPrincipal'
import messages from './messages'
import { IRole } from './types'

type RoleFormProps = {
  role: IRole
  isDisabled: boolean
}

const RoleForm = ({ isDisabled, role }: RoleFormProps) => {
  const isNew = !role.id

  const { formatMessage } = useIntl()

  const validationSchema = getRoleValidationSchema(formatMessage)
  const { onClose } = useSideModalContext()
  const [createRole] = useCreateRoleMutation()
  const [updateRole] = useUpdateRoleMutation()

  const { enqueue } = useNotificationStack()

  return (
    <Formik
      initialValues={role}
      onSubmit={async (values: FormikValues) => {
        const securityPrincipalAllocations = values?.securityPrincipals.map((sp: any) => {
          return {
            roleId: values.id,
            securityPrincipalId: sp.id,
          }
        })
        if (!values.id) {
          await createRole({
            tenantId: values.tenantId ?? -1,
            customerId: values.customerId,
            body: { ...values, securityPrincipalAllocations } as IRole,
          })
          enqueue(formatMessage(messages.roleCreated))
        } else {
          await updateRole({
            tenantId: values.tenantId ?? -1,
            customerId: values.customerId,
            body: { ...values, securityPrincipalAllocations } as IRole,
          })
          enqueue(formatMessage(messages.roleUpdated))
        }
        onClose()
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Box sx={{ m: '32px' }}>
          <Box sx={{ width: '100%', p: '40px' }}>
            <Grid item xs={12}>
              <MainInfo isDisabled={isDisabled} />
            </Grid>
            <Grid item xs={12}>
              <SecurityPrincipals isDisabled={isDisabled} />
            </Grid>
          </Box>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              padding: '16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            {!isDisabled && <SubmitButtons create={isNew} />}
          </Box>
        </Box>
      </Form>
    </Formik>
  )
}

export default RoleForm
