import { Box, Grid, Tab, Tabs, ThemeProvider, createTheme, useTheme } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useCreateUserMutation, useUpdateUserMutation } from '../../../../app/redux-fetch/apiQuery'
import SubmitButtons from '../../../../components/Buttons/SubmitButtons'
import TabPanel from '../../../../components/TabPanel/TabPanel'
import { useSideModalContext } from '../../../AssetsManagement/modals/SideModalContext'
import { useNotificationStack } from '../../../Shipments/hooks/useNotificationStack'
import { IUser } from '../types'
import MainInfo from './MainInfo'
import UserGroupTable from './UserGroupTable'
import { getUserValidationSchema } from './UserValidationSchema'
import messages from './messages'

type UserFormProps = {
  user: IUser
  isDisabled: boolean
}

const UserForm = ({ user, isDisabled }: UserFormProps) => {
  const isNew = !user.id

  const { formatMessage } = useIntl()
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()

  const validationSchema = getUserValidationSchema(formatMessage)

  const { onClose } = useSideModalContext()

  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  const { enqueueMsg, enqueue } = useNotificationStack()

  let theme = useTheme()

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })
  return (
    <Formik
      initialValues={user}
      onSubmit={async (values: FormikValues) => {
        if (!values.id) {
          await createUser({
            tenantId: values.tenantId ?? -1,
            body: { ...values } as IUser,
          })
          enqueue(formatMessage(messages.userCreated))
        } else {
          await updateUser({
            tenantId: values.tenantId ?? -1,
            body: { ...values } as IUser,
          })

          enqueue(formatMessage(messages.userUpdated))
        }
        onClose()
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Box sx={{ m: '32px' }}>
          <Box sx={{ width: '100%', p: '40px' }}>
            <ThemeProvider theme={theme}>
              <Tabs
                sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                value={activeTab}
                onChange={handleChange}
                centered
                variant='standard'
                scrollButtons={false}
              >
                <Tab label='General' />
                <Tab label='Groups' />
              </Tabs>
            </ThemeProvider>
            <Box />
            <TabPanel value={activeTab} index={0}>
              <Grid item xs={12}>
                <MainInfo isDisabled={isDisabled} />
              </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Grid item xs={12}>
                <UserGroupTable isDisabled={isDisabled} />
              </Grid>
            </TabPanel>
          </Box>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              padding: '16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            {!isDisabled && <SubmitButtons create={isNew} />}
          </Box>
        </Box>
      </Form>
    </Formik>
  )
}

export default UserForm
