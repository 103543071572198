import { Divider, FormControlLabel, Grid, Switch } from '@mui/material'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'

import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import ValuePairControl from '../../../../components/ValuePairControl/ValuePairControl'
import ValueUnitControl from '../../../../components/ValueUnitControl/ValueUnitControl'
import {
  fetchAllTemperatureRangesSelector,
  fetchAllTemperatureUnitsSelector,
} from '../../selectors'
import { ILoad } from '../../types'
import messages from './messages'
import { type ILoadDetailsProps } from './types'

interface TemperatureSettingProps extends ILoadDetailsProps {
  path: string
}

const TemperatureSetting = (props: TemperatureSettingProps): ReactElement => {
  const { formatMessage } = useIntl()
  const { formik, index, load, disabled, handleSwitchLoadChanges, path, handleSelectLoadChanges } =
    props

  const getError = (propertyName: string): boolean => {
    const temperatureSetting =
      formik.errors.loads && (formik.errors.loads[index] as unknown as ILoad)?.temperatureSetting
    const touchedTemperatureSetting = formik.touched.loads?.[index]?.temperatureSetting
    const touched =
      touchedTemperatureSetting && getProperty(touchedTemperatureSetting, propertyName)
    const errorMessage = temperatureSetting && getProperty(temperatureSetting, propertyName)
    // eslint-disable-next-line
    return touched && (errorMessage as any)
  }

  const tempUnitItems = useAppSelector(fetchAllTemperatureUnitsSelector)
  const temperatureRangesItems = useAppSelector(fetchAllTemperatureRangesSelector.data) ?? []
  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
          {formatMessage(messages.temperatureSettings)}
        </Divider>
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          disabled={disabled}
          control={
            <Switch
              size='small'
              checked={!!load.temperatureSetting?.isTemperatureControlled}
              color='secondary'
              id={`loads.${index}.temperatureSetting.isTemperatureControlled`}
              name={`loads.${index}.temperatureSetting.isTemperatureControlled`}
              onChange={(e, value) => {
                if (value) {
                  handleSwitchLoadChanges(e, value)
                } else {
                  formik.setFieldValue(`loads.${index}.temperatureSetting`, undefined)
                }
              }}
            />
          }
          label={formatMessage(messages.temperatureControlled)}
        />
      </Grid>
      {(formik.values.loads[index]?.temperatureSetting?.isTemperatureControlled ?? false) && (
        <>
          <Grid item xs={4}>
            <FormDropdown
              id={`${path}.temperatureSetting.temperatureRangeId`}
              label={formatMessage(messages.temperatureRange)}
              onChange={handleSelectLoadChanges}
              value={formik.values.loads[index]?.temperatureSetting?.temperatureRangeId}
              items={temperatureRangesItems}
              handleOnBlur={formik.handleBlur}
              errorText={getError('temperatureRangeId')}
              error={Boolean(getError('temperatureRangeId'))}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <ValueUnitControl
            unitId={`loads.${index}.temperatureSetting.temperatureUnitId`}
            valueId={`loads.${index}.temperatureSetting.setPoint`}
            handleOnBlur={formik.handleBlur}
            errorUnit={getError('temperatureUnitId')}
            errorValue={getError('setPoint')}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.temperatureSetting.temperatureUnitId`, newValue)
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.temperatureSetting.setPoint`, newValue)
            }}
            labelUnit={formatMessage(messages.temperatureUnit)}
            labelValue={formatMessage(messages.setPoint)}
            selectedUnitId={formik.values.loads[index].temperatureSetting?.temperatureUnitId ?? 0}
            unitList={tempUnitItems}
            value={formik.values.loads[index].temperatureSetting?.setPoint ?? ''}
            disabled={disabled}
          />
          <ValuePairControl
            leftId={`loads.${index}.temperatureSetting.lowerThresholdWarning`}
            rightId={`loads.${index}.temperatureSetting.upperThresholdWarning`}
            leftError={getError('lowerThresholdWarning')}
            rightError={getError('upperThresholdWarning')}
            handleOnBlur={formik.handleBlur}
            handleChangeOfLeft={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.temperatureSetting.lowerThresholdWarning`,
                newValue
              )
            }}
            handleChangeOfRight={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.temperatureSetting.upperThresholdWarning`,
                newValue
              )
            }}
            labelLeft={formatMessage(messages.lowerThresholdWarning)}
            labelRight={formatMessage(messages.upperThresholdWarning)}
            leftValue={formik.values.loads[index]?.temperatureSetting?.lowerThresholdWarning}
            rightValue={formik.values.loads[index]?.temperatureSetting?.upperThresholdWarning}
            disabled={disabled}
          />
          <ValuePairControl
            leftId={`loads.${index}.temperatureSetting.lowerThresholdCritical`}
            rightId={`loads.${index}.temperatureSetting.upperThresholdCritical`}
            leftError={getError('lowerThresholdCritical')}
            rightError={getError('upperThresholdCritical')}
            handleOnBlur={formik.handleBlur}
            handleChangeOfLeft={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.temperatureSetting.lowerThresholdCritical`,
                newValue
              )
            }}
            handleChangeOfRight={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.temperatureSetting.upperThresholdCritical`,
                newValue
              )
            }}
            labelLeft={formatMessage(messages.lowerThresholdCritical)}
            labelRight={formatMessage(messages.upperThresholdCritical)}
            leftValue={formik.values.loads[index]?.temperatureSetting?.lowerThresholdCritical}
            rightValue={formik.values.loads[index]?.temperatureSetting?.upperThresholdCritical}
            disabled={disabled}
          />
        </>
      )}
    </>
  )
}

export default TemperatureSetting
