import { AxiosInstance } from 'axios'
import {
  assetsAuthorizedFetch,
  coresAuthorizedFetch,
  shipmentsAuthorizedFetch,
  usersAuthorizedFetch,
} from '../axios/omnialog'

export async function fetchData(
  domain: 'Asset' | 'Shipment' | 'User' | 'Core',
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  body?: any
) {
  let authorizedFetch: AxiosInstance

  if (domain === 'Asset') {
    authorizedFetch = assetsAuthorizedFetch
  } else if (domain === 'Shipment') {
    authorizedFetch = shipmentsAuthorizedFetch
  } else if (domain === 'User') {
    authorizedFetch = usersAuthorizedFetch
  } else if (domain === 'Core') {
    authorizedFetch = coresAuthorizedFetch
  } else {
    throw new Error('Invalid domain')
  }

  let result
  if (method === 'GET') {
    result = await authorizedFetch.get(url)
  } else if (method === 'POST') {
    result = await authorizedFetch.post(url, body)
  } else if (method === 'PUT') {
    result = await authorizedFetch.put(url, body)
  } else if (method === 'DELETE') {
    result = await authorizedFetch.delete(url)
  } else {
    throw new Error('Invalid method')
  }

  return { data: result.data.data }
}
