import * as yup from 'yup'

const detail = yup.object({
  brandId: yup.number().positive(),
  manufacturerId: yup.number().positive(),
  model: yup.string(),
  productionYear: yup
    .number()
    .positive()
    .typeError('Production year must be a number')
    .max(
      new Date().getFullYear(),
      `Production year cannot be later than ${new Date().getFullYear()}`
    )
    .min(1900, 'Production year cannot be earlier than 1900'),
  numberOfDoors: yup.number().positive(),
  chassisNumber: yup.string(),
  registrationNumber: yup.string(),
  registrationDate: yup.date(),
  registrationExpiry: yup.date().min(yup.ref('registrationDate')),
  insuranceExpiry: yup.date(),
  operationCardExpiry: yup.date(),
  mvpiExpiry: yup.date(),
  maintenanceEndDate: yup.date(),
  isOwned: yup.boolean().required(),
})

const physicalProperties = yup.object({
  weightMeasureUnitId: yup.number().positive(),
  volumeMeasureUnitId: yup.number().positive(),
  lengthMeasureUnitId: yup.number().positive(),
  kerbWieght: yup.number().positive(),
  grossWeight: yup.number().positive(),
  permissibleLoad: yup.number().positive(),
  internalVolume: yup.number().positive(),
  internalHeight: yup.number().positive(),
  internalLength: yup.number().positive(),
  internalWidth: yup.number().positive(),
  radius: yup.number().positive(),
  clearLoadingHeight: yup.number().positive(),
})

const portPass = yup.object({
  portName: yup.string().required(),
  issueDate: yup.date().required(),
  expiryDate: yup.date().required().min(yup.ref('issueDate')),
})

export const assetManagementCreateSchema = (): yup.AnyObjectSchema => {
  return yup
    .object({
      assetClassId: yup
        .number()
        .required('Asset Class is required')
        .positive('Asset Class is required'),
      assetTypeId: yup
        .number()
        .required('Asset Type is required')
        .positive('Asset Type is required'),
      transportModeId: yup.number().required('Transport mode ID is required'),
      assetCategoryId: yup
        .number()
        .required('Asset Category is required')
        .positive('Asset Category is required'),
      identification: yup.string().required('Fleet Number is required'),
      detail,
      physicalProperties,
    })
    .required('The entire object is required')
}

export const assetManagementUpdateSchema = (): yup.AnyObjectSchema => {
  return yup
    .object({
      id: yup.number().required().positive(),
    })
    .concat(assetManagementCreateSchema())
    .required()
}
