import { Box, Link, Typography, alpha } from '@mui/material'
import dayjs from 'dayjs'
import { MRT_ColumnDef as MRTColumnDef, MRT_Row as MRTRow } from 'material-react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from '../../app/hooks/useFormContext'
import {
  useGetDocumentCategoryQuery,
  useGetDocumentsByEntityIdQuery,
} from '../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { SectionTitleTypography } from '../../components/Typographies/styles'
import { useDocumentHandle } from '../AssetsManagement/hooks/useDocumentHandle'
import DocumentFormModal from './DocumentFormModal'
import { IDocument } from './types'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'

type DocumentsProps = {
  categoryId: number
  entityId?: number
  isDisabled?: boolean
}

const Documents = ({ categoryId, entityId, isDisabled = false }: DocumentsProps) => {
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const { values, setFieldValue } = useFormContext()

  const { data: documents, isSuccess: loaded } = useGetDocumentsByEntityIdQuery(entityId ?? 0, {
    refetchOnMountOrArgChange: true,
  })

  const { data: documentCategory } = useGetDocumentCategoryQuery(categoryId)

  const columns = useMemo<MRTColumnDef<IDocument>[]>(() => {
    return [
      {
        id: 'name',
        accessorFn: (row) => {
          return <Typography sx={{ fontWeight: 700 }}>{row.name}</Typography>
        },
        header: 'Name',
      },
      {
        accessorFn: (row) => {
          return dayjs(row.expiryDate).format('DD MMM YYYY')
        },
        header: 'Expiry Date',
      },
      {
        accessorFn: (row) => {
          return documentCategory?.documentTypes?.find((type) => type.id === row.documentTypeId)
            ?.name
        },
        header: 'Document Type',
      },
    ]
  }, [documentCategory])

  const [selectedDocument, setSelectedDocument] = useState(-1)

  const getDocumentToEdit = useCallback(() => {
    if (selectedDocument > -1) {
      return values.documents[selectedDocument]
    }
    return undefined
  }, [values.documents, selectedDocument])

  useEffect(() => {
    setFieldValue('documents', documents ?? [])
  }, [documents])

  const { removeDocument } = useDocumentHandle()

  const handleDelete = (rowId: any) => {
    const updatedDocuments = [...values.documents]
    const removedDocuments = updatedDocuments.splice(rowId, 1)
    if (removedDocuments.length > 0) {
      removedDocuments[0].fileId && removeDocument(removedDocuments[0].fileId)
    }
    setFieldValue('documents', updatedDocuments)
  }

  const openDeleteConfirmModal = (row: MRTRow<IDocument>) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      handleDelete(row.index)
    }
  }
  return (
    <Box sx={{ pt: '16px' }}>
      {createOpen && (
        <DocumentFormModal
          isOpen={createOpen}
          categoryId={categoryId}
          handleCancel={function (): void {
            setCreateOpen((pv) => !pv)
          }}
          handleSubmit={(document) => {
            setFieldValue('documents', [...values.documents, document])
            setCreateOpen((pv) => !pv)
            setSelectedDocument(-1)
          }}
        />
      )}
      {editOpen && (
        <DocumentFormModal
          categoryId={categoryId}
          isOpen={editOpen}
          handleCancel={function (): void {
            setEditOpen((pv) => !pv)
          }}
          documentToEdit={getDocumentToEdit()}
          handleSubmit={(document) => {
            setFieldValue(`documents.${selectedDocument}`, document)
            setEditOpen((pv) => !pv)
            setSelectedDocument(-1)
          }}
        />
      )}
      {viewOpen && (
        <DocumentFormModal
          categoryId={categoryId}
          isOpen={viewOpen}
          documentToEdit={getDocumentToEdit()}
          handleCancel={function (): void {
            setViewOpen((pv) => !pv)
          }}
        />
      )}
      <Box>
        <MRTDataGridv2
          initialState={{
            sorting: [
              {
                id: 'name',
                desc: false,
              },
            ],
          }}
          state={{ isLoading: !loaded }}
          leftHeadingComponent={<SectionTitleTypography>Documents</SectionTitleTypography>}
          rightHeadingComponent={
            <AddButton disabled={isDisabled} text={'New'} onClick={() => setCreateOpen(true)} />
          }
          columns={columns}
          data={values.documents ?? []}
          getRowId={(row) => row.id as any}
          enableEditing={true}
          enableRowActions
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={true}
          enableSorting={false}
          enableBottomToolbar={true}
          enableTopToolbar={false}
          positionActionsColumn='last'
          muiTableBodyProps={{
            sx: (theme) => ({
              '& tr:nth-of-type(odd)': {
                backgroundColor: alpha(theme.palette.secondary.main, 0.08),
              },
            }),
          }}
          muiTableHeadCellProps={{
            sx: {
              color: '#636363',
              fontSize: '14px',
            },
          }}
          renderRowActions={({ row }: any) => {
            return (
              <>
                <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  <ConditionalRender condition={!isDisabled}>
                    <Link
                      underline='none'
                      color='secondary'
                      onClick={() => {
                        setSelectedDocument(row.index)
                        setEditOpen(true)
                      }}
                      sx={{
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                    >
                      {'Edit'}
                    </Link>
                    <Typography color='secondary'>|</Typography>
                  </ConditionalRender>
                  <Link
                    underline='none'
                    color='secondary'
                    onClick={() => {
                      setSelectedDocument(row.index)
                      setViewOpen(true)
                    }}
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    {'View'}
                  </Link>
                  <ConditionalRender condition={!isDisabled}>
                    <Typography color='secondary'>|</Typography>
                    <Link
                      underline='none'
                      color='secondary'
                      onClick={() => {
                        openDeleteConfirmModal(row)
                        setSelectedDocument(-1)
                      }}
                      sx={{
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                    >
                      {'Del'}
                    </Link>
                  </ConditionalRender>
                </Box>
              </>
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default Documents
