import { Box, Grid, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import type { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import { useGetUnassignedRoutesQuery } from '../../../../app/redux-fetch/apiQuery'
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ICreateTransportForm, ITransportRouteLoad } from '../../types'
import { messages } from '../messages'

interface IRoutesTableProps {
  formik: FormikProps<ICreateTransportForm>
}

const RouteLoadsTable = ({ formik }: IRoutesTableProps) => {
  const { formatMessage } = useIntl()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const tenantId = loggedInUser?.tenantId ?? -1

  const { data: routes, isFetching } = useGetUnassignedRoutesQuery({ tenantId })

  const columns = useMemo<MRTColumnDef<ITransportRouteLoad>[]>(() => {
    return [
      {
        accessorFn: (row) => row.description ?? 'Test 1',
        header: 'Description',
      },
      {
        accessorFn: (row) =>
          `${row.grossWeight ? row.grossWeight : ''} ${row.weightUnit ? row.weightUnit : ''}`,
        header: 'Weight',
      },
      {
        accessorFn: (row) =>
          row.containerNumbers && row.containerNumbers.length > 0
            ? row.containerNumbers.reduce((acc: any, curr: any) => {
              return `${acc ? `${acc}, ` : ''}${curr}`
            }, '')
            : '',
        header: 'Container Number',
      },
      {
        accessorFn: (row) =>
          `${row.setPoint ? row.setPoint : ''} ${row.temperatureUnit ? row.temperatureUnit : ''}`,
        header: 'Set Point',
      },
    ]
  }, [formik.values.route.loads])

  const getLocationNames = () => {
    return formik.values.route.locations.map((d) => d.name).join(' > ')
  }

  return (
    <Grid container spacing={2} pt={4}>
      <Grid item xs={12}>
        <Typography sx={{ pl: '8px', fontSize: '18px' }}>Route Destinations</Typography>
        <Typography sx={{ pl: '8px', fontSize: '18px' }}>{getLocationNames()}</Typography>
      </Grid>
      <Grid item xs={12}>
        <MRTDataGridv2
          leftHeadingComponent={
            <>
              <Box sx={{ display: 'inline-flex' }}>
                <TableTitleTypography sx={{ fontSize: '24px' }}>
                  {formatMessage(messages.routeLoad)}
                </TableTitleTypography>
              </Box>
            </>
          }
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
          columns={columns as any}
          data={formik.values.route.loads}
          // eslint-disable-next-line react/prop-types
          getRowId={(row: any) => row.id}
        />
      </Grid>
    </Grid>
  )
}

export default RouteLoadsTable
