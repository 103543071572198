import { Box } from '@mui/material';

type EmbeddedComponentProps = {
    children?: React.ReactNode
}

const EmbeddedContainer = ({ children }: EmbeddedComponentProps) => {
    return (
        <Box
            sx={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    padding: 2,
                    backgroundColor: '#F6F6F6'
                }
            }
        >
            {children}
        </Box>
    );
}

export default EmbeddedContainer;