import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  newDocument: {
    id: 'documents.newDocument',
    description: 'New Document',
    defaultMessage: 'New Document',
  },
  documentName: {
    id: 'documents.documentName',
    description: 'Document Name',
    defaultMessage: 'Document Name',
  },
  expiryDate: {
    id: 'documents.expiryDate',
    description: 'Expiry Date',
    defaultMessage: 'Expiry Date',
  },
  documentType: {
    id: 'documents.documentType',
    description: 'Document Type',
    defaultMessage: 'Document Type',
  },
  cancelBtn: {
    id: 'documents.cancelBtn',
    description: 'Cancel',
    defaultMessage: 'Cancel',
  },
})
