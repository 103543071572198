import { Box } from '@mui/material'
import { useState } from 'react'
import AssetAvailabilityDashboard from './AssetAvailabilityDashboard'
import DashboardHeader from './DashboardHeader'
import MapDashboard from './MapDashboard'
import OperationDashboard from './OperationDashboard'
import ShipmentDashboard from './ShipmentDashboard'
import { DashboardContext, TimeFrame } from './hooks/useDashboard'

const Dashboard = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

  const [timeFrame, setTimeFrame] = useState<TimeFrame>({
    startDate: startDate,
    endDate: currentDate
  })

  return (
    <DashboardContext.Provider value={{ timeFrame, setTimeFrame }}>
      <Box>
        <DashboardHeader />
        <Box display="flex" flexWrap="wrap" gap={2}>
          <ShipmentDashboard />
          <MapDashboard />
          <AssetAvailabilityDashboard />
          <OperationDashboard />
        </Box>
      </Box>

    </DashboardContext.Provider>
  )
}

export default Dashboard
