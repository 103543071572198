import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IRole, ISecurityPrincipal } from '../../features/CommonSettings/RoleManagement/types'
import {
  ITrackingNumberParameterType,
  ITrackingNumberTemplate,
} from '../../features/CommonSettings/TrackingNumber/types'
import { IUserGroup } from '../../features/CommonSettings/UserManagement/UserGroups/types'
import { ICurrency } from '../../features/Currency/types'
import { IIndustry } from '../../features/CustomerSettings/Customer/types'
import { RoutesCount } from '../../features/Dashboard/type'
import { IDocumentCategory } from '../../features/Documents/types'
import {
  IHsHeading,
  IHsLocalTariff,
  IHsSubheading,
  IProduct,
  IProductCategory,
} from '../../features/Products/types'
import { ShipmentNote } from '../../features/Shipments/components/Notes/types'
import {
  ContainerData,
  GetContainerDetailRequest,
} from '../../features/Shipments/components/TerminalInfo/types'
import {
  ILocationByTenantCustomer,
  IShipment,
  ITransportMode,
} from '../../features/Shipments/types'
import { GetTemperatureDataRequest } from '../../features/TemperatureChart/types'
import { IBranding } from '../../features/TenantSettings/types'
import { IUnassginedRoute } from '../../features/Transport/components/Routes/types'
import { ITransportRouteLoad } from '../../features/Transport/types'
import { TransportJob } from '../../features/TransportJob/types'
import { IAssetMeasureUnit, ICustomer } from '../../features/types'
import { OMNILOG_SHIPMENTS_API_URL } from '../axios/const'
import snackbarUtil from '../snackbar/snackbarUtil'
import { IDropDownItem } from '../types'
import { fetchData } from './fetchData'
import { setLoading } from './loadingSlice'

const ExcludeLoadingUrls = ['refresh-inbound-container-status', 'temperature-series']

const apiAxiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: 'GET' | 'POST' | 'PUT' | 'DELETE'
      domain: 'Asset' | 'Shipment' | 'User' | 'Core'
      body?: any
    },
    unknown,
    unknown
  > =>
  async ({ url, method, domain, body }, api) => {
    const { dispatch } = api
    try {
      if (method !== 'GET' && !ExcludeLoadingUrls.some((u) => url.split('/').includes(u))) {
        dispatch(setLoading(true))
      }
      return await fetchData(domain, method, url, body)
    } catch (axiosError) {
      const err = axiosError as AxiosError
      if (err.response?.status !== 404) {
        snackbarUtil.apiError(err.response?.data as any)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    } finally {
      if (method !== 'GET') {
        dispatch(setLoading(false))
      }
    }
  }

export const apiSlice = createApi({
  baseQuery: apiAxiosBaseQuery({
    baseUrl: OMNILOG_SHIPMENTS_API_URL,
  }),
  tagTypes: [
    'Shipments',
    'Carrier',
    'Assets',
    'Loads',
    'Locations',
    'Journeys',
    'Journey',
    'AssetGroup',
    'TrackingNumberParameterTypes',
    'TrackingNumberTemplate',
    'TransportMode',
    'AssetTypes',
    'AssetConfigurations',
    'MeasureUnits',
    'Brands',
    'Customers',
    'Branding',
    'Categories',
    'Country',
    'SubHsHeadings',
    'HsHeadings',
    'Tariffs',
    'Products',
    'Transport Jobs',
    'Shipment Notes',
    'Users',
    'UserGroups',
    'Workforce',
    'Temperature',
    'Roles',
    'Customer',
    'Unassigned Loads',
    'Currency',
  ],
  endpoints(build) {
    return {
      shipmentById: build.query<
        IShipment,
        { shipmentId: number; tenantId: number; customerId?: number }
      >({
        query: ({ shipmentId, tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `/tenant/${tenantId}/${customer}shipment/${shipmentId}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, { shipmentId }) => [{ type: 'Shipments', shipmentId }],
      }),
      trackingNumberParamsTypes: build.query<ITrackingNumberParameterType[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/trackingnumber/parameters`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, { tenantId }) => [
          { type: 'TrackingNumberParameterTypes', tenantId },
        ],
      }),
      trackingNumberTemplate: build.query<ITrackingNumberTemplate, { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/trackingnumber/template`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, { tenantId }) => [
          { type: 'TrackingNumberTemplate', tenantId },
        ],
      }),
      createTrackingNumberTemplate: build.mutation<
        any,
        { tenantId: number; body: ITrackingNumberTemplate }
      >({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/trackingnumber/template`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
      }),

      // useGetTransportModeQuery
      getTransportMode: build.query<ITransportMode[], void>({
        query: () => {
          return {
            url: '/get-all-transport-modes',
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error) => [{ type: 'TransportMode', id: 'any' }],
      }),
      locationById: build.query<
        ILocationByTenantCustomer,
        { locationId: number; tenantId: number; customerId?: number }
      >({
        query: ({ locationId }) => {
          return {
            url: `/location/${locationId}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [{ type: 'Locations', locationId }],
      }),
      loadsByRouteId: build.query<ITransportRouteLoad[], { routeId: number; tenantId: number }>({
        query: ({ routeId, tenantId }) => {
          return {
            url: `/tenant/${tenantId}/route/${routeId}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [{ type: 'Loads', locationId }],
      }),
      getUnassignedRoutes: build.query<IUnassginedRoute[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/route`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [
          { type: 'Loads', locationId },
          { type: 'Loads', id: 'Unassigned Routes' },
        ],
      }),
      // useGetMeasureUnitsQuery
      getMeasureUnits: build.query<IAssetMeasureUnit[], void>({
        query: () => {
          return {
            url: '/get-all-measure-units',
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: () => [{ type: 'MeasureUnits' }],
      }),
      // Get customers by tenantId
      getCustomers: build.query<{ id: number; name: string }[], number>({
        query: (tenantId: number) => ({
          url: `/tenant/${tenantId}/customer`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Customers', id: 'Customers' }],
      }),
      getCustomerById: build.query<ICustomer, { tenantId: number; customerId: number }>({
        query: ({ tenantId, customerId }) => ({
          url: `tenant/${tenantId}/customer/${customerId}`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Customers', id: 'Customers' }],
      }),
      setDefaultBranding: build.mutation<IBranding, number>({
        query: (tenantId: number) => ({
          url: `/tenant/${tenantId}/branding/reset`,
          method: 'POST',
          domain: 'User',
        }),
        invalidatesTags: () => [{ type: 'Branding', id: 'Branding' }],
      }),
      deleteLocation: build.mutation<void, { locationId: number; tenantId: number }>({
        query: ({ locationId, tenantId }) => ({
          url: `tenant/${tenantId}/location/${locationId}`,
          method: 'DELETE',
          domain: 'Shipment',
        }),
        invalidatesTags: () => [{ type: 'Locations', id: 'Locations' }],
      }),
      // Products
      getProducts: build.query<IProduct[], number>({
        query: (customerId: number) => ({
          url: `/customer/${customerId}/product`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Products', id: 'Products' }],
      }),
      getProductById: build.query<IProduct, { customerId: number; productId: number }>({
        query: ({ customerId, productId }) => ({
          url: `/customer/${customerId}/product/${productId}`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Products', id: 'Products' }],
      }),
      createProduct: build.mutation<{ id: number }, { customerId: number; body: IProduct }>({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [{ type: 'Products', id: 'Products' }],
      }),
      updateProduct: build.mutation<{ id: number }, { customerId: number; body: IProduct }>({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product`,
          method: 'PUT',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [{ type: 'Products', id: 'Products' }],
      }),
      getProductCategories: build.query<IProductCategory[], number>({
        query: (customerId: number) => ({
          url: `/customer/${customerId}/product/category`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Categories', id: 'Categories' }],
      }),

      getAllSubHsHeadings: build.query<IHsSubheading[], void>({
        query: () => ({
          url: '/subheading',
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'SubHsHeadings', id: 'SubHsHeadings' }],
      }),
      getProductCategoryById: build.query<
        IProductCategory,
        { customerId: number; categoryId: number }
      >({
        query: ({ customerId, categoryId }) => ({
          url: `/customer/${customerId}/product/category/${categoryId}`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: (result, error, { customerId, categoryId }) => [
          { type: 'Categories', id: categoryId },
        ],
      }),
      getAllHsHeadings: build.query<IHsHeading[], void>({
        query: () => ({
          url: '/hsheading',
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'HsHeadings', id: 'HsHeadings' }],
      }),
      createProductCategory: build.mutation<
        { id: number },
        { customerId: number; body: IProductCategory }
      >({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product/category`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [{ type: 'Categories', id: 'Categories' }],
      }),
      updateProductCategory: build.mutation<
        { id: number },
        { customerId: number; body: IProductCategory }
      >({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product/${body.id}/category`,
          method: 'PUT',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [
          { type: 'Categories', id: body.id },
          { type: 'Categories', id: 'Categories' },
        ],
      }),
      createHsLocalTariff: build.mutation<{ id: number }, { body: IHsLocalTariff }>({
        query: ({ body }) => ({
          url: '/product/category/tariffs/local',
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: () => [{ type: 'Tariffs', id: 'LocalTariffs' }],
      }),
      // Job //
      getTransportJobs: build.query<TransportJob[], number>({
        query: (tenantId) => ({
          url: `/tenant/${tenantId}/transport-job`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Transport Jobs', id: 'Transport Jobs' }],
      }),
      // Job //
      // Notes //
      getNotesById: build.query<ShipmentNote[], number>({
        query: (id: number) => ({
          url: `shipment/${id}/note`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Shipment Notes', id: 'Shipment Notes' }],
      }),
      addNote: build.mutation<any, { body: ShipmentNote }>({
        query: ({ body }) => ({
          url: 'shipment/note',
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
      }),
      // Notes //
      // User Groups //
      getUserGroups: build.query<IUserGroup[], { tenantId: number }>({
        query: ({ tenantId }) => ({
          url: `tenant/${tenantId}/user-group`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'UserGroups', id: 'UserGroups' }],
      }),
      getUserGroupById: build.query<IUserGroup, { userGroupId: number }>({
        query: ({ userGroupId }) => ({
          url: `user-group/${userGroupId}`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'UserGroups', id: 'UserGroups' }],
      }),
      getRoles: build.query<IRole[], { tenantId: number; customerId?: number }>({
        query: ({ tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `/tenant/${tenantId}/${customer}role`,
            method: 'GET',
            domain: 'User',
          }
        },
        providesTags: () => [
          { type: 'Roles', id: 'Roles' },
          { type: 'UserGroups', id: 'UserGroups' },
        ],
      }),
      getRoleById: build.query<IRole, { roleId: number }>({
        query: ({ roleId }) => ({
          url: `role/${roleId}`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Roles', id: 'Roles' }],
      }),
      getSecurityPrincipals: build.query<ISecurityPrincipal[], void>({
        query: () => {
          return {
            url: '/security-principal',
            method: 'GET',
            domain: 'User',
          }
        },
      }),
      createRole: build.mutation<any, { tenantId: number; customerId?: number; body: IRole }>({
        query: ({ tenantId, customerId, body }) => {
          return {
            url: `tenant/${tenantId}/${customerId ? `customer/${customerId}/` : ''}role`,
            method: 'POST',
            domain: 'User',
            body: body,
          }
        },
        invalidatesTags: () => [{ type: 'Roles', id: 'Roles' }],
      }),
      updateRole: build.mutation<any, { tenantId: number; customerId?: number; body: IRole }>({
        query: ({ tenantId, customerId, body }) => {
          return {
            url: `tenant/${tenantId}/${customerId ? `customer/${customerId}/` : ''}role`,
            method: 'PUT',
            domain: 'User',
            body: body,
          }
        },
        invalidatesTags: () => [{ type: 'Roles', id: 'Roles' }],
      }),

      createUserGroup: build.mutation<any, { tenantId: number; customerId?: number; body: any }>({
        query: ({ tenantId, customerId, body }) => {
          return {
            url: `tenant/${tenantId}/${customerId ? `customer/${customerId}/` : ''}user-group`,
            method: 'POST',
            domain: 'User',
            body: body,
          }
        },
        invalidatesTags: () => [{ type: 'UserGroups', id: 'UserGroups' }],
      }),
      updateUserGroup: build.mutation<any, { tenantId: number; customerId?: number; body: any }>({
        query: ({ tenantId, customerId, body }) => {
          return {
            url: `tenant/${tenantId}/${customerId ? `customer/${customerId}/` : ''}user-group`,
            method: 'PUT',
            domain: 'User',
            body: body,
          }
        },
        invalidatesTags: () => [{ type: 'UserGroups', id: 'UserGroups' }],
      }),
      // User Groups //
      // Users //
      createUser: build.mutation<any, { tenantId: number; body: any }>({
        query: ({ tenantId, body }) => ({
          url: `tenant/${tenantId}/user`,
          method: 'POST',
          domain: 'User',
          body: body,
        }),
        invalidatesTags: [
          { type: 'Workforce', id: 'Workforce' },
          { type: 'Users', id: 'Users' },
        ],
      }),
      getUser: build.query<any, { userId: number }>({
        query: ({ userId }) => ({
          url: `user/${userId}`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Users', id: 'Users' }],
      }),

      getUserTypes: build.query<IDropDownItem[], { tenantId: number; customerId?: number }>({
        query: ({ tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `tenant/${tenantId}/${customer}workforce-type`,
            method: 'GET',
            domain: 'User',
          }
        },
        providesTags: () => [{ type: 'Workforce', id: 'Workforce' }],
      }),
      updateUser: build.mutation<any, { tenantId: number; body: any }>({
        query: ({ tenantId, body }) => ({
          url: `tenant/${tenantId}/user`,
          method: 'PUT',
          domain: 'User',
          body: body,
        }),
        invalidatesTags: [
          { type: 'Workforce', id: 'Workforce' },
          { type: 'Users', id: 'Users' },
        ],
      }),
      deleteUser: build.mutation<any, { tenantId: number; userId: number }>({
        query: ({ tenantId, userId }) => ({
          url: `tenant/${tenantId}/user/${userId}`,
          method: 'DELETE',
          domain: 'User',
        }),
        invalidatesTags: [
          { type: 'Workforce', id: 'Workforce' },
          { type: 'Users', id: 'Users' },
        ],
      }),
      // Users //
      getWorkforce: build.query<any, { userId: number }>({
        query: ({ userId }) => ({
          url: `workforce/${userId}`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Users', id: 'Users' }],
      }),
      getUsers: build.query<any, { tenantId: number; customerId?: number }>({
        query: ({ tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `tenant/${tenantId}/${customer}user`,
            method: 'GET',
            domain: 'User',
          }
        },
        providesTags: () => [{ type: 'Users', id: 'Users' }],
      }),
      getWorkforces: build.query<any, { tenantId: number }>({
        query: ({ tenantId }) => ({
          url: `tenant/${tenantId}/workforce`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Workforce', id: 'Workforce' }],
      }),
      // Reports //
      getShipmentsReport: build.query<
        any,
        { tenantId: number; startDate: string; endDate: string }
      >({
        query: ({ tenantId, startDate, endDate }) => {
          const formattedStartDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss')
          const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss')
          const queryParams = new URLSearchParams({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          }).toString()

          return {
            url: `tenant/${tenantId}/shipment/report?${queryParams}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
      }),
      getShipmentsReportByPickupCountry: build.query<
        any,
        { tenantId: number; startDate: string; endDate: string }
      >({
        query: ({ tenantId, startDate, endDate }) => {
          const formattedStartDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss')
          const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss')
          const queryParams = new URLSearchParams({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          }).toString()

          return {
            url: `tenant/${tenantId}/shipments/grouped-pickup-country?${queryParams}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
      }),
      getDriverOperations: build.query<
        any,
        { tenantId: number; startDate: string; endDate: string }
      >({
        query: ({ tenantId, startDate, endDate }) => {
          const formattedStartDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss')
          const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss')
          const queryParams = new URLSearchParams({
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          }).toString()

          return {
            url: `tenant/${tenantId}/drivers/operations?${queryParams}`,
            method: 'GET',
            domain: 'User',
          }
        },
      }),

      getRoutesCount: build.query<RoutesCount, { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `tenant/${tenantId}/reports/routes-count`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
      }),
      getTemperatureData: build.mutation<
        any,
        { tenantId: number; body: GetTemperatureDataRequest }
      >({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/shipment/temperature-series`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: () => [{ type: 'Temperature', id: 'LoadTemperatureData' }],
      }),
      // Industry //
      getIndustries: build.query<IIndustry[], void>({
        query: () => ({
          url: 'industry',
          method: 'GET',
          domain: 'User',
        }),
      }),
      // Industry //
      // Customer //
      postCustomer: build.mutation<any, { tenantId: number; body: ICustomer }>({
        query: ({ tenantId, body }) => ({
          url: `tenant/${tenantId}/customer`,
          method: 'POST',
          domain: 'User',
          body: body,
        }),
        invalidatesTags: () => [{ type: 'Customers', id: 'Customers' }],
      }),
      putCustomerCustomer: build.mutation<any, { tenantId: number; body: ICustomer }>({
        query: ({ tenantId, body }) => ({
          url: `tenant/${tenantId}/customer`,
          method: 'PUT',
          domain: 'User',
          body: body,
        }),
        invalidatesTags: () => [{ type: 'Customers', id: 'Customers' }],
      }),
      getContainerDetails: build.mutation<ContainerData, { body: GetContainerDetailRequest }>({
        query: ({ body }) => ({
          url: 'refresh-inbound-container-status',
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
      }),
      getDocumentCategory: build.query<IDocumentCategory[], { id: number }>({
        query: ({ id }) => ({
          url: `document-category/${id}`,
          method: 'GET',
          domain: 'Core',
        }),
      }),
      getAllTransportModes: build.query<ITransportMode[], void>({
        query: () => ({
          url: 'get-all-transport-modes',
          method: 'GET',
          domain: 'Shipment',
        }),
      }),
      getSystemCurrency: build.query<ICurrency[], void>({
        query: () => ({
          url: 'currency',
          method: 'GET',
          domain: 'Shipment',
        }),
      }),
      // Core //
    }
  },
})

export const {
  useShipmentByIdQuery,
  useLocationByIdQuery,
  useLoadsByRouteIdQuery,
  useGetUnassignedRoutesQuery,
  useTrackingNumberParamsTypesQuery,
  useTrackingNumberTemplateQuery,
  useCreateTrackingNumberTemplateMutation,
  useGetTransportModeQuery,
  useGetMeasureUnitsQuery,
  // Users //
  useGetCustomersQuery,
  useSetDefaultBrandingMutation,
  // Locations //
  useDeleteLocationMutation,
  // Products //
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductCategoriesQuery,
  useGetAllHsHeadingsQuery,
  useGetAllSubHsHeadingsQuery,
  useGetProductCategoryByIdQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useCreateHsLocalTariffMutation,
  // Products //
  // Transport Jobs //
  useGetTransportJobsQuery,
  // Transport Jobs //
  // Notes //
  useGetNotesByIdQuery,
  useAddNoteMutation,
  // Notes //
  // Workforce //
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetWorkforceQuery,
  useGetWorkforcesQuery,
  useGetUserTypesQuery,
  // Workforce //
  // Report //
  useGetShipmentsReportQuery,
  useGetShipmentsReportByPickupCountryQuery,
  useGetDriverOperationsQuery,
  useGetRoutesCountQuery,
  // Report //
  // Temperature Data //
  useGetTemperatureDataMutation,
  // Temperature Data //
  // Industry //
  useGetIndustriesQuery,
  // Industry //
  // Customer //
  usePostCustomerMutation,
  usePutCustomerCustomerMutation,
  useGetCustomerByIdQuery,
  // Customer //
  useGetContainerDetailsMutation,
  // Customer //
  // Users //
  useGetUsersQuery,
  useDeleteUserMutation,
  // Users //
  // User Groups //
  useGetUserGroupsQuery,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useGetUserGroupByIdQuery,
  // User Groups //
  // Roles //
  useGetRolesQuery,
  useGetSecurityPrincipalsQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  // Roles //
  useGetAllTransportModesQuery,
  useGetSystemCurrencyQuery,
  endpoints: apiEndpoints,
} = apiSlice
