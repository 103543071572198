import { useAppSelector } from '../../app/hooks'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import AssetManagementForm from './AssetManagementForm'

const CreateAsset = () => {
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const newAsset = {
    tenantId,
    transportModeId: 0,
    assetCategoryId: 0,
    detail: {
      isOwned: true,
    },
    physicalProperties: {},
    documents: [],
    active: true,
  }
  return <AssetManagementForm asset={newAsset} />
}

export default CreateAsset
