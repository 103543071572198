import { Box, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import { useGetUserTypesQuery } from '../../../../app/redux-fetch/apiQuery'
import { IDropDownItem } from '../../../../app/types'
import RequiredAsterisk from '../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../../components/Typographies/styles'
import { IWorkforceTypes } from '../../../TenantSettings/Components/AssetSettings/types'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { useUserFormContext } from './hooks/useUserFormContext'
import messages from './messages'

type MainInfoProps = {
    isDisabled?: boolean
}

const MainInfo = ({ isDisabled }: MainInfoProps) => {

    const { formatMessage } = useIntl()

    const { values, setFieldValue, errors, touched } = useUserFormContext()

    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { data: userTypes } = useGetUserTypesQuery({
        tenantId,
    })


    const handleTextFieldChange = ({
        target,
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(target.id, target.value)
    }
    const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
        setFieldValue(name, newValue)
    }

    const mapTpDropDownItems = (items: IWorkforceTypes[]) => {
        return items.map((item) => {
            return {
                id: item.id,
                name: item.name,
            } as IDropDownItem
        })
    }

    const { firstName, lastName, email, userTypeId, phone } = values

    return (
        <Box sx={{ width: '50%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography>{formatMessage(messages.mainInfoTitle)}</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    {formatMessage(messages.firstNameLabel)}
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='firstName'
                    value={firstName}
                    defaultValue={firstName}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    {formatMessage(messages.lastNameLabel)}
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='lastName'
                    value={lastName}
                    defaultValue={lastName}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    {formatMessage(messages.emailLabel)}
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    id='email'
                    onChange={handleTextFieldChange}
                    value={email}
                    disabled={isDisabled}
                    fullWidth
                    defaultValue={email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    {formatMessage(messages.phoneLabel)}
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <MuiTelInput
                    fullWidth
                    id={'phone'}
                    onChange={(value: any, i: any) => {
                        setFieldValue('phone', i.numberValue ?? '')
                    }}
                    value={phone ?? ''}
                    helperText={errors?.phone}
                    disabled={isDisabled}
                    error={Boolean(errors?.phone)}
                />
            </Box>
        </Box>
    )
}

export default MainInfo