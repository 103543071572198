/* eslint-disable */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  assetsTransportMode: {
    id: 'assets.transportMode',
    description: 'Transport Mode',
    defaultMessage: 'Transport Mode',
  },
  assetsCategories: {
    id: 'assets.assetsCategories',
    description: 'Asset Categories',
    defaultMessage: 'Asset Categories',
  },
  assetsClass: {
    id: 'assets.assetsClass',
    description: 'Asset Classt',
    defaultMessage: 'Asset Class',
  },
  assetsType: {
    id: 'assets.assetsType',
    description: 'Asset Type',
    defaultMessage: 'Asset Type',
  },
  addAsset: {
    id: 'assets.addAsset',
    description: 'Create new asset',
    defaultMessage: 'Create new asset',
  },
  AllAssetsBtn: {
    id: 'assets.allAssets',
    description: 'Assets',
    defaultMessage: 'Assets',
  },
  name: {
    id: 'assets.name',
    description: 'Name',
    defaultMessage: 'Name',
  },
  model: {
    id: 'assets.model',
    description: 'Model',
    defaultMessage: 'Model',
  },
  plateNumber: {
    id: 'assets.plateNumber',
    description: 'Plate Number',
    defaultMessage: 'Plate Number',
  },
  fleetNumber: {
    id: 'assets.fleetNumber',
    description: 'Fleet Number',
    defaultMessage: 'Fleet Number',
  },
  submit: {
    id: 'assets.submit',
    description: 'Submit',
    defaultMessage: 'Submit',
  },
  update: {
    id: 'assets.update',
    description: 'Update',
    defaultMessage: 'Update',
  },
})

export default messages
