import { BlockBlobClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid'; // To generate a new GUID
import { useGetSASForDeleteFileQuery, useGetSASForReadFileQuery, useGetSASForUploadFileQuery } from '../../../app/redux-fetch/apiCoreQuery';

export const useDocumentHandle = () => {

  const { refetch: getReadSas } = useGetSASForReadFileQuery()
  const { refetch: getUploadSas } = useGetSASForUploadFileQuery()
  const { refetch: getDeleteSas } = useGetSASForDeleteFileQuery()

  return {
    uploadDocument: async (file: File, documentType: string) => {
      const res = await getUploadSas()
      const parts = res.data.split('?')
      const name = `${documentType}.${uuidv4()}.${file.name}`

      const sasUrl = `${parts[0]}/${name}?${parts[1]}`
      const blobClient = new BlockBlobClient(sasUrl)

      const blobOptions = { blobHTTPHeaders: { blobContentType: file.type } }

      try {
        await blobClient.uploadData(file, blobOptions)
      } catch (error) {
        console.error('Error uploading to Azure Blob Storage:', error)
      }
      return name
    },
    viewDocument: async (fileId: string) => {
      if (fileId) {
        const res = await getReadSas()
        if (!res.data) {
          return
        }

        const parts = res.data.split('?')
        const sasUrl = `${parts[0]}/${fileId}?${parts[1]}`

        window.open(sasUrl, '_blank')
      }
    },
    removeDocument: async (fileId: string) => {
      const res = await getDeleteSas()
      if (!res.data) {
        return
      }
      const parts = res.data.split('?')
      const sasUrl = `${parts[0]}/${fileId}?${parts[1]}`

      const blobClient = new BlockBlobClient(sasUrl)

      try {
        await blobClient.delete()
        console.log(`Blob with name: ${fileId} deleted successfully from Azure Blob Storage.`)
        // ... (any other success handling)
      } catch (error) {
        console.error('Error deleting from Azure Blob Storage:', error)
        // ... (any other error handling)
      }
    },
  }
}
