import { Box, Link, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../app/hooks';
import { useGetRolesQuery } from '../../../app/redux-fetch/apiQuery';
import AddButton from '../../../components/Buttons/AddButton';
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2';
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography';
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper';
import { fetchLoggedInUserSelector } from '../../selectors';
import { ILoggedInUser } from '../../types';
import CreateRoleModal from './CreateRoleModal';
import RoleModal from './RoleModal';
import messages from './messages';

const RoleTable = () => {
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId, customerId } = loggedInUser
    const { formatMessage } = useIntl()

    const { data: allRoles, isSuccess } = useGetRolesQuery({
        tenantId,
        customerId: customerId
    }, { refetchOnMountOrArgChange: true });

    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'name',
                header: formatMessage(messages.roleName)
            },
            {
                accessorFn: (row: any) => {
                    return row.description
                },
                header: formatMessage(messages.description)
            },
            {
                accessorFn: (row: any) => {
                    return row.securityPrincipals.length
                },
                header: formatMessage(messages.securityPrincipals)
            }
        ];
    }, []);

    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);

    const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>();

    return (<>
        <SideModalWrapper
            open={createOpen}
            key={'create'}
            headerLabel={formatMessage(messages.addRole)}
            onClose={() => {
                setCreateOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <CreateRoleModal />
        </SideModalWrapper>
        <SideModalWrapper
            open={editOpen}
            key={'edit'}
            headerLabel={formatMessage(messages.editRole)}
            onClose={() => {
                setEditOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <RoleModal isDisabled={false} roleId={parseInt(selectedRoleId ?? '')} />
        </SideModalWrapper>
        <SideModalWrapper
            open={viewOpen}
            key={'view'}
            headerLabel={formatMessage(messages.viewRole)}
            onClose={() => {
                setViewOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <RoleModal isDisabled={true} roleId={parseInt(selectedRoleId ?? '')} />
        </SideModalWrapper>
        <MRTDataGridv2
            state={{
                isLoading: !isSuccess
            }}
            initialState={{
                sorting: [
                {
                  id: 'name',
                  desc: false,
                }]}
            }
            leftHeadingComponent={
                <Box sx={{ display: 'inline-flex' }}>
                    <TableTitleTypography>{formatMessage(messages.title)}</TableTitleTypography>
                </Box>
            }
            rightHeadingComponent={
                <AddButton
                    text={formatMessage(messages.addRole)}
                    onClick={() => setCreateOpen(true)}
                />
            }
            columns={columns}
            data={allRoles || []}
            getRowId={(row) => row.id as any}
            enableEditing
            enableRowActions
            positionActionsColumn='last'
            renderRowActions={({ row, table }: any) => {
                return (
                    <>
                        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <Link

                                underline='none'
                                color='secondary'
                                onClick={() => {
                                    setSelectedRoleId(row.id)
                                    setEditOpen(true)
                                }}
                                sx={{
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                }}
                            >
                                {formatMessage(messages.editRole)}
                            </Link>
                            <Typography color='secondary'>/</Typography>
                            <Link
                                underline='none'
                                color='secondary'
                                onClick={() => {
                                    setSelectedRoleId(row.id)
                                    setViewOpen(true)
                                }}
                                sx={{
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                }}
                            >
                                {formatMessage(messages.viewRole)}
                            </Link>
                        </Box>
                    </>
                )
            }}
        />
    </>)
}

export default RoleTable
