import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'

import { DialogProps as MUIDialogProps } from '@mui/material/Dialog'
import { ReactNode, useEffect, useState } from 'react'
import FixedBottomBox from './FixedBottomBox'
import { SideModalContext } from './SideModalContext'

interface DialogProps extends MUIDialogProps {
  width?: string
}

const Transition = (props: any) => {
  return <Slide direction='left' {...props} />
}

const StyledDialog = styled(Dialog)<DialogProps>(({ theme, width = '62.5%' }) => ({
  '& .MuiDialog-paper': {
    width: width,
    margin: 0,
    height: '100%', // set to full height
    maxHeight: 'none', // override default max height
    maxWidth: 'none', // override default max height
    right: 0, // anchor to the right side
    top: 0,
    bottom: 0,
    position: 'fixed', // ensure it's positioned correctly
    overflowY: 'auto', // add scrolling if content exceeds height
    borderRadius: 0, // optionally remove border-radius for a flush look
  },
}))

interface SideModalWrapperProps extends DialogProps {
  open: boolean
  onClose: () => void
  onDelete?: () => void
  onSave?: () => void
  headerLabel: string
  withBottomBox?: boolean
  children?: ReactNode
  width?: string
}

const SideModalWrapper = ({
  open,
  children,
  headerLabel,
  width,
  withBottomBox = true,
  onDelete,
  onSave,
  onClose,
}: SideModalWrapperProps) => {
  const [bottomComponent, setBottomComponent] = useState<ReactNode>(null)

  const value = {
    bottomComponent,
    onClose,
    onDelete,
    onSave,
    setBottomComponent: (component: ReactNode) => setBottomComponent(component),
  }

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape' && open) {
        onClose()
      }
    }

    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [open, onClose])

  return (
    <SideModalContext.Provider value={value}>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        onClose={onClose}
        width={width}
        aria-labelledby='custom-dialog-title'
        aria-describedby='custom-dialog-description'
      >
        <AppBar sx={{ position: 'fixed', width: width ?? '62.5%' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {headerLabel}
            </Typography>
          </Toolbar>
        </AppBar>

        {withBottomBox ? (
          <>
            <Box sx={{ mt: '48px' }}>{children}</Box>
            <FixedBottomBox />
          </>
        ) : (
          <>{children}</>
        )}
      </StyledDialog>
    </SideModalContext.Provider>
  )
}

export default SideModalWrapper
