import { createContext, useContext } from 'react'

export const DashboardContext = createContext<DashboardContext | undefined>(undefined)

export type DashboardContext = {
  timeFrame: TimeFrame
  setTimeFrame: (timeFrame: TimeFrame) => void
}

export type TimeFrame = {
  startDate: Date
  endDate: Date
}

export const useDashboard = () => {
  const context = useContext(DashboardContext)
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}
