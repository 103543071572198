import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import { useNotificationStack } from '../../../../../app/hooks/useNotificationStack'
import {
  useAssetCategoriesQuery,
  useCreateAssetClassMutation,
  useUpdateAssetClassMutation,
} from '../../../../../app/redux-fetch/apiAssets'
import { IDropDownItem } from '../../../../../app/types'
import FormDropdown from '../../../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import { messages } from '../../messages'
import { AssetClass, AssetType } from '../../types'
import { getAssetClassValidationSchema } from './validationSchema'

type AssetClassFormProps = {
  isOpen: boolean
  assetClass?: AssetType
  handleClose: () => void
}

const AssetClassForm = ({ isOpen, assetClass, handleClose }: AssetClassFormProps) => {
  const { formatMessage } = useIntl()
  const isNew = !assetClass?.id

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: assetCategories = [] } = useAssetCategoriesQuery({ tenantId })

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const validationSchema = getAssetClassValidationSchema()

  const [createAssetClass] = useCreateAssetClassMutation()
  const [updateAssetClass] = useUpdateAssetClassMutation()

  return (
    <Formik
      initialValues={{ ...assetClass } as AssetClass}
      validationSchema={validationSchema}
      onSubmit={async (values: FormikValues) => {
        if (isNew) {
          await createAssetClass({ tenantId, body: values as AssetClass })
            .then(() => {
              enqueueSuccess(messages.assetClassCreated)
            })
            .catch(() => {
              enqueueFailure(messages.assetClassNotCreated)
            })
        } else {
          await updateAssetClass({ tenantId, body: values as AssetClass })
            .then(() => {
              enqueueSuccess(messages.assetClassUpdated)
            })
            .catch(() => {
              enqueueFailure(messages.assetClassNotUpdated)
            })
        }
        handleClose()
      }}
    >
      {({ values, errors, handleChange, setFieldValue, submitForm }) => {
        return (
          <Form>
            <Dialog open={isOpen} onClose={handleClose}>
              <DialogTitle>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '20px',
                  }}
                >
                  {formatMessage(messages.addEditAssetType)}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{ minWidth: '450px' }}>
                <Box sx={{ mb: '16px' }}>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.assetClassName)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <TextField
                      fullWidth
                      id={'name'}
                      name={'name'}
                      value={values?.name ?? ''}
                      onChange={handleChange}
                      helperText={errors?.name as React.ReactNode}
                      error={Boolean(errors?.name)}
                    />
                  </Box>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.categoryName)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <FormDropdown
                      id={'assetCategoryId'}
                      items={(assetCategories as IDropDownItem[]) ?? []}
                      onChange={(_e: any, name: string, value: number) =>
                        setFieldValue(name, value)
                      }
                      value={values.assetCategoryId}
                      error={Boolean(errors?.assetCategoryId)}
                      errorText={errors?.assetCategoryId}
                    />
                  </Box>
                  <Box>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.enabledDisabled)}
                    </SectionLabelTypography>
                    <FormControlLabel
                      sx={{ justifyContent: 'end', minHeight: '56px' }}
                      value={values.enabled}
                      labelPlacement='end'
                      control={
                        <Switch
                          color='secondary'
                          checked={values.enabled}
                          value={values.enabled}
                          onChange={({ target }) => {
                            setFieldValue('enabled', target.checked)
                          }}
                        />
                      }
                      label={'Enabled'}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button color='secondary' variant='outlined' onClick={() => handleClose()}>
                  {formatMessage(messages.cancelBtn)}
                </Button>
                <Button color='secondary' variant='contained' onClick={() => submitForm()}>
                  {isNew ? 'Create' : 'Update'}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AssetClassForm
