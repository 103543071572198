import { useAppSelector } from '../../../../app/hooks'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import UserGroupForm from './UserGroupForm'
import { IUserGroup } from './types'


const CreateUserGroupModal = () => {

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId, customerId } = loggedInUser

    return (
        <UserGroupForm
            userGroup={
                {
                    customerId: customerId,
                    tenantId: tenantId,
                    disabled: false,
                    users: [],
                    roles: [],
                    userAllocations: [],
                    roleAllocations: [],
                } as IUserGroup
            }
            isDisabled={false}
        />
    )
}

export default CreateUserGroupModal
