import { defineMessages } from 'react-intl'

const messages = defineMessages({
  mainInfoTitle: {
    id: 'tenantSettings.userManagement.users.mainInfoTitle',
    description: 'Main Info Title',
    defaultMessage: 'Main Info',
  },
  nameLabel: {
    id: 'tenantSettings.userManagement.users.nameLabel',
    description: 'Name label',
    defaultMessage: 'Name',
  },
  firstNameLabel: {
    id: 'tenantSettings.userManagement.users.firstNameLabel',
    description: 'First Name label',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'tenantSettings.userManagement.users.lastNameLabel',
    description: 'Last Name label',
    defaultMessage: 'Last Name',
  },
  emailLabel: {
    id: 'tenantSettings.userManagement.users.emailLabel',
    description: 'Email label',
    defaultMessage: 'Email',
  },
  phoneLabel: {
    id: 'tenantSettings.userManagement.users.phoneLabel',
    description: 'Phone label',
    defaultMessage: 'Mobile Phone',
  },
  systemUserLabel: {
    id: 'tenantSettings.userManagement.users.systemUserLabel',
    description: 'System user type label',
    defaultMessage: 'System',
  },
  workforceUserLabel: {
    id: 'tenantSettings.userManagement.users.workforceUserLabel',
    description: 'Workforce User type label',
    defaultMessage: 'Workforce',
  },
  userType: {
    id: 'tenantSettings.userManagement.users.userType',
    description: 'User Type',
    defaultMessage: 'User Type',
  },
  descriptionLabel: {
    id: 'tenantSettings.userManagement.users.descriptionLabel',
    description: 'Description label',
    defaultMessage: 'Description',
  },
  nameIsRequired: {
    id: 'tenantSettings.userManagement.users.nameIsRequired',
    description: 'Name is required',
    defaultMessage: 'Name is required',
  },
  descriptionIsRequired: {
    id: 'tenantSettings.userManagement.users.descriptionIsRequired',
    description: 'Description is required',
    defaultMessage: 'Description is required',
  },
  activeLabel: {
    id: 'tenantSettings.userManagement.users.activeLabel',
    description: 'Active label',
    defaultMessage: 'Active',
  },
  yesLabel: {
    id: 'tenantSettings.userManagement.users.yesLabel',
    description: 'Yes label',
    defaultMessage: 'Yes',
  },
  noLabel: {
    id: 'tenantSettings.userManagement.users.noLabel',
    description: 'No label',
    defaultMessage: 'No',
  },
  createUserLabel: {
    id: 'tenantSettings.userManagement.users.createUserLabel',
    description: 'Create user label',
    defaultMessage: 'Create User',
  },
  editUserLabel: {
    id: 'tenantSettings.userManagement.users.editUserLabel',
    description: 'Edit user label',
    defaultMessage: 'Edit',
  },
  viewUserLabel: {
    id: 'tenantSettings.userManagement.users.viewUserLabel',
    description: 'View user label',
    defaultMessage: 'View',
  },
  newUserLabel: {
    id: 'tenantSettings.userManagement.users.newUserLabel',
    description: 'New user label',
    defaultMessage: 'New User',
  },
  cancelBtn: {
    id: 'tenantSettings.userManagement.users.cancelBtn',
    description: 'Cancel button',
    defaultMessage: 'Cancel',
  },
  editBtn: {
    id: 'tenantSettings.userManagement.users.editBtn',
    description: 'Edit button',
    defaultMessage: 'Edit',
  },
  activeLbl: {
    id: 'tenantSettings.userManagement.users.activeLbl',
    description: 'Active label',
    defaultMessage: 'Active',
  },
  firstNameIsRequired: {
    id: 'tenantSettings.userManagement.users.firstNameIsRequired',
    description: 'First Name is required',
    defaultMessage: 'First Name is required',
  },
  lastNameIsRequired: {
    id: 'tenantSettings.userManagement.users.lastNameIsRequired',
    description: 'Last Name is required',
    defaultMessage: 'Last Name is required',
  },
  emailIsRequired: {
    id: 'tenantSettings.userManagement.users.emailIsRequired',
    description: 'Email is required',
    defaultMessage: 'Email is required',
  },
  phoneIsRequired: {
    id: 'tenantSettings.userManagement.users.phoneIsRequired',
    description: 'Phone is required',
    defaultMessage: 'Phone is required',
  },
  userTypeIsRequired: {
    id: 'tenantSettings.userManagement.users.userTypeIsRequired',
    description: 'User Type is required',
    defaultMessage: 'User Type is required',
  },
  userCreated: {
    id: 'tenantSettings.userManagement.users.userCreated',
    description: 'User Created API msg',
    defaultMessage: 'User Created',
  },
  userUpdated: {
    id: 'tenantSettings.userManagement.users.userUpdated',
    description: 'User Type is required',
    defaultMessage: 'User Updated',
  },
  userApiError: {
    id: 'tenantSettings.userManagement.users.userApiError',
    description: 'BE return API unhandled error',
    defaultMessage: 'Something went wrong, try again later ',
  },
})

export default messages
