import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import ModalButtons from '../../../components/Buttons/ModalButtons'
import { SectionTitleTypography } from '../../../components/Typographies/styles'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import UnassignedLoadsTable from '../UnassiagnedLoadsTable'
import { messages } from '../messages'

const AddLoadsSelection = () => {
  const { formatMessage } = useIntl()

  const { setBottomComponent } = useSideModalContext()

  useEffect(() => {
    setBottomComponent && setBottomComponent(<ModalButtons saveBtn='Next' />)
  }, [setBottomComponent])

  return (
    <Box sx={{ p: '32px' }}>
      <Box>
        <SectionTitleTypography>{formatMessage(messages.loadsName)}</SectionTitleTypography>
        <Typography variant='body2' sx={{ pt: '16px' }}>
          {formatMessage(messages.selectLoads)}
        </Typography>
        <UnassignedLoadsTable isAddLoadMode={true} />
      </Box>
    </Box>
  )
}

export default AddLoadsSelection
