import { Box, Button } from '@mui/material'
import { useIntl } from 'react-intl'
import { useSideModalContext } from '../../features/AssetsManagement/modals/SideModalContext'
import messages from './messages'

export type ModalButtonsPros = {
  saveBtn?: string
  cancelBtn?: string
}

const ModalButtons = ({ saveBtn, cancelBtn }: ModalButtonsPros) => {
  const { formatMessage } = useIntl()
  const { onClose, onSave } = useSideModalContext()

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '16px',
          backgroundColor: '#fff',
        }}
      ></Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button color='secondary' variant='contained' onClick={onSave}>
          {saveBtn ?? formatMessage(messages.saveBtn)}
        </Button>
        <Button color='secondary' variant='outlined' onClick={onClose}>
          {cancelBtn ?? formatMessage(messages.cancelBtn)}
        </Button>
      </Box>
    </>
  )
}

export default ModalButtons
