import AddIcon from '@mui/icons-material/Add'

import { AccordionSummary, Box, Fab, Grid, Typography, type SelectChangeEvent } from '@mui/material'
import { type FormikProps } from 'formik'
import React, { type ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { ShipmentAccordion } from './styles'

import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import { useValidation } from '../../hooks/useValidation'
import { ILoad, IShipment } from '../../types'
import { sortByDisplayOrder } from '../../utils'
import ShipmentSummaryHeader from '../Common/ShipmentSummaryHeader/ShimpenSummaryHeader'
import Load from './Load'
import messages from './messages'
import { type ILoadPalletVector } from './types'

interface IShipmentLoadStepProps {
  formik: FormikProps<IShipment>
  handleAddLoad: () => number
  handleAddPallet: (index: number) => void
  handleCopyLoads: (loadIndex: number, count: number) => void
  handleFormDropDownChanges: (e: SelectChangeEvent<number>, name: string, newValue: any) => void
  handleRemovePallet: (loadIndex: number, palletIndex: number) => void
  handleSwitchLoadChanges: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ) => void
  lastCreatedLoadIndex: number | null
  lastCreatedPallet: ILoadPalletVector | null
  disabled?: boolean
  disabledPast?: boolean
}

const ShipmentLoadStep = (props: IShipmentLoadStepProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()

  const {
    formik,
    handleAddLoad,
    handleAddPallet,
    handleCopyLoads,
    handleFormDropDownChanges,
    handleRemovePallet,
    handleSwitchLoadChanges,
    lastCreatedLoadIndex,
    lastCreatedPallet,
    disabled,
    disabledPast,
  } = props

  const { validAt } = useValidation()
  const hasErrors = !validAt('loads', formik.values)
  return (
    <>
      <ShipmentSummaryHeader formik={formik} />
      <Box display='flex' sx={{ pb: '12px' }}>
        <TableTitleTypography sx={{ fontSize: '24px' }}>Loads</TableTitleTypography>
      </Box>
      {formik.values.loads
        .filter((load: ILoad) => load.isDefined)
        .sort(sortByDisplayOrder)
        .map((load: ILoad) => {
          const index = formik.values.loads.findIndex((l) => load.id === l.id)
          const expanded = lastCreatedLoadIndex === index || hasErrors

          return (
            <Load
              key={index}
              formik={formik}
              handleAddPallet={handleAddPallet}
              index={index}
              handleFormDropDownChanges={handleFormDropDownChanges}
              handleRemovePallet={handleRemovePallet}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              lastCreatedPallet={lastCreatedPallet}
              handleCopyLoads={handleCopyLoads}
              expanded={expanded}
              load={load}
              disabled={disabled}
              disabledPast={disabledPast}
            />
          )
        })}
      {!disabled && (
        <ShipmentAccordion expanded={hasErrors}>
          <AccordionSummary>
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={6} alignItems='center'>
                <Box>
                  <Typography>{formatMessage(messages.newLoad)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end'>
                  <Fab
                    aria-label='add'
                    color='secondary'
                    onClick={() => {
                      handleAddLoad()
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
        </ShipmentAccordion>
      )}
    </>
  )
}

export default ShipmentLoadStep
