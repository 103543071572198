import { Divider, FormControlLabel, Grid, Switch } from '@mui/material'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import ValueUnitControl from '../../../../components/ValueUnitControl/ValueUnitControl'
import {
  fetchAllDensityUnitsSelector,
  fetchAllLengthUnitsSelector,
  fetchAllVolumeUnitsSelector,
  fetchAllWeightUnitsSelector,
} from '../../selectors'

import messages from './messages'
import { type ILoadDetailsProps } from './types'

type PhysicalPropertiesProps = ILoadDetailsProps

const PhysicalProperties = (props: PhysicalPropertiesProps): ReactElement => {
  const { formatMessage } = useIntl()
  const { formik, index, handleSwitchLoadChanges, load, disabled } = props

  const lengthUnitItems = useAppSelector(fetchAllLengthUnitsSelector)
  const densityUnitItems = useAppSelector(fetchAllDensityUnitsSelector)
  const volumeUnitItems = useAppSelector(fetchAllVolumeUnitsSelector)
  const weightUnitItems = useAppSelector(fetchAllWeightUnitsSelector)

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
          {formatMessage(messages.physicalProperties)}
        </Divider>
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          disabled={disabled}
          control={
            <Switch
              size='small'
              color='secondary'
              id={`loads.${index}.physicalProperties.usePhysicalProperties`}
              name={`loads.${index}.physicalProperties.usePhysicalProperties`}
              onChange={(e, value) => {
                handleSwitchLoadChanges(e, value)
              }}
              checked={!!load.physicalProperties?.usePhysicalProperties}
            />
          }
          label={formatMessage(messages.usePhysicalProperties)}
        />
      </Grid>
      <Grid item xs={8}></Grid>
      {(load.physicalProperties?.usePhysicalProperties ?? false) && (
        <>
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.lengthMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.length`}
            handleOnBlur={formik.handleBlur}
            isAllowed={false}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.lengthMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.length`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfLength)}
            labelValue={formatMessage(messages.length)}
            name={`lengthAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.lengthMeasureUnitId ?? 0}
            unitList={lengthUnitItems}
            value={formik.values.loads[index].physicalProperties?.length ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.lengthMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.width`}
            handleOnBlur={formik.handleBlur}
            isAllowed={false}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.lengthMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.width`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfLength)}
            labelValue={formatMessage(messages.width)}
            name={`widthAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.lengthMeasureUnitId ?? 0}
            unitList={lengthUnitItems}
            value={formik.values.loads[index].physicalProperties?.width ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.lengthMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.height`}
            handleOnBlur={formik.handleBlur}
            isAllowed={false}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.lengthMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.height`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfLength)}
            labelValue={formatMessage(messages.height)}
            name={`heightAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.lengthMeasureUnitId ?? 0}
            unitList={lengthUnitItems}
            value={formik.values.loads[index].physicalProperties?.height ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.weightMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.grossWeight`}
            handleOnBlur={formik.handleBlur}
            isAllowed={false}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.weightMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.grossWeight`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfWeight)}
            labelValue={formatMessage(messages.grossWeight)}
            name={`grossWeightAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.weightMeasureUnitId ?? 0}
            unitList={weightUnitItems}
            value={formik.values.loads[index].physicalProperties?.grossWeight ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.weightMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.netWeight`}
            isAllowed={false}
            handleOnBlur={formik.handleBlur}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.weightMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.netWeight`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfWeight)}
            labelValue={formatMessage(messages.netWeight)}
            name={`netWeightAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.weightMeasureUnitId ?? 0}
            unitList={weightUnitItems}
            value={formik.values.loads[index].physicalProperties?.netWeight ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.weightMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.chargeableWeight`}
            handleOnBlur={formik.handleBlur}
            isAllowed={false}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.weightMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.chargeableWeight`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfWeight)}
            labelValue={formatMessage(messages.chargeableWeight)}
            name={`chargeableWeightAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.weightMeasureUnitId ?? 0}
            unitList={weightUnitItems}
            value={formik.values.loads[index].physicalProperties?.chargeableWeight ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.volumeMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.volume`}
            handleOnBlur={formik.handleBlur}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.volumeMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.volume`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfVolume)}
            labelValue={formatMessage(messages.volume)}
            name={`volumeAndUnitControl_load_main_${index}`}
            selectedUnitId={formik.values.loads[index].physicalProperties?.volumeMeasureUnitId ?? 0}
            unitList={volumeUnitItems}
            value={formik.values.loads[index].physicalProperties?.volume ?? ''}
          />
          <ValueUnitControl
            disabled={disabled}
            unitId={`loads.${index}.physicalProperties.densityMeasureUnitId`}
            valueId={`loads.${index}.physicalProperties.density`}
            handleOnBlur={formik.handleBlur}
            isAllowed={false}
            handleChangeOfUnit={(name, newValue) => {
              formik.setFieldValue(
                `loads.${index}.physicalProperties.densityMeasureUnitId`,
                newValue
              )
            }}
            handleChangeOfValue={(name, newValue) => {
              formik.setFieldValue(`loads.${index}.physicalProperties.density`, newValue)
            }}
            labelUnit={formatMessage(messages.unitOfDensity)}
            labelValue={formatMessage(messages.density)}
            name={`densityAndUnitControl_load_main_${index}`}
            selectedUnitId={
              formik.values.loads[index].physicalProperties?.densityMeasureUnitId ?? 0
            }
            unitList={densityUnitItems}
            value={formik.values.loads[index].physicalProperties?.density ?? ''}
          />
        </>
      )}
    </>
  )
}

export default PhysicalProperties
