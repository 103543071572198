import { CUSTOMER_SETTINGS_REDUCER } from '../../features/CustomerSettings/consts'
import { customerSettingsReducers } from '../../features/CustomerSettings/reducers'
import { SHIPMENTS_REDUCER } from '../../features/Shipments/consts'
import { shipmentsReducers } from '../../features/Shipments/reducers'
import { assetsReducer } from '../../features/TenantSettings/Components/AssetSettings/reducers'
import { ASSETS_REDUCER, TENANT_REDUCER } from '../../features/TenantSettings/consts'
import { tenantReducers } from '../../features/TenantSettings/reducers'
import { LOADING, TRANSPORTS_REDUCER } from '../../features/Transport/consts'
import { transportsReducers } from '../../features/Transport/reducers'
import { APP_REDUCER_NAME } from '../../features/const'
import { appReducers } from '../../features/reducers'
import { apiCoreSlice } from '../redux-fetch/apiCoreQuery'
import { apiSlice } from '../redux-fetch/apiQuery'
import loadingSlice from '../redux-fetch/loadingSlice'

export const reducers = {
  [TENANT_REDUCER]: tenantReducers,
  [SHIPMENTS_REDUCER]: shipmentsReducers,
  [APP_REDUCER_NAME]: appReducers,
  [ASSETS_REDUCER]: assetsReducer,
  [TRANSPORTS_REDUCER]: transportsReducers,
  [CUSTOMER_SETTINGS_REDUCER]: customerSettingsReducers,
  [LOADING]: loadingSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [apiCoreSlice.reducerPath]: apiCoreSlice.reducer,
}
