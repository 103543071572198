import { Grid, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useEffect, useMemo, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery'
import { useGetLocationsQuery } from '../../app/redux-fetch/apiShipment'
import SpinnerBlock from '../../components/Spinner/SpinnerBlock'
import { fetchAllCountriesSelector } from '../Shipments/selectors'
import { fetchAllCountriesThunk } from '../Shipments/slice'
import { ILocationByTenantCustomer } from '../Shipments/types'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import LocationsTable from './LocationsTable'
import messages from './messages'

const Locations = (): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const [showLocations, setShowLocations] = useState(false)
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId
  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId

  const { data: locations = [], refetch } = useGetLocationsQuery({
    tenantId,
    customerId,
    includeCustomerLocations: false,
  })
  useEffect(() => {
    dispatch(fetchAllCountriesThunk())
  }, [loggedInUser])

  const {
    data: customers = [],
    isFetching: isCustomerFetching,
    isSuccess: isCustomerLoaded,
  } = useGetCustomersQuery(tenantId ?? -1, { skip: !isTenantUser })

  const columns = useMemo<MRTColumnDef<ILocationByTenantCustomer>[]>(() => {
    if (isTenantUser) {
      return [
        {
          accessorKey: 'name',
          header: formatMessage(messages.name),
        },
        {
          accessorFn: (row) => row.locationType?.name ?? '',
          header: formatMessage(messages.locationType),
        },
        {
          accessorFn: (row) => {
            if (row.customerId) {
              const customer = customers?.find((item) => item.id === row.customerId)
              return customer?.name ?? ''
            }
            return ''
          },
          header: formatMessage(messages.customerLabel),
        },
        {
          accessorKey: 'address.address1',
          header: formatMessage(messages.address),
        },
        {
          accessorKey: 'address.city',
          header: formatMessage(messages.city),
        },
        {
          accessorKey: 'address.state',
          header: formatMessage(messages.state),
        },
        {
          id: 'address.countryId',
          header: formatMessage(messages.country),
          accessorFn: (row) => {
            const country = countries?.find((item) => item.id === row.address?.countryId)
            return country?.name ?? ''
          },
          Cell: ({ cell }: any) => {
            return <Typography>{cell.getValue()}</Typography>
          },
        },
      ]
    } else {
      return [
        {
          accessorKey: 'name',
          header: formatMessage(messages.name),
        },
        {
          accessorFn: (row) => row.locationType?.name ?? '',
          header: formatMessage(messages.locationType),
        },
        {
          accessorKey: 'address.address1',
          header: formatMessage(messages.address),
        },
        {
          accessorKey: 'address.city',
          header: formatMessage(messages.city),
        },
        {
          accessorKey: 'address.state',
          header: formatMessage(messages.state),
        },
        {
          id: 'address.countryId',
          header: formatMessage(messages.country),
          accessorFn: (row) => {
            const country = countries?.find((item) => item.id === row.address?.countryId)
            return country?.name ?? ''
          },
          Cell: ({ cell }: any) => {
            return <Typography>{cell.getValue()}</Typography>
          },
        },
      ]
    }
  }, [countries, isTenantUser, customers])

  const isLoaded = (isCustomerLoaded && !isCustomerFetching) || !isTenantUser
  if (!isLoaded) return <SpinnerBlock />

  return (
    <>
      <Grid container spacing={2} pt={4}>
        <Grid xs={12}>
          <LocationsTable
            columns={columns}
            showLocations={showLocations}
            setShowLocations={setShowLocations}
            locations={locations}
            refetch={() => refetch()}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Locations
