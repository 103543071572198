import { useAppSelector } from '../../app/hooks'
import { useGetAssetQuery } from '../../app/redux-fetch/apiAssets'
import SpinnerModalBlock from '../../components/Spinner/SpinnerModalBlock'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import AssetManagementForm from './AssetManagementForm'

type EditAssetProps = {
  assetId: number
}

const ViewAsset = ({ assetId }: EditAssetProps) => {
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data, isSuccess } = useGetAssetQuery({
    tenantId,
    assetId,
  })
  if (!isSuccess) return <SpinnerModalBlock />

  return <AssetManagementForm asset={data} isDisabled={true} />
}

export default ViewAsset
