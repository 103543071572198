import { Box, TextField } from '@mui/material'
import { useGetUserTypesQuery } from '../../../../app/redux-fetch/apiQuery'
import RequiredAsterisk from '../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../../components/Typographies/styles'
import { useUserGroupFormContext } from './hooks/useUserGroupFormContext'

type MainInfoProps = {
    isDisabled?: boolean
}

const MainInfo = ({ isDisabled }: MainInfoProps) => {

    const { values, setFieldValue, errors, touched } = useUserGroupFormContext()

    const handleTextFieldChange = ({
        target,
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(target.id, target.value)
    }


    const { name, description } = values

    return (
        <Box sx={{ width: '50%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography>Main Info</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    Name
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='name'
                    value={name}
                    defaultValue={name}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    Description
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='description'
                    value={description}
                    defaultValue={description}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                />
            </Box>
        </Box>
    )
}

export default MainInfo