import { useAppSelector } from '../../../../app/hooks'
import { useGetUserQuery } from '../../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../../components/Spinner/SpinnerModalBlock'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import UserForm from './UserForm'

type UserModalProps = {
    userId?: string
    isDisabled?: boolean
}

const UserModal = (
    { userId, isDisabled }: UserModalProps
) => {

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { data, isSuccess } = useGetUserQuery({
        userId: userId ? parseInt(userId) : 0,
    },
        { refetchOnMountOrArgChange: true }
    )
    if (!isSuccess) return <SpinnerModalBlock />

    return (
        <UserForm user={data} isDisabled={isDisabled ?? false} />
    )
}

export default UserModal
