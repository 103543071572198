import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useShipmentByIdQuery } from '../../../../app/redux-fetch/apiQuery'
import NoMatch from '../../../../components/NoMatch/NoMatch'
import SpinnerBlock from '../../../../components/Spinner/SpinnerBlock'
import { fetchAllAssetCategoriesByTenantIdSelector } from '../../../TenantSettings/Components/AssetSettings/selectors'
import { fetchAssetCategoriesByTenantIdThunk } from '../../../TenantSettings/Components/AssetSettings/slice'
import { fetchTransportsAssetConfigurationSelector } from '../../../Transport/selectors'
import { fetchTransportAssetConfigurationThunk } from '../../../Transport/slice'
import { fetchLoggedInUserSelector, isUserCanAcceptShipmentSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import {
  fetchAllCountriesSelector,
  fetchAllCurrenciesSelector,
  fetchAllCustomersByTenantSelector,
  fetchAllLocationsByTenantCustomerSelector,
  fetchAllMeasureUnitsSelector,
  fetchAllTemperatureRangesSelector,
  fetchAllTransportModesSelector,
} from '../../selectors'
import {
  fetchAllCountriesThunk,
  fetchAllCustomersByTenantIdThunk,
  fetchAllPalletTypesThunk,
  fetchAllTemperatureRangeThunk,
  fetchAllTransportModesThunk,
  fetchAllUnitsThunk,
  fetchCurrenciesByCustomerIdThunk,
  fetchLocationsByTenantOrCustomerThunk,
  fetchShipmentByIdThunk,
} from '../../slice'
import { IAssetRequest, ILoad, IShipment } from '../../types'
import EditShipmentForm from './EditShipmentForm'
import { ShipmentActionButtonBehaviour } from './types'

const EditShipment = () => {
  const dispatch = useAppDispatch()
  const { id = -1 } = useParams()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const assetCategoryRows = useAppSelector(fetchAllAssetCategoriesByTenantIdSelector.data)
  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  const isTenantUser = !!tenantId && !customerId
  const isCustomerUser = !!tenantId && !!customerId

  const isCountriesLoaded = useAppSelector(fetchAllCountriesSelector.isFinished)
  const isUnitsLoaded = useAppSelector(fetchAllMeasureUnitsSelector.isFinished)
  const isTemperatureRangesLoaded = useAppSelector(fetchAllTemperatureRangesSelector.isFinished)
  const isPalletTypesLoaded = useAppSelector(fetchAllMeasureUnitsSelector.isFinished)
  const isTransportModeLoaded = useAppSelector(fetchAllTransportModesSelector.isFinished)
  const isAssetCategoriesLoaded = useAppSelector(
    fetchAllAssetCategoriesByTenantIdSelector.isFinished
  )
  const isCustomerLoaded = useAppSelector(fetchAllCustomersByTenantSelector.isFinished)
  const isLocationLoaded = useAppSelector(fetchAllLocationsByTenantCustomerSelector.isFinished)
  const isCurrenciesLoaded = useAppSelector(fetchAllCurrenciesSelector.isFinished)
  const isAssetConfigurationLoaded = useAppSelector(fetchTransportsAssetConfigurationSelector.isFinished)

  const isCountriesFetching = useAppSelector(fetchAllCountriesSelector.isFetching)
  const isUnitsFetching = useAppSelector(fetchAllMeasureUnitsSelector.isFetching)
  const isTemperatureFetching = useAppSelector(fetchAllTemperatureRangesSelector.isFetching)
  const isPalletTypeFetching = useAppSelector(fetchAllMeasureUnitsSelector.isFetching)
  const isTransportModeFetching = useAppSelector(fetchAllTransportModesSelector.isFetching)
  const isAssetCategoriesFetching = useAppSelector(
    fetchAllAssetCategoriesByTenantIdSelector.isFetching
  )
  const isCustomerFetching = useAppSelector(fetchAllCustomersByTenantSelector.isFetching)
  const isCurrenciesFetching = useAppSelector(fetchAllCurrenciesSelector.isFetching)
  const isAssetConfigurationFetching = useAppSelector(fetchTransportsAssetConfigurationSelector.isFetching)
  const isUserCanAcceptShipment = useAppSelector(isUserCanAcceptShipmentSelector)

  const assetConfigurations = useAppSelector(fetchTransportsAssetConfigurationSelector.data) ?? []

  const {
    data: shipment,
    isFetching: shipmentSelectorFetching,
    isSuccess: shipmentLoaded,
    refetch
  } = useShipmentByIdQuery({
    shipmentId: +id,
    tenantId,
    customerId,
  })

  const isFetching =
    isCountriesFetching ||
    isUnitsFetching ||
    isTemperatureFetching ||
    isPalletTypeFetching ||
    isPalletTypeFetching ||
    isTransportModeFetching ||
    isAssetCategoriesFetching ||
    shipmentSelectorFetching
  isCustomerFetching || isCurrenciesFetching || isAssetConfigurationFetching

  const isLoaded =
    !isFetching &&
    isCountriesLoaded &&
    isUnitsLoaded &&
    isTemperatureRangesLoaded &&
    isPalletTypesLoaded &&
    isTransportModeLoaded &&
    (!customerId ? isCustomerLoaded : true) &&
    isLocationLoaded &&
    isAssetCategoriesLoaded &&
    isCurrenciesLoaded &&
    isAssetConfigurationLoaded

  const fetchAPIs = useCallback(async () => {
    dispatch(fetchShipmentByIdThunk(Number(id), tenantId, customerId))
    dispatch(fetchAllTransportModesThunk())
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchAllUnitsThunk())
    dispatch(fetchAllTemperatureRangeThunk())
    dispatch(fetchAllPalletTypesThunk())
    dispatch(fetchAssetCategoriesByTenantIdThunk(tenantId))
    dispatch(fetchTransportAssetConfigurationThunk(tenantId))
  }, [dispatch, loggedInUser])

  useEffect(() => {
    fetchAPIs()
  }, [fetchAPIs])

  const refresh = () => refetch();

  useEffect(() => {
    if (shipment && shipmentLoaded) {
      if (!customerId) {
        dispatch(fetchAllCustomersByTenantIdThunk(tenantId))
      }
      dispatch(
        fetchLocationsByTenantOrCustomerThunk(shipment?.tenantId ?? tenantId, shipment?.customerId)
      )
      shipment?.customerId && dispatch(fetchCurrenciesByCustomerIdThunk(shipment?.customerId, true))
    }
  }, [shipment, tenantId])

  const initialValues = useMemo<IShipment>(() => {
    let loads: ILoad[] = []
    if (shipment?.loads) {
      loads = shipment.loads.map((l) => {
        if (l.temperatureSetting || l.palletDetails || l.physicalProperties) {
          const load = { ...l }
          if (l.physicalProperties) {
            load.physicalProperties = { ...l.physicalProperties, usePhysicalProperties: true }
          }
          if (l.temperatureSetting) {
            load.temperatureSetting = { ...l.temperatureSetting, isTemperatureControlled: true }
          }

          // Set is product to true if product id is present
          load.isProduct = !!l.productId

          return load
        }
        return l
      })
    }
    let assetRequests: IAssetRequest[] = []

    if (shipment?.assetRequests) {
      assetRequests = shipment.assetRequests.map((ar) => {
        const assetRequest = { ...ar }
        if (assetConfigurations.length > 0) {
          assetRequest.transportModeId = assetConfigurations.find(ac => ac.id === assetRequest.assetConfigurationId)?.transportModeId ?? -1
        }
        return assetRequest
      })
    }
    const hasAcceptSecurityPrincipal = isUserCanAcceptShipment // Shipment.Accept
    const isAccepted = !!shipment?.acceptedDate // AC1
    const isSubmitted = !!shipment?.submittedDate // AC2
    const isUnderReview = !!shipment?.underReviewUntil
    const underReviewUntilDate = dayjs(shipment?.underReviewUntil).format('DD/MM/YYYY HH:mm:ss')
    const isUnderReviewActive =
      underReviewUntilDate > dayjs(Date.UTC.toString()).format('DD/MM/YYYY HH:mm:ss')
    const isSameLoggedInUserAndReviwer = shipment?.underReviewByUserId === loggedInUser?.id

    // AC1
    const hideAllButtonsExceptBackToShipment = isAccepted
    // AC2
    let showSubmit = !isSubmitted
    // AC3
    const showSubmitAndAccept = !isSubmitted && isTenantUser && hasAcceptSecurityPrincipal
    showSubmit = !isSubmitted && isTenantUser && hasAcceptSecurityPrincipal
    let showAccept = !isSubmitted && isTenantUser && hasAcceptSecurityPrincipal
    // AC4
    const showRecall = isCustomerUser && isSubmitted && !isAccepted
    // AC5
    const disableRecall = isCustomerUser && isUnderReview
    // AC6
    const isTenantUserWithSecurityPrincipalAndSubmitted =
      isTenantUser && hasAcceptSecurityPrincipal && isSubmitted && !isAccepted
    const showLockForReview = isTenantUserWithSecurityPrincipalAndSubmitted // AC6.1
    showAccept = isTenantUserWithSecurityPrincipalAndSubmitted // AC6.2
    const showReject = isTenantUserWithSecurityPrincipalAndSubmitted // AC6.3
    // AC7
    const isTenantUserWithSecurityPrincipalAndUnderReviewActive =
      isTenantUser && hasAcceptSecurityPrincipal && isUnderReviewActive
    const disableLockForReview = isTenantUserWithSecurityPrincipalAndUnderReviewActive // AC7.1
    const countDownUntil = dayjs(underReviewUntilDate).diff(dayjs(Date.UTC.toString()), 'minute') // AC7.2
    // AC8
    const isTenantUserWithSecurityPrincipalAndActiveReviewAndIsNotLoggedInUser =
      isTenantUser &&
      hasAcceptSecurityPrincipal &&
      isUnderReviewActive &&
      !isSameLoggedInUserAndReviwer
    const disableAccpet = isTenantUserWithSecurityPrincipalAndActiveReviewAndIsNotLoggedInUser // AC8.1
    const disableReject = isTenantUserWithSecurityPrincipalAndActiveReviewAndIsNotLoggedInUser // AC8.2
    // AC9
    const clearReviewFields = !isUnderReviewActive // AC9

    const shipmentActionButtonBehavior: ShipmentActionButtonBehaviour = {
      isAccepted,
      isSubmitted,
      hideAllButtonsExceptBackToShipment,
      showSubmit,
      showSubmitAndAccept,
      showRecall,
      disableRecall,
      showLockForReview,
      disableLockForReview,
      countDownUntil,
      disableAccpet,
      disableReject,
      showAccept,
      showReject,
      clearReviewFields,
    }

    const initialValues = {
      id: shipment?.id,
      tenantId: shipment?.tenantId,
      customerId: shipment?.customerId,
      currencyId: shipment?.currencyId,
      shipperId: shipment?.shipperId,
      trackingNumber: shipment?.trackingNumber,
      customerReferenceNumber: shipment?.customerReferenceNumber,
      description: shipment?.description,
      value: shipment?.value ?? 0,
      loads: loads,
      assetRequests: assetRequests,
      containerDetails: shipment?.containerDetails || [],
      acceptTermsAndCondition: false,
      acceptWarrnings: false,
      usesContainer: Boolean(shipment?.containerDetails.length),
      underReviewByUserId: shipment?.underReviewByUserId,
      underReviewUntil: shipment?.underReviewUntil,
      revision: shipment?.revision,
      shipmentActionButtonBehavior,
    }
    initialValues.loads.forEach((l) => {
      if (l.physicalProperties) {
        l.physicalProperties.usePhysicalProperties = true
      }
    })
    return initialValues
  }, [isLoaded, assetCategoryRows])

  if (!isLoaded) {
    return <SpinnerBlock />
  }
  const isShipmentSubmitted = shipment?.submittedDate !== undefined
  return !isShipmentSubmitted ? <EditShipmentForm shipment={initialValues} refresh={refresh} /> : <NoMatch />
}

export default EditShipment
