import { Box, alpha } from '@mui/material'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_RowData as MRTRowData,
  MaterialReactTable,
  MaterialReactTableProps,
  useMaterialReactTable,
} from 'material-react-table'

type IDataGridProps<TData extends MRTRowData> = MaterialReactTableProps<TData> & {
  contianerStyles?: React.CSSProperties
  leftHeadingComponent?: React.ReactElement
  rightHeadingComponent?: React.ReactElement
  columns: MRTColumnDef<TData, any>[] // Make columns explicitly required
  withChild?: boolean
}

// eslint-disable-next-line
export const MRTDataGridV2 = <TData extends MRTRowData = {}>(props: IDataGridProps<TData>) => {
  const styles = {
    boxStyles: {
      borderRadius: '3px',
      padding: '0 10px 10px 10px',
      marginBottom: '20px',
    },
  }

  const {
    contianerStyles,
    leftHeadingComponent,
    rightHeadingComponent,
    enableRowSelection,
    enableDensityToggle = true,
    state,
    withChild = false,
    ...rest
  } = props

  const table = useMaterialReactTable({
    muiTableBodyProps: {
      sx: (theme) => {
        return {
          '& tr:nth-of-type(odd) > td': {
            backgroundColor: withChild ? '#FFFFFF' : alpha(theme.palette.secondary.main, 0.08),
          },
        }
      },
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: table.getState().isFullScreen ? 99999 : undefined,
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        color: '#636363',
        fontSize: '14px',
      },
    },
    ...{
      ...rest,
      enableRowSelection,
      enableDensityToggle,
      state: { ...state },
      initialState: {
        ...(rest.initialState || {}),
        density: rest.initialState?.density || 'compact',
      },
    },
  })

  return (
    <Box sx={styles.boxStyles}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '48px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{leftHeadingComponent}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{rightHeadingComponent}</Box>
      </Box>
      <Box style={contianerStyles} sx={{ flexGrow: 1 }}>
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  )
}

export default MRTDataGridV2
