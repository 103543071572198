import { defineMessages } from 'react-intl'

const messages = defineMessages({
  name: {
    id: 'productCategory.name',
    description: 'Product Category Name',
    defaultMessage: 'Name',
  },
  hsHeading: {
    id: 'productCategory.hsHeading',
    description: 'HS Heading',
    defaultMessage: 'HS Heading',
  },
  hsSubheading: {
    id: 'productCategory.hsSubheading',
    description: 'HS Subheading',
    defaultMessage: 'HS Subheading',
  },
  hsCode: {
    id: 'productCategory.hsCode',
    description: 'HS Code',
    defaultMessage: 'HS Code',
  },
  hsLocalTariffs: {
    id: 'productCategory.hsLocalTariffs',
    description: 'HS Local Tariffs',
    defaultMessage: 'HS Local Tariffs',
  },
  description: {
    id: 'productCategory.description',
    description: 'Description',
    defaultMessage: 'Description',
  },
  country: {
    id: 'productCategory.country',
    description: 'Import Country',
    defaultMessage: 'Import Country',
  },
  tariff: {
    id: 'productCategory.tariff',
    description: 'Tariff',
    defaultMessage: 'Tariff (%)',
  },
  classificationCode: {
    id: 'productCategory.classificationCode',
    description: 'HS Local Classification Code (optional)',
    defaultMessage: 'HS Local Classification Code (optional)',
  },
  productCategory: {
    id: 'productCategory.productCategory',
    description: 'Product Category',
    defaultMessage: 'Product Category',
  },
  createEditProductCategory: {
    id: 'productCategory.createEditProductCategory',
    description: 'Create/Edit Product Category',
    defaultMessage: 'Create/Edit Product Category',
  },
  viewProductCategory: {
    id: 'productCategory.viewProductCategory',
    description: 'View Product Category',
    defaultMessage: 'View Product Category',
  },
  newProductCategory: {
    id: 'productCategory.newProductCategory',
    description: 'New Product Category',
    defaultMessage: 'New Product Category',
  },
  editLink: {
    id: 'productCategory.editLink',
    description: 'Edit',
    defaultMessage: 'Edit',
  },
  viewLink: {
    id: 'productCategory.viewLink',
    description: 'View',
    defaultMessage: 'View',
  },
  mainInfoTitle: {
    id: 'productCategory.mainInfoTitile',
    description: 'Main Information',
    defaultMessage: 'Main Information',
  },
  localTariffLbl: {
    id: 'productCategory.localTariffLbl',
    description: 'Local Tariff',
    defaultMessage: 'LOCAL TARIFF',
  },
  editTariffLbl: {
    id: 'productCategory.editTariffLbl',
    description: 'Add/Edit Tariff',
    defaultMessage: 'ADD/EDIT LOCAL TARIFF',
  },
  cancelBtn: {
    id: 'productCategory.cancelBtn',
    description: 'Cancel',
    defaultMessage: 'Cancel',
  },
  updateBtn: {
    id: 'productCategory.updateBtn',
    description: 'Update',
    defaultMessage: 'Update',
  },
  addBtn: {
    id: 'productCategory.addBtn',
    description: 'Add',
    defaultMessage: 'Add',
  },
  createProductDialogTitle: {
    id: 'productCategory.createProductDialogTitle',
    description: 'Create Product Category',
    defaultMessage: 'Create Product Category',
  },
  createProductDialogText: {
    id: 'productCategory.createProductDialogText',
    description: 'Create Product Category Text',
    defaultMessage:
      'You will not be able to change the HS Heading and HS Subheading of your Product Category after creation. Once set, this cannot be changed. Are you sure you wish to continue?',
  },
  confirmDialogYes: {
    id: 'productCategory.confirmDialogYes',
    description: 'Yes',
    defaultMessage: 'Yes',
  },
  confirmDialogNo: {
    id: 'productCategory.confirmDialogNo',
    description: 'No',
    defaultMessage: 'No',
  },
  customerLbl: {
    id: 'product.customerLbl',
    description: 'Customer',
    defaultMessage: 'Customer',
  },
})

export default messages
