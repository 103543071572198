import { useAppSelector } from '../../app/hooks'
import { useGetUserTypesQuery, useGetWorkforceQuery } from '../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../components/Spinner/SpinnerModalBlock'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import WorkforceForm from './WorkforceForm'

type EditWorkforceProps = {
    workforceId: number
    isDisabled?: boolean
}

const EditWorkforce = ({ workforceId, isDisabled }: EditWorkforceProps) => {
    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { isSuccess: isUserTypeSuccess } = useGetUserTypesQuery({ tenantId })

    const { data, isSuccess } = useGetWorkforceQuery({
        userId: workforceId
    })
    if (!isSuccess || !isUserTypeSuccess) return <SpinnerModalBlock />

    return <WorkforceForm workforce={data} isDisabled={isDisabled} />

}

export default EditWorkforce