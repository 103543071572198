import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  mainInfoLbl: {
    id: 'assetSettings.mainInfoLbl',
    description: 'Main Info Label',
    defaultMessage: 'Main Info',
  },
  assetClassDeleted: {
    id: 'assetSettings.assetClassDeleted',
    description: 'Asset Class Deleted',
    defaultMessage: 'Asset Class Deleted',
  },
  assetClassNotDeleted: {
    id: 'assetSettings.assetClassNotDeleted',
    description: 'Asset Class Not Deleted',
    defaultMessage: 'Asset Class Not Deleted',
  },
  newEditAssetConfiguration: {
    id: 'assetSettings.newEditAssetConfiguration',
    description: 'New/Edit Asset Configuration',
    defaultMessage: 'New/Edit Asset Configuration',
  },
  assetCategories: {
    id: 'assetSettings.assetCategories',
    description: 'Asset Categories',
    defaultMessage: 'Asset Categories',
  },
  assetCategory: {
    id: 'assetSettings.assetCategory',
    description: 'Asset Category',
    defaultMessage: 'Asset Category',
  },
  assetClasses: {
    id: 'assetSettings.assetClasses',
    description: 'Asset Classes',
    defaultMessage: 'Asset Classes',
  },
  assetTypes: {
    id: 'assetSettings.assetTypes',
    description: 'Asset Types',
    defaultMessage: 'Asset Types',
  },
  assetConfigurations: {
    id: 'assetSettings.assetConfigurations',
    description: 'Asset Configurations',
    defaultMessage: 'Asset Configurations',
  },
  categoryName: {
    id: 'assetSettings.categoryName',
    description: 'Category Name',
    defaultMessage: 'Category Name',
  },
  assetName: {
    id: 'assetSettings.assetName',
    description: 'Asset Name',
    defaultMessage: 'Asset Name',
  },
  transportMode: {
    id: 'assetSettings.transportMode',
    description: 'Transport Mode',
    defaultMessage: 'Transport Mode',
  },
  types: {
    id: 'assetSettings.types',
    description: 'Asset Types',
    defaultMessage: 'Types',
  },
  classes: {
    id: 'assetSettings.classes',
    description: 'Asset lasses',
    defaultMessage: 'Classes',
  },
  enabled: {
    id: 'assetSettings.enabled',
    description: 'Enabled/Disabled',
    defaultMessage: 'Enabled Y/N',
  },
  inUse: {
    id: 'assetSettings.inUse',
    description: 'In Use',
    defaultMessage: 'In Use',
  },
  customerSelectable: {
    id: 'assetSettings.customerSelectable',
    description: 'Customer Selectable',
    defaultMessage: 'Customer Selectable',
  },
  addEditAssetCategory: {
    id: 'assetSettings.addEditAssetCategory',
    description: 'Add/Edit Asset Category',
    defaultMessage: 'Add/Edit Asset Category',
  },
  newAssetCategory: {
    id: 'assetSettings.newAssetCategory',
    description: 'New Asset Category',
    defaultMessage: 'New Category',
  },
  newAssetType: {
    id: 'assetSettings.newAssetType',
    description: 'New Asset Type',
    defaultMessage: 'New Type',
  },
  newAssetConfiguration: {
    id: 'assetSettings.newAssetConfiguration',
    description: 'New Asset Configuration',
    defaultMessage: 'New Configuration',
  },
  newAssetClass: {
    id: 'assetSettings.newAssetClass',
    description: 'New Asset Class',
    defaultMessage: 'New Class',
  },
  editActionLbl: {
    id: 'assetSettings.editActionLbl',
    description: 'Edit Action Label',
    defaultMessage: 'Edit',
  },
  viewActionLbl: {
    id: 'assetSettings.viewActionLbl',
    description: 'View Action Label',
    defaultMessage: 'View',
  },
  yesLbl: {
    id: 'assetSettings.yesLbl',
    description: 'Yes Label',
    defaultMessage: 'Yes',
  },
  noLbl: {
    id: 'assetSettings.noLbl',
    description: 'No Label',
    defaultMessage: 'No',
  },
  cancelBtn: {
    id: 'assetSettings.cancelBtn',
    description: 'Cancel Button',
    defaultMessage: 'Cancel',
  },
  assetTypeName: {
    id: 'assetSettings.assetTypeName',
    description: 'Asset Type Name',
    defaultMessage: 'Type Name',
  },
  workforceTypes: {
    id: 'assetSettings.workforceTypes',
    description: 'Workforce Types',
    defaultMessage: 'Workforce Types',
  },
  motorized: {
    id: 'assetSettings.motorized',
    description: 'Motorized',
    defaultMessage: 'Motorized',
  },
  isTemperatureControlled: {
    id: 'assetSettings.isTemperatureControlled',
    description: 'Is Temperature Controlled',
    defaultMessage: 'Temperature Controlled',
  },
  requiresDriver: {
    id: 'assetSettings.requiresDriver',
    description: 'Requires Driver',
    defaultMessage: 'Requires Driver',
  },
  sealable: {
    id: 'assetSettings.sealable',
    description: 'Sealable',
    defaultMessage: 'Sealable',
  },
  carriesPayload: {
    id: 'assetSettings.carriesPayload',
    description: 'Carries Payload',
    defaultMessage: 'Carries Payload',
  },
  category: {
    id: 'assetSettings.category',
    description: 'Category',
    defaultMessage: 'Category Name',
  },
  addEditAssetType: {
    id: 'assetSettings.addEditAssetType',
    description: 'Add/Edit Asset Type',
    defaultMessage: 'Add/Edit Asset Type',
  },
  selectTypeOptions: {
    id: 'assetSettings.selectTypeOptions',
    description: 'Select Type Options',
    defaultMessage: 'Select Type Options',
  },
  requiresWorkforce: {
    id: 'assetSettings.requiresWorkforce',
    description: 'Requires Workforce',
    defaultMessage: 'Requires Workforce',
  },
  assetClassName: {
    id: 'assetSettings.assetClassName',
    description: 'Asset Class Name',
    defaultMessage: 'Class Name',
  },
  enabledDisabled: {
    id: 'assetSettings.enabledDisabled',
    description: 'Enabled/Disabled',
    defaultMessage: 'Enabled/Disabled',
  },
  configurationName: {
    id: 'assetSettings.configurationName',
    description: 'Configuration Name',
    defaultMessage: 'Configuration Name',
  },
  numberOfAssets: {
    id: 'assetSettings.numberOfAssets',
    description: 'Number of Assets',
    defaultMessage: 'Number of Assets',
  },
  assetTypeCreated: {
    id: 'assetSettings.assetTypeCreated',
    description: 'Asset Type Created',
    defaultMessage: 'Asset Type Created',
  },
  assetTypeNotCreated: {
    id: 'assetSettings.assetTypeNotCreated',
    description: 'Asset Type Not Created',
    defaultMessage: 'Asset Type Not Created',
  },
  assetTypeUpdated: {
    id: 'assetSettings.assetTypeUpdated',
    description: 'Asset Type Updated',
    defaultMessage: 'Asset Type Updated',
  },
  assetTypeNotUpdated: {
    id: 'assetSettings.assetTypeNotUpdated',
    description: 'Asset Type Not Updated',
    defaultMessage: 'Asset Type Not Updated',
  },
  assetTypeDeleted: {
    id: 'assetSettings.assetTypeDeleted',
    description: 'Asset Type Deleted',
    defaultMessage: 'Asset Type Deleted',
  },
  assetTypeNotDeleted: {
    id: 'assetSettings.assetTypeNotDeleted',
    description: 'Asset Type Not Deleted',
    defaultMessage: 'Asset Type Not Deleted',
  },
  assetClassCreated: {
    id: 'assetSettings.assetClassCreated',
    description: 'Asset Class Created',
    defaultMessage: 'Asset Class Created',
  },
  assetClassNotCreated: {
    id: 'assetSettings.assetClassNotCreated',
    description: 'Asset Class Not Created',
    defaultMessage: 'Asset Class Not Created'
  },
  assetClassUpdated: {
    id: 'assetSettings.assetClassUpdated',
    description: 'Asset Class Updated',
    defaultMessage: 'Asset Class Updated',
  },
  assetClassNotUpdated: {
    id: 'assetSettings.assetClassNotUpdated',
    description: 'Asset Class Not Updated',
    defaultMessage: 'Asset Class Not Updated'
  },
  assetConfigurationCreated: {
    id: 'assetSettings.assetConfigurationCreated',
    description: 'Asset Configuration Created',
    defaultMessage: 'Asset Configuration Created',
  },
  assetConfigurationNotCreated: {
    id: 'assetSettings.assetConfigurationNotCreated',
    description: 'Asset Configuration Not Created',
    defaultMessage: 'Asset Configuration Not Created',
  },
  assetConfigurationUpdated: {
    id: 'assetSettings.assetConfigurationUpdated',
    description: 'Asset Configuration Updated',
    defaultMessage: 'Asset Configuration Updated',
  },
  assetConfigurationNotUpdated: {
    id: 'assetSettings.assetConfigurationNotUpdated',
    description: 'Asset Configuration Not Updated',
    defaultMessage: 'Asset Configuration Not Updated',
  },
})
