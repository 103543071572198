import CloseIcon from '@mui/icons-material/Close'
import CopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { FormikProps } from 'formik'
import { cloneDeep } from 'lodash/fp'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { v4 as uuidv4 } from 'uuid'
import { useAppSelector } from '../../../../app/hooks'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { fetchTransportsAssetConfigurationSelector } from '../../selectors'
import { ICreateTransportForm } from '../../types'
import Asset from '../Common/Asset'
import { AssetRequestAccordion } from '../styles'

export interface IAssetConfigurationProps {
  formik: FormikProps<ICreateTransportForm>
  idx: number
}

const AssetConfiguration = ({ formik, idx }: IAssetConfigurationProps) => {
  const [isCopyMode, setIsCopyMode] = useState(false)
  const [countForCopy, setCountForCopy] = useState(1)
  const availableAssetConfiguration =
    useAppSelector(fetchTransportsAssetConfigurationSelector.data) ?? []

  const handleCopyAssetConfiguration = () => {
    const currentAssetConfigurations = formik.values.assetConfigurations
    const currentAssetConfiguration = formik.values.assetConfigurations[idx]
    const copiedAssetConfigurations = []
    for (let i = 0; i < countForCopy; i++) {
      const copiedAssetConfiguration = cloneDeep(currentAssetConfiguration)
      copiedAssetConfigurations.push(copiedAssetConfiguration)
    }
    formik.setFieldValue('assetConfigurations', [
      ...currentAssetConfigurations,
      ...copiedAssetConfigurations,
    ])
  }
  const handleChangeAssetConfig = (e: any, name: string, newValue: any) => {
    formik.setFieldValue(`${name}.assetConfigurationId`, newValue)
    formik.setFieldValue(
      `${name}.allocations`,
      availableAssetConfiguration
        .find((m) => m.id === newValue)
        ?.allocations.map((a) => {
          return {
            ...a,
            id: -1,
            uid: uuidv4(),
            employeesId: [],
            employes: [],
            seals: [],
            loadIds: [],
          }
        })
    )
  }

  const handleRemoveAssetConfiguration = (idx: number) => {
    const assetConfigurations = formik.values.assetConfigurations
    assetConfigurations.splice(idx, 1)
    formik.setFieldValue('assetConfigurations', assetConfigurations)
  }

  return (
    <>
      <AssetRequestAccordion
        sx={{
          backgroundColor: 'rgba(0,160,228,0.1)',
        }}
      >
        <AccordionSummary>
          <Grid container>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='flex-start' alignItems={'center'}>
                <Typography sx={{ pr: '16px' }}>{`Asset configuration #${idx + 1}`} </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='flex-end'>
                {isCopyMode ? (
                  <Box display='flex' justifyContent='flex-end'>
                    <NumericFormat
                      customInput={TextField}
                      decimalScale={0}
                      label='How many?'
                      sx={{ paddingRight: '16px' }}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                      onValueChange={(vals) => {
                        setCountForCopy(vals.floatValue ?? 0)
                      }}
                      value={countForCopy}
                      InputLabelProps={{ shrink: true }}
                    />
                    <IconButton
                      size='large'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleCopyAssetConfiguration()
                        setIsCopyMode(false)
                        setCountForCopy(1)
                      }}
                    >
                      <DoneIcon color='success' />
                    </IconButton>
                    <IconButton
                      size='large'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setCountForCopy(1)
                        setIsCopyMode(false)
                      }}
                    >
                      <CloseIcon color='error' />
                    </IconButton>
                  </Box>
                ) : (
                  <Fab
                    aria-label='copy'
                    color='secondary'
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setIsCopyMode(true)
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <CopyIcon />
                  </Fab>
                )}
                <Fab
                  aria-label='remove'
                  color='info'
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleRemoveAssetConfiguration(idx)
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <DeleteIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container gap={2}>
            <Grid item xs={4}>
              <FormDropdown
                id={`assetConfigurations.${idx}`}
                items={availableAssetConfiguration}
                label='Select asset configuration'
                onChange={handleChangeAssetConfig}
                value={formik.values.assetConfigurations[idx].assetConfigurationId}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexFlow: 'row wrap' }}>
              {formik.values.assetConfigurations[idx].allocations?.map((ar, arIdx) => (
                <>
                  <Asset formik={formik} idx={arIdx} assetConfigIdx={idx} asset={ar}></Asset>
                </>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </AssetRequestAccordion>
    </>
  )
}

export default AssetConfiguration
