import * as yup from 'yup'
import messages from './messages'

export const getUserValidationSchema = (formatMessage: any) =>
  yup.object({
    firstName: yup.string().required(formatMessage(messages.firstNameIsRequired)),
    lastName: yup.string().required(formatMessage(messages.lastNameIsRequired)),
    email: yup.string().email().required(formatMessage(messages.emailIsRequired)),
    phone: yup.string().required(formatMessage(messages.phoneIsRequired)),
  })
