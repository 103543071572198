import { Box, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { useShipmentByIdQuery } from '../../../../app/redux-fetch/apiQuery'
import { TableColumn, TableItem } from '../../../../components/Headers/styles'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { fetchAllTenantCurrenciesSelector } from '../../selectors'
import { getStatus } from './utils'

const ViewShipmentTabHeader = () => {
  const theme = useTheme()
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId

  const {
    data: shipment,
    isFetching: shipmentSelectorFetching,
    isSuccess: shipmentLoaded,
  } = useShipmentByIdQuery({
    shipmentId: Number(id),
    tenantId,
    customerId,
  })
  const currencies = useAppSelector(fetchAllTenantCurrenciesSelector.data) ?? []

  const renderMoneyValue = (value: number, currencyId: number) => {
    return `${currencies.find((c) => c.id === currencyId)?.iso3} ${value
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  return (
    <Box display='flex' flexDirection='column' sx={{ pt: '32px' }}>
      <Box display='flex'>
        <TableTitleTypography>Shipment Information</TableTitleTypography>
      </Box>
      <Box display='flex'>
        <TableColumn>
          <Typography variant='body1'>Tracking Number</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Status</Typography>
        </TableColumn>
        {isTenantUser && (
          <TableColumn>
            <Typography variant='body1'>Customer</Typography>
          </TableColumn>
        )}
        <TableColumn>
          <Typography variant='body1'>Customer Reference Number</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Shipper</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Cargo Description</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Declared Value</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Submitted Date</Typography>
        </TableColumn>
      </Box>
      <Box display='flex'>
        <TableItem>
          <Typography>{shipment?.trackingNumber}</Typography>
        </TableItem>
        <TableItem>
          <Typography>{getStatus(formatMessage, shipment)}</Typography>
        </TableItem>
        {isTenantUser && (
          <TableItem>
            <Typography>{shipment?.customer?.name}</Typography>
          </TableItem>
        )}
        <TableItem>
          <Typography>{shipment?.customerReferenceNumber}</Typography>
        </TableItem>
        <TableItem>
          <Typography>{shipment?.shipper?.name}</Typography>
        </TableItem>
        <TableItem>
          <Typography>{shipment?.description}</Typography>
        </TableItem>
        <TableItem>
          <Typography>
            {shipment && renderMoneyValue(shipment?.value, shipment?.currencyId ?? -1)}
          </Typography>
        </TableItem>
        <TableItem>
          <Typography>{shipment && dayjs(shipment.submittedDate).format('DD/MM/YYYY')}</Typography>
        </TableItem>
      </Box>
    </Box>
  )
}

export default ViewShipmentTabHeader
