import AddIcon from '@mui/icons-material/Add'
import { AccordionSummary, Box, Button, Fab, Grid, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { useCreateAssetGroupMutation } from '../../../../app/redux-fetch/apiAssets'
import { useUpdateTransportJourneyMutation } from '../../../../app/redux-fetch/apiShipment'
import rootEnum from '../../../../components/Routes/rootEnum'
import { fetchLoggedInUserSelector } from '../../../selectors'
import {
  IAssetGroupAllocationDetails,
  IAssetGroupAssetAllocation,
  ICreateTransportForm,
  ITransportJob,
  ITransportRoute,
  IUpdateJourney,
} from '../../types'
import { AssetRequestAccordion } from '../styles'
import AssetConfiguration from './AssetConfiguration'
import RouteLoadsTable from './RouteLoadsTable'

interface ICreateTransportFormProps {
  journey: IUpdateJourney
  route: ITransportRoute
}

const CreateTransportForm = ({
  route,
  journey,
}: ICreateTransportFormProps): ReactElement<any, any> => {
  const [isSubmit, setIsSubmit] = useState(false)
  const navigate = useNavigate()

  const [createAssetGroup] = useCreateAssetGroupMutation()
  const [updateJourney] = useUpdateTransportJourneyMutation()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const tenantId = loggedInUser?.tenantId ?? -1

  const initialValues = (): ICreateTransportForm => {
    return {
      assetConfigurations: [],
      route: route,
    }
  }
  const formik = useFormik<ICreateTransportForm>({
    initialValues: initialValues(),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const assetGroupDetails = mapFormToAssetGroup(values)
      const result = await createAssetGroup({
        tenantId: assetGroupDetails.tenantId ?? -1,
        body: assetGroupDetails,
      })
      if ('error' in result && (result.error as any).status === 500) {
        enqueueSnackbar('Transport Job has not been created', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        return
      }

      const journeyWithJobs = updateJourneyWithTransportJob((result as unknown as any).data.id)
      await updateJourney({
        tenantId: assetGroupDetails.tenantId ?? -1,
        journeyId: journey.id ?? -1,
        body: journeyWithJobs,
      })
        .then(() => {
          enqueueSnackbar('Transport Job has been created', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
          navigate(rootEnum.UNASSIGNED_ROUTES)
        })
        .catch(() => {
          enqueueSnackbar('Transport Job has not been created', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
    },
  })
  const handleAddConfigurationRequest = (): void => {
    const id = formik.values.assetConfigurations.length + 1
    formik.setFieldValue(
      'assetConfigurations',
      [
        ...formik.values.assetConfigurations,
        {
          id,
          allocations: [],
        },
      ],
      false
    )
  }
  const mapFormToAssetGroup = (form: ICreateTransportForm): IAssetGroupAllocationDetails => {
    let assetAllocations: IAssetGroupAssetAllocation[] = []

    form.assetConfigurations.forEach((config) => {
      const allocations = config.allocations.map((asset) => ({
        assetId: asset.id || 0,
        userAllocations:
          asset.employes?.map((em) => ({
            userId: em.id,
            isDriver: em.isDriver,
          })) || [],
        sealAllocations:
          asset.seals?.map((seal) => ({
            sealNumber: seal,
          })) || [],
        loadAllocations:
          asset.loadIds?.map((loadId) => ({
            loadId,
          })) || [],
      }))
      assetAllocations = [...assetAllocations, ...allocations]
    })
    return {
      tenantId: tenantId,
      assetAllocations,
    }
  }

  const updateJourneyWithTransportJob = (assetGroupId: number) => {
    let loadIds: number[] = []
    const updatedJourney = cloneDeep(journey)
    formik.values.assetConfigurations.forEach((config) => {
      const allocations = config.allocations.map((asset) => ({
        loadAllocations:
          asset.loadIds?.map((loadId) => ({
            loadId,
          })) || [],
      }))

      // Extract the loadIds from allocations
      for (const allocation of allocations) {
        for (const loadAllocation of allocation.loadAllocations) {
          loadIds.push(loadAllocation.loadId)
        }
      }
    })

    // Deduplicate loadIds
    loadIds = [...new Set(loadIds)]
    const job: ITransportJob = {
      routeId: +route.id,
      assetGroupId: assetGroupId,
      loadAllocations: loadIds.map((lid) => {
        return {
          loadId: lid,
        }
      }),
    }

    for (const leg of updatedJourney.legs || []) {
      for (const r of leg.routes) {
        if (r.id === +route.id) {
          if (!r.transportJobs) {
            r.transportJobs = []
          }

          r.transportJobs.push(job)
        }
      }
    }
    return updatedJourney
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pb: '12px' }}>
        <Typography>Create Transport Job</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          <Button
            color='secondary'
            disabled={formik.isSubmitting}
            variant='contained'
            onClick={() => {
              navigate(rootEnum.UNASSIGNED_ROUTES)
            }}
            sx={{
              marginRight: 'auto',
            }}
          >
            Back
          </Button>
          <Button
            color='secondary'
            disabled={formik.isSubmitting}
            variant='contained'
            sx={{
              alignSelf: 'flex-end',
            }}
            onClick={() => {
              setIsSubmit(true)
              formik.handleSubmit()
            }}
          >
            Create Transport Job
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <RouteLoadsTable formik={formik} />
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Typography>Asset Configuration</Typography>
        </Box>
        <AssetRequestAccordion expanded={false}>
          <AccordionSummary>
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={6} alignItems='center'>
                <Box>
                  <Typography>Add Asset Configuration</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end'>
                  <Fab
                    aria-label='add'
                    color='secondary'
                    onClick={() => {
                      handleAddConfigurationRequest()
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
        </AssetRequestAccordion>
      </Grid>
      {formik.values.assetConfigurations.map((ac, idx) => {
        return (
          <>
            <Grid item xs={12}>
              <AssetConfiguration formik={formik} idx={idx} />
            </Grid>
          </>
        )
      })}
    </Grid>
  )
}

export default CreateTransportForm
