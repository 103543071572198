import * as yup from 'yup'


export const getDocumentValidationSchema = () => {
    return yup.object({
      name: yup.string().required('Name is required'),
      fileId: yup.string().required('File is required'),
      expiryDate: yup.string().required('Expiry Date is required'),
      documentTypeId: yup.string().required('Document Type is required'),
    })
  }
  