import { Box } from '@mui/material';
import { MRT_RowSelectionState as MRTRowSelectionState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/hooks';
import { useGetUsersQuery } from '../../../../app/redux-fetch/apiQuery';
import CommonButton from '../../../../components/Buttons/CommonButton';
import RemoveButton from '../../../../components/Buttons/RemoveButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography';
import { fetchLoggedInUserSelector } from '../../../selectors';
import { ILoggedInUser } from '../../../types';
import { IUser } from '../types';
import { useUserGroupFormContext } from './hooks/useUserGroupFormContext';
import messages from './messages';

type UserGroupMembersProps = {
    isDisabled: boolean
}

const UserGroupMembers = ({ isDisabled }: UserGroupMembersProps) => {

    const { values, setFieldValue } = useUserGroupFormContext()
    const { users: groupUsers } = values
    const { formatMessage } = useIntl()

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId } = loggedInUser


    const [isEditMembersMode, setIsEditMembersMode] = useState(false)


    const { data: allUsers, isSuccess } = useGetUsersQuery({
        tenantId,
    });


    const [selectedMembers, setSelectedMembers] = useState<MRTRowSelectionState>({})




    useEffect(() => {
        if (isEditMembersMode) {
            const selectedUsers = allUsers?.filter((user: IUser) => {
                return user.id && selectedMembers[user.id]
            })
            setFieldValue('users', selectedUsers ?? [])
        }
    }, [selectedMembers])

    const users = useMemo(() => {
        if (isEditMembersMode) {
            return allUsers.map((user: IUser) => {
                return {
                    ...user,
                    formattedLastUpdated: user.lastUpdated ? new Date(user.lastUpdated).toLocaleDateString() : '-',
                }
            }) ?? [];
        }
        return groupUsers?.map((user: IUser) => {
            return {
                ...user,
                formattedLastUpdated: user.lastUpdated ? new Date(user.lastUpdated).toLocaleDateString() : '-',
            }
        }) ?? [];
    }, [allUsers, isEditMembersMode, groupUsers]);

    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'firstName',
                header: formatMessage(messages.firstName),
            },
            {
                accessorKey: 'lastName',
                header: formatMessage(messages.lastName),
            },
            {
                accessorKey: 'email',
                header: formatMessage(messages.email),
            },
            {
                accessorKey: 'phone',
                header: formatMessage(messages.phone),
            },
        ];
    }, []);


    return (
        <>
            <MRTDataGridv2
                initialState={{
                        sorting: [
                            {
                                id: 'firstName',
                                desc: false,
                            },
                            {
                                id: 'lastName',
                                desc: false
                            }]
                    }
                }
                leftHeadingComponent={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <TableTitleTypography sx={{ fontSize: '20px' }}>
                            {formatMessage(messages.tabUsers)}
                        </TableTitleTypography>
                    </Box>
                }
                rightHeadingComponent={
                    !isDisabled ? (<Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px',
                        }}
                    >
                        <Box>
                            {!isEditMembersMode ? (
                                <CommonButton
                                    text={formatMessage(messages.editButton)}
                                    onClick={() => {
                                        const predefinedMembers = groupUsers?.reduce((acc, user: IUser) => {
                                            if (user.id) acc[user.id] = true
                                            return acc;
                                        }, {} as Record<number, boolean>);
                                        setIsEditMembersMode(true)
                                        setSelectedMembers(predefinedMembers)
                                    }}
                                />
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center'
                                }}>
                                    <RemoveButton
                                        text={formatMessage(messages.cancelButton)}
                                        onClick={
                                            () => {
                                                setIsEditMembersMode(false)
                                                setSelectedMembers({})
                                            }
                                        }
                                    />
                                </Box>

                            )}

                        </Box>
                    </Box>) : <></>

                }
                columns={columns}
                data={users || []}
                getRowId={(row: any) => row.id as any}
                positionActionsColumn='last'
                enableRowSelection={isEditMembersMode}
                onRowSelectionChange={setSelectedMembers}
                state={{
                    rowSelection: selectedMembers
                }}
            />
        </>
    );
}

export default UserGroupMembers;