import { type AxiosResponse } from 'axios'
import {
  assetsAuthorizedFetch,
  shipmentsAuthorizedFetch,
  usersAuthorizedFetch,
} from '../../app/axios/omnialog'
import { IShipment } from './types'

export const fetchAllCountries = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/get-countries')
}

export const fetchShipmentById = async (
  shipmentId: number,
  tenantId: number,
  customerId = null
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/${customer}shipment/${shipmentId}`)
}

export const fetchAllShipments = async (
  tenantId: number,
  customerId = null
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/${customer}shipment`)
}

export const fetchAllCurrencies = async (
  tenantId: number,
  customerId = null
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/${customer}shipments`)
}

export const fetchAllTenantCurrencies = async (
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/currency`)
}

export const fetchAllCustomersByTenantId = async (
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.get(`tenant/${tenantId}/customer`)
}

export const fetchCurrenciesByCustomerId = async (
  customerId: number,
  hideDisabled: boolean
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get(`/customer/${customerId}/currency`, {
    params: { hideDisabled },
  })
}

export const fetchSystemCurrencies = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/currency')
}

export const fetchTemperatureRanges = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/get-temperature-ranges')
}

export const submitShipment = async (
  tenantId: number,
  shipment: IShipment,
  id: any,
  customerId?: number
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.put(
    `/tenant/${tenantId}/${customer}shipment/${id}/submit`,
    shipment
  )
}

export const acceptShipment = async (
  tenantId: number,
  shipment: IShipment,
  id: any
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.put(`/tenant/${tenantId}/shipment/${id}/accept`, shipment)
}

export const recallShipment = async (
  tenantId: number,
  shipment: IShipment,
  id: any,
  customerId?: number
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.put(
    `/tenant/${tenantId}/${customer}shipment/${id}/recall`,
    shipment
  )
}

export const resetReviewShipment = async (
  tenantId: number,
  shipment: IShipment,
  id: any
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.put(
    `/tenant/${tenantId}/shipment/${id}/reset-review`,
    shipment
  )
}

export const reviewShipment = async (
  tenantId: number,
  shipment: IShipment,
  id: any
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.put(`/tenant/${tenantId}/shipment/${id}/review`, shipment)
}

export const rejectShipment = async (
  tenantId: number,
  shipment: IShipment,
  id: any
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.put(`/tenant/${tenantId}/shipment/${id}/reject`, shipment)
}

export const postDraftShipment = async (
  tenantId: number,
  shipment: IShipment
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.post(`tenant/${tenantId}/shipment`, shipment)
}

export const updateShipment = async (
  id: any,
  shipment: IShipment,
  tenantId: any,
  customerId: any = null
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.put(
    `/tenant/${tenantId}/${customer}shipment/${id}`,
    shipment
  )
}

export const fetchLocationsByCustomerOrTenant = async (
  tenantId: any,
  includeCustomerLocations?: boolean,
  customerId = null
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId && !includeCustomerLocations ? `customer/${customerId}/` : ''
  const includeCustomerLocationsQuery = includeCustomerLocations
    ? !customerId
      ? '?includeCustomerLocations=true'
      : `?includeCustomerLocations=true&customerId=${customerId}`
    : ''
  return await shipmentsAuthorizedFetch.get(
    `/tenant/${tenantId}/${customer}location${includeCustomerLocationsQuery}`
  )
}

export const fetchLocationByCustomerOrTenant = async (
  id: number,
  tenantId: number,
  customerId?: number
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/${customer}location/${id}`)
}

export const postAsset = async (tenantId: any, asset: any): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.post(`/tenant/${tenantId}/asset`, asset)
}

export const postLocation = async (
  postLocation: any,
  tenantId: any,
  customerId?: any
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.post(
    `/tenant/${tenantId}/${customer}location`,
    postLocation
  )
}

export const updateLocation = async (
  postLocation: any,
  tenantId: any,
  customerId?: any
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.put(`/tenant/${tenantId}/${customer}location`, postLocation)
}

export const fetchAllMeasureUnits = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/get-all-measure-units')
}

export const fetchAllPalletTypes = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/get-all-pallet-types')
}

export const fetchAllTransportModes = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/get-all-transport-modes')
}

export const fetchAllLocationTypes = async (): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get('/get-all-location-types')
}

export const validateContainerNumber = async (
  containerNumber: string
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(`/check-container-number/${containerNumber}`)
}
