import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ClearingIcon from '../../assets/icons/clearingIcon.svg'
import DashbordIcon from '../../assets/icons/dashbordIcon.svg'
import DatasetIcon from '../../assets/icons/datasetsIcon.svg'
import MaintenanceIcon from '../../assets/icons/maintenanceIcon.svg'
import ReportsIcon from '../../assets/icons/reportsIcon.svg'
import SettingIcon from '../../assets/icons/settingsIcon.svg'
import ShippingIcon from '../../assets/icons/shippingIcon.svg'
import { CategoryButton } from '../../components/Buttons/CategoryButton'
import rootEnum from '../../components/Routes/rootEnum'
import { fetchLoggedInUserSelector } from '../../features/selectors'
import { ILoggedInUser } from '../../features/types'
import useAppSelector from './useAppSelector'

interface ICategory {
  name: string
  items: { name: string; action: () => void }[]
  element: JSX.Element
  action: () => void
}

const useNavigation = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { tenantId, customerId } = loggedInUser
  const isTenantUser = tenantId && !customerId

  const [appBarOpen, setAppBarOpen] = useState(false)

  const handleDrawerToggle = (categoryName: string) => {
    if (categoryName === selectedCategory) {
      setAppBarOpen((pv) => !pv)
    } else {
      setAppBarOpen(true)
    }
  }

  const navigate = useNavigate()

  const categories: ICategory[] = useMemo(
    () => [
      {
        name: 'Dashboard',
        items: [],
        element: (
          <CategoryButton
            icon={
              <img
                src={DashbordIcon}
                alt='dashboard'
                style={{ width: 32, height: 32, color: 'white' }}
              />
            }
            label='Dashboard'
            action={() => setAppBarOpen(false)}
            isSelected={selectedCategory === 'Dashboard'}
          />
        ),
        action: () => {
          setSelectedCategory('Dashboard')
          navigate(rootEnum.DASHBOARD)
        },
      },
      {
        name: 'Datasets',
        action: () => {
          setSelectedCategory('Datasets')
        },
        element: (
          <CategoryButton
            icon={
              <img
                src={DatasetIcon}
                alt='datasets'
                style={{ width: 32, height: 32, color: 'white' }}
              />
            }
            label='Datasets'
            action={() => handleDrawerToggle('Datasets')}
            isSelected={selectedCategory === 'Datasets'}
          />
        ),
        items: [
          {
            name: 'Assets',
            action: () => {
              setSelectedSubCategory('Assets')
              navigate(rootEnum.ASSETS)
              handleDrawerToggle('Datasets')
            },
          },
          ...(isTenantUser ? [{
            name: 'Customers', action: () => {
              setSelectedSubCategory('Customers')
              navigate(rootEnum.CUSTOMERS)
              handleDrawerToggle('Datasets')
            }
          }] : []),
          {
            name: 'Locations',
            action: () => {
              setSelectedSubCategory('Locations')
              navigate(rootEnum.LOCATIONS)
              handleDrawerToggle('Datasets')
            },
          },

          {
            name: 'Products',
            action: () => {
              setSelectedSubCategory('Products')
              handleDrawerToggle('Datasets')
              navigate(rootEnum.PRODUCTS)
            },
          },
          {
            name: 'Workforce',
            action: () => {
              setSelectedSubCategory('Workforce')
              handleDrawerToggle('Datasets')
              setAppBarOpen(false)
              navigate(rootEnum.WORKFORCE)
            },
          },
        ],
      },
      {
        name: 'Shipping',
        element: (
          <CategoryButton
            icon={
              <img
                src={ShippingIcon}
                alt='shipping'
                style={{ width: 32, height: 32, color: 'white' }}
              />
            }
            label='Shipping'
            action={() => handleDrawerToggle('Shipping')}
            isSelected={selectedCategory === 'Shipping'}
          />
        ),
        action: () => {
          setSelectedCategory('Shipping')
        },
        items: isTenantUser
          ? [
            {
              name: 'Shipments',
              action: () => {
                setSelectedSubCategory('Shipments')
                handleDrawerToggle('Shipping')
                navigate(rootEnum.SHIPMENTS)
              },
            },
            {
              name: 'Journeys',
              action: () => {
                setSelectedSubCategory('Journeys')
                handleDrawerToggle('Shipping')
                navigate(rootEnum.JOURNEY)
              },
            },
            {
              name: 'Unassigned Routes',
              action: () => {
                setSelectedSubCategory('Unassigned Routes')
                handleDrawerToggle('Shipping')
                navigate(rootEnum.UNASSIGNED_ROUTES)
              },
            },
            {
              name: 'Transport Jobs',
              action: () => {
                setSelectedSubCategory('Transport Jobs')
                handleDrawerToggle('Shipping')
                navigate(rootEnum.TRANSPORT_JOB_LISTS)
              },
            },
          ]
          : [
            {
              name: 'Shipments',
              action: () => {
                setSelectedSubCategory('Shipments')
                handleDrawerToggle('Shipping')
                navigate(rootEnum.SHIPMENTS)
              },
            },
          ],
      },
      {
        name: 'Clearing',
        element: (
          <CategoryButton
            icon={
              <img
                src={ClearingIcon}
                alt='clearing'
                style={{ width: 32, height: 32, color: 'white' }}
              />
            }
            label='Clearing'
            action={() => {
              setSelectedCategory('Clearing')
              setSelectedSubCategory('')
              setAppBarOpen(false)
              navigate(rootEnum.COMING_SOON)
            }}
            isSelected={selectedCategory === 'Clearing'}
          />
        ),
        action: () => {
          setSelectedCategory('Clearing')
          navigate(rootEnum.COMING_SOON)
        },
        items: [],
      },
      {
        name: 'Maintenance',
        element: (
          <CategoryButton
            icon={
              <img
                src={MaintenanceIcon}
                alt='maintenance'
                style={{ width: 32, height: 32, color: 'white' }}
              />
            }
            label='Maintenance'
            action={() => {
              setSelectedCategory('')
              setSelectedSubCategory('')
              setAppBarOpen(false)
              navigate(rootEnum.COMING_SOON)
            }}
            isSelected={selectedCategory === 'Maintenance'}
          />
        ),
        action: () => {
          setSelectedCategory('Maintenance')
          setSelectedSubCategory('')
          setAppBarOpen(false)
          navigate(rootEnum.COMING_SOON)
        },
        items: [],
      },
      {
        name: 'Reports',
        element: (
          <CategoryButton
            icon={
              <img
                src={ReportsIcon}
                alt='reports'
                style={{ width: 32, height: 32, color: 'white' }}
              />
            }
            label='Reports'
            action={() => {
              setAppBarOpen(false)
              navigate(rootEnum.COMING_SOON)
            }}
            isSelected={selectedCategory === 'Reports'}
          />
        ),
        action: () => {
          setSelectedCategory('Reports')
          navigate(rootEnum.COMING_SOON)
        },
        items: [],
      },
      {
        name: 'Settings',
        element: (
          <CategoryButton
            icon={
              <img src={SettingIcon} alt='omni' style={{ width: 32, height: 32, color: 'white' }} />
            }
            label='Settings'
            action={() => handleDrawerToggle('Settings')}
            isSelected={selectedCategory === 'Settings'}
          />
        ),
        action: () => {
          setSelectedCategory('Settings')
        },
        items: isTenantUser
          ? [
            {
              name: 'Asset Managment',
              action: () => {
                setSelectedSubCategory('Asset Managment')
                handleDrawerToggle('Settings')
                navigate(rootEnum.ASSET_MANAGEMENT)
              },
            },
            {
              name: 'Branding',
              action: () => {
                setSelectedSubCategory('Branding')
                handleDrawerToggle('Settings')
                navigate(rootEnum.BRANDING)
              },
            },
            {
              name: 'Integrations',
              action: () => {
                setSelectedSubCategory('Integrations')
                handleDrawerToggle('Settings')
                navigate(rootEnum.INTEGRATIONS)
              },
            },
            {
              name: 'Roles & Permissions',
              action: () => {
                setSelectedSubCategory('Roles & Permissions')
                handleDrawerToggle('Settings')
                navigate(rootEnum.ROLES_PERMISSIONS)
              },
            },
            {
              name: 'Shipping',
              action: () => {
                setSelectedSubCategory('Shipping')
                handleDrawerToggle('Settings')
                navigate(rootEnum.SHIPPING)
              },
            },
            {
              name: 'User Managment',
              action: () => {
                setSelectedSubCategory('User Managment')
                handleDrawerToggle('Settings')
                navigate(rootEnum.USER_MANAGEMENT)
              },
            },
            {
              name: 'Currencies',
              action: () => {
                setSelectedSubCategory('Currencies')
                handleDrawerToggle('Settings')
                navigate(rootEnum.CURRENCY_SETTINGS)
              },
            },
            {
              name: 'Contacts',
              action: () => {
                setSelectedSubCategory('Contacts')
                handleDrawerToggle('Settings')
                navigate(rootEnum.CONTACTS_SETTINGS)
              },
            },
          ]
          : [
            {
              name: 'Currencies',
              action: () => {
                setSelectedSubCategory('Currencies')
                handleDrawerToggle('Settings')
                navigate(rootEnum.CURRENCY_SETTINGS)
              },
            },
            {
              name: 'Contacts',
              action: () => {
                setSelectedSubCategory('Contacts')
                handleDrawerToggle('Settings')
                navigate(rootEnum.CONTACTS_SETTINGS)
              },
            },
            {
              name: 'Integrations',
              action: () => {
                setSelectedSubCategory('Integrations')
                handleDrawerToggle('Settings')
                navigate(rootEnum.INTEGRATIONS)
              },
            },
            {
              name: 'Roles & Permissions',
              action: () => {
                setSelectedSubCategory('Roles & Permissions')
                handleDrawerToggle('Settings')
                navigate(rootEnum.ROLES_PERMISSIONS)
              },
            },
            {
              name: 'User Managment',
              action: () => {
                setSelectedSubCategory('User Managment')
                handleDrawerToggle('Settings')
                navigate(rootEnum.USER_MANAGEMENT)
              },
            },
          ],
      },
    ],
    [selectedCategory, isTenantUser]
  )

  return {
    appBarOpen,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    categories,
    navigate,
    setAppBarOpen,
    handleDrawerToggle,
  }
}

export default useNavigation
