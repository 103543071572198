/* eslint-disable react/prop-types */
import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery'
import { useGetJourneysByTenantIdQuery } from '../../app/redux-fetch/apiShipment'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import EmbeddedLegTable from './Leg/EmbeddedLegTable'
import EmbeddedLoadTable from './Load/EmbeddedLoadTable'
import EmbeddedRoutesTable from './Route/EmbeddedRouteTable'
import ViewJourneyAction from './components/ViewJourneyAction'
import useJourneyStepperContext from './hooks/useJourneyStepperContext'
import { messages } from './messages'
import { Journey } from './types'
import { countRoutes, countUniqueLoadIds, getAllRoutes, getAllUniqueLoads } from './utils'

const JourneyTable = () => {

    const { formatMessage } = useIntl()

    const [selectedJourneyId, setSelectedJourneyId] = useState('not selected')

    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { data: customers = [], isSuccess: isCustomersLoaded } = useGetCustomersQuery(tenantId)

    const { data: journeys, isSuccess: isJourneyLoaded } = useGetJourneysByTenantIdQuery({
        tenantId,
    })

    const [isLegSelected, setIsLegSelected] = useState(false)
    const [isRouteSelected, setIsRouteSelected] = useState(false)
    const [isLoadSelected, setIsLoadSelected] = useState(false)

    const { goToStep, reset, setSelectedJourney, setMode } = useJourneyStepperContext()

    const columns = useMemo<MRTColumnDef<Journey>[]>(() => {
        return [
            {
                id: 'id',
                accessorFn: (row) => {
                    return `Journey ${row.id}`
                },
                Cell: ({ cell }: any) => {
                    return (<Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>)
                },
                header: formatMessage(messages.journeyName),
            },
            {
                accessorFn: (row) => {
                    const legsCount = row.legs.length
                    return legsCount
                },
                enableSorting: false,
                Cell: ({ cell, table, row }) => {
                    return <ViewJourneyAction text={cell.getValue<string>()} onClick={() => {
                        table.setExpanded({ [row.id]: !isLegSelected })
                        setIsLoadSelected(false)
                        setIsLegSelected(pv => !pv)
                        setIsRouteSelected(false)
                        setSelectedJourneyId(row.id)
                    }} />
                },
                Header: ({ column, header }) => {
                    if (isLegSelected) {
                        return (<Box >
                            <Typography variant="subtitle1" component="h2" style={{ textDecoration: 'underline', fontWeight: 700 }}>
                                {column.columnDef.header}
                            </Typography>
                        </Box>)
                    }
                    else {
                        return column.columnDef.header
                    }
                },
                header: formatMessage(messages.legsName),
            },
            {
                accessorFn: (row) => {
                    const routesCount = countRoutes(row)
                    return routesCount;
                },
                enableSorting: false,
                Cell: ({ cell, table, row }) => {
                    return <ViewJourneyAction text={cell.getValue<string>()} onClick={() => {
                        setSelectedJourneyId(row.id)
                        table.setExpanded({ [row.id]: !isRouteSelected })
                        setIsLoadSelected(false)
                        setIsLegSelected(false)
                        setIsRouteSelected(pv => !pv)
                    }} />
                },
                Header: ({ column, header }) => {
                    if (isRouteSelected) {
                        return (
                            <Box >
                                <Typography variant="subtitle1" component="h2" style={{ textDecoration: 'underline', fontWeight: 700 }}>
                                    {column.columnDef.header}
                                </Typography>
                            </Box>
                        );
                    }
                    else {
                        return column.columnDef.header
                    }
                },
                header: formatMessage(messages.routesName),
            },
            {
                accessorFn: (row) => {
                    const loadsCount = countUniqueLoadIds(row)
                    return loadsCount
                },
                enableSorting: false,
                Cell: ({ cell, table, row }) => {
                    return <ViewJourneyAction text={cell.getValue<string>()} onClick={() => {
                        table.setExpanded({ [row.id]: !isLoadSelected })
                        setIsLoadSelected(pv => !pv)
                        setIsLegSelected(false)
                        setIsRouteSelected(false)
                        setSelectedJourneyId(row.id)
                    }} />
                },
                Header: ({ column, header }) => {
                    if (isLoadSelected) {
                        return (<Box >
                            <Typography variant="subtitle1" component="h2" style={{ textDecoration: 'underline', fontWeight: 700 }}>
                                {column.columnDef.header}
                            </Typography>
                        </Box>)
                    }
                    else {
                        return column.columnDef.header
                    }
                },
                header: formatMessage(messages.loadsName),
            },
            {
                accessorFn: (row) => {
                    return row.isApproved ? formatMessage(messages.approvedStatus) : formatMessage(messages.draftStatus)
                },
                header: formatMessage(messages.statusName),
            }
        ]
    }, [isRouteSelected, isLegSelected, isLoadSelected, selectedJourneyId, journeys])

    return (

        <>
            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    sorting: [
                    {
                    id: 'id',
                    desc: false,
                    }]}
                }
                state={{ isLoading: !(isJourneyLoaded && isCustomersLoaded) }}
                rightHeadingComponent={
                    <AddButton
                        text={formatMessage(messages.newJourney)}
                        onClick={() => {
                            reset()
                            setMode('create')
                            goToStep(1)
                        }}
                    />
                }
                columns={columns}
                data={journeys || []}
                getRowId={(row) => row.id as any}
                enableEditing
                withChild={true}
                enableSorting={true}
                enableFilters={false}
                enableColumnActions={false}
                enableExpandAll={true}
                enableRowActions
                positionActionsColumn='last'
                muiExpandButtonProps={({ table }) => ({
                    onClick: () => {
                        if (isLegSelected || isRouteSelected || isLoadSelected) {
                            setIsLegSelected(false)
                            setIsRouteSelected(false)
                            setIsLoadSelected(false)
                        }
                        table.setExpanded({})
                    },
                })}
                renderDetailPanel={({ row }) => {
                    if (isLegSelected) {
                        return (
                            <EmbeddedLegTable legs={row.original.legs} journey={row.original} />
                        )
                    }
                    else if (isRouteSelected) {
                        return (
                            <EmbeddedRoutesTable routes={getAllRoutes(row.original)} journey={row.original} />
                        )
                    }
                    else if (isLoadSelected) {
                        return (
                            <EmbeddedLoadTable loads={getAllUniqueLoads(row.original)} journey={row.original} customers={customers} />
                        )
                    }
                }}
                renderRowActions={({ row, table }: any) => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                {!row.original.isApproved && (
                                    <>
                                        <Link
                                            underline='none'
                                            color='secondary'
                                            onClick={() => {
                                                setMode('edit')
                                                setSelectedJourney(row.original)
                                                goToStep(3)
                                            }}
                                            sx={{
                                                fontWeight: 700,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {formatMessage(messages.editLink)}
                                        </Link>
                                        <Typography color='secondary'>|</Typography>
                                    </>)}
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setMode('view')
                                        goToStep(3)
                                        setSelectedJourney(row.original)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.viewLink)}
                                </Link>
                            </Box>
                        </>
                    )
                }}
            />
        </>

    )
}

export default JourneyTable
