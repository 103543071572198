import { createContext, useContext } from 'react'
import { ITransportJourney } from '../../Transport/types'

type JourneyStepperContextType = {
  currentStep: number
  isMultiModalJourney: boolean
  withLoadJourney: boolean
  originId: number
  destinationId: number
  selectedLoads: number[]
  selectedJourney: ITransportJourney | undefined
  mode: 'create' | 'edit' | 'view'
  setMode: React.Dispatch<React.SetStateAction<'create' | 'edit' | 'view'>>
  nextStep: () => void
  prevStep: () => void
  goToStep: (step: number) => void
  setIsMultiModalJourney: React.Dispatch<React.SetStateAction<boolean>>
  setWithLoadJourney: React.Dispatch<React.SetStateAction<boolean>>
  setOriginId: React.Dispatch<React.SetStateAction<number>>
  setDestinationId: React.Dispatch<React.SetStateAction<number>>
  setSelectedLoads: React.Dispatch<React.SetStateAction<number[]>>
  setSelectedJourney: React.Dispatch<React.SetStateAction<ITransportJourney | undefined>>
  reset: () => void
}

export const JourneyStepperContext = createContext<JourneyStepperContextType | undefined>(undefined)

const useJourneyStepperContext = () => {
  const context = useContext(JourneyStepperContext)

  if (!context) {
    throw new Error('useJourneyStepperContext must be used within a JourneyStepperProvider')
  }

  return context
}

export default useJourneyStepperContext
