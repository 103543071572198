import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Link } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import {
  useDeleteAssetConfigurationMutation,
  useGetAssetConfigurationsQuery,
} from '../../../../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiQuery'
import AddButton from '../../../../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../../../../components/MRTDataGrid/MRTDataGridv2'
import YesNoTypography from '../../../../../components/YesNoTypograpphy/YesNoTypography'
import SideModalWrapper from '../../../../AssetsManagement/modals/SideModalWrapper'
import { useNotificationStack } from '../../../../Shipments/hooks/useNotificationStack'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import { messages } from '../../messages'
import { AssetConfiguration } from '../../types'
import CreateAssetConfigurationModal from './CreateAssetConfigurationModal'
import EditAssetConfigurationModal from './EditAssetConfigurationModal'

const AssetConfigurationTable = () => {
  const { formatMessage } = useIntl()

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: assetConfigurations = [], isSuccess: assetConfigurationLoaded } =
    useGetAssetConfigurationsQuery({ tenantId })
  const { data: transportModes = [], isSuccess: transportModeLoaded } = useGetTransportModeQuery()

  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const { enqueue, enqueueFailureMsg } = useNotificationStack()
  const [selectedAssetConfigurationId, setSelectedAssetConfigurationId] = useState(-1)
  const [deleteAssetConfiguration] = useDeleteAssetConfigurationMutation()

  const columns = useMemo<MRTColumnDef<AssetConfiguration>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: formatMessage(messages.configurationName),
      },
      {
        accessorFn: (row) => {
          return transportModes.find((tm) => tm.id === row.transportModeId)?.name
        },
        header: formatMessage(messages.transportMode),
      },
      {
        accessorFn: (row) => {
          return row.allocations?.length
        },
        header: formatMessage(messages.numberOfAssets),
      },
      {
        accessorFn: (row) => {
          return row.customerSelectable ? 'true' : 'false'
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.customerSelectable),
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        }
      },
      {
        accessorFn: (row) => {
          return row.active ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.enabled),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        }
      },
    ]
  }, [assetConfigurations, transportModes])

  return (
    <>
      <SideModalWrapper
        open={createOpen}
        key={'create'}
        headerLabel={formatMessage(messages.newEditAssetConfiguration)}
        onClose={() => {
          setCreateOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <CreateAssetConfigurationModal />
      </SideModalWrapper>
      <SideModalWrapper
        open={editOpen}
        key={'edit'}
        headerLabel={formatMessage(messages.newEditAssetConfiguration)}
        onClose={() => {
          setEditOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <EditAssetConfigurationModal
          assetConfiguration={
            assetConfigurations.find((ac) => ac.id === selectedAssetConfigurationId) ??
            ({} as AssetConfiguration)
          }
        />
      </SideModalWrapper>
      <SideModalWrapper
        open={viewOpen}
        key={'view'}
        headerLabel={formatMessage(messages.newEditAssetConfiguration)}
        onClose={() => {
          setViewOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <EditAssetConfigurationModal
          isDisabled={true}
          assetConfiguration={
            assetConfigurations.find((ac) => ac.id === selectedAssetConfigurationId) ??
            ({} as AssetConfiguration)
          }
        />
      </SideModalWrapper>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          sorting: [
            {
              id: 'name',
              desc: false,
            }
          ]
        }
        }
        state={{ isLoading: !assetConfigurationLoaded || !transportModeLoaded }}
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newAssetConfiguration)}
            onClick={() => setCreateOpen(true)}
          />
        }
        columns={columns ?? []}
        data={assetConfigurations}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedAssetConfigurationId(row.id)
                    setEditOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editActionLbl)} /
                </Link>

                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedAssetConfigurationId(row.id)
                    setViewOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.viewActionLbl)}
                </Link>
                <CancelIcon
                  color='secondary'
                  sx={{
                    cursor: 'pointer',
                    pl: '4px',
                  }}
                  onClick={() => {
                    deleteAssetConfiguration({ tenantId, assetConfigurationId: row.id }).then(() => {
                      enqueue('Asset Configuration deleted successfully.')
                    })
                      .catch(() => {
                        enqueueFailureMsg('Failed to delete asset configuration')
                      })
                  }}
                />
              </Box>
            </>
          )
        }}
      />
    </>
  )
}

export default AssetConfigurationTable
