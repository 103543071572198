import { Box, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAppSelector } from '../../app/hooks';
import { useGetShipmentsReportQuery } from '../../app/redux-fetch/apiQuery';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';
import { useDashboard } from './hooks/useDashboard';

const DashboardHeader = () => {
    const { timeFrame, setTimeFrame } = useDashboard();

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { data, isSuccess, refetch } = useGetShipmentsReportQuery({
        tenantId: loggedInUser.tenantId,
        startDate: timeFrame.startDate.toISOString(),
        endDate: timeFrame.endDate.toISOString()
    },
        { refetchOnMountOrArgChange: true }
    )

    const handleStartDateChange = (newValue: any) => {
        setTimeFrame({ ...timeFrame, startDate: newValue });
    };

    const handleEndDateChange = (newValue: any) => {
        setTimeFrame({ ...timeFrame, endDate: newValue });
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h4' color='primary.main' sx={{ fontWeight: 700, alignSelf: 'center' }}>Quick Stats</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ marginRight: 2, color: '#999B9F' }}>Select timeframe: </Typography>
                <DesktopDatePicker
                    format='DD/MM/YYYY'
                    value={dayjs(timeFrame.startDate)}
                    onChange={(e: any) => {
                        handleStartDateChange(e)
                    }}
                    slotProps={{
                        textField: {
                            InputLabelProps: {
                                shrink: true
                            },
                            InputProps: {
                                sx: {
                                    width: '150px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: 0,
                                    },
                                }
                            }
                        }
                    }}
                />
                <DesktopDatePicker
                    format='DD/MM/YYYY'
                    value={dayjs(timeFrame.endDate)}
                    onChange={(e: any) => {
                        handleEndDateChange(e)
                    }}
                    slotProps={{
                        textField: {
                            InputLabelProps: {
                                shrink: true
                            },
                            InputProps: {

                                sx: {
                                    width: '150px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                        borderRadius: 0,
                                    },
                                }
                            }
                        }
                    }}
                />
            </Box>
        </Box>
    )
}

export default DashboardHeader;
