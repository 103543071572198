import { Box } from '@mui/material'
import { MRT_RowSelectionState as MRTRowSelectionState } from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useGetSecurityPrincipalsQuery } from '../../../app/redux-fetch/apiQuery'
import CommonButton from '../../../components/Buttons/CommonButton'
import RemoveButton from '../../../components/Buttons/RemoveButton'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography'
import { useRoleFormContext } from './hooks/useRoleFormContext'
import messages from './messages'
import { ISecurityPrincipal } from './types'

type SecurityPrincipalProps = {
    isDisabled: boolean
}


const SecurityPrincipal = ({ isDisabled }: SecurityPrincipalProps) => {

    const { values, setFieldValue } = useRoleFormContext()
    const { securityPrincipals: roleSecurityPrincipal } = values
    const [isEditMembersMode, setIsEditMembersMode] = useState(false)
    const { formatMessage } = useIntl()
    const { securityPrincipals: savedSecurityPrincipals } = values

    const { data: allSecurityPrincipals, isSuccess } = useGetSecurityPrincipalsQuery()


    const [selectedMembers, setSelectedMembers] = useState<MRTRowSelectionState>({})


    useEffect(() => {
        if (isEditMembersMode) {
            const selectedSecurityPrincipal = allSecurityPrincipals?.filter((securityPrincipal: ISecurityPrincipal) => {
                return securityPrincipal.id && selectedMembers[securityPrincipal.id]
            })
            setFieldValue('securityPrincipals', selectedSecurityPrincipal ?? [])

        }
    }, [selectedMembers])


    useEffect(() => {
        if (isEditMembersMode && selectedMembers && Object.keys(selectedMembers).length !== 0) {

            const unSelectedMembers = savedSecurityPrincipals?.filter((securityPrincipal: ISecurityPrincipal) => {
                return !selectedMembers[securityPrincipal.id ?? -1]
            })


            const spToUnselect = allSecurityPrincipals?.filter((sp => {
                return sp.prerequisites?.some(p => unSelectedMembers?.some(usm => usm.id === p.id))
            }))

            setSelectedMembers((prev) => {
                const newSelectedMembers = { ...prev };
                let hasChanged = false;

                if (unSelectedMembers && unSelectedMembers?.length > 0) {
                    for (const key in newSelectedMembers) {

                        if (Object.prototype.hasOwnProperty.call(newSelectedMembers, key)) {
                            const element = newSelectedMembers[key];
                            newSelectedMembers[key] = spToUnselect?.some((sp) => sp.id === Number(key)) ? false : element;
                            hasChanged = true;
                        }
                    }
                }
                if (unSelectedMembers?.length === 0) {
                    const preRequisiteIds: number[] = [];

                    for (const key in newSelectedMembers) {
                        if (allSecurityPrincipals && newSelectedMembers[key]) {
                            const securityPrincipal = allSecurityPrincipals.find((securityPrincipal: ISecurityPrincipal) => securityPrincipal.id === Number(key));
                            if (securityPrincipal && securityPrincipal.prerequisites) {
                                preRequisiteIds.push(...securityPrincipal.prerequisites.map((prerequisite) => prerequisite.id ?? -1));
                            }
                        }
                    }
                    const setRreRequisiteIds = new Set(preRequisiteIds)

                    setRreRequisiteIds.forEach((id) => {
                        if (!newSelectedMembers[id]) {
                            newSelectedMembers[id] = true
                            hasChanged = true;
                        }
                    });
                }
                return hasChanged ? newSelectedMembers : prev;
            });

        }
    }, [isEditMembersMode, selectedMembers, allSecurityPrincipals, savedSecurityPrincipals]);

    const securityPrincipals = useMemo(() => {
        if (isEditMembersMode && allSecurityPrincipals) {
            return allSecurityPrincipals.map((securityPrincipal: ISecurityPrincipal) => {
                return {
                    ...securityPrincipal,
                }
            }) ?? [];
        }
        return roleSecurityPrincipal?.map((securityPrincipal: ISecurityPrincipal) => {
            return {
                ...securityPrincipal,
            }
        }) ?? [];
    }, [allSecurityPrincipals, isEditMembersMode, roleSecurityPrincipal]);

    const columns = useMemo(() => {
        return [
            {
                accessorFn: (row: any) => {
                    return row.name
                },
                header: formatMessage(messages.name)
            }
        ];
    }, []);

    return (
        <>
            <Box sx={{
                zIndex: 1,
                paddingBottom: '30px',
                pt: '30px'
            }}>
                <MRTDataGridv2
                    leftHeadingComponent={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <TableTitleTypography sx={{ fontSize: '20px' }}>
                                {formatMessage(messages.securityPrincipals)}
                            </TableTitleTypography>
                        </Box>
                    }
                    rightHeadingComponent={
                        !isDisabled ? (<Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                            }}
                        >
                            <Box>
                                {!isEditMembersMode ? (
                                    <CommonButton
                                        text={formatMessage(messages.editButton)}
                                        onClick={() => {
                                            const predefinedSecurityPrincipals = roleSecurityPrincipal?.reduce((acc, securityPrincipal: ISecurityPrincipal) => {
                                                if (securityPrincipal.id) acc[securityPrincipal.id] = true
                                                return acc;
                                            }, {} as Record<number, boolean>);
                                            setIsEditMembersMode(true)
                                            setSelectedMembers(predefinedSecurityPrincipals ?? {})
                                        }}
                                    />
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <RemoveButton
                                            text={formatMessage(messages.cancelButton)}
                                            onClick={
                                                () => {
                                                    setIsEditMembersMode(false)
                                                    setSelectedMembers({})
                                                }
                                            }
                                        />
                                    </Box>

                                )}

                            </Box>
                        </Box>) : <></>

                    }
                    columns={columns}
                    data={securityPrincipals || []}
                    getRowId={(row) => row.id as any}
                    enableRowSelection={isEditMembersMode}
                    onRowSelectionChange={setSelectedMembers}
                    state={{
                        rowSelection: selectedMembers,
                        isLoading: !isSuccess
                    }}
                    positionActionsColumn='last'
                />
            </Box>

        </>
    );
}

export default SecurityPrincipal;