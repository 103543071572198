import { type AccountInfo } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'
import { Person, PersonViewType } from '@microsoft/mgt-react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React, { type ReactElement } from 'react'
import { useAppSelector } from '../../app/hooks'
import CustomerTenantSelect from '../../features/UserManagement/Components/CustomerTenantSelect/CustomerTenantSelect'
import { fetchLoggedInUserSelector } from '../../features/selectors'
import { ILoggedInUser } from '../../features/types'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

const settings = ['Logout']

const AppBar = (): ReactElement<any, any> => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const user = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }
  const handleLogout = (): void => {
    void instance.logout()
  }

  const { instance } = useMsal()

  const accountInfo: AccountInfo | null = instance.getActiveAccount()

  return (
    <>
      {accountInfo && (
        <>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem disabled={true}>
              <Typography textAlign='center'>
                {user !== null ? `${user?.firstName} ${user?.lastName}` : 'Omnialog'}
              </Typography>
            </MenuItem>

            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleLogout}>
                <Typography textAlign='center'>{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <CustomerTenantSelect user={user} />
          <LanguageSelector />
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Person personQuery='me' view={PersonViewType.twolines} avatarType='initials' />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  )
}

export default AppBar
