import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { useGetBrandQuery } from '../../../app/redux-fetch/apiAssets';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import FormDropdown from '../../../components/FormDropDown/FormDropdown';
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../components/Typographies/styles';
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext';
import { messages } from '../messages';
import { getMaxDate, getMinDate } from '../utils/utils';


export function AssetDetailGroup({ disabled }: { disabled?: boolean }) {
  const { formatMessage } = useIntl()
  const { values, errors, touched, setFieldValue } = useAssetManagementFormContext()
  const { detail } = values

  const registrationExpiryMinDate = getMinDate(values.detail?.registrationDate)
  const registrationDateMaxDate = getMaxDate(values.detail?.registrationExpiry)
  const { data: brands = [] } = useGetBrandQuery()

  return (
    <>
      <Box>
        <Box
          sx={{ mb: '16px', display: 'flex', height: '32px', justifyContent: 'space-between', alignItems: 'center' }}

        >
          <SectionTitleTypography>{formatMessage(messages.assetsDetails)}</SectionTitleTypography>
        </Box>
        <Box sx={{ mb: '8px' }}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.assetsDetailsBrand)}
          </SectionLabelTypography>
          <FormDropdown
            id='brandId'
            items={brands}
            onChange={(_e: any, _name: string, value: number) => {
              setFieldValue('detail.brandId', value)
              setFieldValue('detail.manufacturerId', value)
            }}
            value={detail?.brandId}
            defaultValue={detail?.brandId}
            disabled={disabled || isEmpty(brands)}
          />
        </Box>
        <Box sx={{ mb: '8px' }}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.assetsDetailsModel)}
          </SectionLabelTypography>
          <TextField
            name='model'
            onChange={({ target }) => setFieldValue('detail.model', target.value)}
            disabled={disabled}
            value={detail?.model}
            defaultValue={detail?.model}
            sx={{ pb: '3px' }}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1, mr: 2 }}>
            <Box sx={{ mb: '8px', flex: 1 }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '5.5px' }}>
                {formatMessage(messages.assetsDetailsProductionYear)}
              </SectionLabelTypography>
              <NumericFormat
                id={'productionYear'}
                name={'productionYear'}
                customInput={TextField}
                fullWidth={true}
                decimalScale={3}
                allowNegative={false}
                multiline
                maxLength={4}
                sx={{ width: '100%', pb: '3px' }}
                onValueChange={(vals) => {
                  setFieldValue('detail.productionYear', vals.floatValue)
                }}
                value={detail.productionYear}
                inputProps={{ maxLength: 4 }}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
                error={touched.detail && (touched.detail as any).productionYear && !!(errors.detail as any)?.productionYear}
                helperText={(errors.detail as any)?.productionYear}
              />
            </Box>
            <Box sx={{ mb: '8px', flex: 1 }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsRegistrationNumber)}
              </SectionLabelTypography>
              <TextField
                name='registrationNumber'
                onChange={({ target }) => setFieldValue('detail.registrationNumber', target.value)}
                disabled={disabled}
                value={detail?.registrationNumber}
                sx={{ pb: '3px' }}
                defaultValue={detail?.registrationNumber}
                placeholder='Registration / Plate No.'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsRegistrationDate)}
              </SectionLabelTypography>
              <DatePicker
                name={'registrationDate'}
                value={detail?.registrationDate}
                disablePast={false}
                disabled={disabled}
                maxDate={registrationDateMaxDate}
                onChange={(date) => setFieldValue('detail.registrationDate', date)}
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsInsuranceExpiry)}
              </SectionLabelTypography>
              <DatePicker
                name={'insuranceExpiry'}
                value={detail?.insuranceExpiry}
                disabled={disabled}
                onChange={(date) => setFieldValue('detail.insuranceExpiry', date)}
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsOperationCardExpiry)}
              </SectionLabelTypography>
              <DatePicker
                name={'operationCardExpiry'}
                value={detail?.operationCardExpiry}
                disabled={disabled}
                onChange={(date) => setFieldValue('detail.operationCardExpiry', date)}
              />
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ mb: '8px', flex: 1 }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '5.5px' }}>
                {formatMessage(messages.assetsDetailsNumberOfDoors)}
              </SectionLabelTypography>
              <TextField
                name='numberOfDoors'
                onChange={({ target }) => setFieldValue('detail.numberOfDoors', target.value)}
                disabled={disabled}
                value={detail?.numberOfDoors}
                sx={{ pb: '3px' }}
                defaultValue={detail?.numberOfDoors}
                type='number'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsChassisNumber)}
              </SectionLabelTypography>
              <TextField
                name='chassisNumber'
                onChange={({ target }) => setFieldValue('detail.chassisNumber', target.value)}
                disabled={disabled}
                value={detail?.chassisNumber}
                defaultValue={detail?.chassisNumber}
                sx={{ pb: '3px' }}
                placeholder='Fleet No.'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsRegistrationExpiry)}
              </SectionLabelTypography>
              <DatePicker
                name={'registrationExpiry'}
                value={detail?.registrationExpiry}
                minDate={registrationExpiryMinDate}
                disabled={disabled || !detail?.registrationDate}
                onChange={(date) => setFieldValue('detail.registrationExpiry', date)}
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsMvpiExpiry)}
              </SectionLabelTypography>
              <DatePicker
                name={'mvpiExpiry'}
                value={detail?.mvpiExpiry}
                disabled={disabled}
                onChange={(date) => setFieldValue('detail.mvpiExpiry', date)}
              />
            </Box>
            <Box sx={{ mb: '8px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.assetsDetailsMaintenanceEndDate)}
              </SectionLabelTypography>
              <DatePicker
                name={'maintenanceEndDate'}
                value={detail?.maintenanceEndDate}
                disabled={disabled}
                onChange={(date) => setFieldValue('detail.maintenanceEndDate', date)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
