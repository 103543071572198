import { defineMessages } from 'react-intl'

const messages = defineMessages({
  tabGeneral: {
    id: 'tenantSettings.userGroup.tabGeneral',
    description: 'General tab',
    defaultMessage: 'General',
  },
  tabUsers: {
    id: 'tenantSettings.userGroup.tabUsers',
    description: 'Members tab',
    defaultMessage: 'Members',
  },
  tabRoles: {
    id: 'tenantSettings.userGroup.tabRoles',
    description: 'Roles tab',
    defaultMessage: 'Roles',
  },
  firstName: {
    id: 'tenantSettings.userGroup.firstName',
    description: 'First Name',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'tenantSettings.userGroup.lastName',
    description: 'Last Name',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'tenantSettings.userGroup.email',
    description: 'Email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'tenantSettings.userGroup.phone',
    description: 'Phone',
    defaultMessage: 'Mobile Phone',
  },
  editButton: {
    id: 'tenantSettings.userGroup.editButton',
    description: 'Edit button',
    defaultMessage: 'Edit',
  },
  viewButton: {
    id: 'tenantSettings.userGroup.viewButton',
    description: 'View button',
    defaultMessage: 'View',
  },
  cancelButton: {
    id: 'tenantSettings.userGroup.cancelButton',
    description: 'Cancel button',
    defaultMessage: 'Cancel',
  },
  nameLabel: {
    id: 'tenantSettings.userGroup.nameLabel',
    description: 'Name label',
    defaultMessage: 'Name',
  },
  descriptionLabel: {
    id: 'tenantSettings.userGroup.descriptionLabel',
    description: 'Description label',
    defaultMessage: 'Description',
  },
  activeLabel: {
    id: 'tenantSettings.userGroup.activeLabel',
    description: 'Active label',
    defaultMessage: 'Active',
  },
  yesLabel: {
    id: 'tenantSettings.userGroup.yesLabel',
    description: 'Yes label',
    defaultMessage: 'Yes',
  },
  noLabel: {
    id: 'tenantSettings.userGroup.noLabel',
    description: 'No label',
    defaultMessage: 'No',
  },
  createUserGroupLabel: {
    id: 'tenantSettings.userGroup.createUserGroupLabel',
    description: 'Create user group label',
    defaultMessage: 'Create User Group',
  },
  editUserGroupLabel: {
    id: 'tenantSettings.userGroup.editUserGroupLabel',
    description: 'Edit user group label',
    defaultMessage: 'Edit User Group',
  },
  viewUserGroupLabel: {
    id: 'tenantSettings.userGroup.viewUserGroupLabel',
    description: 'View user group label',
    defaultMessage: 'View User Group',
  },
  newUserGroupLabel: {
    id: 'tenantSettings.userGroup.newUserGroupLabel',
    description: 'New user group label',
    defaultMessage: 'New User Group',
  },
  nameIsRequired: {
    id: 'tenantSettings.userGroup.nameIsRequired',
    description: 'Name is required',
    defaultMessage: 'Name is required',
  },
  descriptionIsRequired: {
    id: 'tenantSettings.userGroup.descriptionIsRequired',
    description: 'Description is required',
    defaultMessage: 'Description is required',
  },
  userGroupCreated: {
    id: 'tenantSettings.userManagement.users.userGroupCreated',
    description: 'User Group created API msg',
    defaultMessage: 'User Group Created',
  },
  userGroupUpdated: {
    id: 'tenantSettings.userManagement.users.userGroupUpdated',
    description: 'User Type is required',
    defaultMessage: 'User Group Updated',
  },
  userGroupApiError: {
    id: 'tenantSettings.userManagement.users.userGroupApiError',
    description: 'BE returns API unhandled error',
    defaultMessage: 'Something went wrong, try again later ',
  },
})

export default messages
