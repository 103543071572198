/* eslint-disable no-param-reassign */
import { useMsal } from '@azure/msal-react'
import axios, { AxiosError, AxiosResponse, type AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import { useIntl } from 'react-intl'
import { loginRequest } from '../../authConfig'
import localizedMessages from './messages'

import { InteractionRequiredAuthError } from '@azure/msal-browser'
import {
  makeAssetsApiHeaders,
  makeCoresApiHeaders,
  makeCustomerSettingsApiHeaders,
  makeShipmentsApiHeaders,
  makeUsersApiHeaders,
} from '../headers'
import logger from '../middleware/log'
import {
  OMNIALOG_CORE_API_URL,
  OMNILOG_ASSETS_API_URL,
  OMNILOG_SHIPMENTS_API_URL,
  OMNILOG_USERS_API_URL,
} from './const'

export const shipmentsUnathorizedOmnilogFetch = axios.create({
  baseURL: OMNILOG_SHIPMENTS_API_URL,
  headers: makeShipmentsApiHeaders(),
})

export const usersUnathorizedOmnilogFetch = axios.create({
  baseURL: OMNILOG_SHIPMENTS_API_URL,
  headers: makeUsersApiHeaders(),
})

export const coresUnathorizedOmnialogFetch = axios.create({
  baseURL: OMNILOG_SHIPMENTS_API_URL,
  headers: makeCoresApiHeaders(),
})

export let shipmentsAuthorizedFetch: AxiosInstance = shipmentsUnathorizedOmnilogFetch
export let usersAuthorizedFetch: AxiosInstance = usersUnathorizedOmnilogFetch
export let coresAuthorizedFetch: AxiosInstance = coresUnathorizedOmnialogFetch
export let assetsAuthorizedFetch: AxiosInstance = usersUnathorizedOmnilogFetch
export let customerSettingsAuthorizedFetch: AxiosInstance = usersUnathorizedOmnilogFetch

export const googleApiFetch: AxiosInstance = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api',
})

export const AuthorizedAxios = async (): Promise<void> => {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const { formatMessage } = useIntl()
  const formattedError = formatMessage(localizedMessages.noActiveAccount)

  shipmentsAuthorizedFetch = axios.create({
    baseURL: OMNILOG_SHIPMENTS_API_URL,
    headers: makeShipmentsApiHeaders(),
  })

  usersAuthorizedFetch = axios.create({
    baseURL: OMNILOG_USERS_API_URL,
    headers: makeShipmentsApiHeaders(),
  })

  axiosRetry(usersAuthorizedFetch, {
    retries: 3,
    retryCondition: (error: any) => {
      logger.error(error)
      return true
    },
  })

  assetsAuthorizedFetch = axios.create({
    baseURL: OMNILOG_ASSETS_API_URL,
    headers: makeAssetsApiHeaders(),
  })
  coresAuthorizedFetch = axios.create({
    baseURL: OMNIALOG_CORE_API_URL,
    headers: makeCoresApiHeaders(),
  })
  customerSettingsAuthorizedFetch = axios.create({
    baseURL: OMNILOG_SHIPMENTS_API_URL,
    headers: makeCustomerSettingsApiHeaders(),
  })

  // Interceptors
  shipmentsAuthorizedFetch.interceptors.request.use(async (config) => {
    if (account == null) {
      throw Error(formattedError)
    }

    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest)
        }
        logger.error(error)
      })

    const bearer = `Bearer ${response?.accessToken}`
    config.headers.Authorization = bearer

    return config
  })

  coresAuthorizedFetch.interceptors.request.use(async (config) => {
    if (account == null) {
      throw Error(formattedError)
    }

    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest)
        }
        logger.error(error)
      })

    const bearer = `Bearer ${response?.accessToken}`
    config.headers.Authorization = bearer

    return config
  })

  usersAuthorizedFetch.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        // Logout user if you get a 401
        instance.logout()
      }
      return Promise.reject(error)
    }
  )
  usersAuthorizedFetch.interceptors.request.use(async (config) => {
    if (account == null) {
      throw Error(formattedError)
    }

    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest)
        }
        logger.error(error)
      })

    const bearer = `Bearer ${response?.accessToken}`
    config.headers.Authorization = bearer

    return config
  })

  assetsAuthorizedFetch.interceptors.request.use(async (config) => {
    if (account == null) {
      throw Error(formattedError)
    }

    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest)
        }
        logger.error(error)
      })
    const bearer = `Bearer ${response?.accessToken}`
    config.headers.Authorization = bearer

    return config
  })

  customerSettingsAuthorizedFetch.interceptors.request.use(async (config) => {
    if (account == null) {
      throw Error(formattedError)
    }

    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest)
        }
        logger.error(error)
      })

    const bearer = `Bearer ${response?.accessToken}`
    config.headers.Authorization = bearer

    return config
  })
}
