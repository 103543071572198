import { Box } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useAppSelector } from '../../app/hooks'
import { useCreateUserMutation, useGetUserTypesQuery, useUpdateUserMutation } from '../../app/redux-fetch/apiQuery'
import SubmitButtons from '../../components/Buttons/SubmitButtons'
import { useSideModalContext } from '../AssetsManagement/modals/SideModalContext'
import Documents from '../Documents/Documents'
import { useNotificationStack } from '../Shipments/hooks/useNotificationStack'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import DriverInfo from './DriverInfo'
import UserInfo from './UserInfo'
import { workforceSchema } from './schemas/driverValidationSchema'
import { IWorkforce } from './types'

type WorkforceFormProps = {
    workforce: IWorkforce,
    isDisabled?: boolean
}

const WorkforceForm = ({ workforce, isDisabled }: WorkforceFormProps) => {
    const { onClose } = useSideModalContext()

    const isNew = !workforce.id
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const tenantId = loggedInUser.tenantId ?? -1
    const customerId = loggedInUser.customerId

    const [createDriver] = useCreateUserMutation()
    const [updateDriver] = useUpdateUserMutation()

    const { enqueue, enqueueFailureMsg } = useNotificationStack()

    const { data: userTypes } = useGetUserTypesQuery({ tenantId, customerId })

    return (
        <Formik
            initialValues={workforce}
            onSubmit={async (values: FormikValues) => {
                if (isNew) {
                    await createDriver({
                        tenantId,
                        body: { ...values } as IWorkforce,
                    }).then((res: any) => {
                        if (!res?.error) {
                            enqueue('Workforce created successfully')
                        }
                    }).catch(() => {
                        enqueueFailureMsg('Failed to create workforce')
                    })
                } else {
                    await updateDriver({
                        tenantId,
                        body: { ...values } as IWorkforce,
                    }).then((res: any) => {
                        if (!res?.error) {
                            enqueue('Workforce updated successfully')
                        }
                    }).catch(() => {
                        enqueueFailureMsg('Failed to update workforce')
                    })
                }
                onClose()
            }}
            validationSchema={workforceSchema()}
        >
            {({
                values,
            }) => (
                <Form style={{ marginTop: '56px', marginBottom: '82px' }}>
                    <Box sx={{ m: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <UserInfo isNew={isNew} isDisabled={isDisabled} />
                        </Box>
                        {userTypes?.find(ut => ut.id === values.userType?.id)?.name === 'Driver' && (<Box sx={{ flex: 1, mr: 2 }}>
                            <DriverInfo isNew={isNew} isDisabled={isDisabled} />
                            <Box>
                                <Documents categoryId={1} entityId={values.id} />
                            </Box>
                        </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            width: '100%',
                            padding: '16px',
                            backgroundColor: '#fff',
                            borderTop: '1px solid #e0e0e0',
                            zIndex: 999,
                        }}
                    >
                        {!isDisabled && <SubmitButtons create={isNew} />}
                    </Box>
                </Form>)}
        </Formik>
    )
}

export default WorkforceForm
