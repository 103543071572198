import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../app/redux-fetch'
import { fetchTenantAllUsersSelector } from '../selectors'
import {
  JOURNEYS_BY_TENANT_ID_REDUCER_NAME,
  TRANSPORTS_ASSETS_CONFIGURATION_REDUCER_NAME,
  TRANSPORTS_ASSETS_REDUCER_NAME,
  TRANSPORTS_EMPLOYEE_REDUCER_NAME,
  TRANSPORTS_LEG_BY_ID_REDUCER_NAME,
  TRANSPORTS_LOADS_REDUCER_NAME,
  TRANSPORTS_REDUCER,
} from './consts'
import { IAsset, ITransportAssetConfiguration } from './types'

export const fetchTransportsLegSelector = makeTypedFetchSelectors<any[]>(
  TRANSPORTS_REDUCER,
  JOURNEYS_BY_TENANT_ID_REDUCER_NAME
)

export const fetchTransportsLegByIdSelector = makeTypedFetchSelectors<any[]>(
  TRANSPORTS_REDUCER,
  TRANSPORTS_LEG_BY_ID_REDUCER_NAME
)

export const fetchTransportsLoadsSelector = makeTypedFetchSelectors<any[]>(
  TRANSPORTS_REDUCER,
  TRANSPORTS_LOADS_REDUCER_NAME
)

export const fetchTransportsAssetsSelector = makeTypedFetchSelectors<IAsset[]>(
  TRANSPORTS_REDUCER,
  TRANSPORTS_ASSETS_REDUCER_NAME
)

export const fetchTransportsAssetConfigurationSelector = makeTypedFetchSelectors<
  ITransportAssetConfiguration[]
>(TRANSPORTS_REDUCER, TRANSPORTS_ASSETS_CONFIGURATION_REDUCER_NAME)

export const assetConfigurationByIdSelector = createSelector(
  [fetchTransportsAssetConfigurationSelector.data, (_, id) => id],
  (configs, id) => {
    return configs?.find((c) => c.id === id)
  }
)

export const assetConfigurationsByTransportModeId = createSelector(
  [fetchTransportsAssetConfigurationSelector.data, (_, transportModeId) => transportModeId],
  (configs, transportModeId) => {
    return configs ? configs.filter((c) => c.transportModeId === transportModeId) : []
  }
)

export const fetchTransportsEmployeeSelector = makeTypedFetchSelectors<any[]>(
  TRANSPORTS_REDUCER,
  TRANSPORTS_EMPLOYEE_REDUCER_NAME
)

export const transportsEmployeeDropdownSelector = createSelector(
  fetchTenantAllUsersSelector.data,
  (employes) => {
    return employes
      ? employes.map((e) => {
          return {
            id: e.id,
            name: `${e.firstName} ${e.lastName}`,
            userTypeId: e.userTypeId,
            driverDetailId: e.driverDetailId,
          }
        })
      : []
  }
)
