import * as yup from 'yup'

const driverDetail = yup.object({
  licenceNumber: yup.string().required('License number is required'),
  licenceExpiry: yup.date().required('License expiry date is required'),
})

const userType = yup.object({
  id: yup.number().required('User Type is required'),
  name: yup.string(),
})

export const workforceSchema = (): yup.AnyObjectSchema => {
  return yup
    .object({
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      email: yup.string().required('Email is required'),
      phone: yup.string().required('Phone is required'),
      userType: userType,
      driverDetail: yup.object().when('userType', (userType, schema) => {
        if (userType && userType.name === 'Driver') {
          return schema.shape(driverDetail.fields)
        }
        return schema.strip()
      }),
    })
    .required('The entire object is required')
}
