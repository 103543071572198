import { useIntl } from 'react-intl';
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper';
import AddLoadsSelection from '../JourneyForm/AddLoadsSelection';
import useJourneyStepperContext from '../hooks/useJourneyStepperContext';
import { messages } from '../messages';

const AddLoadsModal = () => {

    const { formatMessage } = useIntl()
    const { currentStep, goToStep } = useJourneyStepperContext()

    return (
        <SideModalWrapper open={currentStep === 2} onClose={() => {
            goToStep(0);
        }} onSave={() => {
            goToStep(3);
        }} headerLabel={formatMessage(messages.addLoads)} width='67.5%'>
            <AddLoadsSelection />
        </SideModalWrapper>
    );
}

export default AddLoadsModal

