import { MenuItem, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useAppSelector } from '../../../../app/hooks'
import { useGetUnassignedRoutesQuery } from '../../../../app/redux-fetch/apiQuery'
import MRTDataGrid from '../../../../components/MRTDataGrid/MRTDataGrid'
import RoutesEnum from '../../../../components/Routes/rootEnum'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { messages } from './messages'
import { IUnassginedRoute } from './types'

const UnassignedRoutes = () => {
  const navigate = useNavigate()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId
  const { data: routes, isFetching } = useGetUnassignedRoutesQuery({ tenantId })

  const columns = useMemo<MRTColumnDef<IUnassginedRoute>[]>(() => {
    return [
      {
        accessorFn: (row) => {
          return `Route ${row.routeId}`
        },
        Cell: ({ cell }: any) => {
          return <Typography >{cell.getValue()}</Typography>
        },
        header: 'Route',
      },
      {
        accessorFn: (row) => {
          return `Journey ${row.journeyId}`
        },
        Cell: ({ cell }: any) => {
          return <Typography >{cell.getValue()}</Typography>
        },
        header: 'Journey',
      },
      {
        accessorKey: 'startLocationName',
        header: 'Start',
      },
      {
        accessorKey: 'endLocationName',
        header: 'End',
      },
      {
        accessorKey: 'startDate',
        Cell: ({ cell }: any) => {
          return dayjs(cell.getValue()).format('DD/MM/YYYY')
        },
        header: 'Start Date',
      },
      {
        accessorKey: 'endDate',
        Cell: ({ cell }: any) => {
          return dayjs(cell.getValue()).format('DD/MM/YYYY')
        },
        header: 'End Date',
      },
    ]
  }, [])
  const handleCreateTransport = (id: number, journeyId: number) => {
    navigate(
      RoutesEnum.CREATE_TRANSPORT.replace(':journeyId', String(journeyId)).replace(
        ':id',
        String(id)
      )
    )
  }
  return (
    <MRTDataGrid
      key={uuidv4()}
      initialState={{
        pagination: { pageSize: 100, pageIndex: 0 },
        density: 'compact',
        sorting: [
          {
            id: 'startDate',
            desc: false,
          },
          {
            id: 'startLocationName',
            desc: false,
          },
          {
            id: 'endDate',
            desc: false,
          },
          {
            id: 'endLocationName',
            desc: false,
          },
        ],
      }}
      heading={messages.unassignedRouteTitle}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          maxSize: 64,
          minSize: 64,
        },
      }}
      state={{
        isLoading: isFetching,
      }}
      columns={columns as any[]}
      data={routes ?? []}
      enableColumnActions={false}
      enableColumnFilters={false}
      muiTableBodyRowProps={{ hover: false }}
      enableRowActions={true}
      renderRowActionMenuItems={({ row }: { row: any }) => [
        <MenuItem
          key='delete'
          onClick={() => {
            handleCreateTransport(row.original.routeId, row.original.journeyId)
          }}
        >
          Create Transport Job
        </MenuItem>,
      ]}
    />
  )
}

export default UnassignedRoutes
