import { useGetUserGroupByIdQuery } from '../../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../../components/Spinner/SpinnerModalBlock'
import UserGroupForm from './UserGroupForm'

type UserModalProps = {
    userGroupId?: string
    isDisabled?: boolean
}

const UserModal = (
    { userGroupId: userId, isDisabled }: UserModalProps
) => {

    const { data, isSuccess } = useGetUserGroupByIdQuery({
        userGroupId: userId ? parseInt(userId) : 0
    }, { refetchOnMountOrArgChange: true }
    )
    if (!isSuccess) return <SpinnerModalBlock />
    return (
        <UserGroupForm userGroup={data} isDisabled={isDisabled ?? false} />
    )
}

export default UserModal
