import { Divider, FormControlLabel, Grid, SelectChangeEvent, Switch } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import { useGetContactByEntityIdQuery } from '../../../../app/redux-fetch/apiCoreQuery'
import { IDropDownItem } from '../../../../app/types'
import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { fetchAllPickupConsigneeSelector, fetchAllPickupLocationsSelector } from '../../selectors'
import { ILoad } from '../../types'
import { handleDateChanges } from '../../utils'
import messages from './messages'
import { type ILoadDetailsProps } from './types'

type TransportDetailsProps = ILoadDetailsProps

const TransportDetails = (props: TransportDetailsProps): ReactElement => {
  const {
    formik,
    index,
    disabled,
    handleSelectLoadChanges,
    handleSwitchLoadChanges,
    load,
    disabledPast = true,
  } = props
  const { formatMessage } = useIntl()

  const { data: deliveryContacts = [] } = useGetContactByEntityIdQuery(
    formik.values.loads[index]?.transportDetail?.deliveryLocationId ?? -1,
    {
      skip: !formik.values.loads[index]?.transportDetail?.pickupLocationId,
    }
  )

  const { data: pickupContacts = [] } = useGetContactByEntityIdQuery(
    formik.values.loads[index]?.transportDetail?.pickupLocationId ?? -1,
    {
      skip: !formik.values.loads[index]?.transportDetail?.pickupLocationId,
    }
  )

  const pickUpLocations = useAppSelector(fetchAllPickupLocationsSelector)
  const consigneeLocations = useAppSelector(fetchAllPickupConsigneeSelector)
  const getError = (propertyName: string): boolean => {
    const transportDetail =
      formik.errors.loads &&
      formik.errors.loads[index] &&
      (formik.errors.loads[index] as unknown as ILoad).transportDetail
    const toucheTtransportDetail = formik.touched.loads?.[index]?.transportDetail
    const touched = toucheTtransportDetail && getProperty(toucheTtransportDetail, propertyName)
    const errorMessage = transportDetail && getProperty(transportDetail, propertyName)
    // eslint-disable-next-line
    return touched && (errorMessage as any)
  }

  const handleDropDownChange = (e: any, name: string, value: any) => {
    formik.setFieldValue(name, value)
  }
  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
          {formatMessage(messages.transportDetailTitle)}
        </Divider>
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          disablePast={disabledPast}
          disabled={disabled}
          format='DD/MM/YYYY'
          label={formatMessage(messages.pickUpDate)}
          value={
            formik.values.loads[index]?.transportDetail?.pickupDate != null
              ? dayjs(formik.values.loads[index]?.transportDetail?.pickupDate)
              : null
          }
          onChange={(e: any) => {
            handleDateChanges(e, `loads.${index}.transportDetail.pickupDate`, formik)
          }}
          slotProps={{
            textField: {
              id: `loads.${index}.transportDetail.pickupDate`,
              name: `loads.${index}.transportDetail.pickupDate`,
              fullWidth: true,
              InputLabelProps: {
                shrink: true,
              },
              error: getError('pickupDate'),
              helperText: getError('pickupDate'),
              onBlur: formik.handleBlur,
              disabled: disabled,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropdown
          id={`loads.${index}.transportDetail.pickupLocationId`}
          label={formatMessage(messages.pickUpLocation)}
          onChange={(e: SelectChangeEvent<number>, name: string, newValue: any) => {
            handleSelectLoadChanges(e, name, newValue)

            const pickUpCountryId = pickUpLocations.find((x) => x.id === newValue)?.address
              ?.countryId

            const consigneeCountryId = consigneeLocations.find(
              (x) => x.id === load.transportDetail?.deliveryLocationId
            )?.address?.countryId

            if (pickUpCountryId && consigneeCountryId && pickUpCountryId !== consigneeCountryId) {
              formik.setFieldValue(
                `loads.${index}.transportDetail.isCrossBorder`,
                !(pickUpCountryId === consigneeCountryId)
              )
            }
            if (!load.isDefined) {
              formik.setFieldValue(`loads.${index}.countryOfOriginId`, pickUpCountryId)
            }
            formik.setFieldValue(`loads.${index}.transportDetail.pickupNotifyPartyId`, undefined)
          }}
          value={formik.values.loads[index]?.transportDetail?.pickupLocationId}
          items={pickUpLocations as IDropDownItem[]}
          handleOnBlur={formik.handleBlur}
          errorText={getError('pickupLocationId')}
          error={Boolean(getError('pickupLocationId'))}
          disabled={disabled}
          showSystemEntitiesText={formatMessage(messages.locations)}
          itemsFilterForNonSystemEntities={(l) => l.locationTypeId == 255}
        />
      </Grid>
      <Grid item xs={2}>
        <FormDropdown
          id={`loads.${index}.transportDetail.pickupNotifyPartyId`}
          label={formatMessage(messages.pickupNotifyPartyLabel)}
          onChange={handleDropDownChange}
          value={formik.values.loads[index]?.transportDetail?.pickupNotifyPartyId}
          items={pickupContacts as IDropDownItem[]}
          handleOnBlur={formik.handleBlur}
          errorText={getError('pickupNotifyPartyId')}
          error={Boolean(getError('pickupNotifyPartyId'))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          disabled={disabled}
          control={
            <Switch
              size='small'
              color='secondary'
              id={`loads.${index}.transportDetail.isCrossBorder`}
              name={`loads.${index}.transportDetail.isCrossBorder`}
              value={load.transportDetail?.isCrossBorder}
              checked={load.transportDetail?.isCrossBorder}
              onChange={(e, value) => {
                handleSwitchLoadChanges(e, value)
              }}
            />
          }
          label='Cross-Border'
        />
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          disablePast={true}
          disabled={disabled}
          format='DD/MM/YYYY'
          label={formatMessage(messages.requestedDeliveryDateLabel)}
          value={
            formik.values.loads[index]?.transportDetail?.deliveryDate != null
              ? dayjs(formik.values.loads[index]?.transportDetail?.deliveryDate)
              : null
          }
          onChange={(e: any) => {
            handleDateChanges(e, `loads.${index}.transportDetail.deliveryDate`, formik)
          }}
          slotProps={{
            textField: {
              id: `loads.${index}.transportDetail.deliveryDate`,
              name: `loads.${index}.transportDetail.deliveryDate`,
              fullWidth: true,
              InputLabelProps: {
                shrink: true,
              },
              error: getError('deliveryDate'),
              helperText: getError('deliveryDate'),
              onBlur: formik.handleBlur,
              disabled: disabled,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropdown
          id={`loads.${index}.transportDetail.deliveryLocationId`}
          label={'Consignee'}
          onChange={(e: SelectChangeEvent<number>, name: string, newValue: any) => {
            handleSelectLoadChanges(e, name, newValue)

            const pickUpCountryId = pickUpLocations.find(
              (x) => x.id === load.transportDetail?.pickupLocationId
            )?.address?.countryId

            const consigneeCountryId = consigneeLocations.find((x) => x.id === newValue)?.address
              ?.countryId

            if (pickUpCountryId && consigneeCountryId && pickUpCountryId !== consigneeCountryId) {
              formik.setFieldValue(
                `loads.${index}.transportDetail.isCrossBorder`,
                !(pickUpCountryId === consigneeCountryId)
              )
            }
            formik.setFieldValue(`loads.${index}.transportDetail.deliveryNotifyPartyId`, undefined)
          }}
          value={formik.values.loads[index]?.transportDetail?.deliveryLocationId}
          items={consigneeLocations as IDropDownItem[]}
          handleOnBlur={formik.handleBlur}
          errorText={getError('deliveryLocationId')}
          error={Boolean(getError('deliveryLocationId'))}
          disabled={disabled}
          showSystemEntitiesText={formatMessage(messages.locations)}
          itemsFilterForNonSystemEntities={(l) => l.locationTypeId == 255} // 255 is user-defined
        />
      </Grid>
      <Grid item xs={2}>
        <FormDropdown
          id={`loads.${index}.transportDetail.deliveryNotifyPartyId`}
          label={formatMessage(messages.deliveryNotifyPartyLabel)}
          onChange={handleDropDownChange}
          value={formik.values.loads[index]?.transportDetail?.deliveryNotifyPartyId}
          items={deliveryContacts as IDropDownItem[]}
          handleOnBlur={formik.handleBlur}
          errorText={getError('deliveryNotifyPartyId')}
          error={Boolean(getError('deliveryNotifyPartyId'))}
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

export default TransportDetails
