import { createSelector } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import { makeTypedFetchSelectors } from '../../../../app/redux-fetch'
import {
  ASSETS_ALL_ASSET_CATEGORIES_BY_TENANT_ID,
  ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME,
  ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME,
  ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME,
  ASSETS_ALL_ASSET_TYPES_REDUCER_NAME,
  ASSETS_ALL_USER_TYPES_REDUCER_NAME,
  ASSETS_REDUCER,
} from './consts'
import { IAsset, IAssetCategory, IAssetClass, IAssetType, IWorkforceTypes } from './types'
import { processAssets } from './utils'

export const fetchAllCategoriesByTransportModeSelector = makeTypedFetchSelectors<IAssetCategory[]>(
  ASSETS_REDUCER,
  ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME
)

export const fetchAssetClassesByCategorySelector = makeTypedFetchSelectors<IAssetClass[]>(
  ASSETS_REDUCER,
  ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME
)

export const fetchAssetTypesByCategorySelector = makeTypedFetchSelectors<IAssetType[]>(
  ASSETS_REDUCER,
  ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME
)

export const fetchAssetTypeSelector = makeTypedFetchSelectors<IAssetType[]>(
  ASSETS_REDUCER,
  ASSETS_ALL_ASSET_TYPES_REDUCER_NAME
)

export const fetchUserTypesSelector = makeTypedFetchSelectors<IWorkforceTypes[]>(
  ASSETS_REDUCER,
  ASSETS_ALL_USER_TYPES_REDUCER_NAME
)

export const fetchSortedAssetCategoriesByTransportModeSelector = createSelector(
  fetchAllCategoriesByTransportModeSelector.data,
  (categories) => {
    if (!categories) return []

    const assets = processAssets(categories as IAsset[], 'category')
    return assets
  }
)

export const fetchSortedAssetClassByCategorySelector = createSelector(
  fetchAssetClassesByCategorySelector.data,
  (classes: IAssetClass[] | null) => {
    if (!classes) return []

    const assets = processAssets(classes as IAsset[], 'class')
    return assets
  }
)

export const fetchSortedAssetTypesByCategorySelector = createSelector(
  fetchAssetTypesByCategorySelector.data,
  (types: IAssetType[] | null) => {
    if (!types) return []

    const assets = processAssets(types as IAsset[], 'type')
    return assets
  }
)

export const fetchAllAssetCategoriesByTenantIdSelector = makeTypedFetchSelectors<IAssetCategory[]>(
  ASSETS_REDUCER,
  ASSETS_ALL_ASSET_CATEGORIES_BY_TENANT_ID
)

export const fetchAssetCategoriesByTenantIdSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    fetchAllAssetCategoriesByTenantIdSelector.data,
    // Take the second arg, `category`, and forward to the output selector
    (state, transportModeId) => transportModeId,
  ],
  (categories, transportModeId: number) => {
    if (!categories) return []
    return categories.filter((c) => c.transportModeId === transportModeId)
  }
)

export const fetchAssetCategoryByIdSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    fetchAllAssetCategoriesByTenantIdSelector.data,
    // Take the second arg, `category`, and forward to the output selector
    (state, categoryId) => categoryId,
  ],
  (categories, categoryId: number) => {
    if (!categories) return null
    return categories.find((c) => c.id === categoryId) as IAssetCategory
  }
)

export const fetchAssetCategoryByAssetClassSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    fetchAllAssetCategoriesByTenantIdSelector.data,
    // Take the second arg, `category`, and forward to the output selector
    (state, classId) => classId,
  ],
  (categories, classId: number) => {
    if (!categories) return null
    return categories.find((c) => c.assetClasses?.some((ac) => ac.id === classId)) as IAssetCategory
  }
)

export const fetchSortedUserTypesByTenantSelector = createSelector(
  fetchUserTypesSelector.data,
  (types: IWorkforceTypes[] | null) => {
    if (!types) return []

    const assets = sortBy(types, ['name'])
    return assets
  }
)
