import { useEffect, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useLoadsByRouteIdQuery } from '../../../../app/redux-fetch/apiQuery'
import {
  useGetJourneyByTenantIdQuery,
  useGetLocationsQuery,
} from '../../../../app/redux-fetch/apiShipment'
import SpinnerBlock from '../../../../components/Spinner/SpinnerBlock'
import { fetchUserTypesSelector } from '../../../TenantSettings/Components/AssetSettings/selectors'
import { fetchUserTypeThunk } from '../../../TenantSettings/Components/AssetSettings/slice'
import { fetchLoggedInUserSelector, fetchTenantAllUsersSelector } from '../../../selectors'
import { fetchAllTenantUsersThunk } from '../../../slice'
import {
  fetchTransportsAssetConfigurationSelector,
  fetchTransportsAssetsSelector,
} from '../../selectors'
import { fetchTransportAssetConfigurationThunk, fetchTransportsAssetsThunk } from '../../slice'
import { IJourneyLegRoutesLocation, IUpdateJourney } from '../../types'
import CreateTransportForm from './CreateTransportForm'

const CreateTransport = (): ReactElement<any, any> => {
  const dispatch = useAppDispatch()
  const { id = -1, journeyId = -1 } = useParams()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const tenantId = loggedInUser?.tenantId

  const isUsersFetching = useAppSelector(fetchTenantAllUsersSelector.isFetching)
  const isAssetConfigurationFetching = useAppSelector(
    fetchTransportsAssetConfigurationSelector.isFetching
  )

  const isUsersFetched = useAppSelector(fetchTenantAllUsersSelector.isFinished)
  const isAssetConfigurationFetched = useAppSelector(
    fetchTransportsAssetConfigurationSelector.isFinished
  )

  const isAssetsFetching = useAppSelector(fetchTransportsAssetsSelector.isFetching)

  const isAssetsFetched = useAppSelector(fetchTransportsAssetsSelector.isFinished)

  const isFetchingUserTypes = useAppSelector(fetchUserTypesSelector.isFetching)

  const isFetchedUserTypes = useAppSelector(fetchUserTypesSelector.isFinished)

  const { data: loads, isSuccess: isLoadFetched } = useLoadsByRouteIdQuery({
    routeId: +id,
    tenantId: tenantId ?? -1,
  })

  const {
    data: locations = [],
    isSuccess: isLocationsFetched,
    isFetching: isLocationsFetching,
  } = useGetLocationsQuery({
    tenantId: tenantId ?? -1,
    includeCustomerLocations: false,
  })

  const { data: journey, isSuccess } = useGetJourneyByTenantIdQuery(
    {
      id: +journeyId,
      tenantId: tenantId ?? -1,
    },
    {
      skip: !isLoadFetched,
    }
  )

  const isLoaded =
    isUsersFetched &&
    !isUsersFetching &&
    isAssetConfigurationFetched &&
    !isAssetConfigurationFetching &&
    isLoadFetched &&
    !isAssetsFetching &&
    isAssetsFetched &&
    !isFetchingUserTypes &&
    isFetchedUserTypes &&
    !isLocationsFetching &&
    isLocationsFetched

  useEffect(() => {
    if (tenantId) {
      dispatch(fetchAllTenantUsersThunk(tenantId))
      dispatch(fetchTransportAssetConfigurationThunk(tenantId))
      dispatch(fetchTransportsAssetsThunk(tenantId))
      dispatch(fetchUserTypeThunk())
    }
  }, [tenantId])

  if (!isLoaded || !journey) {
    return <SpinnerBlock />
  }

  const getLocationsByRouteId = (
    journey: IUpdateJourney,
    routeId: number
  ): IJourneyLegRoutesLocation[] => {
    const mappedLocations: IJourneyLegRoutesLocation[] = []
    if (journey.legs)
      for (const leg of journey.legs) {
        for (const route of leg.routes) {
          if (route.id === routeId) {
            if (route.destinations)
              for (const destination of route.destinations) {
                if (destination.locationId) {
                  const location = locations.find((l) => l.id === destination.locationId)
                  if (location)
                    mappedLocations.push({
                      locationId: location.id ?? -1,
                      name: location.name ?? '',
                      sequence: destination.sequence ?? -1,
                      scheduledDate: new Date(destination.scheduledDate ?? ''),
                    })
                }
              }
          }
        }
      }

    return mappedLocations
  }

  return (
    <CreateTransportForm
      route={{
        id: `${id}`,
        loads: loads ?? [],
        locations: getLocationsByRouteId(journey, +id),
      }}
      journey={journey}
    />
  )
}

export default CreateTransport
