import Loading from '../../assets/loading.gif'
import { SpinnerBoxCenter } from './styles'

const SpinnerBoxBlock = () => {
    return (
        <SpinnerBoxCenter>
            <img src={Loading} alt="Loading..." />
        </SpinnerBoxCenter>
    )
}


export default SpinnerBoxBlock