import CancelIcon from '@mui/icons-material/CancelOutlined';
import { Box, Link } from '@mui/material';
import {
    MRT_ColumnDef as MRTColumnDef
} from 'material-react-table';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../app/hooks';
import { useNotificationStack } from '../../app/hooks/useNotificationStack';
import { useDeleteCurrencyByIdMutation, useGetCurrenciesByCustomerIdQuery, useGetCurrenciesByTenantIdQuery } from '../../app/redux-fetch/apiShipment';
import AddButton from '../../components/Buttons/AddButton';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2';
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography';
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';
import CurrencyForm from './CurrencyForm';
import { messages } from './messages';
import { ICurrency } from './types';

const CurrencyTable = () => {

    const { formatMessage } = useIntl()
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId, customerId } = loggedInUser

    const [selectedCurrencyId, setSelectedCurrencyId] = useState(-1)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteCurrency] = useDeleteCurrencyByIdMutation()

    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const { data: currencies = [], isFetching: currenciesLoading, isSuccess: currenciesLoaded } = customerId ? useGetCurrenciesByCustomerIdQuery({ customerId, hideDisabled: false }) : useGetCurrenciesByTenantIdQuery(tenantId)

    const columns: MRTColumnDef<ICurrency>[] = [
        {
            accessorKey: 'name',
            header: formatMessage(messages.currencyName),
        },
        {
            accessorKey: 'iso3',
            header: formatMessage(messages.iso3code)
        },
        {
            accessorKey: 'localisedSymbol',
            header: formatMessage(messages.localisedSymbol),

        },
        {
            accessorFn: (row) => {
                return row.enabled ? 'true' : 'false'
            },
            filterVariant: 'checkbox',
            header: formatMessage(messages.enabled),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }: any) => {
                return <YesNoTypography isYes={cell.getValue() === 'true'} />
            },
        },
        {
            accessorFn: (row) => {
                return row.inUse ? 'true' : 'false'
            },
            filterVariant: 'checkbox',
            header: formatMessage(messages.inUse),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }: any) => {
                return <YesNoTypography isYes={cell.getValue() === 'true'} />
            }
        }
    ]
    return (
        <>
            <ConditionalRender condition={createOpen} >
                <CurrencyForm currency={{
                    tenantId: +tenantId, customerId: customerId, id: 0, name: '', currency: '', iso3: '', symbol: '', localisedSymbol: '', enabled: true, inUse: true
                }}
                    handleClose={() => setCreateOpen(false)}
                    isOpen={createOpen}
                />
            </ConditionalRender>
            <ConditionalRender condition={editOpen} >
                <CurrencyForm currency={currencies.find(c => c.id === selectedCurrencyId) ?? {
                    tenantId: +tenantId, customerId: customerId, id: 0, name: '', currency: '', iso3: '', symbol: '', localisedSymbol: '', enabled: true, inUse: true
                }} handleClose={
                    () => setEditOpen(false)
                }
                    isOpen={editOpen}
                />
            </ConditionalRender>
            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    density: 'comfortable',
                    sorting: [
                        {
                            id: 'name',
                            desc: false,
                        },
                    ],
                }}
                leftHeadingComponent={
                    <Box sx={{ display: 'inline-flex' }}>
                        <TableTitleTypography>{formatMessage(messages.currencyTableTitle)}</TableTitleTypography>
                    </Box>
                }
                rightHeadingComponent={
                    <AddButton
                        text={formatMessage(messages.newCurrencyBtn)}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                state={{
                    isLoading: currenciesLoading || !currenciesLoaded,
                }}
                columns={columns}
                data={currencies || []}
                getRowId={(row) => row.id as any}
                enableEditing
                enableRowActions
                positionActionsColumn='last'
                renderRowActions={({ row, table }: any) => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedCurrencyId(row.id)
                                        setEditOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.editBtn)}
                                </Link>
                                <ConditionalRender condition={row.original.inUse == false}>
                                    <CancelIcon
                                        color='secondary'
                                        sx={{
                                            cursor: 'pointer',
                                            pl: '4px',
                                        }}
                                        onClick={() => {
                                            deleteCurrency({ currencyId: row.id })
                                                .then(() => {
                                                    enqueueSuccess(messages.currencyDeleted)
                                                })
                                                .catch(() => {
                                                    enqueueFailure(messages.currencyNotDeleted)
                                                })
                                        }}
                                    />
                                </ConditionalRender>
                            </Box>
                        </>
                    )
                }}
            />
        </>
    )
}

export default CurrencyTable;


