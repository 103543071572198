import { defineMessages } from 'react-intl'

const messages = defineMessages({
  tableHeader: {
    id: 'customerSettings.customer.tableHeader',
    description: 'Customer table header',
    defaultMessage: 'Customers',
  },
  name: {
    id: 'customerSettings.customer.name',
    description: 'Customer Settings name',
    defaultMessage: 'Customer Name',
  },
  alias: {
    id: 'customerSettings.customer.alias',
    description: 'Customer Settings alias',
    defaultMessage: 'Alias',
  },
  accountNumber: {
    id: 'customerSettings.customer.accountNumber',
    description: 'Customer Settings accountNumber',
    defaultMessage: 'Account Number',
  },
  locationName: {
    id: 'customerSettings.customer.locationName',
    description: 'Customer Settings Location Name name',
    defaultMessage: 'Location Name',
  },
  address: {
    id: 'customerSettings.customer.address',
    description: 'Customer Settings address',
    defaultMessage: 'Address',
  },
  addressIsRequired: {
    id: 'customerSettings.customer.addressIsRequired',
    description: 'Address is required',
    defaultMessage: 'Address is required',
  },
  defaultCurrencyIsRequired: {
    id: 'customerSettings.customer.defaultCurrencyIsRequired',
    description: 'Default currency is required',
    defaultMessage: 'Default currency is required',
  },
  cityIsRequired: {
    id: 'customerSettings.customer.cityIsRequired',
    description: 'City is required',
    defaultMessage: 'City is required',
  },
  countryIsRequired: {
    id: 'customerSettings.customer.countryIsRequired',
    description: 'Country is required',
    defaultMessage: 'Country is required',
  },
  address1: {
    id: 'customerSettings.customer.address1',
    description: 'Customer Settings address1',
    defaultMessage: 'Address 1',
  },
  address1isRequired: {
    id: 'customerSettings.customer.address1isRequired',
    description: 'Customer Settings address1isRequired',
    defaultMessage: 'Address is required',
  },
  customerNameRequired: {
    id: 'customerSettings.customer.customerNameRequired',
    description: 'Customer Settings customerNameRequired',
    defaultMessage: 'Customer Name is required',
  },
  accountOwnerEmailRequired: {
    id: 'customerSettings.customer.accountOwnerEmailRequired',
    description: 'Customer Settings accountOwnerEmailRequired',
    defaultMessage: 'Account Owner Email is required',
  },
  address2: {
    id: 'customerSettings.customer.address2',
    description: 'Customer Settings address2',
    defaultMessage: 'Address 2',
  },
  address3: {
    id: 'customerSettings.customer.address3',
    description: 'Customer Settings address3',
    defaultMessage: 'Address 3',
  },
  city: {
    id: 'customerSettings.customer.city',
    description: 'Customer Settings city',
    defaultMessage: 'City',
  },
  state: {
    id: 'customerSettings.customer.state',
    description: 'Customer Settings state',
    defaultMessage: 'State',
  },
  postalCode: {
    id: 'customerSettings.customer.postalCode',
    description: 'Customer Settings postalCode',
    defaultMessage: 'Postal Code',
  },
  country: {
    id: 'customerSettings.customer.country',
    description: 'Customer Settings country',
    defaultMessage: 'Country',
  },
  defaultCurrency: {
    id: 'customerSettings.customer.defaultCurrency',
    description: 'Customer Settings defaultCurrency',
    defaultMessage: 'Default Currency',
  },
  enabled: {
    id: 'customerSettings.customer.enabled',
    description: 'Customer Settings enabled',
    defaultMessage: 'Enabled',
  },

  // API message
  customerCreateSuccess: {
    id: 'customerSettings.customer.customerCreateSuccess',
    description: 'Customer Settings customerCreateSuccess',
    defaultMessage: 'Successfully created {customerName} customer',
  },
  customerCreateError: {
    id: 'customerSettings.customer.customerCreateError',
    description: 'Customer Settings customerCreateError',
    defaultMessage: 'Error creating {customerName} customer',
  },
  customerUpdateSuccess: {
    id: 'customerSettings.customer.customerUpdateSuccess',
    description: 'Customer Settings customerUpdateSuccess',
    defaultMessage: 'Successfully updated {customerName} customer',
  },
  customerUpdateError: {
    id: 'customerSettings.customer.customerUpdateError',
    description: 'Customer Settings customerUpdateError',
    defaultMessage: 'Error updating {customerName} customer',
  },
  customerDeleteSuccess: {
    id: 'customerSettings.customer.customerDeleteSuccess',
    description: 'Customer Settings customerDeleteSuccess',
    defaultMessage: 'Successfully deleted {customerName} customer',
  },
  customerDeleteError: {
    id: 'customerSettings.customer.customerDeleteError',
    description: 'Customer Settings customerDeleteError',
    defaultMessage: 'Error deleting {customerName} customer',
  },

  // Validation messages
  propertyValueRequired: {
    id: 'customerSettings.customer.propertyValueRequired',
    description: 'Customer value required',
    defaultMessage: 'Customer {propertyName} is required',
  },
  propertyValueMustBeUnique: {
    id: 'customerSettings.customer.propertyValueMustBeUnique',
    description: 'Customer value must be unique',
    defaultMessage: 'Customer {propertyName} must be unique',
  },
  propertyValueMaxLength: {
    id: 'customerSettings.customer.propertyValueMaxLength',
    description: 'Customer value max length',
    defaultMessage: 'Customer {propertyName} must be max {length} characters',
  },

  addNewCustomerModalTitle: {
    id: 'customerSettings.customer.addNewCustomerModalTitle',
    description: 'Customer Settings addNewCustomerModalTitle',
    defaultMessage: 'Add New Customer',
  },
  viewBtn: {
    id: 'customerSettings.customer.viewBtn',
    description: 'Customer Settings viewBtn',
    defaultMessage: 'View',
  },
  editBtn: {
    id: 'customerSettings.customer.editBtn',
    description: 'Customer Settings editBtn',
    defaultMessage: 'Edit',
  },
  deleteBtn: {
    id: 'customerSettings.customer.deleteBtn',
    description: 'Customer Settings deleteBtn',
    defaultMessage: 'Delete',
  },
  customers: {
    id: 'customerSettings.customer.customers',
    description: 'Customer Settings showAllCustomers',
    defaultMessage: 'Customers',
  },
  createEditCustomer: {
    id: 'customerSettings.customer.createEditCustomer',
    description: 'Customer Settings createEditCustomer',
    defaultMessage: 'Create/Edit Customer',
  },
  viewCustomer: {
    id: 'customerSettings.customer.viewCustomer',
    description: 'Customer Settings viewCustomer',
    defaultMessage: 'View Customer',
  },
  newCustomerLbl: {
    id: 'customerSettings.customer.newCustomerLbl',
    description: 'Customer Settings newCustomerLbl',
    defaultMessage: 'New Customer',
  },
  showAllCustomerslbl: {
    id: 'customerSettings.customer.showAllCustomerslbl',
    description: 'Customer Settings showAllCustomerslbl',
    defaultMessage: 'Show All Customers',
  },
  customerDetail: {
    id: 'customerSettings.customer.customerDetail',
    description: 'Customer Detail',
    defaultMessage: 'Customer Detail',
  },
  customerName: {
    id: 'customerSettings.customer.customerName',
    description: 'Customer Name',
    defaultMessage: 'Customer Name',
  },
  accountOwnerEmail: {
    id: 'customerSettings.customer.accountOwnerEmail',
    description: 'Account Owner Email',
    defaultMessage: 'Account Owner Email',
  },
  accountOwnerFirstName: {
    id: 'customerSettings.customer.accountOwnerFirstName',
    description: 'Account Owner First Name',
    defaultMessage: 'Account Owner First Name',
  },
  accountOwnerLastName: {
    id: 'customerSettings.customer.accountOwnerLastName',
    description: 'Account Owner Last Name',
    defaultMessage: 'Account Owner Last Name',
  },
  accountOwnerFirstNameIsRequired: {
    id: 'customerSettings.customer.accountOwnerFirstNameIsRequired',
    description: 'Account Owner First Name is required',
    defaultMessage: 'Account Owner First Name is required',
  },
  accountOwnerLastNameIsRequired: {
    id: 'customerSettings.customer.accountOwnerLastNameIsRequired',
    description: 'Account Owner Last Name is required',
    defaultMessage: 'Account Owner Last Name is required',
  },
  customerAlias: {
    id: 'customerSettings.customer.customerAlias',
    description: 'Customer Alias',
    defaultMessage: 'Customer Alias',
  },
  customerAccountNumber: {
    id: 'customerSettings.customer.customerAccountNumber',
    description: 'Account Number',
    defaultMessage: 'Account Number',
  },
  customerEmail: {
    id: 'customerSettings.customer.customerEmail',
    description: 'Customer Email',
    defaultMessage: 'Customer Email',
  },
  secondaryEmail: {
    id: 'customerSettings.customer.secondaryEmail',
    description: 'Secondary Email',
    defaultMessage: 'Secondary Email',
  },
  companyRegistrationNumber: {
    id: 'customerSettings.customer.companyRegistrationNumber',
    description: 'Company Registration Number',
    defaultMessage: 'Company Registration Number',
  },
  VATNumber: {
    id: 'customerSettings.customer.VATNumber',
    description: 'VAT Number',
    defaultMessage: 'VAT NUmber',
  },
  industry: {
    id: 'customerSettings.customer.industry',
    description: 'Industry',
    defaultMessage: 'Industry',
  },
  webSite: {
    id: 'customerSettings.customer.webSite',
    description: 'Customer Website',
    defaultMessage: 'Customer Website',
  },
})

export default messages
