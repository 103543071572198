import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from '../../app/hooks/useFormContext'
import { useGetContactByEntityIdQuery } from '../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { SectionTitleTypography } from '../../components/Typographies/styles'
import ContactFormModal from './ContactFormModal'
import { messages } from './messages'
import { IContact } from './types'

type ContactTableProps = {
  isDisabled?: boolean
  contactCategoryId?: number
}

const ContactTable = ({ isDisabled, contactCategoryId }: ContactTableProps) => {
  const { formatMessage } = useIntl()

  const { values, setFieldValue } = useFormContext()

  const { data: contacts } = useGetContactByEntityIdQuery(values.id, {
    skip: !values.id,
    refetchOnMountOrArgChange: true,
  })


  useEffect(() => {
    setFieldValue('contacts', contacts ?? [])
  }, [contacts])


  const columns = useMemo<MRTColumnDef<IContact>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 50,
        Cell: ({ cell }: any) => {
          return (<Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>)
      },
      },
      {
        accessorFn: (row) => row.phone,
        header: 'Phone',
        size: 50,
      },
    ]
  }, [])

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isViewContactOpen, setIsViewContactOpen] = useState(false)

  const [selectedIdx, setSelectedIdx] = useState(-1)

  const getContactToEdit = useCallback(() => {
    if (selectedIdx > -1) {
      return { ...values.contacts[selectedIdx], contactCategoryId }
    }
    return {
      contactCategoryId: contactCategoryId ?? 1
    }
  }, [values.contacts, selectedIdx])

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <ContactFormModal
          contactToEdit={getContactToEdit()}
          isOpen={isCreateModalOpen}
          handleSubmit={(contact: IContact) => {
            setFieldValue('contacts', [...values.contacts, contact])
            setSelectedIdx(-1)
            setCreateModalOpen((pv) => !pv)
          }}
          handleCancel={() => {
            setCreateModalOpen((pv) => !pv)
          }}
        />
        <ContactFormModal
          contactToEdit={getContactToEdit()}
          isOpen={isEditModalOpen}
          handleSubmit={(contact: IContact) => {
            setFieldValue(`contacts.${selectedIdx}`, contact)
            setSelectedIdx(-1)
            setEditModalOpen((pv) => !pv)
          }}
          handleCancel={() => {
            setEditModalOpen((pv) => !pv)
          }}
        />
        <ContactFormModal
          contactToEdit={getContactToEdit()}
          isOpen={isViewContactOpen}
          handleCancel={() => {
            setIsViewContactOpen(false)
          }}
        />
      </Box>
      <Box mt={2}>
        <MRTDataGridv2
          initialState={{
            sorting: [
            {
              id: 'name',
              desc: false,
            }]}
          }
          columns={columns}
          leftHeadingComponent={<SectionTitleTypography>{formatMessage(messages.title)}</SectionTitleTypography>}
          rightHeadingComponent={<AddButton disabled={isDisabled} text={'New'} onClick={() => setCreateModalOpen(true)} />}
          data={values.contacts ?? []}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          positionActionsColumn='last'
          enableRowActions={true}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isDisabled && (
                <>
                  <Link
                    underline='none'
                    color='secondary'
                    onClick={() => {
                      setSelectedIdx(+row.id)
                      setEditModalOpen(true)
                    }}
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    {formatMessage(messages.editButtonLbl)}
                  </Link>
                  <Typography color='secondary'>/</Typography>
                </>
              )}
              <Link
                underline='none'
                color='secondary'
                onClick={() => {
                  setSelectedIdx(+row.id)
                  setIsViewContactOpen(true)
                }}
                sx={{
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                {formatMessage(messages.viewButtonLbl)}
              </Link>

              {!isDisabled && (
                <>
                  <Typography color='secondary'>/</Typography>
                  <Link
                    underline='none'
                    color='secondary'
                    onClick={() => {
                      const currentContacts = [...values.contacts]
                      currentContacts.splice(+row.id, 1)
                      setFieldValue('contacts', currentContacts)
                    }}
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    {formatMessage(messages.deleteButtonLbl)}
                  </Link>
                </>
              )}
            </Box>
          )}
          muiTableBodyRowProps={{ hover: false }}
        />
      </Box>
    </Box>
  )
}

export default ContactTable
