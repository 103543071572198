import * as yup from 'yup'
import { getContactsValidationSchema } from '../Contacts/contactValidation'
import messages from './messages'

export const getLocationValidationSchema = (formatMessage: any) => {
  return yup
    .object({
      name: yup.string().required(formatMessage(messages.nameValidation)),
      address: yup
        .object({
          countryId: yup.string().required(formatMessage(messages.countryValidation)),
          address1: yup.string().required(formatMessage(messages.address1Validation)),
          city: yup.string().required(formatMessage(messages.cityValidation)),
          state: yup.string().required(formatMessage(messages.stateValidation)),
          postalCode: yup.string().required(formatMessage(messages.postCodeValidation)),
        })
        .nullable(),
      lat: yup.number().min(-90).max(90),
      lng: yup.number().min(-180).max(180),
    })
    .concat(getContactsValidationSchema(formatMessage))
}
