import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik, FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useAddContactTypeMutation,
  useGetContactCategoriesByCustomerIdQuery,
  useGetContactCategoriesByTenantIdQuery,
  useUpdateContactTypeMutation,
} from '../../../../app/redux-fetch/apiCoreQuery'
import { IDropDownItem } from '../../../../app/types'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../components/Typographies/styles'
import { IContactType } from '../../../Contacts/types'
import messages from '../messages'
import { contactTypeValidationSchema } from './contactTypeValidationSchema'

type ContactTypeFormProps = {
  contactType: IContactType
  handleClose: () => void
  isOpen: boolean
}

const ContactTypeForm = ({ contactType, handleClose, isOpen }: ContactTypeFormProps) => {
  const { formatMessage } = useIntl()

  const isNew = !contactType?.id || contactType?.id === 0

  const [addContactType] = useAddContactTypeMutation()
  const [updateContactType] = useUpdateContactTypeMutation()

  const { tenantId, customerId } = useLoggedInUser()

  const { data: contactCategories = [] } = customerId
    ? useGetContactCategoriesByCustomerIdQuery(customerId)
    : useGetContactCategoriesByTenantIdQuery(tenantId)

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const onSubmit = async (data: IContactType) => {
    if (isNew) {
      await addContactType(data)
        .then((res: any) => {
          if (!res.error) {
            enqueueSuccess(messages.contactTypeCreated)
          }
        })
        .catch((err: any) => {
          enqueueFailure(messages.contactTypeNotCreated)
        })
    } else {
      await updateContactType(data)
        .then(() => {
          enqueueSuccess(messages.contactTypeUpdated)
        })
        .catch(() => {
          enqueueFailure(messages.contactTypeNotUpdated)
        })
    }
    handleClose()
  }

  return (
    <Formik
      initialValues={contactType}
      validationSchema={contactTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        submitForm,
        validateForm,
        setTouched,
      }) => {
        return (
          <Form>
            <Dialog open={isOpen} onClose={handleClose}>
              <DialogTitle>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '20px',
                  }}
                >
                  {formatMessage(messages.createEditContactType)}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{ minWidth: '450px' }}>
                <Box sx={{ mb: '16px' }}>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.contactTypeName)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <TextField
                      fullWidth
                      id={'name'}
                      name={'name'}
                      value={values?.name ?? ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleChange}
                      helperText={touched.name && (errors?.name as React.ReactNode)}
                      error={touched.name && Boolean(errors?.name)}
                    />
                  </Box>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.contactCategory)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <FormDropdown
                      id={'contactCategoryId'}
                      items={(contactCategories as IDropDownItem[]) ?? []}
                      onChange={(_e: any, name: string, value: number) =>
                        setFieldValue(name, value)
                      }
                      value={values.contactCategoryId}
                      error={Boolean(errors?.contactCategoryId)}
                      errorText={errors?.contactCategoryId}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  {formatMessage(messages.cancelBtn)}
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    const handleSubmit = () => {
                      validateForm().then((errors: FormikErrors<any>) => {
                        submitForm().finally(() => {
                          setTouched(setNestedObjectValues(errors, true))
                        })
                      })
                    }
                    handleSubmit()
                  }}
                >
                  {isNew ? 'Create' : 'Update'}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContactTypeForm
