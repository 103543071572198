import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { type FormikProps } from 'formik'
import { useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { type IShipment } from '../../types'
import AssignmentAssetRequestPane from './AssignmentAssetRequestPane'
import AssignmentContainerPane from './AssignmentContainerPane'
import AssignmentLoadPane from './AssignmentLoadPane'
import messages from './messages'

interface IShipmentContainersOrAssetRequestsStepProps {
  formik: FormikProps<IShipment>
  disabled?: boolean
  viewMode?: boolean
  handleRemoveAssetRequest: (index: number) => void
  handleSwitchLoadChanges: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ) => void
  handleSelectLoadChanges: (e: SelectChangeEvent<any>, name: string, newValue: any) => void
  handleFormDropDownChanges: (e: SelectChangeEvent<number>, name: string, newValue: any) => void
}

const ShipmentContainersOrAssetRequestsStep = (
  props: IShipmentContainersOrAssetRequestsStepProps
): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const [isShowConfirmReset, setIsShowConfirmReset] = useState(false)
  const [switchValue, newSwitchValue] = useState(false)
  const theme = useTheme()

  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const handleDialogCancel = () => {
    setIsShowConfirmReset(false)
  }

  const handleConfirmDialog = () => {
    formik.setFieldValue('usesContainer', switchValue)
    formik.setFieldValue('containerDetails', [])
    formik.setFieldValue('assetRequests', [])
    formik.values.loads.forEach((load, index: number) => {
      formik.setFieldValue(`loads.${index}.assetRequestAllocations`, [])
      formik.setFieldValue(`loads.${index}.containerAllocations`, [])
    })
    setIsShowConfirmReset(false)
  }
  const handleSwitch = (newValue: boolean): void => {
    newSwitchValue(newValue)
    if (
      !formik.values.usesContainer &&
      formik.values.assetRequests &&
      formik.values.assetRequests.length > 0
    ) {
      setIsShowConfirmReset(true)
    } else if (
      formik.values.usesContainer &&
      formik.values.assetRequests &&
      formik.values.containerDetails.length > 0
    ) {
      setIsShowConfirmReset(true)
    } else {
      formik.setFieldValue('usesContainer', newValue)
    }
  }
  const {
    formik,
    disabled,
    viewMode,
    handleRemoveAssetRequest,
    handleSwitchLoadChanges,
    handleSelectLoadChanges,
    handleFormDropDownChanges,
  } = props

  const isVirtualLoadMode = !(formik.values.loads.filter((x) => x.isDefined).length > 0)
  const isMixedLoads =
    formik.values.loads.some((l) => l.isDefined) && formik.values.loads.some((l) => !l.isDefined)
  return (
    <>
      <Dialog
        maxWidth='md'
        fullScreen={fullScreen}
        open={isShowConfirmReset}
        onClose={handleDialogCancel}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>Switching between Asset Request(s) and Pre-Book Container(s)</Typography>
            <Typography>will clear all data entered on this page.</Typography>
            <Typography>Are you sure you wish to continue?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogCancel}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDialog} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={4}>
        {!isVirtualLoadMode && isMixedLoads && (
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Typography sx={{ color: 'red' }}>
                {'All {AssetRequet(s)/Pre-BookedContainer(s)} must be assigned to Load(s)'}
              </Typography>
            </Box>
          </Grid>
        )}
        {!disabled && (
          <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-start' alignItems='center'>
              <FormControlLabel
                control={<></>}
                label={formatMessage(messages.assetRequestOrContainer)}
                sx={{
                  marginLeft: '16px',
                  '& span': {
                    fontSize: '24px', color: '#636363',
                    fontWeight: 700
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    id='containerOrAssetRequestsSwitch'
                    checked={formik.values.usesContainer}
                    name='containerOrAssetRequestsSwitch'
                    onChange={(name, newValue) => {
                      handleSwitch(newValue)
                    }}
                  />
                }
                sx={{
                  '& span': {
                    fontSize: '24px', color: '#636363',
                    fontWeight: 700
                  },
                }}
                label={formatMessage(messages.containersOrAssetRequest)}
              />
            </Box>
          </Grid>
        )}
        {!isVirtualLoadMode && (
          <Grid item xs={6}>
            <AssignmentLoadPane formik={formik} disabled={disabled} />
          </Grid>
        )}
        <Grid item xs={isVirtualLoadMode ? 12 : 6}>
          {formik.values.usesContainer && !isVirtualLoadMode ? (
            <AssignmentContainerPane viewMode={viewMode} formik={formik} disabled={disabled} />
          ) : (
            formik.values.usesContainer && (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography sx={{ color: 'red' }}>
                  You must specify load(s) before you can assign them to pre-booked container(s)
                </Typography>
              </Box>
            )
          )}
          {!formik.values.usesContainer && (
            <AssignmentAssetRequestPane
              formik={formik}
              handleFormDropDownChanges={handleFormDropDownChanges}
              handleRemoveAssetRequest={handleRemoveAssetRequest}
              isVirtualLoadMode={isVirtualLoadMode}
              handleSelectLoadChanges={handleSelectLoadChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              disabled={disabled}
              viewMode={viewMode}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ShipmentContainersOrAssetRequestsStep
