import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export interface IConfirmDialogProps extends DialogProps {
  open: boolean
  title: string
  content: string
  continueButtonText: string
  discardButtonText: string
  onContinueEdit: () => void
  onDiscardChanges: () => void
}
export default function ConfirmDialog({
  open: openDialog,
  onContinueEdit: handleContinueEdit,
  onDiscardChanges,
  title,
  content,
  continueButtonText,
  discardButtonText,
}: IConfirmDialogProps) {
  return (
    <div>
      <Dialog open={openDialog} aria-labelledby='confirm-dialog-title'>
        <DialogTitle id='confirm-dialog-title'>
          <Alert severity='warning'>
            <h3>{title}</h3>
          </Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleContinueEdit} color='secondary' variant='outlined'>
            {continueButtonText}
          </Button>
          <Button onClick={onDiscardChanges} color='secondary' variant='contained'>
            {discardButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
