import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

interface ApplyButtonProps extends ButtonProps {
    text: string
}

const CommonButton: React.FC<ApplyButtonProps> = ({ text, ...props }) => {
    return (
        <Button {...props} color='secondary' variant='contained'>
            {text}
        </Button>
    )
}

export default CommonButton;
