import { getProperty, isEmptyObject } from '../../app/utils'

export const getError = (propertyName: string, formik: any): boolean => {
  const touched = getProperty(formik.touched, propertyName)
  const errorMessage = getProperty(formik.errors as unknown, propertyName)
  return touched && (errorMessage as any)
}

export const getAddressError = (propertyName: string, errors: any, touched: any): boolean => {
  const isTouched =
    touched &&
    !isEmptyObject(touched) &&
    touched.address &&
    getProperty(touched.address, propertyName)
  const errorMessage =
    errors && errors.address && getProperty(errors.address as unknown, propertyName)

  return isTouched && (errorMessage as any)
}

export const convertLatLng = (input: number | undefined) => {
  return !input || input == 0 ? undefined : input
}
