import { Box } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import EmbeddedContainer from '../components/EmbeddedContainer'
import { messages } from '../messages'
import { Destination, Journey, Leg } from '../types'
import { getAllUniqueLoadsForLeg } from '../utils'


type EmbeddedLegTableProps = {
    legs: Leg[]
    journey: Journey
}

const EmbeddedLegTable = ({ legs }: EmbeddedLegTableProps) => {
    const { formatMessage } = useIntl()

    const columns = useMemo<MRTColumnDef<Leg>[]>(() => {
        return [
            {
                accessorFn: (row) => {
                    return `Leg ${row.id}`
                },
                header: formatMessage(messages.legsName),
            },
            {
                accessorFn: (row) => {
                    return row.carrier?.name ?? ''
                },
                header: formatMessage(messages.carrierName),
            },
            {
                accessorFn: (row) => {
                    return row.routes.reduce((acc, item) => {

                        const orderedDestinations = [...item.destinations];

                        orderedDestinations.sort((a: Destination, b: Destination) => {
                            const dateA = new Date(a.scheduledDate);
                            const dateB = new Date(b.scheduledDate);
                            return dateA.getTime() - dateB.getTime();
                        });

                        if (acc === '') {
                            return `Route ${item.id} - (${orderedDestinations[0].location.name} > ${orderedDestinations[item.destinations.length - 1].location.name})`
                        }
                        return acc + `\n Route ${item.id} - (${orderedDestinations[0].location.name} > ${orderedDestinations[item.destinations.length - 1].location.name})`
                    }, '')
                },
                header: formatMessage(messages.routesName),
            },
            {
                accessorFn: (row) => {
                    const uniqueLoads = getAllUniqueLoadsForLeg(row)

                    return uniqueLoads.reduce((acc, item) => {
                        if (acc === '') {
                            return `Load ${item.id.toString()}`
                        }
                        return acc + ` / Load ${item.id}`
                    }, '')
                },
                header: formatMessage(messages.loadsName)
            },
            {
                accessorFn: (row) => {
                    return row.status ?? ''
                },
                header: formatMessage(messages.statusName)
            }
        ]
    }, [])


    return (
        <EmbeddedContainer>
            <Box sx={{
                width: '100%',
                height: '100%',
            }}>
                <MRTDataGridv2
                    columns={columns}
                    data={legs || []}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableSorting={false}
                    enableBottomToolbar={false}
                    enableTopToolbar={false}
                    positionActionsColumn='last'
                />
            </Box>

        </EmbeddedContainer>
    )
}

export default EmbeddedLegTable

