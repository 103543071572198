/* eslint-disable */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  // #Stepper messages.
  title: {
    id: 'ShipmentStepper.title',
    description: 'Create Shipments Stepper title',
    defaultMessage: 'Create Shipments',
  },
  summaryStepName: {
    id: 'ShipmentStepper.summaryStepName',
    description: 'Shipment Summary',
    defaultMessage: 'Shipment Summary',
  },
  createLoadsStepName: {
    id: 'ShipmentStepper.createLoadsStepName',
    description: 'Create Loads (optional)',
    defaultMessage: 'Load(s)',
  },
  assignContainersOrAssetRequestStepName: {
    id: 'ShipmentStepper.assignContainersOrAssetRequestStepName',
    description: 'Assign Containers or Asset Request',
    defaultMessage: 'Container(s) / Asset Request(s)',
  },
  confirmShipment: {
    id: 'ShipmentStepper.confirmShipment',
    description: 'Confirm Shipment',
    defaultMessage: 'Confirm Shipment',
  },
  buttonBack: {
    id: 'ShipmentStepper.buttonBack',
    description: 'Back Button',
    defaultMessage: 'Previous',
  },
  buttonNext: {
    id: 'ShipmentStepper.buttonNext',
    description: 'Next Button',
    defaultMessage: 'Next',
  },
  buttonBackToShipmentst: {
    id: 'ShipmentStepper.buttonBackToShipmentst',
    description: 'Back to Shipments button',
    defaultMessage: 'Back to Shipments',
  },
  buttonSaveDraft: {
    id: 'ShipmentStepper.buttonSaveDraft',
    description: 'Save Draft Button',
    defaultMessage: 'Save Draft',
  },
  buttonLockForReview: {
    id: 'ShipmentStepper.buttonLockForReview',
    description: 'Lock for Review Button',
    defaultMessage: 'Lock for Review',
  },
  buttonAccept: {
    id: 'ShipmentStepper.buttonAccept',
    description: 'Accept Button',
    defaultMessage: 'Accept',
  },
  buttonSubmitAndAccept: {
    id: 'ShipmentStepper.buttonSubmitAndAccept',
    description: 'Accept Button',
    defaultMessage: 'Submit & Accept',
  },
  buttonReject: {
    id: 'ShipmentStepper.buttonReject',
    description: 'Reject Button',
    defaultMessage: 'Reject',
  },
  buttonRecall: {
    id: 'ShipmentStepper.buttonRecall',
    description: 'Recall Button',
    defaultMessage: 'Recall',
  },
  buttonSubmit: {
    id: 'ShipmentStepper.buttonSubmit',
    description: 'Submit Button',
    defaultMessage: 'Submit',
  },
  countdownTitle: {
    id: 'ShipmentStepper.countdownTitle',
    description: 'Countdown title',
    defaultMessage: 'Locked for review for {value}',
  },
  transportDetailTitle: {
    id: 'ShipmentStepper.transportDetailTitle',
    description: 'Transport Detail Title',
    defaultMessage: 'Transport Detail',
  },
  assignContainerRequest: {
    id: 'createShipment.assignContainerRequest',
    description: 'Assign Pre-Booked Container',
    defaultMessage: 'Assign Pre-Booked Container',
  },
  assignAssetRequest: {
    id: 'createShipment.assignAssetRequest',
    description: 'Assign Asset Requestr',
    defaultMessage: 'Assign Asset Requestr',
  },
  assetRequest: {
    id: 'createShipment.assetRequest',
    description: 'Asset request label',
    defaultMessage: 'Asset Request',
  },
  assetRequestClass: {
    id: 'createShipment.assetRequestClass',
    description: 'Asset request class label',
    defaultMessage: 'Asset Class',
  },
  assetRequestType: {
    id: 'createShipment.assetRequestType',
    description: 'Asset request type label',
    defaultMessage: 'Asset Type',
  },
  assetConfigurationName: {
    id: 'createShipment.assetConfigurationName',
    description: 'Asset request configuration label',
    defaultMessage: 'Asset Configuration',
  },
  assetRequestOrContainer: {
    id: 'createShipment.assetRequestOrContainer',
    description: 'Asset request or container switch left label',
    defaultMessage: 'Asset Request(s)',
  },
  shipmentDescription: {
    id: 'createShipment.Description',
    description: 'Shipment description label',
    defaultMessage: 'Description',
  },
  cargoValue: {
    id: 'createShipment.cargoValue',
    description: 'Cargo value label',
    defaultMessage: 'Cargo Value',
  },
  loadValue: {
    id: 'createLoad.value',
    description: 'Load value label',
    defaultMessage: 'Value',
  },
  createLoadTitle: {
    id: 'createLoad.title',
    description: 'Load title',
    defaultMessage: 'Load details',
  },
  chargeableWeight: {
    id: 'createShipment.chargeableWeight',
    description: 'Chargeable Weight label',
    defaultMessage: 'Chargeable Weight',
  },
  container: {
    id: 'createShipment.containers',
    description: 'Container label',
    defaultMessage: 'Container',
  },
  containerNumber: {
    id: 'createShipment.containerNumber',
    description: 'Container Number label',
    defaultMessage: 'Container Number',
  },
  containersForMapping: {
    id: 'createShipment.containersForMapping',
    description: 'Containers for Mapping label',
    defaultMessage: 'Pre-Booked Container(s)',
  },
  containersOrAssetRequest: {
    id: 'createShipment.containersOrAssetRequest',
    description: 'Containers or Asset Request switch label',
    defaultMessage: 'Pre-Booked Container(s)',
  },
  countryOfOrigin: {
    id: 'createShipment.countryOfOrigin',
    description: 'Country of origin label',
    defaultMessage: 'Country of Origin',
  },
  currencyLabel: {
    id: 'createShipment.currencyLabel',
    description: 'Currency label',
    defaultMessage: 'Currency',
  },
  customerReferenceNumber: {
    id: 'createShipment.customerReferenceNumber',
    description: 'Customer reference number label',
    defaultMessage: 'Customer Reference Number',
  },
  customerReferenceNumberPlaceholder: {
    id: 'createShipment.customerReferenceNumberPlaceholder',
    description: 'Customer reference number placeholder',
    defaultMessage: 'Customer unique reference',
  },
  density: {
    id: 'createShipment.density',
    description: 'Density label',
    defaultMessage: 'Density',
  },
  description: {
    id: 'createShipment.description',
    description: 'Description label',
    defaultMessage: 'Description',
  },
  details: {
    id: 'createShipment.details',
    description: 'Shipment details accordion title',
    defaultMessage: 'Shipment details',
  },
  genSetRequired: {
    id: 'createShipment.genSetRequired',
    description: 'GenSet Required label',
    defaultMessage: 'GenSet Required',
  },
  grossWeight: {
    id: 'createShipment.grossWeight',
    description: 'Gross Weight label',
    defaultMessage: 'Gross Weight',
  },
  height: {
    id: 'createShipment.height',
    description: 'Height label',
    defaultMessage: 'Height',
  },
  length: {
    id: 'createShipment.length',
    description: 'Length label',
    defaultMessage: 'Length',
  },
  load: {
    id: 'createShipment.load',
    description: 'Load label',
    defaultMessage: 'Load',
  },
  loadsForMapping: {
    id: 'createShipment.loadsForMapping',
    description: 'Loads for Mapping label',
    defaultMessage: 'Load(s)',
  },
  lowerThresholdCritical: {
    id: 'createShipment.lowerThresholdCritical',
    description: 'Lower Critical Threshold label',
    defaultMessage: 'Lower Critical Threshold',
  },
  lowerThresholdWarning: {
    id: 'createShipment.lowerThresholdWarning',
    description: 'Lower Warning Threshold label',
    defaultMessage: 'Lower Warning Threshold',
  },
  netWeight: {
    id: 'createShipment.netWeight',
    description: 'Net Weight label',
    defaultMessage: 'Net Weight',
  },
  newAssetRequest: {
    id: 'createShipment.newAssetRequest',
    description: 'New Asset Request label',
    defaultMessage: 'Add Asset Request',
  },
  newContainer: {
    id: 'createShipment.newContainer',
    description: 'New Container label',
    defaultMessage: 'Add Container',
  },
  newLoad: {
    id: 'createShipment.newLoad',
    description: 'New Load label',
    defaultMessage: 'Add Load',
  },
  noAssetRequests: {
    id: 'createShipment.noAssetRequests',
    description: 'No asset requests label',
    defaultMessage: 'No asset request(s) in shipment.',
  },
  noContainers: {
    id: 'createShipment.noContainers',
    description: 'No Containers label',
    defaultMessage: 'No containers in shipment.',
  },
  noLoads: {
    id: 'createShipment.noLoads',
    description: 'No Loads label',
    defaultMessage: 'No loads in shipment.',
  },
  newPalette: {
    id: 'createShipment.newPallet',
    description: 'New Pallet label',
    defaultMessage: 'Add Pallet Details',
  },
  pallet: {
    id: 'createShipment.pallet',
    description: 'Pallet Type label',
    defaultMessage: 'Pallet Type',
  },
  palletDetails: {
    id: 'createShipment.pallet.details',
    description: 'Pallet details accordion title',
    defaultMessage: 'Pallet details',
  },
  palletQuantity: {
    id: 'createShipment.palletQuantity',
    description: 'Pallet Quantity label',
    defaultMessage: 'Quantity',
  },
  palletStackable: {
    id: 'createShipment.palletStackable',
    description: 'Pallet Stackable label',
    defaultMessage: 'Stackable',
  },
  palletType: {
    id: 'createShipment.pallet.type',
    description: 'Pallet type dropdown label',
    defaultMessage: 'Pallet Type',
  },
  physicalProperties: {
    id: 'createShipment.physicalProperties',
    description: 'Physical properties label',
    defaultMessage: 'Physical properties',
  },
  product: {
    id: 'createShipment.product',
    description: 'Product label',
    defaultMessage: 'Product',
  },
  quantity: {
    id: 'createShipment.quantity',
    description: 'Quantity label',
    defaultMessage: 'Quantity',
  },
  shipper: {
    id: 'createShipment.shipper',
    description: 'Shipper label',
    defaultMessage: 'Shipper',
  },
  setPoint: {
    id: 'createShipment.setPoint',
    description: 'Setpoint label',
    defaultMessage: 'Set point',
  },
  temperatureControlled: {
    id: 'createShipment.temperatureControled',
    description: 'Temperature Controlled label',
    defaultMessage: 'Temperature Controlled',
  },
  temperatureRange: {
    id: 'createShipment.temperatureRange',
    description: 'Temperature Range label',
    defaultMessage: 'Temperature Range',
  },
  temperatureSettings: {
    id: 'createShipment.temperatureSettings',
    description: 'Temperature settings label',
    defaultMessage: 'Temperature settings',
  },
  temperatureUnit: {
    id: 'createShipment.temperatureUnit',
    description: 'Temperature Unit label',
    defaultMessage: 'Temperature Unit',
  },
  trackingNumber: {
    id: 'createShipment.trackingNumber',
    description: 'Tracking number label',
    defaultMessage: 'Tracking Number',
  },
  unitOfDensity: {
    id: 'createShipment.unitOfDensity',
    description: 'Unit of Density label',
    defaultMessage: 'Unit of Density',
  },
  unitOfHeight: {
    id: 'createShipment.unitOfHeight',
    description: 'Unit of Height label',
    defaultMessage: 'Unit of Height',
  },
  unitOfLength: {
    id: 'createShipment.unitOfLength',
    description: 'Unit of Length label',
    defaultMessage: 'Unit of Length',
  },
  unitOfVolume: {
    id: 'createShipment.unitOfVolume',
    description: 'Unit of Volume label',
    defaultMessage: 'Unit of Volume',
  },
  unitOfWeight: {
    id: 'createShipment.unitOfWeight',
    description: 'Unit of Weight label',
    defaultMessage: 'Unit of Weight',
  },
  upperThresholdCritical: {
    id: 'createShipment.upperThresholdCritical',
    description: 'Upper Critical Threshold label',
    defaultMessage: 'Upper Critical Threshold',
  },
  upperThresholdWarning: {
    id: 'createShipment.upperThresholdWarning',
    description: 'Upper Warning Threshold label',
    defaultMessage: 'Upper Warning Threshold',
  },
  usePhysicalProperties: {
    id: 'createShipment.usePhysicalProperties',
    description: 'Show Physical Properties label',
    defaultMessage: 'Show Physical Properties',
  },
  volume: {
    id: 'createShipment.volume',
    description: 'Volume label',
    defaultMessage: 'Volume',
  },
  width: {
    id: 'createShipment.width',
    description: 'Width label',
    defaultMessage: 'Width',
  },

  // Validation messages.
  validationErrorCreateShipment_fieldMaxLength: {
    id: 'createShipment.validation.fieldMaxLength',
    description: 'Max length 50',
    defaultMessage: 'Max length 50',
  },
  validationErrorCreateShipment_fieldMinimumLengthOne: {
    id: 'createShipment.validation.fieldMinimumLengthOne',
    description: 'Minimum length is 1',
    defaultMessage: 'Minimum length is 1',
  },
  validationErrorCreateShipment_shipperRequired: {
    id: 'createShipment.validation.shipperRequired',
    description: 'Shipper number required',
    defaultMessage: 'Shipper is required',
  },
  validationErrorCreateShipment_customerId: {
    id: 'createShipment.validation.customerId',
    description: 'Customer is required',
    defaultMessage: 'Customer is required',
  },
  createShipment_customerId: {
    id: 'createShipment.customerId',
    description: 'Customer label',
    defaultMessage: 'Customer',
  },
  validationErrorCreateShipment_shipmentTrackingNumberRequired: {
    id: 'createShipment.validation.shipmentTrackingNumberRequired',
    description: 'Shipment Tracking Number is required',
    defaultMessage: 'Shipment Tracking Number is required',
  },
  validationErrorCreateShipment_customerReferenceNumber: {
    id: 'createShipment.validation.customerReferenceNumber',
    description: 'Reference number is required',
    defaultMessage: 'Customer Reference Number is required',
  },
  validationErrorCreateShipment_shippingDescriptionRequired: {
    id: 'createShipment.validation.shippingDescription',
    description: 'Description is required',
    defaultMessage: 'Description is required',
  },
  validationErrorCreateShipment_cargoValueMaximum: {
    id: 'createShipment.validation.cargoValueMaximum',
    description: 'Max value is 19 numbers',
    defaultMessage: 'Max value is 19 numbers',
  },
  validationErrorCreateShipment_cargoValueMinimum: {
    id: 'createShipment.validation.cargoValueMinimum',
    description: 'Min value is 0.0001',
    defaultMessage: 'Min value is 0.0001',
  },
  validationErrorCreateShipment_cargoValueRequired: {
    id: 'createShipment.validation.cargoValueRequired',
    description: 'Cargo Value is required',
    defaultMessage: 'Cargo Value is required',
  },
  validationErrorCreateLoad_ValueRequired: {
    id: 'createLoad.validation.cargoValueRequired',
    description: 'Value is required',
    defaultMessage: 'Value is required',
  },
  validationErrorCreateShipment_currencyRequired: {
    id: 'createShipment.validation.currencyRequired',
    description: 'Currency is required',
    defaultMessage: 'Currency is required',
  },
  validationErrorCreateShipment_productRequired: {
    id: 'createShipment.validation.productRequired',
    description: 'Product is required',
    defaultMessage: 'Product is required',
  },
  validationErrorCreateShipment_quantityRequired: {
    id: 'createShipment.validation.quantityRequired',
    description: 'Quantity is required',
    defaultMessage: 'Quantity is required',
  },
  validationErrorCreateShipment_palletHeightRequired: {
    id: 'createShipment.validation.palletHeightRequired',
    description: 'Pallet height is required',
    defaultMessage: 'Pallet height is required',
  },
  validationErrorCreateShipment_palletQuantityRequired: {
    id: 'createShipment.validation.palletQuantityRequired',
    description: 'Pallet quantity is required',
    defaultMessage: 'Pallet quantity is required',
  },
  validationErrorCreateShipment_loadCargoValueRequired: {
    id: 'createShipment.validation.loadCargoValueRequired',
    description: 'Validation label for required value inside the load',
    defaultMessage: 'Value is required',
  },
  validationErrorCreateShipment_countryOfOriginRequired: {
    id: 'createShipment.validation.countryOfOriginRequired',
    description: 'Country of origin is required',
    defaultMessage: 'Country of origin is required',
  },
  validationErrorCreateShipment_grossWeightUnitRequired: {
    id: 'createShipment.validation.grossWeightUnitRequired',
    description: 'Gross weight is required',
    defaultMessage: 'Gross weight is required',
  },
  validationErrorCreateShipment_weightUnitRequired: {
    id: 'createShipment.validation.weightUnitRequired',
    description: 'Weight unit is required',
    defaultMessage: 'Weight unit is required',
  },
  validationErrorCreateShipment_temperatureRangeRequired: {
    id: 'createShipment.validation.temperatureRangeRequired',
    description: 'Temperature Range is required',
    defaultMessage: 'Temperature Range is required',
  },
  validationErrorCreateShipment_setPointRequired: {
    id: 'createShipment.validation.setPointRequired',
    description: 'Set Point is required',
    defaultMessage: 'Set Point is required',
  },
  validationErrorCreateShipment_upperThresholdCriticalRequired: {
    id: 'createShipment.validation.upperThresholdCriticalRequired',
    description: 'Upper Critical Threshold is required',
    defaultMessage: 'Upper Critical Threshold is required',
  },
  validationErrorCreateShipment_upperThresholdWarningRequired: {
    id: 'createShipment.validation.upperThresholdWarningRequired',
    description: 'Upper Warning Threshold is required',
    defaultMessage: 'Upper Warning Threshold is required',
  },
  validationErrorCreateShipment_lowerThresholdCriticalRequired: {
    id: 'createShipment.validation.lowerThresholdCriticalRequired',
    description: 'Low Critical Threshold is required',
    defaultMessage: 'Low Critical Threshold is required',
  },
  validationErrorCreateShipment_lowerThresholdWarningRequired: {
    id: 'createShipment.validation.lowerThresholdWarningRequired',
    description: 'Low Warning Threshold is required',
    defaultMessage: 'Low Warning Threshold is required',
  },
  validationErrorCreateShipment_pickUpLocationRequired: {
    id: 'createShipment.validation.pickUpLocationRequired',
    description: 'Pickup location is required',
    defaultMessage: 'Pickup location is required',
  },
  validationErrorCreateShipment_deliveryLocationRequired: {
    id: 'createShipment.validation.deliveryLocationRequired',
    description: 'Delivaery location is required',
    defaultMessage: 'Delivery location is required',
  },
  validationErrorCreateShipment_consigneeIsRequired: {
    id: 'createShipment.validation.consigneeIsRequired',
    description: 'Consignee is required',
    defaultMessage: 'Consignee is required',
  },
  validationErrorCreateShipment_requestedPickupDateRequired: {
    id: 'createShipment.validation.requestedPickupDateRequired',
    description: 'Requested Pickup Date',
    defaultMessage: 'Requested Pickup Date is required',
  },
  validationErrorCreateShipment_containerNumberRequired: {
    id: 'createShipment.validation.containerNumberRequired',
    description: 'Container number is required',
    defaultMessage: 'Container number is required',
  },
  validationErrorPickupDateNotInPast: {
    id: 'createShipment.validation.pickupDateNotInPast',
    description: 'The Requested Pickup Date cannot be in the past',
    defaultMessage: 'The Requested Pickup Date cannot be in the past',
  },
  validationErrorPickupDateNotBeforeRequested: {
    id: 'createShipment.validation.ErrorPickupDateNotBeforeRequested',
    description: 'The Requested Delivery Date cannot be before the Requested Pickup Date',
    defaultMessage: 'The Requested Delivery Date cannot be before the Requested Pickup Date',
  },
  validationErrorAtLeastOneLoad: {
    id: 'createShipment.validation.atLeastOneLoad',
    description: 'At least one load should be assigned',
    defaultMessage: 'At least one load should be assigned',
  },
  pickUpLocation: {
    id: 'createShipment.pickUpLocation',
    description: 'Pickup location label in placeholder',
    defaultMessage: 'Pickup Location',
  },
  locations: {
    id: 'createShipment.locations',
    description: 'the word "Locations", used in filterable drop-down',
    defaultMessage: 'Locations',
  },
  termsAndConditions: {
    id: 'createShipment.acceptTermsAndCondition',
    description: 'Accept Terms and Conditions',
    defaultMessage: 'Accept Terms and Conditions',
  },
  acceptWarrnings: {
    id: 'createShipment.acceptWarrnings',
    description: 'Accept warrnings',
    defaultMessage: 'Accept warrnings',
  },
  pickUpDate: {
    id: 'createShipment.pickUpDate',
    description: 'pickup date placeholder',
    defaultMessage: 'Requested Pickup Date',
  },
  assetRequestQuantity: {
    id: 'createShipment.assetRequestQuantity',
    description: 'Asset request quantity',
    defaultMessage: 'Quantity',
  },
  requestedDeliveryDateLabel: {
    id: 'load.requestedDeliveryDateLabel',
    description: 'Requested Delivery Date Label placeholder',
    defaultMessage: 'Requested Delivery Date (Optional)',
  },
  requestedDeliveryLocationLabel: {
    id: 'load.requestedDeliveryLocationLabel',
    description: 'Consignee Label placeholder',
    defaultMessage: 'Consignee',
  },
  requestedDeliveryLocationStatementLabel: {
    id: 'load.requestedDeliveryLocationStatementLabel',
    description: 'Requested Delivery Date Label placeholder',
    defaultMessage: 'Requested Delivery Date',
  },
  shipmentDetails: {
    id: 'createShipment.Shipment.details',
    description: 'Shipment details',
    defaultMessage: 'Shipment details',
  },
  resetSwitchProductAuto: {
    id: 'createShipment.resetSwitchProductAuto',
    description: 'Reset load message during switch use product',
    defaultMessage:
      'Switching mode to select from existing products will clear all data entered for this Load. Are you sure you wish to continue?',
  },
  resetSwitchProductManual: {
    id: 'createShipment.resetSwitchProductManual',
    description: 'Reset load message during switch use product',
    defaultMessage:
      'Switching mode to manually enter a description will clear all data entered for this Load. Are you sure you wish to continue?',
  },
  transportMode: {
    id: 'createShipment.transportMode',
    description: 'Transport Mode',
    defaultMessage: 'Transport Mode',
  },
  confirmDialogTitle: {
    id: 'createShipment.confirmDialogTitle',
    description: 'Confirm Dialog Title',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogMessageContent: {
    id: 'createShipment.confirmDialogMessageContent',
    description: 'Confirm Dialog Message Content',
    defaultMessage: 'Would you like to continue editing or discard your changes?',
  },
  confirmDialogContinueEditing: {
    id: 'createShipment.confirmDialogContinueEditing',
    description: 'Confirm Dialog Continue Editing',
    defaultMessage: 'Continue Editing',
  },
  confirmDialogDiscardSelection: {
    id: 'createShipment.confirmDialogDiscardSelection',
    description: 'Confirm Dialog Discard Selection',
    defaultMessage: 'Discard Changes',
  },
  shipmentCreateSuccess: {
    id: 'createShipment.shipmentCreateSuccess',
    description: 'Shipment Create Success',
    defaultMessage: 'Shipment has been saved',
  },
  shipmentCreateError: {
    id: 'createShipment.shipmentCreateError',
    description: 'Shipment Create Error',
    defaultMessage: 'Shipment has not been saved',
  },
  shipmentUpdateSuccess: {
    id: 'createShipment.shipmentUpdateSuccess',
    description: 'Shipment Update Success',
    defaultMessage: 'Shipment has been saved',
  },
  shipmentUpdateError: {
    id: 'createShipment.shipmentUpdateError',
    description: 'Shipment Update Error',
    defaultMessage: 'Shipment has not been saved',
  },
  deliveryNotifyPartyLabel: {
    id: 'createShipment.deliveryNotifyPartyLabel',
    description: 'Label of delivery contact dropdown ',
    defaultMessage: 'Delivery contact',
  },
  pickupNotifyPartyLabel: {
    id: 'createShipment.pickupNotifyPartyLabel',
    description: 'Label of pickup contact dropdown',
    defaultMessage: 'Pickup contact',
  },
  useProduct: {
    id: 'createShipment.useProduct',
    description: 'Use Product',
    defaultMessage: 'Use Product',
  },
})

export default messages
