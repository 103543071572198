import { Box } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useAppSelector } from '../../app/hooks'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'
import { useCreateAssetMutation, useUpdateAssetMutation } from '../../app/redux-fetch/apiAssets'
import SubmitButtons from '../../components/Buttons/SubmitButtons'
import Documents from '../Documents/Documents'
import { fetchLoggedInUserSelector } from '../selectors'
import { IAssetManagement, ILoggedInUser } from '../types'
import { PhysicalPropertiesGroup } from './PhysicalProperties/PhysicalPropertiesGroup'
import { AssetDetailGroup } from './groups/AssetDetailGroup'
import { AssetSettingsGroup } from './groups/AssetSettingsGroup'
import { messages } from './messages'
import { useSideModalContext } from './modals/SideModalContext'
import {
  assetManagementCreateSchema,
  assetManagementUpdateSchema,
} from './schemas/assetManagementSchema'

type AssetManagementFormProps = {
  asset: IAssetManagement
  isDisabled?: boolean
}

const AssetManagementForm = ({ asset, isDisabled }: AssetManagementFormProps) => {
  const { onClose } = useSideModalContext()
  const isNew = !asset.id
  const schema = !isNew ? assetManagementUpdateSchema() : assetManagementCreateSchema()
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const [
    createAsset,
    {
      isLoading: createIsLoading,
      isSuccess: createIsSuccess,
      data: createData,
      isError: createIsError,
    },
  ] = useCreateAssetMutation()

  const [
    updateAsset,
    {
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      isUninitialized: updateIsUninitialized,
      isError: updateIsError,
    },
  ] = useUpdateAssetMutation()

  const onCreate = (values: IAssetManagement) => {
    createAsset({
      tenantId,
      body: {
        ...values,
        tenantId,
      },
    })
      .then(() => {
        enqueueSuccess(messages.assetCreated)
      })
      .catch(() => {
        enqueueFailure(messages.assetNotCreated)
      })
  }

  const onUpdate = (values: IAssetManagement) => {
    updateAsset({
      tenantId,
      body: {
        ...values,
        tenantId,
      },
    })
      .then(() => {
        enqueueSuccess(messages.assetUpdated)
      })
      .catch(() => {
        enqueueFailure(messages.assetNotUpdated)
      })
  }

  return (
    <Formik
      initialValues={{ ...asset }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: FormikValues) => {
        if (isNew) {
          onCreate(values as IAssetManagement)
        } else {
          onUpdate(values as IAssetManagement)
        }
        onClose()
      }}
    >
      <Form style={{ marginTop: '56px', marginBottom: '82px' }}>
        <Box sx={{ m: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
          <Box sx={{ flex: 1, mr: 2 }}>
            <AssetSettingsGroup disabled={isDisabled} />
            <PhysicalPropertiesGroup disabled={isDisabled} />
          </Box>
          <Box sx={{ flex: 1, mr: 2 }}>
            <AssetDetailGroup disabled={isDisabled} />
            <Documents categoryId={1} entityId={asset.id} isDisabled={isDisabled} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            padding: '16px',
            backgroundColor: '#fff',
            borderTop: '1px solid #e0e0e0',
            zIndex: 999,
          }}
        >
          {!isDisabled && <SubmitButtons create={isNew} isEnabled={true} />}
        </Box>
      </Form>
    </Formik>
  )
}

export default AssetManagementForm
