import { Paper, styled } from '@mui/material';


export const DashboardTile = styled(Paper)({
    borderRadius: '10px',
    border: '1px solid rgba(99, 99, 99, 0.20)',
    boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    margin: '20px 0px 20px 0px',
});

export const DashboardInsideTile = styled(Paper)({
    borderRadius: '10px',
    border: '1px solid #0086D4',
    margin: '20px 0px 20px 0px',
});

