import { Box, TextField } from '@mui/material'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { useLocationFormContext } from '../hooks/useLocationFormContext'
import messages from '../messages'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

type MainInfoProps = {
    isDisabled?: boolean
    type: 'create' | 'view' | 'edit'
    isTenantUser?: boolean
}

const MainInfo = ({ isDisabled, type, isTenantUser }: MainInfoProps): ReactElement<any, any> => {
    const { values, touched, errors, setFieldValue } = useLocationFormContext()
    const { formatMessage } = useIntl()

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId } = loggedInUser

    const { data: customers = [], isSuccess: isCustomerLoaded } = useGetCustomersQuery(tenantId ?? -1)

    const handleTextFieldChange = ({
        target,
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(target.id, target.value)
    }

    const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
        setFieldValue(name, newValue)
    }

    const isCustomerIdNotEditable = type === 'edit'

    return (
        <Box sx={{ mb: '16px', width: '75%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography >{formatMessage(messages.mainInfo)}</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.name)}<RequiredAsterisk /></SectionLabelTypography>
                <TextField
                    disabled={isDisabled}
                    fullWidth
                    id='name'
                    name='name'
                    value={values.name}
                    onChange={handleTextFieldChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={errors.name}
                />
            </Box>
            <ConditionalRender condition={isTenantUser && (type !== 'edit' || (type === 'edit' && !!values.customerId))}>
                <Box >
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.customerLabel)}</SectionLabelTypography>
                    <FormDropdown
                        id='customerId'
                        disabled={isDisabled || isCustomerIdNotEditable}
                        onChange={handleFormDropDownChange}
                        value={values.customerId}
                        items={customers}
                        errorText={isCustomerIdNotEditable ? '' : formatMessage(messages.customerOptionalLabel)}
                    />
                </Box>
            </ConditionalRender>
        </Box>
    )
}

export default MainInfo