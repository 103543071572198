import { ICurrency } from '../../features/Currency/types'
import { Journey } from '../../features/Journey/types'
import { ICarrier, ILocationByTenantCustomer } from '../../features/Shipments/types'
import { IUpdateJourney } from '../../features/Transport/types'
import { IJourneyLegLoad } from './../../features/Transport/types'
import { apiSlice } from './apiQuery'

export const apiShipment = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUnassignedLoads: build.query<IJourneyLegLoad[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/load/journey`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error, tenantId) => [
        { type: 'Unassigned Loads', tenantId },
        { type: 'Journey', id: 'Journey' },
        { type: 'Journeys', id: 'Journeys' },
      ],
    }),
    getJourneyLoadsById: build.query<IJourneyLegLoad[], { tenantId: number; journeyId: number }>({
      query: ({ tenantId, journeyId }) => {
        return {
          url: `/tenant/${tenantId}/journey/${journeyId}/loads`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error, tenantId) => [
        { type: 'Unassigned Loads', tenantId },
        { type: 'Journey', id: 'Journey' },
      ],
    }),
    getCurrenciesByCustomerId: build.query<
      ICurrency[],
      { customerId: number; hideDisabled: boolean }
    >({
      query: ({ customerId, hideDisabled }) => {
        return {
          url: `/customer/${customerId}/currency`,
          method: 'GET',
          domain: 'Shipment',
          params: { hideDisabled },
        }
      },
      providesTags: (result, error) => [{ type: 'Currency', id: 'Currency' }],
    }),
    getCurrenciesByTenantId: build.query<ICurrency[], number>({
      query: (tenantId) => {
        return {
          url: `/tenant/${tenantId}/currency`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Currency', id: 'Currency' }],
    }),
    deleteCurrencyById: build.mutation<any, { currencyId: number }>({
      query: ({ currencyId }) => {
        return {
          url: `/currency/${currencyId}`,
          method: 'DELETE',
          domain: 'Shipment',
        }
      },
      invalidatesTags: (result, error, { currencyId }) => [{ type: 'Currency', id: 'Currency' }],
    }),
    addCurrency: build.mutation<any, { body: ICurrency }>({
      query: ({ body }) => {
        return {
          url: '/currency',
          method: 'POST',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, { body }) => [{ type: 'Currency', id: 'Currency' }],
    }),
    updateCurrency: build.mutation<any, { body: ICurrency }>({
      query: ({ body }) => {
        return {
          url: '/currency',
          method: 'PUT',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, { body }) => [{ type: 'Currency', id: 'Currency' }],
    }),
    getJourneysByTenantId: build.query<Journey[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/journey`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [
        { type: 'Journey', id: 'Journey' },
        { type: 'Journeys', id: 'Journeys' },
      ],
    }),
    getJourneyByTenantId: build.query<IUpdateJourney, { id: number; tenantId: number }>({
      query: ({ id, tenantId }) => {
        return {
          url: `/tenant/${tenantId}/journey/${id}`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Journey', id: 'Journey' }],
    }),
    updateTransportJourney: build.mutation<
      any,
      { tenantId: number; journeyId: number; body: IUpdateJourney }
    >({
      query: ({ tenantId, journeyId, body }) => ({
        url: `/tenant/${tenantId}/journey/${journeyId}`,
        method: 'PUT',
        domain: 'Shipment',
        body: body,
      }),
      invalidatesTags: (journeyId) => [{ type: 'Journey', id: 'Journey' }],
    }),
    approveJourney: build.mutation<any, { tenantId: number; journeyId: number }>({
      query: ({ tenantId, journeyId }) => ({
        url: `/tenant/${tenantId}/journey/${journeyId}/approve`,
        method: 'PUT',
        domain: 'Shipment',
      }),
      invalidatesTags: (result, error, { journeyId, tenantId }) => [
        { type: 'Journey', id: journeyId },
        { type: 'Transport Jobs', id: 'Transport Jobs' },
        { type: 'Journeys', id: tenantId },
        { type: 'Journey', id: 'Journey' },
      ],
    }),
    getCarriers: build.query<ICarrier[], number>({
      query: (tenantId: number) => ({
        url: `tenant/${tenantId}/carrier`,
        method: 'GET',
        domain: 'Shipment',
      }),
      providesTags: () => [{ type: 'Carrier', id: 'Carrier' }],
    }),
    getCountries: build.query<any, void>({
      query: () => ({
        url: '/get-countries',
        method: 'GET',
        domain: 'Shipment',
      }),
      providesTags: () => [{ type: 'Country', id: 'Country' }],
    }),
    saveDraftJourney: build.mutation<any, { tenantId: number; body: any }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/journey`,
        method: 'POST',
        domain: 'Shipment',
        body,
      }),
      invalidatesTags: (result, error, tenantId) => [{ type: 'Journeys', tenantId }],
    }),
    updateJourney: build.mutation<any, { tenantId: number; journeyId: number; body: any }>({
      query: ({ tenantId, journeyId, body }) => ({
        url: `/tenant/${tenantId}/journey/${journeyId}`,
        method: 'PUT',
        domain: 'Shipment',
        body,
      }),
      invalidatesTags: (result, error, tenantId) => [{ type: 'Journeys', tenantId }],
    }),
    getLocations: build.query<
      ILocationByTenantCustomer[],
      { tenantId: number; includeCustomerLocations?: boolean; customerId?: number }
    >({
      query: ({ tenantId, includeCustomerLocations, customerId }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        const includeCustomerLocationsQuery = includeCustomerLocations
          ? '?includeCustomerLocations=true'
          : ''
        return {
          url: `/tenant/${tenantId}/${customer}location${includeCustomerLocationsQuery}`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: () => [{ type: 'Locations', id: 'Locations' }],
    }),
    createLocation: build.mutation<
      any,
      { tenantId: number | undefined; customerId: number | undefined; body: any }
    >({
      query: ({ tenantId, customerId, body }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}location`,
          method: 'POST',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, tenantId) => [{ type: 'Locations', tenantId }],
    }),
    updateLocation: build.mutation<
      any,
      { tenantId: number | undefined; customerId: number | undefined; body: any }
    >({
      query: ({ tenantId, customerId, body }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}location`,
          method: 'PUT',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, tenantId) => [{ type: 'Locations', tenantId }],
    }),
  }),
})

export const {
  useGetJourneysByTenantIdQuery,
  useGetJourneyByTenantIdQuery,
  useUpdateTransportJourneyMutation,
  useUpdateJourneyMutation,
  useApproveJourneyMutation,
  useGetCarriersQuery,
  useGetUnassignedLoadsQuery,
  useSaveDraftJourneyMutation,
  useGetJourneyLoadsByIdQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useGetLocationsQuery,
  useGetCountriesQuery,
  useGetCurrenciesByCustomerIdQuery,
  useGetCurrenciesByTenantIdQuery,
  useAddCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyByIdMutation,
} = apiShipment
