import { Delete } from '@mui/icons-material'
import { FabStyled } from './FabStyled'

export interface IDeleteItemActionProps {
  show: boolean
  disabled: boolean
  onDeleteItem?: () => void
}

export const DeleteItemAction = ({ show, disabled, onDeleteItem }: IDeleteItemActionProps) => {
  if (!show) return null
  return (
    <FabStyled
      aria-label='delete'
      color='secondary'
      onClick={onDeleteItem}
      size='small'
      sx={{ margin: '10px 10px' }}
      disabled={disabled}
    >
      <Delete />
    </FabStyled>
  )
}
