import { Box } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useIntl } from 'react-intl'
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from '../../../app/redux-fetch/apiQuery'
import SubmitButtons from '../../../components/Buttons/SubmitButtons'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import Documents from '../../Documents/Documents'
import { IProduct } from '../types'
import MainInfo from './MainInfo'
import { getProductValidationSchema } from './productVlaidaitonSchema'

type CreateProductFormProps = {
  product: IProduct
  isDisabled: boolean
}

const ProductForm = ({ product, isDisabled }: CreateProductFormProps) => {
  const { onClose } = useSideModalContext()

  const { formatMessage } = useIntl()
  const isNew = !product.id

  const [createProduct] = useCreateProductMutation()
  const [updateProduct] = useUpdateProductMutation()
  const validationSchema = getProductValidationSchema(formatMessage)

  return (
    <Formik
      initialValues={product}
      onSubmit={async (values: FormikValues) => {
        if (!values.id) {
          await createProduct({
            customerId: values.customerId ?? -1,
            body: { ...values } as IProduct,
          })
        } else {
          await updateProduct({
            customerId: values.customerId ?? -1,
            body: { ...values } as IProduct,
          })
        }
        onClose()
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Box sx={{ p: '32px', pb:'64px', m: '32px', display: 'flex', flexDirection: 'row' }}>
          <MainInfo isDisabled={isDisabled} />
          <Box sx={{ width: '50%', ml: '16px' }}>
            <Documents categoryId={3} entityId={product.id} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            padding: '16px',
            backgroundColor: '#fff',
            borderTop: '1px solid #e0e0e0',
          }}
        >
          {!isDisabled && <SubmitButtons create={isNew} isEnabled={true} />}
        </Box>
      </Form>
    </Formik>
  )
}

export default ProductForm
