import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { useGetCustomerByIdQuery, useLocationByIdQuery } from '../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../components/Spinner/SpinnerModalBlock'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ICustomer, ILoggedInUser } from '../../types'
import CustomerForm from './CustomerForm'

type EditCustomerProps = {
  customerId: number
  isDisabled?: boolean
}

const EditCustomer = ({ customerId, isDisabled = false }: EditCustomerProps) => {
  const { tenantId, tenants } =
    useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const currencyId = tenants?.find((t) => t.id === tenantId)?.currencyId

  const {
    data: customerData,
    isSuccess: customerLoaded,
    isLoading: isCustomerFetching,
  } = useGetCustomerByIdQuery(
    {
      tenantId,
      customerId,
    },
    { refetchOnMountOrArgChange: true }
  )

  const {
    data: location,
    isSuccess: locationLoaded,
    isLoading: isLocationFetching,
  } = useLocationByIdQuery(
    {
      tenantId,
      locationId: customerData?.locationId ?? 0,
    },
    { refetchOnMountOrArgChange: true, skip: !customerData?.locationId }
  )

  const [updatedCustomer, setUpdatedCustomer] = useState<ICustomer>()

  useEffect(() => {
    if (isCustomerFetching || isLocationFetching) {
      setUpdatedCustomer(undefined)
    }
  }, [isCustomerFetching, isLocationFetching])

  useEffect(() => {
    if (
      customerLoaded &&
      locationLoaded &&
      customerData &&
      !(isCustomerFetching || isLocationFetching)
    ) {
      const updatedCustomerWithLocation = {
        ...customerData,
        ...location,
        name: customerData.name,
        locationName: location?.name,
        locationId: location?.id,
        currencyId: currencyId,
        id: customerData.id,
      }
      setUpdatedCustomer(updatedCustomerWithLocation as ICustomer)
    }
  }, [
    isLocationFetching,
    isCustomerFetching,
    customerLoaded,
    locationLoaded,
    customerData,
    location,
  ])

  if (!customerLoaded || !locationLoaded || !updatedCustomer) return <SpinnerModalBlock />
  return <CustomerForm customer={updatedCustomer as ICustomer} isDisabled={isDisabled} />
}

export default EditCustomer
