import { Box } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { IDropDownItem } from '../../../app/types'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import { Load } from '../../TransportJob/types'
import EmbeddedContainer from '../components/EmbeddedContainer'
import { messages } from '../messages'
import { Journey } from '../types'
import { getUniqueLegsForLoad, getUniqueRoutesForLoad } from '../utils'


type EmbeddedLoadTableProps = {
    loads: Load[]
    journey: Journey
    customers: IDropDownItem[]
}

const EmbeddedLoadTable = ({ loads, journey, customers }: EmbeddedLoadTableProps) => {
    const { formatMessage } = useIntl()

    const columns = useMemo<MRTColumnDef<Load>[]>(() => {
        return [
            {
                accessorFn: (row) => {
                    return `Load ${row.id}`
                },
                header: formatMessage(messages.loadName),
            },
            {
                accessorFn: (row) => {
                    return row.shipment?.trackingNumber ?? ''
                },
                header: formatMessage(messages.trackingNumber),
            },
            {
                accessorFn: (row) => {
                    return row.quantity
                },
                header: formatMessage(messages.quantity),
            },
            {
                accessorFn: (row) => {
                    return `${row.currency?.iso3} ${row.value.toFixed(2)}`
                },
                header: formatMessage(messages.value),
            },
            {
                accessorFn: (row) => {
                    const routes = getUniqueRoutesForLoad(journey, row.id)
                    return routes.reduce((acc, item) => {
                        if (acc === '') {
                            return `Route ${item.id}`
                        }
                        return acc + ` / Route ${item.id}`
                    }, '')
                },
                header: formatMessage(messages.routesName)
            },
            {
                accessorFn: (row) => {
                    const legs = getUniqueLegsForLoad(journey, row.id)
                    return legs.reduce((acc, item) => {
                        if (acc === '') {
                            return `Leg ${item.id}`
                        }
                        return acc + ` / Leg ${item.id}`
                    }, '')
                },
                header: formatMessage(messages.legsName)
            },
            {
                accessorFn: (row) => {
                    return customers.find(c => c.id === row.shipment?.customerId)?.name ?? ''
                },
                header: formatMessage(messages.customerName)
            }

        ]
    }, [])


    return (
        <EmbeddedContainer>
            <Box sx={{
                width: '100%',
                height: '100%',
            }}>
                <MRTDataGridv2
                    columns={columns}
                    data={loads || []}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableSorting={false}
                    enableBottomToolbar={false}
                    enableTopToolbar={false}
                    positionActionsColumn='last'
                />
            </Box>

        </EmbeddedContainer>
    )
}

export default EmbeddedLoadTable
