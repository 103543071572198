import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery'
import {
  useGetJourneyLoadsByIdQuery,
  useGetUnassignedLoadsQuery,
} from '../../../app/redux-fetch/apiShipment'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography'
import { IJourneyLegLoad } from '../../Transport/types'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import { messages } from '../messages'
import { getAllUniqueLoads } from '../utils'

const JourneyLoadsTable = () => {
  const { formatMessage } = useIntl()

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: loads, isSuccess: isLoadsLoaded } = useGetUnassignedLoadsQuery({ tenantId })
  const { data: customers = [], isSuccess: isCustomersLoaded } = useGetCustomersQuery(tenantId)

  const { setSelectedLoads, selectedLoads, selectedJourney } = useJourneyStepperContext()

  const { data: journeyLoads = [] } = useGetJourneyLoadsByIdQuery(
    { tenantId, journeyId: selectedJourney?.id ?? 0 },
    {
      skip: !selectedJourney,
    }
  )

  const [selectedMembers, setSelectedMembers] = useState<MRTRowSelectionState>(
    !selectedJourney
      ? selectedLoads.reduce((acc, curr) => {
        const rowId = loads?.findIndex((load) => load.loadId === curr) ?? -1
        acc[rowId] = true
        return acc
      }, {} as MRTRowSelectionState)
      : {}
  )

  useEffect(() => {
    const selectedRowIds = Object.keys(selectedMembers)
    const loadIds =
      loads
        ?.filter((load, idx) => selectedRowIds.includes(idx.toString()))
        ?.map((load) => load.loadId) ?? []
    if (selectedJourney) {
      const oldLoadIds = getAllUniqueLoads(selectedJourney as any).map((load) => load.id)
      setSelectedLoads([...oldLoadIds, ...loadIds])
    } else {
      setSelectedLoads(loadIds)
    }
  }, [selectedMembers])

  const columns = useMemo<MRTColumnDef<IJourneyLegLoad>[]>(() => {
    return [
      {
        accessorFn: (row) => row.trackingNumber,
        header: formatMessage(messages.trackingNumber),
      },
      {
        accessorFn: (row) => {
          return customers.find((c) => c.id === row.customerId)?.name ?? ''
        },
        header: formatMessage(messages.customerName),
      },
      {
        accessorFn: (row) => {
          return row.pickupLocationName
        },
        header: formatMessage(messages.pickupLocation),
      },
      {
        accessorFn: (row) => row.pickupDate,
        header: formatMessage(messages.pickupDate),
      },
      {
        accessorFn: (row) => row.consignee,
        header: formatMessage(messages.consigneeName),
      },
      {
        accessorFn: (row) => row.deliveryDate,
        header: formatMessage(messages.deliveryDate),
      },
      {
        accessorFn: (row) =>
          row.containerDetails && row.containerDetails.length > 0
            ? row.containerDetails.reduce((acc: any, curr: any) => {
              return `${acc ? `${acc}, ` : ''}${curr.containerNumber}`
            }, '')
            : '',
        header: formatMessage(messages.containerNumber),
      },
      {
        accessorFn: (row) => row.description,
        header: formatMessage(messages.description),
      },
      {
        accessorFn: (row) => row.temperatureRange,
        header: formatMessage(messages.temperatureRange),
      },
    ]
  }, [customers, journeyLoads])

  const getData = useMemo(() => {
    if (!selectedJourney) {
      return loads?.filter((load) => selectedLoads.includes(load.loadId)) ?? []
    }
    return journeyLoads
  }, [selectedLoads, journeyLoads])


  return (
    <>
      <MRTDataGridv2
        state={{ isLoading: !(isLoadsLoaded && isCustomersLoaded), rowSelection: selectedMembers }}
        columns={columns}
        data={getData}
        getRowId={(row) => row.id as any}
        enableSorting={false}
        enableFilters={false}
        enableColumnActions={false}
        enableExpandAll={true}
        enableRowActions={false}
        onRowSelectionChange={setSelectedMembers}
        positionActionsColumn='last'
        enableTopToolbar={false}
        leftHeadingComponent={
          <TableTitleTypography>
            {' '}
            {selectedJourney ? `${formatMessage(messages.journey)} ${selectedJourney.id}  - ` : ''}
            {formatMessage(messages.loadsName)}
          </TableTitleTypography>
        }
      />
    </>
  )
}

export default JourneyLoadsTable
