import { Box, Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material';

const LoadTemperatureCard = ({ data }: any) => {
    const {
        loadName,
        date,
        setPoint,
        tempRange,
        latestReading,
        average,
        upperThreshold,
        upperCritical,
        lowerThreshold,
        lowerCritical,
        color,
    } = data;

    const renderBoldText = (text: string, color?: string) => (
        <Typography component="span" fontWeight="bold" sx={{
            color: color ? color : 'black'
        }}>
            {text}
        </Typography >
    );

    return (
        <Card sx={{ mr: '20px', minWidth: 275, width: '50%', borderRadius: 2, color: '#0086D4', border: 1 }}>
            <CardContent>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Chip label=" " size="small" sx={{ bgcolor: color, width: '15px', height: '15px', borderRadius: '50%' }} />
                    <Typography variant="h6" component="div" color='secondary.main'>
                        {loadName}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {date}
                    </Typography>
                </Stack>
                <Divider sx={{ my: 1, borderColor: (theme) => theme.palette.secondary.main }} variant="middle" />
                <Typography sx={{ mb: 1.5, fontWeight: 700 }} color="text.secondary">
                    Temperature Details
                </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{
                        width: '180px',
                        color: 'black'
                    }}>
                        Set Point {renderBoldText(`${setPoint}°C`)}
                    </Typography>
                    <Typography sx={{
                        width: '180px',
                        color: 'black'

                    }}>
                        Temp Range {renderBoldText(`${tempRange}°C`)}
                    </Typography>
                    <Typography sx={{
                        width: '180px',
                        color: 'black'
                    }}>
                        Latest Reading {renderBoldText(`${latestReading}°C`, '#04C000')}
                    </Typography>
                    <Typography sx={{
                        width: '130px',
                        color: 'black'
                    }}>
                        Average {renderBoldText(`${average}°C`, '#04C000')}
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{
                        width: '180px',
                        color: 'black'
                    }}>
                        Upper Threshold {renderBoldText(`${upperThreshold}°C`)}
                    </Typography>
                    <Typography sx={{
                        width: '180px',
                        color: 'black'
                    }}>
                        Upper Critical {renderBoldText(`${upperCritical}°C`)}
                    </Typography>
                    <Typography sx={{
                        width: '180px',
                        color: 'black'
                    }}>
                        Lower Threshold {renderBoldText(`${lowerThreshold}°C`)}
                    </Typography>
                    <Typography sx={{
                        width: '130px', color: 'black'
                    }}>
                        Lower Critical {renderBoldText(`${lowerCritical}°C`)}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default LoadTemperatureCard;
