import { useIntl } from 'react-intl'
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper'
import JourneyTypeSelection from '../JourneyForm/JourneyTypeSelection'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import { messages } from '../messages'

const SelectJourneyTypeModal = () => {

    const { formatMessage } = useIntl()
    const { currentStep, goToStep, withLoadJourney } = useJourneyStepperContext()

    return (
        <SideModalWrapper open={currentStep === 1} onClose={() => {
            goToStep(0)
        }} onSave={() => {
            !withLoadJourney ? goToStep(3) : goToStep(2)
        }} headerLabel={formatMessage(messages.createJourney)} width='30%'>
            <JourneyTypeSelection />
        </SideModalWrapper>
    )
}

export default SelectJourneyTypeModal

