import { combineReducers } from '@reduxjs/toolkit'

import {
  CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME,
  CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME,
} from '../CommonSettings/UserSettings/User/consts'
import {
  fetchAllCustomerSettingsUserByCustomerIdReducers,
  postUserByCustomerIdReducers,
} from '../CommonSettings/UserSettings/User/slice'

export const customerSettingsReducers = combineReducers({
  [CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME]: fetchAllCustomerSettingsUserByCustomerIdReducers,
  [CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME]: postUserByCustomerIdReducers,
})
