import AddIcon from '@mui/icons-material/Add'
import { AccordionSummary, Box, Fab, Grid, SelectChangeEvent, Typography } from '@mui/material'
import { type FormikProps } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { IShipment } from '../../types'
import { CreateVirtualLoadForAssetRequest, sortByDisplayOrder } from '../../utils'
import AssetRequestAccardion from './AssetRequestAccardion'
import messages from './messages'
import { AssetRequestAccordion } from './styles'

interface IAssignmentAssetRequestPaneProps {
  formik: FormikProps<IShipment>
  isVirtualLoadMode: boolean
  disabled?: boolean
  viewMode?: boolean
  handleRemoveAssetRequest: (index: number) => void
  handleSwitchLoadChanges: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ) => void
  handleSelectLoadChanges: (e: SelectChangeEvent<any>, name: string, newValue: any) => void
  handleFormDropDownChanges: (e: SelectChangeEvent<number>, name: string, newValue: any) => void
}

const AssignmentAssetRequestPane = (
  props: IAssignmentAssetRequestPaneProps
): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const {
    formik,
    isVirtualLoadMode,
    disabled,
    viewMode,
    handleRemoveAssetRequest,
    handleSwitchLoadChanges,
    handleSelectLoadChanges,
    handleFormDropDownChanges,
  } = props

  const handleAddAssetRequest = (): void => {
    const lastId = formik.values.assetRequests.slice(-1)[0]?.id ?? 0
    const lastDisplayOrder =
      formik.values.assetRequests.sort(sortByDisplayOrder).slice(-1)[0]?.displayOrder ?? 0

    const id = lastId > 0 ? -1 * (lastId - 1) : lastId - 1
    const loadLastId = formik.values.loads.slice(-1)[0]?.id ?? 0
    const virtualLoadId = CreateVirtualLoadForAssetRequest(loadLastId, id, formik)
    formik.setFieldValue(
      'assetRequests',
      [
        ...formik.values.assetRequests,
        {
          id,
          loadsId: [virtualLoadId],
          shipmentId: formik.values.id ?? -1,
          displayOrder: lastDisplayOrder + 1,
        },
      ], // TODO: Shipment ID should be removed
      false
    )
  }
  return (
    <>
      {formik.values.assetRequests.length > 0 &&
        formik.values.assetRequests.map((assetRequest, assetRequestIndex) => {
          return (
            <AssetRequestAccardion
              key={assetRequestIndex}
              formik={formik}
              assetRequestIndex={assetRequestIndex}
              assetRequest={assetRequest}
              isVirtualLoadMode={isVirtualLoadMode}
              handleRemoveAssetRequest={handleRemoveAssetRequest}
              handleSelectLoadChanges={handleSelectLoadChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              handleFormDropDownChanges={handleFormDropDownChanges}
              disabled={disabled}
              viewMode={viewMode}
            />
          )
        })}
      {!disabled && (
        <AssetRequestAccordion expanded={false}>
          <AccordionSummary>
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={6} alignItems='center'>
                <Box>
                  <Typography>{formatMessage(messages.newAssetRequest)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end'>
                  <Fab
                    aria-label='add'
                    color='secondary'
                    onClick={() => {
                      handleAddAssetRequest()
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
        </AssetRequestAccordion>
      )}
    </>
  )
}

export default AssignmentAssetRequestPane
