import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import AnyChart from 'anychart-react';
import { useAppSelector } from '../../app/hooks';
import { useGetShipmentsReportQuery } from '../../app/redux-fetch/apiQuery';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';

import { v4 as uuidv4 } from 'uuid'; // To generate a new GUID
import SpinnerBoxBlock from '../../components/Spinner/SpinnerBoxBlock';
import { DashboardInsideTile, DashboardTile } from './DashboardTile';
import { useDashboard } from './hooks/useDashboard';

const getGradient = (index: number) => {
    const gradients = [
        '#075A8A',
        '#567F97',
    ];
    return gradients[index % gradients.length];
};


const StatusBreakdown = (props: any) => {
    return (
        <Box>
            <Box>
                <Typography variant="h6" gutterBottom>
                    Status Breakdown
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" gutterBottom color={'#666'} fontSize={14}>
                    {`Total Shipments: ${props.data.totalCount}`}
                </Typography>
            </Box>
            <List>
                {props.data.status.map((status: any, index: any) => (
                    <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            sx={{
                                width: 20,
                                height: 20,
                                marginRight: 1,
                                background: getGradient(index), // Apply unique gradient
                            }}
                        />
                        <Typography variant="body1">
                            {status.name}: {status.value}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const ShipmentDashboard = () => {
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { timeFrame } = useDashboard()
    const { data, isSuccess } = useGetShipmentsReportQuery({
        tenantId: loggedInUser.tenantId,
        startDate: timeFrame.startDate.toISOString(),
        endDate: timeFrame.endDate.toISOString()
    },
        { refetchOnMountOrArgChange: true }
    )
    if (!isSuccess) {
        return (
            <DashboardTile sx={{ width: '760px' }}>
                <SpinnerBoxBlock />
            </DashboardTile>
        )
    }
    const getData = () => {
        if (data) {
            return data.status.map((status: any, index: number) => {
                return {
                    x: status.name,
                    value: status.value,
                    normal: {
                        fill: getGradient(index),
                    },
                }
            })
        }
        return []
    }
    const complexSettings = {
        width: 360,
        height: 360,
        contextMenu: true,
        type: 'pie',
        bound: false,
        data: getData(),
        exports: {
            path: 'https://cdn.anychart.com/3rd/',
            enabled: true,
            fallback: true,
        },
        legend: false
    }

    return (
        <DashboardTile sx={{ width: '760px' }}>
            <Grid container spacing={2}>
                <Grid item xs={7} >
                    <AnyChart id={uuidv4()} {...complexSettings} />
                </Grid>
                <Grid item xs={5} >
                    <DashboardInsideTile sx={{ margin: '20px', padding: '20px' }}>
                        <StatusBreakdown data={data} />
                    </DashboardInsideTile>
                </Grid>
            </Grid>
        </DashboardTile>
    )
}

export default ShipmentDashboard;