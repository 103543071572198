import { Link, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { messages } from '../messages'

type ViewJourneyActionProps = {
    onClick: () => void
    text: string
}

const ViewJourneyAction = ({ text, onClick }: ViewJourneyActionProps) => {
    const { formatMessage } = useIntl()

    return (
        <Typography
            component="div"
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
            }}
        >
            <Link
                underline='none'
                color='secondary'
                onClick={onClick}
                sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                }}
            >
                {formatMessage(messages.viewLink)}
                <Typography
                    component="span"
                    sx={{ color: 'black', marginLeft: 1 }}
                >
                    {text}
                </Typography>
            </Link>
        </Typography>
    )
}

export default ViewJourneyAction