import { Box, Grid, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { a11yProps } from '../../app/utils'
import TabPanel from '../../components/TabPanel/TabPanel'
import JourneyStepper from './JourneyForm/JourneyStepper'
import JourneyTable from './JourneyTable'
import AddLoadsModal from './Modals/AddLoadsModal'
import JourneyModal from './Modals/JourneyModal'
import SelectJourneyTypeModal from './Modals/SelectJourneyTypeModal'
import UnassignedLoadsTable from './UnassiagnedLoadsTable'
import { messages } from './messages'

const Journey = () => {
    const { formatMessage } = useIntl()

    const [activeTab, setActiveTab] = useState(0)

    const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
        setActiveTab(tabId)
    }

    return (<>
        <JourneyStepper>
            <SelectJourneyTypeModal />
            <AddLoadsModal />
            <JourneyModal />
            <Box sx={{ width: '100%', justifyContent: 'start' }}>
                <Tabs
                    sx={(theme) => {
                        return { '& .MuiTabs-indicator': { backgroundColor: theme.palette.secondary.main } }
                    }}
                    value={activeTab}
                    onChange={handleChange}
                    variant='standard'
                    scrollButtons={false}
                    textColor='secondary'
                    aria-label='Asset settings scrollable tabs'
                >
                    <Tab label={formatMessage(messages.journeys)} {...a11yProps(0)} />
                    <Tab label={formatMessage(messages.unassignedLoadsTitle)} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={activeTab} index={0}>
                <Grid item xs={12}>
                    <JourneyTable />
                </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <Grid item xs={12}>
                    <UnassignedLoadsTable />
                </Grid>
            </TabPanel>
        </JourneyStepper>
    </>
    )
}

export default Journey