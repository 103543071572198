export const makeShipmentsApiHeaders = (): any => {
  return {
    'Content-type': 'application/json',
  }
}

export const makeShipmentsAuthorizedHeaders = (): any => {
  // TODO: Add shipments authorization headers
  return {
    ...makeShipmentsApiHeaders(),
  }
}

export const makeUsersApiHeaders = (): any => {
  return {
    'Content-type': 'application/json',
  }
}

export const makeCoresApiHeaders = (): any => {
  return {
    'Content-type': 'application/json',
  }
}

export const makeCoresApiAuthorizedHeaders = (): any => {
  return {
    ...makeUsersApiHeaders(),
  }
}

export const makeUsersApiAuthorizedHeaders = (): any => {
  // TODO: Add users authorization headers
  return {
    ...makeUsersApiHeaders(),
  }
}

export const makeAssetsApiHeaders = (): any => {
  return {
    'Content-type': 'application/json',
  }
}

export const makeAssetsApiAuthorizedHeaders = (): any => {
  // TODO: Add users authorization headers
  return {
    ...makeAssetsApiHeaders(),
  }
}

export const makeCustomerSettingsApiHeaders = (): any => {
  return {
    'Content-type': 'application/json',
  }
}
