import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  journeys: {
    id: 'journey.journeys',
    description: 'Journeys Title',
    defaultMessage: 'Journeys',
  },
  unassignedLoadsTitle: {
    id: 'journey.nedLoadsTitle',
    description: 'Unassigned Loads Title',
    defaultMessage: 'Unassigned Loads',
  },
  newJourney: {
    id: 'journey.newJourney',
    description: 'New Journey',
    defaultMessage: 'New Journey',
  },
  addLeg: {
    id: 'journey.addLeg',
    description: 'Add Leg',
    defaultMessage: 'Add Leg',
  },
  saveDraft: {
    id: 'journey.saveDraft',
    description: 'Save Draft',
    defaultMessage: 'Save Draft',
  },
  reset: {
    id: 'journey.reset',
    description: 'Reset',
    defaultMessage: 'Reset',
  },
  cancel: {
    id: 'journey.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel',
  },
  approveForTransport: {
    id: 'journey.approveForTransport',
    description: 'Approve For Transport',
    defaultMessage: 'Approve For Transport',
  },
  journeyName: {
    id: 'journey.journey.name',
    description: 'Name of the journey',
    defaultMessage: 'Name',
  },
  journey: {
    id: 'journey.journey',
    description: 'Journey',
    defaultMessage: 'Journey',
  },
  legsName: {
    id: 'journey.legs.name',
    description: 'Name of the legs',
    defaultMessage: 'Legs',
  },
  customerName: {
    id: 'journey.customer.name',
    description: 'Name of the customer',
    defaultMessage: 'Customer',
  },
  consigneeName: {
    id: 'journey.consignee.name',
    description: 'Name of the consignee',
    defaultMessage: 'Consignee',
  },

  legName: {
    id: 'journey.leg.name',
    description: 'Name of the leg',
    defaultMessage: 'Leg',
  },
  routesName: {
    id: 'journey.routes.name',
    description: 'Name of the routes',
    defaultMessage: 'Routes',
  },
  loadsName: {
    id: 'journey.loads.name',
    description: 'Name of the loads',
    defaultMessage: 'Loads',
  },
  noLoadsSelected: {
    id: 'journey.noLoadsSelected',
    description: 'No Loads Selected',
    defaultMessage: 'No Loads Selected.',
  },
  createdOn: {
    id: 'journey.createdOn',
    description: 'Created On',
    defaultMessage: 'Created On',
  },
  editLink: {
    id: 'journey.editLink',
    description: 'Edit Link',
    defaultMessage: 'Edit',
  },
  viewLink: {
    id: 'journey.viewLink',
    description: 'View Link',
    defaultMessage: 'View',
  },
  routeName: {
    id: 'journey.route.name',
    description: 'Name of the route',
    defaultMessage: 'Route',
  },
  loadName: {
    id: 'journey.load.name',
    description: 'Name of the load',
    defaultMessage: 'Load',
  },
  carrierName: {
    id: 'journey.carrier.name',
    description: 'Name of the carrier',
    defaultMessage: 'Carrier',
  },
  pickupLocation: {
    id: 'journey.pickup.location',
    description: 'Pickup Location',
    defaultMessage: 'Pickup Location',
  },
  pickupDate: {
    id: 'journey.pickup.date',
    description: 'Pickup Date',
    defaultMessage: 'Pickup Date',
  },
  deliveryLocation: {
    id: 'journey.delivery.location',
    description: 'Delivery Location',
    defaultMessage: 'Delivery Location',
  },
  deliveryDate: {
    id: 'journey.delivery.date',
    description: 'Delivery Date',
    defaultMessage: 'Delivery Date',
  },
  idName: {
    id: 'journey.id.name',
    description: 'Name  of the id',
    defaultMessage: 'Id',
  },
  statusName: {
    id: 'journey.status.name',
    description: 'Name of the status',
    defaultMessage: 'Status',
  },
  approvedStatus: {
    id: 'journey.approvedStatus',
    description: 'Approved',
    defaultMessage: 'Approved',
  },
  draftStatus: {
    id: 'journey.draftStatus',
    description: 'Draft',
    defaultMessage: 'Draft',
  },
  trackingNumber: {
    id: 'journey.trackingNumber',
    description: 'Tracking Number',
    defaultMessage: 'Tracking Number',
  },
  quantity: {
    id: 'journey.quantity',
    description: 'Quantity',
    defaultMessage: 'Quantity',
  },
  value: {
    id: 'journey.value',
    description: 'Value',
    defaultMessage: 'Value',
  },
  containerNumber: {
    id: 'journey.containerNumber',
    description: 'Container Number',
    defaultMessage: 'Container Number',
  },
  description: {
    id: 'journey.description',
    description: 'Description',
    defaultMessage: 'Description',
  },
  temperatureRange: {
    id: 'journey.temperatureRange',
    description: 'Temperature Range',
    defaultMessage: 'Temperature Range',
  },
  journeyType: {
    id: 'journeyType',
    description: 'Journey Type',
    defaultMessage: 'Journey Type',
  },
  selectTypeOfJourney: {
    id: 'journey.selectTypeOfJourney',
    description: 'Select Type Of Journey',
    defaultMessage: 'Please select the type of journey you want to create',
  },
  withLoad: {
    id: 'journey.withLoad',
    description: 'With Load',
    defaultMessage: 'With Load',
  },
  createJourney: {
    id: 'journey.createJourney',
    description: 'Create Journey',
    defaultMessage: 'Create Journey',
  },
  standardJourney: {
    id: 'journey.standardJourney',
    description: 'Standard Journey',
    defaultMessage: 'Standard Journey',
  },
  multiModalJourney: {
    id: 'journey.multiModalJourney',
    description: 'Multimodal Journey',
    defaultMessage: 'Multimodal Journey',
  },
  withoutLoad: {
    id: 'journey.withoutLoad',
    description: 'Without Load',
    defaultMessage: 'Without Load',
  },
  journeyConfiguration: {
    id: 'journey.journeyConfiguration',
    description: 'Journey Configuration',
    defaultMessage: 'Please select Journey Configuration',
  },
  legOrigin: {
    id: 'journey.leg.origin',
    description: 'Middle Leg - Origin',
    defaultMessage: 'Middle Leg - Origin',
  },
  legDestination: {
    id: 'journey.leg.destination',
    description: 'Middle Leg - Destination',
    defaultMessage: 'Middle Leg - Destination',
  },
  selectLoads: {
    id: 'journey.selectLoads',
    description: 'Select Loads',
    defaultMessage: 'Please select the loads you want to add to the journey',
  },
  addLoads: {
    id: 'journey.addLoads',
    description: 'Add Loads',
    defaultMessage: 'Add Loads',
  },
  cannotCombineLoadsAndAssets: {
    id: 'journey.cannotCombineLoadsAndAssets',
    defaultMessage: 'You cannot combine shipment loads and asset requests in the same journey',
    description:
      'Error message when attempting to combine shipment loads and asset requests in the same journey.',
  },
  noSelectedLoadsWarning: {
    id: 'journey.noSelectedLoadsWarning',
    defaultMessage:
      'You have not selected any loads. Are you sure you wish to create a Journey without loads?',
    description: 'Warning message when attempting to create a journey without selecting any loads.',
  },
  addRoute: {
    id: 'journey.addRoute',
    description: 'Add Route',
    defaultMessage: 'Add Route',
  },
  legCarrierLabel: {
    id: 'journey.legCarrierLabel',
    description: 'Leg Carrier Label',
    defaultMessage: 'Carrier',
  },
  legCarrierWaybillMaster: {
    id: 'journey.legCarrierWaybillMaster',
    description: 'Leg Carrier Waybill Master',
    defaultMessage: 'Carrier Waybill Master',
  },
  selectLocation: {
    id: 'journey.selectLocation',
    description: 'Select Location',
    defaultMessage: 'Select Location',
  },
  start: {
    id: 'journey.start',
    description: 'Start',
    defaultMessage: 'Start',
  },
  end: {
    id: 'journey.end',
    description: 'End',
    defaultMessage: 'End',
  },
  stop: {
    id: 'journey.stop',
    description: 'Stop',
    defaultMessage: 'Stop {stopNumber}',
  },
  selectContact: {
    id: 'journey.selectContact',
    description: 'Select Contact',
    defaultMessage: 'Select Contact',
  },
  pickup: {
    id: 'journey.pickup',
    description: 'Pickup',
    defaultMessage: 'Pickup',
  },
  dropoff: {
    id: 'journey.dropoff',
    description: 'Dropoff',
    defaultMessage: 'Dropoff',
  },
  removeLoadFromRoute: {
    id: 'journey.removeLoadFromRoute',
    description: 'Remove Load From Route',
    defaultMessage: 'Remove Load From Route',
  },
  noLoads: {
    id: 'journey.noLoads',
    description: 'No Loads',
    defaultMessage: 'No Loads',
  },
  journeyNotSaved: {
    id: 'journey.journeyNotSaved',
    description: 'Journey Not Saved',
    defaultMessage: 'Journey not saved',
  },
  journeyNotApproved: {
    id: 'journey.journeyNotApproved',
    description: 'Journey Not Approved',
    defaultMessage: 'Journey not approved',
  },
  journeyApprovedSuccessfully: {
    id: 'journey.journeyApprovedSuccessfully',
    description: 'Journey Approved Successfully',
    defaultMessage: 'Journey approved successfully',
  },
  journeySavedSuccessfully: {
    id: 'journey.journeySavedSuccessfully',
    description: 'Journey Saved Successfully',
    defaultMessage: 'Journey saved successfully',
  },
  loadPickupDeliveryValidation: {
    id: 'journey.loadPickupDeliveryValidation',
    description: 'Load Pickup Delivery Validation',
    defaultMessage: 'Pickup location must be before delivery location',
  },
  loadPickupDeliveryLegsValidation: {
    id: 'journey.loadPickupDeliveryLegsValidation',
    description: 'Load Pickup Delivery Legs Validation',
    defaultMessage: 'Load pickup location must be before leg delivery location',
  },
  loadPickupDropoffValidation: {
    id: 'journey.loadPickupDropoffValidation',
    description: 'Load Pickup Dropoff Validation',
    defaultMessage: 'Pickup location must be before dropoff location',
  },
  invalidRouteLocationCount: {
    id: 'journey.invalidRouteLocationCount',
    description: 'Invalid Route Location Count',
    defaultMessage: 'A route must have at least two locations',
  },
})
