import { styled } from '@mui/system'

export const SpinnerCenter = styled('div')({
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const SpinnerModalCenter = styled('div')({
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '70%',
  transform: 'translate(-50%, -50%)',
})

export const SpinnerBoxCenter = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

export const MutationSpinnerBoxCenter = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 9999,
})
