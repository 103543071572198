import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik, FormikErrors, FormikValues, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import {
  useAssetCategoriesQuery,
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
} from '../../../../../app/redux-fetch/apiAssets'
import { IDropDownItem } from '../../../../../app/types'
import FormDropdown from '../../../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import UserTypesAutocomplete from '../../../Components/AssetSettings/AssetType/fields/UserTypesAutocomplete'
import { IWorkforceTypeAllocations, IWorkforceTypes } from '../../../Components/AssetSettings/types'
import { messages } from '../../messages'
import { AssetType } from '../../types'
import { getAssetTypeValidationSchema } from './validationSchema'
import { useNotificationStack } from '../../../../../app/hooks/useNotificationStack'

type AssetTypeFormProps = {
  isOpen: boolean
  assetType?: AssetType
  handleClose: () => void
}

const AssetTypeForm = ({ isOpen, assetType, handleClose }: AssetTypeFormProps) => {
  const { formatMessage } = useIntl()
  const isNew = !assetType?.id

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: assetCategories = [] } = useAssetCategoriesQuery({ tenantId })

  const validationSchema = getAssetTypeValidationSchema()

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const [createAssetType] = useCreateAssetTypeMutation()
  const [updateAssetType] = useUpdateAssetTypeMutation()

  return (
    <Formik
      initialValues={{ ...assetType } as AssetType}
      validationSchema={validationSchema}
      onSubmit={async (values: FormikValues) => {
        if (isNew) {
          await createAssetType({ tenantId, body: values as AssetType })
            .then(() => {
              enqueueSuccess(messages.assetTypeCreated)
            })
            .catch(() => {
              enqueueFailure(messages.assetTypeNotCreated)
            })
        } else {
          await updateAssetType({ tenantId, body: values as AssetType })
            .then(() => {
              enqueueSuccess(messages.assetTypeUpdated)
            })
            .catch(() => {
              enqueueFailure(messages.assetTypeNotUpdated)
            })
        }
        handleClose()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        submitForm,
        validateForm,
        setTouched,
      }) => {
        return (
          <Form>
            <Dialog open={isOpen} onClose={handleClose}>
              <DialogTitle>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '20px',
                  }}
                >
                  {formatMessage(messages.addEditAssetType)}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{ minWidth: '450px' }}>
                <Box sx={{ mb: '16px' }}>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.assetTypeName)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <TextField
                      fullWidth
                      id={'name'}
                      name={'name'}
                      value={values?.name ?? ''}
                      onChange={handleChange}
                      helperText={touched.name && (errors?.name as React.ReactNode)}
                      error={touched.name && Boolean(errors?.name)}
                    />
                  </Box>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.assetCategory)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <FormDropdown
                      id={'assetCategoryId'}
                      items={(assetCategories as IDropDownItem[]) ?? []}
                      onChange={(_e: any, name: string, value: number) =>
                        setFieldValue(name, value)
                      }
                      value={values.assetCategoryId}
                      error={touched.assetCategoryId && Boolean(errors?.assetCategoryId)}
                      errorText={touched.assetCategoryId && errors?.assetCategoryId}
                    />
                  </Box>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.selectTypeOptions)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isMotorized'
                            name='isMotorized'
                            color='secondary'
                            checked={values.isMotorized}
                            value={values.isMotorized}
                            onChange={({ target }) => {
                              setFieldValue('isMotorized', target.checked)
                            }}
                          />
                        }
                        label={formatMessage(messages.motorized)}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: '8px' }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='requiresDriver'
                            name='requiresDriver'
                            color='secondary'
                            checked={values.requiresDriver}
                            value={values.requiresDriver}
                            onChange={({ target }) => {
                              setFieldValue('requiresDriver', target.checked)
                            }}
                          />
                        }
                        label={formatMessage(messages.requiresDriver)}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: '8px' }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isTemperatureControlled'
                            name='isTemperatureControlled'
                            color='secondary'
                            checked={values.requiresDriver}
                            value={values.requiresDriver}
                            onChange={({ target }) => {
                              setFieldValue('isTemperatureControlled', target.checked)
                            }}
                          />
                        }
                        label={formatMessage(messages.isTemperatureControlled)}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: '16px' }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='sealable'
                            name='sealable'
                            color='secondary'
                            checked={values.sealable}
                            value={values.sealable}
                            onChange={({ target }) => {
                              setFieldValue('sealable', target.checked)
                            }}
                          />
                        }
                        label={formatMessage(messages.sealable)}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: '16px' }}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='carriesPayload'
                            name='carriesPayload'
                            color='secondary'
                            checked={values.carriesPayload}
                            value={values.carriesPayload}
                            onChange={({ target }) => {
                              setFieldValue('carriesPayload', target.checked)
                            }}
                          />
                        }
                        label={formatMessage(messages.carriesPayload)}
                      />
                    </FormControl>
                  </Box>
                  <UserTypesAutocomplete
                    defaultValue={values.workforceTypes}
                    label={formatMessage(messages.workforceTypes)}
                    onChange={(items: IWorkforceTypes[]) => {
                      const assetTypeId = values.id
                      const workforceTypeAllocations = items.reduce((acc, item) => {
                        acc.push({
                          assetTypeId,
                          workforceTypeId: item.id,
                        } as IWorkforceTypeAllocations)
                        return acc
                      }, [] as IWorkforceTypeAllocations[])
                      setFieldValue('workforceTypeAllocations', workforceTypeAllocations)
                      setFieldValue('userTypes', items)
                    }}
                  />
                  <Box>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.enabledDisabled)}
                    </SectionLabelTypography>
                    <FormControlLabel
                      sx={{ justifyContent: 'end', minHeight: '56px' }}
                      value={values.enabled}
                      labelPlacement='end'
                      control={
                        <Switch
                          color='secondary'
                          checked={values.enabled}
                          value={values.enabled}
                          onChange={({ target }) => {
                            setFieldValue('enabled', target.checked)
                          }}
                        />
                      }
                      label={'Enabled'}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  {formatMessage(messages.cancelBtn)}
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    const handleSubmit = () => {
                      validateForm().then((errors: FormikErrors<any>) => {
                        submitForm().finally(() => {
                          setTouched(setNestedObjectValues(errors, true))
                        })
                      })
                    }
                    handleSubmit()
                  }}
                >
                  {isNew ? 'Create' : 'Update'}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AssetTypeForm
