import { Box, Switch, Typography } from '@mui/material';
import React from 'react';

type SwitchWithLabelsProps = {
    isSelected: boolean;
    setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
    labelBefore: string;
    labelAfter: string;
};

const SwitchWithLabels = ({
    isSelected,
    setIsSelected,
    labelBefore,
    labelAfter,

}: SwitchWithLabelsProps) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '56px' }}>
            <Typography sx={(theme) => ({ marginRight: 2, color: isSelected ? '#636363' : theme.palette.secondary.main })}>
                {labelBefore}
            </Typography>
            <Switch
                color='secondary'
                checked={isSelected}
                onChange={({ target }) => setIsSelected(target.checked)}
            />
            <Typography sx={(theme) => ({ marginRight: 2, color: !isSelected ? '#636363' : theme.palette.secondary.main })}>
                {labelAfter}
            </Typography>
        </Box>
    );
}

export default SwitchWithLabels;