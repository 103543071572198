import { Box } from '@mui/material';
import { MRT_RowSelectionState as MRTRowSelectionState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/hooks';
import { useGetUserGroupsQuery } from '../../../../app/redux-fetch/apiQuery';
import CommonButton from '../../../../components/Buttons/CommonButton';
import RemoveButton from '../../../../components/Buttons/RemoveButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import { fetchLoggedInUserSelector } from '../../../selectors';
import { ILoggedInUser } from '../../../types';
import { IUserGroup } from '../UserGroups/types';
import { useUserFormContext } from './hooks/useUserFormContext';
import messages from './messages';

type UserGroupTableProps = {
    isDisabled: boolean
}
const UserGroupTable = ({ isDisabled }: UserGroupTableProps) => {

    const { formatMessage } = useIntl()

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { tenantId } = loggedInUser

    const { values, setFieldValue, errors, touched } = useUserFormContext()

    const { userGroups: userGroups } = values

    const { data: allGroups = [], isSuccess: userGroupsLoaded } = useGetUserGroupsQuery({
        tenantId,
    });

    const [isEditMembersMode, setIsEditMembersMode] = useState(false)


    const [selectedMembers, setSelectedMembers] = useState<MRTRowSelectionState>({})

    useEffect(() => {
        if (isEditMembersMode) {
            const selectedGroups = allGroups?.filter((group: IUserGroup) => {
                return group.id && selectedMembers[group.id]
            })
            setFieldValue('userGroups', selectedGroups ?? [])
        }
    }, [selectedMembers])

    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'name',
                header: formatMessage(messages.nameLabel),
            },
            {
                accessorFn: (row: any) => {
                    return row.description
                },
                header: formatMessage(messages.descriptionLabel),
            }
        ];
    }, []);


    const groups = useMemo(() => {
        if (isEditMembersMode) {
            return allGroups.map((group: IUserGroup) => {
                return {
                    ...group,
                }
            }) ?? [];
        }
        return userGroups?.map((group: IUserGroup) => {
            return {
                ...group,
            }
        }) ?? [];
    }, [allGroups, isEditMembersMode, userGroups]);

    return (
        <MRTDataGridv2
            initialState={{
                    sorting: [
                        {
                            id: 'name',
                            desc: false,
                        }]
                }
            }
            rightHeadingComponent={
                (!isDisabled ? (<Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                    }}
                >
                    <Box>
                        {!isEditMembersMode ? (
                            <CommonButton
                                text={formatMessage(messages.editBtn)}
                                onClick={() => {
                                    const predefinedMembers = userGroups?.reduce((acc, group: IUserGroup) => {
                                        if (group.id) acc[group.id] = true
                                        return acc;
                                    }, {} as Record<number, boolean>);
                                    setIsEditMembersMode(true)
                                    setSelectedMembers(predefinedMembers)
                                }}
                            />
                        ) : (
                            <Box sx={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center'
                            }}>
                                <RemoveButton
                                    text={formatMessage(messages.cancelBtn)}
                                    onClick={
                                        () => {
                                            setIsEditMembersMode(false)
                                            setSelectedMembers({})
                                        }
                                    }
                                />
                            </Box>

                        )}

                    </Box>
                </Box>) : <></>)

            }
            columns={columns}
            data={groups || []}
            getRowId={(row) => row.id as any}
            positionActionsColumn='last'
            enableRowSelection={isEditMembersMode}
            onRowSelectionChange={setSelectedMembers}
            state={{
                isLoading: !userGroupsLoaded,
                rowSelection: selectedMembers
            }}
        />
    )
}

export default UserGroupTable