import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useGetMeasureUnitsQuery } from '../../../app/redux-fetch/apiQuery'
import { DropdownItem } from '../../../components/FormDropDown/FormDropdown'
import { TableSubTitleTypography } from '../../../components/Typographies/TableTitleTypography'
import { ValueUnitControl } from '../ValueUnitControl/ValueUnitControl'
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext'
import { messages } from '../messages'

interface PhysicalPropertiesGridProps {
  disabled?: boolean
}

interface Items {
  densityUnitItems: DropdownItem[]
  lengthUnitItems: DropdownItem[]
  weightUnitItems: DropdownItem[]
  volumeUnitItems: DropdownItem[]
}

export function PhysicalPropertiesGroup({ disabled }: PhysicalPropertiesGridProps) {
  const { data: measureUnits = [] } = useGetMeasureUnitsQuery()
  const { formatMessage } = useIntl()

  const { values, setFieldValue } = useAssetManagementFormContext()
  const { physicalProperties } = values
  const isDisabled = disabled || isEmpty(measureUnits)

  const items = useMemo(() => {
    return measureUnits.reduce(
      (acc, item) => {
        const { measureTypeId, id, units } = item
        switch (measureTypeId) {
          case 1:
            acc.weightUnitItems.push({ id, name: units })
            break
          case 2:
            acc.volumeUnitItems.push({ id, name: units })
            break
          case 4:
            acc.lengthUnitItems.push({ id, name: units })
            break
          case 6:
            acc.densityUnitItems.push({ id, name: units })
            break
          default:
            break
        }
        return acc
      },
      {
        densityUnitItems: [],
        lengthUnitItems: [],
        weightUnitItems: [],
        volumeUnitItems: [],
      } as Items
    )
  }, [measureUnits])

  return (
    <Box
      sx={{
        ml: '32px',
      }}
    >
      <Box
        sx={{ mb: '16px', display: 'flex', height: '32px', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <TableSubTitleTypography>Physical Properties</TableSubTitleTypography>
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='lengthMeasureUnitId'
          valueId='internalLength'
          unitList={items.lengthUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfLength)}
          labelValue={formatMessage(messages.assetsInternalLength)}
          selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
          value={Number(physicalProperties?.internalLength)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='lengthMeasureUnitId'
          valueId='internalWidth'
          unitList={items.lengthUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfLength)}
          labelValue={formatMessage(messages.assetsInternalWidth)}
          selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
          value={Number(physicalProperties?.internalWidth)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='lengthMeasureUnitId'
          valueId='internalHeight'
          unitList={items.lengthUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfLength)}
          labelValue={formatMessage(messages.assetsInternalHeight)}
          selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
          value={Number(physicalProperties?.internalHeight)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='volumeMeasureUnitId'
          valueId='internalVolume'
          unitList={items.volumeUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfVolume)}
          labelValue={formatMessage(messages.assetsInternalVolume)}
          selectedUnitId={Number(physicalProperties?.volumeMeasureUnitId)}
          value={Number(physicalProperties?.internalVolume)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='lengthMeasureUnitId'
          valueId='radius'
          unitList={items.lengthUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfLength)}
          labelValue={formatMessage(messages.assetsRadius)}
          selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
          value={Number(physicalProperties?.radius)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='lengthMeasureUnitId'
          valueId='clearLoadingHeight'
          unitList={items.lengthUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfLength)}
          labelValue={formatMessage(messages.assetsClearLoadingHeight)}
          selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
          value={Number(physicalProperties?.clearLoadingHeight)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='weightMeasureUnitId'
          valueId='grossWeight'
          unitList={items.weightUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfWeight)}
          labelValue={formatMessage(messages.assetsGrossWeight)}
          selectedUnitId={Number(physicalProperties?.weightMeasureUnitId)}
          value={Number(physicalProperties?.grossWeight)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='weightMeasureUnitId'
          valueId='kerbWieght'
          unitList={items.weightUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfWeight)}
          labelValue={formatMessage(messages.assetsKerbWieght)}
          selectedUnitId={Number(physicalProperties?.weightMeasureUnitId)}
          value={Number(physicalProperties?.kerbWieght)}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <ValueUnitControl
          disabled={isDisabled}
          unitId='weightMeasureUnitId'
          valueId='permissibleLoad'
          unitList={items.weightUnitItems}
          handleChangeOfUnit={(name: string, newValue: string) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
            setFieldValue(`physicalProperties.${name}`, Number(newValue))
          }}
          labelUnit={formatMessage(messages.unitOfWeight)}
          labelValue={formatMessage(messages.assetsPermissibleLoad)}
          selectedUnitId={Number(physicalProperties?.weightMeasureUnitId)}
          value={Number(physicalProperties?.permissibleLoad)}
        />
      </Box>
    </Box>
  )
}
