import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiShipment'
import { IDropDownItem } from '../../../app/types'
import ModalButtons from '../../../components/Buttons/ModalButtons'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import SwitchWithLabels from '../../../components/Switches/SwitchWithLabels'
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../components/Typographies/styles'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import { messages } from '../messages'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

const JourneyTypeSelection = () => {
  const { formatMessage } = useIntl()

  const {
    isMultiModalJourney,
    setIsMultiModalJourney,
    withLoadJourney,
    setWithLoadJourney,
    originId,
    setOriginId,
    setDestinationId,
    destinationId,
  } = useJourneyStepperContext()

  const { setBottomComponent } = useSideModalContext()

  useEffect(() => {
    setBottomComponent && setBottomComponent(<ModalButtons saveBtn='Next' />)
  }, [])

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: locations = [] } = useGetLocationsQuery({
    tenantId: tenantId ?? -1,
    includeCustomerLocations: false,
  })

  return (
    <>
      <Box sx={{ p: '32px' }}>
        <Box>
          <SectionTitleTypography>{formatMessage(messages.journeyType)}</SectionTitleTypography>
          <Typography variant='body2' sx={{ pt: '16px' }}>
            {formatMessage(messages.selectTypeOfJourney)}
          </Typography>
          <SwitchWithLabels
            isSelected={isMultiModalJourney}
            setIsSelected={setIsMultiModalJourney}
            labelBefore={formatMessage(messages.standardJourney)}
            labelAfter={formatMessage(messages.multiModalJourney)}
          />
        </Box>
        <ConditionalRender condition={isMultiModalJourney}>
          <Box sx={{ pt: '16px' }}>
            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
              {formatMessage(messages.legOrigin)}
            </SectionLabelTypography>
            <FormDropdown
              id='legOrigin'
              onChange={(e: any, name: any, newValue: any) => {
                setOriginId(newValue)
              }}
              value={originId}
              items={locations as IDropDownItem[]}
            />
          </Box>
          <Box sx={{ pt: '16px' }}>
            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
              {formatMessage(messages.legDestination)}
            </SectionLabelTypography>
            <FormDropdown
              id='legDestination'
              onChange={(e: any, name: any, newValue: any) => {
                setDestinationId(newValue)
              }}
              value={destinationId}
              items={locations as IDropDownItem[]}
            />
          </Box>
        </ConditionalRender>
        <Box sx={{ pt: '16px' }}>
          <SectionTitleTypography>{formatMessage(messages.withLoad)}</SectionTitleTypography>
          <Typography variant='body2' sx={{ pt: '16px' }}>
            {formatMessage(messages.journeyConfiguration)}
          </Typography>
          <SwitchWithLabels
            isSelected={withLoadJourney}
            setIsSelected={setWithLoadJourney}
            labelBefore={formatMessage(messages.withoutLoad)}
            labelAfter={formatMessage(messages.withLoad)}
          />
        </Box>
      </Box>
    </>
  )
}

export default JourneyTypeSelection
