import { Typography, alpha } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2';
import { SectionTitleTypography } from '../../components/Typographies/styles';
import { ILoad } from '../Shipments/types';
import { TemperatureSeries } from './types';

type LoadTemperatureReadTableProps = {
    data: TemperatureSeries[]
    load?: ILoad
}


const renderBoldText = (text: string, color?: string) => (
    <Typography sx={{
        color: color ? color : 'black'
    }}>
        {text}
    </Typography >
);

const LoadTemperatureReadTable = (props: LoadTemperatureReadTableProps) => {

    const columns = useMemo<MRTColumnDef<TemperatureSeries>[]>(() => {
        return [
            {
                accessorFn: (row) => dayjs(row.externalInsertionDate).format('DD MMM YYYY @ hh:mm:ss A'),
                header: 'Date & Time',
            },
            {
                accessorFn: (row) => row.loadId,
                header: 'Load # / Route #',
            },
            {
                accessorFn: (row) => '-',
                header: 'Route Status',
            },
            {
                accessorFn: (row) => {
                    return renderBoldText(`${row.sensor1} ℃`, '#04C000')
                }
                ,
                header: 'Temp Reading',
            },
            {
                header: 'Image',
            },
            {
                accessorFn: (row) => '-',
                header: 'Email',
            },
            {
                accessorFn: (row) => '-',
                header: 'Added By',
            },
        ]
    }, [])

    return (<>

        <MRTDataGridv2
            state={{ isLoading: false }}
            leftHeadingComponent={<SectionTitleTypography>Latest Reading</SectionTitleTypography>}
            columns={columns}
            data={props.data}
            getRowId={(row) => row.id as any}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={true}
            enableSorting={false}
            enableBottomToolbar={true}
            enableTopToolbar={false}
            positionActionsColumn='last'
            muiTableBodyProps={{
                sx: (theme) => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: alpha(theme.palette.secondary.main, 0.08),
                    },
                }),
            }}
            muiTableHeadCellProps={{
                sx: {
                    color: '#636363',
                    fontSize: '14px',
                },
            }}
        />
    </>)
}

export default LoadTemperatureReadTable;
