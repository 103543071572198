import React, { useState } from 'react';
import { ITransportJourney } from '../../Transport/types';
import { JourneyStepperContext } from '../hooks/useJourneyStepperContext';

type JourneyStepperProps = {
    children: React.ReactNode
}

const JourneyStepper = ({ children }: JourneyStepperProps) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isMultiModalJourney, setIsMultiModalJourney] = useState(false)
    const [withLoadJourney, setWithLoadJourney] = useState(true)
    const [originId, setOriginId] = useState(0)
    const [destinationId, setDestinationId] = useState(0)

    const [selectedJourney, setSelectedJourney] = useState<ITransportJourney | undefined>(undefined)

    const [selectedLoads, setSelectedLoads] = useState<number[]>([])

    const [mode, setMode] = useState<'create' | 'edit' | 'view'>('create')

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }
    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    }
    const goToStep = (step: number) => {
        setCurrentStep(step);
    }

    const reset = () => {
        setCurrentStep(1)
        setIsMultiModalJourney(false)
        setWithLoadJourney(true)
        setOriginId(0)
        setDestinationId(0)
        setSelectedLoads([])
    }

    const stepperContext = {
        currentStep,
        isMultiModalJourney,
        withLoadJourney,
        originId,
        destinationId,
        selectedLoads,
        selectedJourney,
        mode,
        setMode,
        nextStep,
        prevStep,
        goToStep,
        setIsMultiModalJourney,
        setWithLoadJourney,
        setOriginId,
        setDestinationId,
        setSelectedLoads,
        setSelectedJourney,
        reset
    }

    return (
        <JourneyStepperContext.Provider value={stepperContext}>
            {children}
        </JourneyStepperContext.Provider>
    );
}

export default JourneyStepper