import { useSnackbar, VariantType } from 'notistack';

let useSnackbarRef : any

interface ErrorProps {
    errorObj: {
        data: {
            errors: Record<string, string[]>;
        };
    };
}

const displayApiErrors = (errorObj: any, useSnackbarRef: any) => {
        if (errorObj && errorObj.data && errorObj.data.errors) {
            const { errors } = errorObj.data;
            Object.keys(errors).forEach(key => {
                errors[key].forEach((message: any) => {
                    useSnackbarRef.enqueueSnackbar(message, { variant: 'error' });
                });
            });
        }
    
};


export const SnackbarUtilsConfigurator  = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export default {
  success(msg: string) {
    this.toast(msg, 'success')
  },
  warning(msg: string) {
    this.toast(msg, 'warning')
  },
  info(msg: string) {
    this.toast(msg, 'info')
  },
  apiError(err: any) {
    if(err.data?.errors) {
    displayApiErrors(err, useSnackbarRef)
    }else{
        useSnackbarRef.enqueueSnackbar('Internal error, please try again later', { variant: 'error',                 anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        }, })    
    }
  },
  error(msg: string) {
    this.toast(msg, 'error')
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant, anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    }, })
  }
}