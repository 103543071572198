import { useIntl } from 'react-intl';
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper';
import JourneyForm from '../JourneyForm/JourneyForm';
import useJourneyStepperContext from '../hooks/useJourneyStepperContext';
import { messages } from '../messages';

const JourneyModal = () => {

    const { formatMessage } = useIntl()
    const { currentStep, goToStep } = useJourneyStepperContext()

    return (
        <SideModalWrapper withBottomBox={true} open={currentStep === 3} onClose={() => {
            goToStep(0);
        }} onSave={() => {
            goToStep(0);
        }} headerLabel={formatMessage(messages.createJourney)} width='95%'>
            <JourneyForm />
        </SideModalWrapper>
    );
}

export default JourneyModal
