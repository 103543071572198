import { defineMessages } from 'react-intl'

const messages = defineMessages({
  cancelBtn: {
    id: 'submitButton.CancelBtn',
    description: 'Cancel button',
    defaultMessage: 'Cancel',
  },
  saveBtn: {
    id: 'submitButton.SaveBtn',
    description: 'Save button',
    defaultMessage: 'Save',
  },
  createBtn: {
    id: 'submitButton.CreateBtn',
    description: 'Create button',
    defaultMessage: 'Create',
  },
  updateBtn: {
    id: 'submitButton.UpdateBtn',
    description: 'Update button',
    defaultMessage: 'Update',
  },
  deleteBtn: {
    id: 'submitButton.DeleteBtn',
    description: 'Delete button',
    defaultMessage: 'Delete',
  },
  enabled: {
    id: 'submitButton.Enabled',
    description: 'Enabled',
    defaultMessage: 'Enabled',
  },
})
export default messages
