import { Box, Grid } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { useAppSelector } from '../../../../../app/hooks'
import { useNotificationStack } from '../../../../../app/hooks/useNotificationStack'
import {
  useAssetCategoriesQuery,
  useCreateAssetConfigurationMutation,
  useUpdateAssetConfigurationMutation,
} from '../../../../../app/redux-fetch/apiAssets'
import { IDropDownItem } from '../../../../../app/types'
import SubmitButtons from '../../../../../components/Buttons/SubmitButtons'
import { SectionTitleTypography } from '../../../../../components/Typographies/styles'
import { useSideModalContext } from '../../../../AssetsManagement/modals/SideModalContext'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import {
  AssetConfigCard,
  AssetConfigCardProps,
} from '../../../Components/AssetSettings/AssetConfigurations/Card/AssetConfigCard'
import { IAllocation } from '../../../Components/AssetSettings/types'
import { messages } from '../../messages'
import { AssetConfiguration } from '../../types'
import MainInfo from './MainInfo'
import { getAssetConfigurationValidationSchema } from './validationSchema'

type AssetConfigurationFormPros = {
  isDisabled: boolean
  assetConfiguration: AssetConfiguration
}

const defaultItem = {
  assetCategoryId: 0,
  assetClassId: 0,
  assetTypeId: 0,
  sequence: 0,
}

const AssetConfigurationForm = ({ isDisabled, assetConfiguration }: AssetConfigurationFormPros) => {
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { onClose } = useSideModalContext()

  const [createAssetConfiguration] = useCreateAssetConfigurationMutation()
  const [updateAssetConfiguration] = useUpdateAssetConfigurationMutation()

  const { data: assetCategories = [], isLoading: assetCategoriesLoading } = useAssetCategoriesQuery(
    { tenantId }
  )

  const validationSchema = getAssetConfigurationValidationSchema()
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const isNew = !assetConfiguration?.id

  return (
    <Formik
      initialValues={assetConfiguration}
      validationSchema={validationSchema}
      onSubmit={async (values: FormikValues) => {
        if (isNew) {
          await createAssetConfiguration({ tenantId, body: values as AssetConfiguration })
            .then(() => {
              enqueueSuccess(messages.assetConfigurationCreated)
            })
            .catch(() => {
              enqueueFailure(messages.assetConfigurationNotCreated)
            })
        } else {
          await updateAssetConfiguration({ tenantId, body: values as AssetConfiguration })
            .then(() => {
              enqueueSuccess(messages.assetConfigurationUpdated)
            })
            .catch(() => {
              enqueueFailure(messages.assetConfigurationNotUpdated)
            })
        }
        onClose()
      }}
    >
      {({ values, setFieldValue }) => {
        const onAddCard = () => {
          const currentItems = values.allocations ?? []
          setFieldValue('allocations', [
            ...currentItems,
            { ...defaultItem, sequence: currentItems.length, tenantId },
          ])
        }

        const onRemoveCard = (itemIndex: number) => {
          setFieldValue(
            'allocations',
            values.allocations?.filter((_item: any, index: any) => index !== itemIndex)
          )
        }

        const onDragEnd = (result: DropResult) => {
          if (result.destination && result.source && values.allocations) {
            let items = values.allocations.filter(
              (_: any, index: any) => index !== result.source.index
            )
            const item = values.allocations[result.source.index]

            if (result.destination.index === 0) {
              items.unshift(item)
            } else if (result.destination.index === values.allocations.length - 1) {
              items.push(values.allocations[result.source.index])
            } else {
              items = [
                ...items.slice(0, result.destination.index),
                item,
                ...items.slice(result.destination.index),
              ]
            }
            setFieldValue('allocations', items)
          }
        }

        const onConfigChange: AssetConfigCardProps['onChange'] = (value) => {
          const newItems = values.allocations?.map((item: any, index: any) => {
            if (value.itemIndex === index) {
              return { ...value.allocation }
            }
            return { ...item }
          })
          setFieldValue('allocations', newItems)
        }
        return (
          <Form>
            <Box sx={{ m: '32px' }}>
              <MainInfo isDisabled={isDisabled} />
            </Box>
            <Box sx={{ m: '32px' }}>
              <Box sx={{ pb: '16px' }}>
                <SectionTitleTypography>{`List of Assets [${
                  values.allocations?.filter(
                    (a: IAllocation) => a.assetClassId && a.assetClassId > 0
                  ).length ?? 0
                }]`}</SectionTitleTypography>
              </Box>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable' direction='horizontal'>
                  {(provided) => (
                    <Grid
                      spacing={1}
                      container
                      sx={{
                        background: 'rgb(243, 246, 249)',
                        padding: '20px',
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {values.allocations?.map((allocation: IAllocation, index: number) => (
                        <Draggable key={index} draggableId={String(index)} index={index}>
                          {(providedChild) => (
                            <Grid
                              item
                              ref={providedChild.innerRef}
                              {...providedChild.draggableProps}
                              {...providedChild.dragHandleProps}
                            >
                              <AssetConfigCard
                                tenantId={tenantId}
                                onChange={onConfigChange}
                                index={index}
                                allocation={allocation}
                                assetCategories={
                                  assetCategories.filter(
                                    (ac) => ac.transportModeId === values.transportModeId
                                  ) as IDropDownItem[]
                                }
                                onRemove={
                                  values.allocations?.length === 1 || isDisabled
                                    ? undefined
                                    : onRemoveCard
                                }
                                onAdd={isDisabled ? undefined : onAddCard}
                                disabled={isDisabled}
                              />
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                padding: '16px',
                backgroundColor: '#fff',
                borderTop: '1px solid #e0e0e0',
              }}
            >
              {!isDisabled && <SubmitButtons create={isNew} isEnabled={true} />}
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AssetConfigurationForm
