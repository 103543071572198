/* eslint-disable react/prop-types */
import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import { useAssetCategoriesQuery } from '../../../../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiQuery'
import AddButton from '../../../../../components/Buttons/AddButton'
import ConditionalRender from '../../../../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../../../../components/MRTDataGrid/MRTDataGridv2'
import YesNoTypography from '../../../../../components/YesNoTypograpphy/YesNoTypography'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import { messages } from '../../messages'
import { AssetCategory } from '../../types'
import AssetCategoryForm from './AssetCategoryForm'

const AssetCategoryTable = () => {
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const [selectedASsetCategoryId, setSelectedASsetCategoryId] = useState(-1)

  const { data: transportModes = [], isLoading: transportModesLoading } = useGetTransportModeQuery()

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: assetCategories = [], isLoading: assetCategoriesLoading } = useAssetCategoriesQuery(
    { tenantId }
  )

  const { formatMessage } = useIntl()

  const columns = useMemo<MRTColumnDef<AssetCategory>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: formatMessage(messages.categoryName),
        Cell: ({ cell }) => {
          return <Typography sx={{ fontWeight: 700 }}>{cell.getValue<string>()}</Typography>
        },
      },
      {
        id: 'transportModeId',
        accessorFn: (row) => {
          return transportModes.find((tm) => tm.id === row.transportModeId)?.name
        },
        Cell: ({ cell }) => {
          return <>{cell.getValue()}</>
        },
        header: formatMessage(messages.transportMode),
      },
      {
        accessorFn: (row) => {
          return row.assetTypes?.map((at) => at?.name).join(', ') ?? ''
        },
        header: formatMessage(messages.types),
        maxSize: 180,
        Cell: ({ cell }) => (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
            }}
          >
            {cell.getValue<string>()}
          </Box>
        ),
      },
      {
        accessorFn: (row) => {
          return row.assetClasses?.map((ac) => ac?.name).join(', ') ?? ''
        },
        header: formatMessage(messages.classes),
        Cell: ({ cell }) => (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
            }}
          >
            {cell.getValue<string>()}
          </Box>
        ),

        maxSize: 360,
      },
      {
        accessorFn: (row) => {
          return row.enabled ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.enabled),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
    ]
  }, [assetCategories, transportModes])

  return (
    <>
      <ConditionalRender condition={createOpen}>
        <AssetCategoryForm
          isOpen={createOpen}
          handleClose={() => {
            setCreateOpen((pv) => {
              return !pv
            })
          }}
          assetCategory={{
            enabled: true,
            tenantId,
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={editOpen}>
        <AssetCategoryForm
          isOpen={editOpen}
          handleClose={() => {
            setEditOpen((pv) => {
              return !pv
            })
          }}
          assetCategory={assetCategories.find((ac) => ac.id === selectedASsetCategoryId)}
        />
      </ConditionalRender>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          sorting: [
            {
              id: 'transportModeId',
              desc: false,
            },
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
        state={{ isLoading: assetCategoriesLoading || transportModesLoading }}
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newAssetCategory)}
            onClick={() => setCreateOpen(true)}
          />
        }
        columns={columns}
        data={assetCategories}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        globalFilterFn='contains'
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedASsetCategoryId(row.id)
                    setEditOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editActionLbl)}
                </Link>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedASsetCategoryId(row.id)
                    setViewOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                ></Link>
              </Box>
            </>
          )
        }}
      />
    </>
  )
}

export default AssetCategoryTable
