import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid'; // To generate a new GUID
import { ChartDataItem } from './types';

interface SolidChartComponentProps {
    data: ChartDataItem;
    title: string;
}

function solidChart(data: ChartDataItem) {
    const gauge = anychart.gauges.circular();

    gauge.background().fill(['#AFE2FF', '#FFFFFF'], 90);
    gauge.data([data.value, data.total]);
    gauge.padding(5);
    gauge.margin(0);
    const axis = gauge.axis().radius(100).width(1).fill(null);
    axis
        .scale()
        .minimum(0)
        .maximum(data.total)
        .ticks({ interval: 1 })
        .minorTicks({ interval: 1 });
    axis.labels().enabled(false);
    axis.ticks().enabled(false);
    axis.minorTicks().enabled(false);

    const stroke = '1 #e5e4e4';
    gauge.label().useHtml(true).text(`<span style="color: #438BE3;">${data.value}</span>`); // Set label color to red (#FF0000)
    gauge.label().hAlign('center').anchor('center').padding(5, 10).zIndex(1);
    gauge
        .bar(0)
        .dataIndex(0)
        .radius(80)
        .width(40)
        .fill('#64b5f6')
        .stroke(null)
        .zIndex(5);
    gauge
        .bar(1)
        .dataIndex(1)
        .radius(80)
        .width(40)
        .fill('#F5F4F4')
        .stroke(stroke)
        .zIndex(4);
    gauge
        .label()
        .width('50%')
        .height('25%')
        .adjustFontSize(true)
        .hAlign('center')
        .anchor('center');
    gauge
        .label()
        .hAlign('center')
        .anchor('center')
        .padding(5, 10)
        .zIndex(1);
    gauge.background().enabled(true);
    gauge.fill(null);
    gauge.stroke(null);
    return gauge;
}

const SolidChartComponent = ({ data, title }: SolidChartComponentProps) => {
    const chartContainerId = useRef(`chart-container-${uuidv4()}`);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (window.anychart && data) {
            if (!chartRef.current) {
                chartRef.current = solidChart(data);
                chartRef.current.container(chartContainerId.current);
            }

            chartRef.current.title(title);

            chartRef.current.draw();
        }
    }, [data, title]);

    return (
        <Box id={chartContainerId.current}
            style={{
                width: '200px',
                height: '180px',
                margin: '20px',
                padding: '20px',
                borderRadius: '8px'
            }}></Box>
    )
}

export default SolidChartComponent;
