import { Box, Button, CircularProgress, Typography, alpha } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

type DropzoneProps = {
  removeHandle: (fileId: string) => void
  viewHandle: (fileId: string) => void
  uploadHandle: (file: File) => Promise<string>
  isDisabled?: boolean
  isRequired?: boolean
  fileId?: string
}
const ACCEPTED_FILE_TYPES = 'image/jpeg, image/png, image/gif, application/pdf'

function extractFileName(fullString: string): string {
  const parts = fullString.split('.')
  parts.shift()
  parts.shift()
  return parts.join('.')
}

const Dropzone = ({
  fileId,
  isDisabled,
  isRequired,
  viewHandle,
  removeHandle,
  uploadHandle,
}: DropzoneProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const [currFileId, setCurrFileId] = useState<string | undefined>(fileId)

  useEffect(() => {
    setCurrFileId(fileId)
  }, [fileId])
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined)

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (isDisabled) return
      const file = acceptedFiles[0]
      setIsUploading(true)
      const uploadedFileId = await uploadHandle(file)
      setCurrFileId(uploadedFileId)
      setIsUploading(false)
      setUploadedFile(file)
    },
    [uploadHandle, isDisabled]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: isDisabled,
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'application/pdf': ['.pdf'],
    },
    noKeyboard: isDisabled,
    noDrag: isDisabled,
  })

  return (
    <Box
      {...getRootProps()}
      sx={(theme) => ({
        border: !isDragActive ? `2px dashed ${theme.palette.secondary.main}` : '2px dashed #cccccc',
        backgroundColor: !isDragActive ? alpha(theme.palette.secondary.main, 0.1) : 'transparent',
        borderRadius: 2,
        padding: 4,
        textAlign: 'center',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...(isRequired && { border: `2px dashed ${theme.palette.error.main}` }),
      })}
    >
      {isUploading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : currFileId ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>{`Uploaded file: ${extractFileName(currFileId)}`}</Typography>
          <Box sx={{ display: 'flex' }}>
            {!isDisabled && (
              <>
                <Button
                  color='secondary'
                  onClick={(e) => {
                    e.stopPropagation()
                    removeHandle(fileId ?? '')
                    setCurrFileId(undefined)
                    setUploadedFile(undefined)
                  }}
                  sx={{ mt: 1 }}
                  disabled={isDisabled}
                >
                  Remove
                </Button>
              </>
            )}
            <Button
              color='secondary'
              onClick={(e) => {
                e.stopPropagation()
                viewHandle(fileId ?? '')
              }}
              sx={{ mt: 1 }}
            >
              View
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {isDragActive ? (
            <Typography>Drop the file here ...</Typography>
          ) : (
            <Typography>Drag & drop a file here, or</Typography>
          )}
          <Button
            component='span'
            variant='contained'
            color='secondary'
            sx={{ mt: 2 }}
            disabled={isDisabled}
          >
            Browse
            <input
              {...getInputProps()}
              style={{ display: 'none' }} // Hide the input element
            />
          </Button>
        </>
      )}
    </Box>
  )
}

export default Dropzone
