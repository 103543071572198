import { AssetConfiguration } from '../../types'
import AssetConfigurationForm from './AssetConfigurationForm'

type EditAssetConfigurationModalProps = {
  assetConfiguration: AssetConfiguration
  isDisabled?: boolean
}

const EditAssetConfigurationModal = ({
  assetConfiguration,
  isDisabled = false,
}: EditAssetConfigurationModalProps) => {
  return <AssetConfigurationForm isDisabled={isDisabled} assetConfiguration={assetConfiguration} />
}

export default EditAssetConfigurationModal
