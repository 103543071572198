import { Box, ListItem, ListItemText, Typography } from '@mui/material'
import { StyledSubCategoryListItemButton } from './styles'

type SubCategoryItemProps = {
  label: string
  action: () => void
  isSelected: boolean
}

export const SubCategoryItem = ({
  label,
  action,
  isSelected,
}: SubCategoryItemProps) => {
  return (
    <ListItem sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
      <StyledSubCategoryListItemButton onClick={action} isSelected={isSelected}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <ListItemText
            primary={
              <Typography gutterBottom align='center' margin={0} fontWeight={700}>
                {label}
              </Typography>
            }
          />
        </Box>
      </StyledSubCategoryListItemButton>
    </ListItem>
  )
}
