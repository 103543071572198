import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import { useCreateAssetCategoryMutation, useUpdateAssetCategoryMutation } from '../../../../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiQuery'
import FormDropdown from '../../../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import { messages } from '../../messages'
import { AssetCategory } from '../../types'
import { getAssetCategoryValidationSchema } from './validationSchema'


type AssetCategoryFormProps = {
    isOpen: boolean
    assetCategory?: AssetCategory
    handleClose: () => void
}

const AssetCategoryForm = ({
    assetCategory,
    handleClose,
    isOpen
}: AssetCategoryFormProps) => {
    const { formatMessage } = useIntl()

    const { data: transportModes = [] } = useGetTransportModeQuery()

    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const isNew = !assetCategory?.id
    const validationSchema = getAssetCategoryValidationSchema(formatMessage)

    const [createAssetCategory] = useCreateAssetCategoryMutation()
    const [updateAssetCategory] = useUpdateAssetCategoryMutation()

    return (
        <>
            <Formik
                initialValues={{ ...assetCategory } as AssetCategory}
                validationSchema={validationSchema}
                onSubmit={async (values: FormikValues) => {
                    if (isNew) {
                        await createAssetCategory({ tenantId, body: values as AssetCategory })
                    } else {
                        await updateAssetCategory({ tenantId, body: values as AssetCategory })
                    }
                    handleClose()
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    submitForm
                }) => {
                    return (
                        <Form>
                            <Dialog open={isOpen} onClose={handleClose}>
                                <DialogTitle>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '20px',
                                        }}
                                    >
                                        {formatMessage(messages.addEditAssetCategory)}
                                    </Typography>
                                </DialogTitle>
                                <DialogContent sx={{ minWidth: '450px' }}>
                                    <Box sx={{ mb: '16px' }}>
                                        <Box sx={{ mb: '16px' }}>
                                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                                {formatMessage(messages.categoryName)}
                                                <RequiredAsterisk />
                                            </SectionLabelTypography>
                                            <TextField
                                                fullWidth
                                                id={'name'}
                                                name={'name'}
                                                value={values?.name ?? ''}
                                                onChange={handleChange}
                                                helperText={errors?.name as React.ReactNode}
                                                error={Boolean(errors?.name)}
                                            />
                                        </Box>
                                        <Box sx={{ mb: '16px' }}>
                                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                                {formatMessage(messages.transportMode)}
                                                <RequiredAsterisk />
                                            </SectionLabelTypography>
                                            <FormDropdown
                                                id={'transportModeId'}
                                                items={transportModes ?? []}
                                                onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
                                                value={values.transportModeId}
                                                error={Boolean(errors?.transportModeId)}
                                                errorText={errors?.transportModeId}
                                            />
                                        </Box>
                                        <Box>
                                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                                {formatMessage(messages.enabledDisabled)}
                                            </SectionLabelTypography>
                                            <FormControlLabel
                                                sx={{ justifyContent: 'end', minHeight: '56px' }}
                                                value={values.enabled}
                                                labelPlacement='end'
                                                control={
                                                    <Switch
                                                        color='secondary'
                                                        checked={values.enabled}
                                                        value={values.enabled}
                                                        onChange={({ target }) => {
                                                            setFieldValue('enabled', target.checked)
                                                        }}
                                                    />
                                                }
                                                label={'Enabled'}
                                            />
                                        </Box>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button color='secondary' variant='outlined' onClick={() => handleClose()}>
                                        {formatMessage(messages.cancelBtn)}
                                    </Button>
                                    <Button color='secondary' variant='contained' onClick={() => submitForm()}>
                                        {isNew ? 'Create' : 'Update'}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik >
        </>
    )
}

export default AssetCategoryForm
