import { useAppSelector } from '../../../app/hooks'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import RoleForm from './RoleForm'
import { IRole } from './types'

const CreateRoleModal = () => {
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const tenantId = loggedInUser.tenantId
    const customerId = loggedInUser.customerId
    return (
        <RoleForm
            role={
                {
                    customerId: customerId,
                    tenantId: tenantId,
                    securityPrincipals: [],
                    securityPrincipalAllocations: [],
                } as IRole
            }
            isDisabled={false}
        />
    )
}

export default CreateRoleModal;
