import { Box, Grid, Tab, Tabs, ThemeProvider, createTheme, useTheme } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
} from '../../../../app/redux-fetch/apiQuery'
import SubmitButtons from '../../../../components/Buttons/SubmitButtons'
import TabPanel from '../../../../components/TabPanel/TabPanel'
import { useSideModalContext } from '../../../AssetsManagement/modals/SideModalContext'
import { useNotificationStack } from '../../../Shipments/hooks/useNotificationStack'
import MainInfo from './MainInfo'
import UserGroupMembers from './UserGroupMembers'
import UserGroupRoles from './UserGroupRoles'
import { getUserGroupValidationSchema } from './UserGroupValidationSchema'
import messages from './messages'
import { IUserGroup } from './types'

type UserGroupFormProps = {
  userGroup: IUserGroup
  isDisabled: boolean
}

const UserGroupForm = ({ userGroup, isDisabled }: UserGroupFormProps) => {
  const isNew = !userGroup.id

  const { formatMessage } = useIntl()
  const { enqueue } = useNotificationStack()

  const [createUserGroup] = useCreateUserGroupMutation()
  const [updateUserGroup] = useUpdateUserGroupMutation()

  const validationSchema = getUserGroupValidationSchema(formatMessage)
  const { onClose } = useSideModalContext()

  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  let theme = useTheme()

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })

  return (
    <Formik
      initialValues={userGroup}
      onSubmit={async (values: FormikValues) => {
        const userAllocations = values.users?.map((user: any) => {
          return {
            userId: user.id,
            userGroupId: values.id,
          }
        })
        const userGroupRoles = values.roles?.map((role: any) => {
          return {
            roleId: role.id,
            userGroupId: values.id,
          }
        })
        if (!values.id) {
          await createUserGroup({
            tenantId: values.tenantId ?? -1,
            customerId: values.customerId,
            body: { ...values, userAllocations, roleAllocations: userGroupRoles } as IUserGroup,
          })
          enqueue(formatMessage(messages.userGroupCreated))
        } else {
          await updateUserGroup({
            tenantId: values.tenantId ?? -1,
            customerId: values.customerId,
            body: { ...values, userAllocations, roleAllocations: userGroupRoles } as IUserGroup,
          })
          enqueue(formatMessage(messages.userGroupUpdated))
        }
        onClose()
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Box sx={{ width: '100%', p: '40px' }}>
          <Box sx={{ width: '100%', p: '40px' }}>
            <ThemeProvider theme={theme}>
              <Tabs
                sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                value={activeTab}
                onChange={handleChange}
                centered
                variant='standard'
                scrollButtons={false}
                aria-label='Tenant settings scrollable tabs'
              >
                <Tab label={formatMessage(messages.tabGeneral)} />
                <Tab label={formatMessage(messages.tabUsers)} />
                <Tab label={formatMessage(messages.tabRoles)} />
              </Tabs>
            </ThemeProvider>
            <Box />
            <TabPanel value={activeTab} index={0}>
              <Grid item xs={12}>
                <MainInfo isDisabled={isDisabled} />
              </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Grid item xs={12}>
                <UserGroupMembers isDisabled={isDisabled} />
              </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Grid item xs={12}>
                <UserGroupRoles isDisabled={isDisabled} />
              </Grid>
            </TabPanel>
          </Box>

          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              padding: '16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            {!isDisabled && <SubmitButtons create={isNew} />}
          </Box>
        </Box>
      </Form>
    </Formik>
  )
}

export default UserGroupForm
