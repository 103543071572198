import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { useGetAssetAvailabilityQuery } from '../../app/redux-fetch/apiAssets';
import SpinnerBoxBlock from '../../components/Spinner/SpinnerBoxBlock';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';
import SolidChartComponent from './Components/SolidChartComponent';
import { DashboardTile } from './DashboardTile';

const AssetAvailabilityDashboard = () => {
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser);
    const { data, isSuccess } = useGetAssetAvailabilityQuery({ tenantId: loggedInUser.tenantId });

    if (!isSuccess) {
        return (
            <DashboardTile sx={{ width: '760px' }}>
                <SpinnerBoxBlock />
            </DashboardTile>
        );
    }

    return (
        <DashboardTile sx={{ width: '760px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ paddingLeft: '20px', paddingTop: '20px' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '700', color: '#0A2761' }}>
                            Asset Dashboard
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        height: '400px',
                        overflowY: 'auto',
                        borderRadius: '8px'
                    }}>
                        {data.assetCategoryAvailability.map((category) => (
                            <Box key={category.assetCategoryId} sx={{ marginLeft: '20px', width: '100%' }}>
                                <Typography sx={(theme) => ({
                                    width: '100%',
                                    textAlign: 'left',
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    color: theme.palette.secondary.main,
                                })}>
                                    {`Asset Category: ${category.assetCategoryName}`}
                                </Typography>
                                <Grid container spacing={2}>
                                    {category.assetTypeAvailability.map((assetType) => (
                                        <Grid item xs={6} key={assetType.assetTypeId}>
                                            <Typography sx={(theme) => ({
                                                width: '100%',
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                color: theme.palette.secondary.main,
                                            })}>
                                                {assetType.assetTypeName}
                                            </Typography>
                                            <Grid container spacing={2}>
                                                {assetType.assetClassAvailability.map((assetClass) => (
                                                    <Grid item xs={6} key={assetClass.assetClassId}>
                                                        <Box sx={{ marginBottom: 2 }}>
                                                            <SolidChartComponent
                                                                data={{ value: assetClass.total, total: data.total }}
                                                                title={assetClass.assetClassName}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </DashboardTile>
    );
};

export default AssetAvailabilityDashboard;
