import { Box, Link, Typography } from '@mui/material';
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/hooks';
import { useGetUserTypesQuery, useGetUsersQuery } from '../../../../app/redux-fetch/apiQuery';
import AddButton from '../../../../components/Buttons/AddButton';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import SideModalWrapper from '../../../AssetsManagement/modals/SideModalWrapper';
import { fetchLoggedInUserSelector } from '../../../selectors';
import { ILoggedInUser } from '../../../types';
import { IUser } from '../types';
import CreateUserModal from './CreateUserModal';
import UserModal from './UserModal';
import messages from './messages';
import ConditionalRender from '../../../../components/ConditionalRender/ConditionalRender';

const UserTable = () => {
    const { formatMessage } = useIntl()

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const tenantId = loggedInUser.tenantId
    const customerId = loggedInUser.customerId

    const { data: fetchedUsers, isSuccess: userLoaded } = useGetUsersQuery({
        tenantId,
        customerId
    }, { refetchOnMountOrArgChange: true }
    );

    const { isSuccess: userTypeLoaded } = useGetUserTypesQuery({
        tenantId
    })


    const users = useMemo<IUser[]>(() => {
        return fetchedUsers?.map((user: IUser) => {
            return {
                ...user,
                formattedLastUpdated: user.lastUpdated ? new Date(user.lastUpdated).toLocaleDateString() : '-',
            }
        }) ?? [];
    }, [fetchedUsers]);

    const columns = useMemo<MRTColumnDef<IUser>[]>(() => {
        return [
            {
                accessorKey: 'firstName',
                header: formatMessage(messages.firstNameLabel),
            },
            {
                accessorKey: 'lastName',
                header: formatMessage(messages.lastNameLabel),
            },
            {
                accessorKey: 'email',
                header: formatMessage(messages.emailLabel),
            },
            {
                accessorKey: 'userTypeId',
                header: formatMessage(messages.userType),
                Cell: ({ cell }: any) => {
                    return cell.getValue() === 0 ? formatMessage(messages.systemUserLabel) : formatMessage(messages.workforceUserLabel)
                }
            },
            {
                accessorKey: 'phone',
                header: formatMessage(messages.phoneLabel),
            },
            {
                accessorKey: 'disabled',
                header: formatMessage(messages.activeLbl),
                Cell: ({ row }: any) => {

                    return !row.original.disabled ? (
                        <Typography sx={{ fontWeight: 700, color: '#0A9D33' }}>{formatMessage(messages.yesLabel)}</Typography>
                    ) : (
                        <Typography sx={{ fontWeight: 700, color: '#D40808' }}>{formatMessage(messages.noLabel)}</Typography>
                    );
                },
            },
        ];
    }, []);



    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

    return (<>
        <SideModalWrapper
            open={createOpen}
            key={'create'}
            headerLabel={formatMessage(messages.createUserLabel)}
            onClose={() => {
                setCreateOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <CreateUserModal />
        </SideModalWrapper>
        <SideModalWrapper
            open={editOpen}
            key={'edit'}
            headerLabel={formatMessage(messages.editUserLabel)}
            onClose={() => {
                setEditOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <UserModal userId={selectedUserId} />
        </SideModalWrapper>
        <SideModalWrapper
            open={viewOpen}
            key={'view'}
            headerLabel={formatMessage(messages.viewUserLabel)}
            onClose={() => {
                setViewOpen((pv) => {
                    return !pv
                })
            }}
            withBottomBox={false}
        >
            <UserModal userId={selectedUserId} isDisabled={true} />
        </SideModalWrapper>
        <MRTDataGridv2
            initialState={{
                sorting: [
                    {
                        id: 'firstName',
                        desc: false,
                    },
                    {
                        id: 'lastName',
                        desc: false
                    }]
            }}
            state={{
                isLoading: !userLoaded || !userTypeLoaded,
            }}
            rightHeadingComponent={
                <AddButton
                    text={formatMessage(messages.newUserLabel)}
                    onClick={() => setCreateOpen(true)}
                />
            }
            columns={columns}
            data={users || []}
            getRowId={(row) => row.id as any}
            enableEditing
            enableRowActions
            positionActionsColumn='last'
            renderRowActions={({ row, table }: any) => {
                return (
                    <>
                        <ConditionalRender condition={row.original.userTypeId === 0}>
                            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedUserId(row.id)
                                        setEditOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.editBtn)}
                                </Link>
                                <Typography color='secondary'>/</Typography>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedUserId(row.id)
                                        setViewOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.viewUserLabel)}
                                </Link>
                            </Box>
                        </ConditionalRender>
                    </>
                )
            }}
        />
    </>
    );
};

export default UserTable;