import { useAppSelector } from '../../../../app/hooks'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { IUser } from '../types'
import UserForm from './UserForm'

const CreateUserModal = () => {

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const tenantId = loggedInUser.tenantId
    const customerId = loggedInUser.customerId
    return (
        <UserForm
            user={
                {
                    customerId: customerId,
                    tenantId: tenantId,
                    disabled: false,
                    userTypeId: 0,
                    userGroups: [],
                } as IUser
            }
            isDisabled={false}
        />
    )
}

export default CreateUserModal
