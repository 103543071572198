import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { useGetWorkforcesQuery } from '../../app/redux-fetch/apiQuery'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography'
import SideModalWrapper from '../AssetsManagement/modals/SideModalWrapper'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import CreateWorkforce from './CreateWorkforce'
import EditWorkforce from './EditWorkforce'
import { IWorkforce } from './types'

const WorkforceTable = () => {

    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const tenantId = loggedInUser.tenantId ?? -1

    const { data: fetchedWorkforces, isSuccess: workforceLoaded } = useGetWorkforcesQuery({ tenantId })

    const workforce = useMemo<IWorkforce[]>(() => {
        return fetchedWorkforces ?? []
    }, [fetchedWorkforces])

    const columns = useMemo<MRTColumnDef<IWorkforce>[]>(() => {
        return [
            {
                accessorKey: 'firstName',
                Cell: ({ cell }: any) => {
                    return (<Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>)
                },
                header: 'First Name',
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
            },
            {
                accessorFn: (row) => {
                    return row.userType?.name ?? ''
                },
                header: 'Workforce Type',
            },
            {
                accessorKey: 'phone',
                header: 'Phone',
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                accessorFn: (row) => {
                    return row.driverDetail?.licenceNumber ?? ''
                },
                header: 'License Number',
            },
            {
                accessorFn: (row) => {
                    return row.driverDetail?.licenceExpiry ?? ''
                },
                header: 'License Expire',
            },
            {
                accessorFn: (row) => {
                    return !row.disabled ? 'true' : 'false'
                },
                filterVariant: 'checkbox',
                header: 'Enabled',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }: any) => {
                    return <YesNoTypography isYes={cell.getValue() === 'true'} />
                }
            },]
    }, [
    ])

    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [viewOpen, setViewOpen] = useState(false)
    const [selectedWorkforceId, setSelectedWorkforceId] = useState(-1)

    const isLoading = !workforceLoaded

    return (
        <>
            {createOpen && (<SideModalWrapper
                open={createOpen}
                key={'create'}
                headerLabel={'Create Workforce'}
                onClose={() => {
                    setCreateOpen((pv) => {
                        return !pv
                    })
                }}
                withBottomBox={false}
            >
                <CreateWorkforce />
            </SideModalWrapper>)
            }
            {editOpen && (<SideModalWrapper
                open={editOpen}
                key={'edit'}
                headerLabel={'Edit Workforce'}
                onClose={() => {
                    setEditOpen((pv) => {
                        return !pv
                    })
                }}
                withBottomBox={false}
            >
                <EditWorkforce workforceId={selectedWorkforceId} isDisabled={false} />
            </SideModalWrapper>)}
            {viewOpen && (
                <SideModalWrapper
                    open={viewOpen}
                    key={'view'}
                    headerLabel={'View Workforce'}
                    onClose={() => {
                        setViewOpen((pv) => {
                            return !pv
                        })
                    }}
                    withBottomBox={false}
                >
                    <EditWorkforce
                        workforceId={selectedWorkforceId}
                        isDisabled={true}
                    />
                </SideModalWrapper>)}
            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    sorting: [
                        {
                            id: 'firstName',
                            desc: false,
                        },
                        {
                            id: 'lastName',
                            desc: false
                        }]
                }}
                state={{
                    isLoading: isLoading
                }}
                leftHeadingComponent={
                    <TableTitleTypography >Workforce</TableTitleTypography>
                }
                rightHeadingComponent={
                    <AddButton
                        text={'New Workforce'}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                columns={columns}
                data={workforce || []}
                getRowId={(row) => row.id as any}
                enableEditing
                enableRowActions
                positionActionsColumn='last'

                renderRowActions={({ row, table }: any) => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedWorkforceId(row.id)
                                        setEditOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    Edit
                                </Link>
                                <Typography color='secondary'>/</Typography>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedWorkforceId(row.id)
                                        setViewOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    View
                                </Link>
                            </Box>
                        </>
                    )
                }}
            />
        </>
    )
}

export default WorkforceTable
