import { Box, Button, Grid, Tab, Tabs, ThemeProvider, createTheme, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import logger from '../../../../app/middleware/log'
import { useGetNotesByIdQuery, useShipmentByIdQuery } from '../../../../app/redux-fetch/apiQuery'
import rootEnum from '../../../../components/Routes/rootEnum'
import SpinnerBlock from '../../../../components/Spinner/SpinnerBlock'
import TabPanel from '../../../../components/TabPanel/TabPanel'
import TemperatureChart from '../../../TemperatureChart/TemperatureChart'
import {
  fetchAllAssetCategoriesByTransportModeThunk,
  fetchAssetTypeThunk,
} from '../../../TenantSettings/slice'
import { fetchLoggedInUserSelector, isUserCanAcceptShipmentSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { NotiStackOptions } from '../../../utils'
import {
  acceptShipment,
  recallShipment,
  rejectShipment,
  resetReviewShipment,
  reviewShipment,
  submitShipment,
} from '../../api'
import {
  fetchAllCountriesThunk,
  fetchAllCurrenciesByTenantIdThunk,
  fetchAllPalletTypesThunk,
  fetchAllTemperatureRangeThunk,
  fetchAllUnitsThunk,
  fetchLocationsByTenantOrCustomerThunk,
  fetchShipmentByIdThunk,
  fetchShipmentsThunk,
} from '../../slice'
import { IShipment } from '../../types'
import { getTimeDiff } from '../../utils'
import { messages } from '../AllShipments/messages'
import ShipmentSummary from '../CreateShipment/ShipmentSummary'
import { messages as btnMessages } from '../CreateShipment/messages'
import ShipmentNoteTab from '../Notes/ShipmentNote'
import TerminalInfo from '../TerminalInfo/TerminalInfo'
import Timeline from '../Timeline/Timeline'
import ViewShipmentTabHeader from './ViewShipmentTabHeader'
import { getStatus } from './utils'
import ConditionalRender from '../../../../components/ConditionalRender/ConditionalRender'

const ViewShipmentTabs = (): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()

  const [activeTab, setActiveTab] = useState(0)

  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
    refetchNotes()
  }
  let theme = useTheme()

  const shipmentId = Number(id)

  const { refetch: refetchNotes } = useGetNotesByIdQuery(shipmentId)

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  const dispatch = useAppDispatch()
  const isTenantUser = !!tenantId && !customerId
  const isCustomerUser = !!tenantId && !!customerId
  const isUserCanAcceptShipment = useAppSelector(isUserCanAcceptShipmentSelector)
  const hasAcceptSecurityPrincipal = isUserCanAcceptShipment // Shipment.Accept
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [underReviewUserId, setUnderReviewUserId] = useState<number | undefined>()
  const {
    data: shipment,
    isFetching: shipmentSelectorFetching,
    isSuccess: shipmentLoaded,
    refetch,
  } = useShipmentByIdQuery({
    shipmentId: Number(id),
    tenantId,
    customerId,
  })

  const isAccepted = !!shipment?.acceptedDate // AC1
  const isSubmitted = !!shipment?.submittedDate // AC2

  const fetchAPIs = useCallback(async () => {
    dispatch(fetchAssetTypeThunk(tenantId))
    if (customerId) {
      dispatch(fetchShipmentsThunk(tenantId, customerId))
    }
    dispatch(fetchAllCurrenciesByTenantIdThunk(tenantId))
    dispatch(fetchAllAssetCategoriesByTransportModeThunk(tenantId, 2))
    dispatch(fetchLocationsByTenantOrCustomerThunk(tenantId, customerId))
    dispatch(fetchShipmentByIdThunk(Number(id), tenantId, customerId))
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchAllUnitsThunk())
    dispatch(fetchAllTemperatureRangeThunk())
    dispatch(fetchAllPalletTypesThunk())
    refetch()
  }, [dispatch, loggedInUser])

  useEffect(() => {
    fetchAPIs()
  }, [fetchAPIs])

  const initialValues = useCallback(() => {
    const initialValues = {
      tenantId: loggedInUser?.tenantId,
      customerId: shipment?.customerId || loggedInUser?.customerId || 1,
      currencyId: shipment?.currencyId || 1,
      shipperId: shipment?.shipperId || 1,
      trackingNumber: shipment?.trackingNumber || '',
      customerReferenceNumber: shipment?.customerReferenceNumber || '',
      description: shipment?.description || '',
      value: shipment?.value || 0,
      loads: shipment?.loads || [],
      assetRequests: shipment?.assetRequests || [],
      containerDetails: shipment?.containerDetails || [],
      acceptTermsAndCondition: false,
      acceptWarrnings: false,
      underReviewUntil: shipment?.underReviewUntil,
      usesContainer: Boolean(shipment?.containerDetails.length),
      isAccepted: !!shipment?.acceptedDate,
    }

    return initialValues
  }, [shipment])

  const formik = useFormik<IShipment>({
    initialValues: initialValues(),
    enableReinitialize: true,
    onSubmit: async () => {
      navigate(rootEnum.SHIPMENTS)
    },
  })
  useEffect(() => {
    if (shipment && shipment.underReviewByUserId && isTenantUser) {
      setUnderReviewUserId(shipment.underReviewByUserId)
    } else {
      setUnderReviewUserId(undefined)
    }
  }, [shipment])

  useEffect(() => {
    if (shipment?.underReviewUntil) {
      const diffTime = getTimeDiff(shipment?.underReviewUntil)
      if (diffTime > 0) {
        setCountdown(diffTime)
        setIsUnderReview(true)
      } else {
        setIsUnderReview(false)
      }
    } else {
      setIsUnderReview(false)
      setCountdown(0)
    }
  }, [shipment])

  const handleBackToShipment = useCallback(async () => {
    if (underReviewUserId === loggedInUser.id && isTenantUser) {
      await resetReviewShipment(tenantId, shipment as IShipment, id)
    }
    navigate(rootEnum.SHIPMENTS)
  }, [navigate, underReviewUserId, loggedInUser])

  const handleLockForReview = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await reviewShipment(tenantId, shipment as IShipment, id)
      setUnderReviewUserId(loggedInUser.id)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateSuccess), NotiStackOptions.success)
      setCountdown(10 * 60)
      setIsUnderReview(true)
      setIsSubmitting(false)
    } catch (e: any) {
      setIsSubmitting(false)
      logger.warn('shipment saving error', e)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateError), NotiStackOptions.error)
    }
  }, [navigate, id, shipment])

  const handleAccept = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await acceptShipment(tenantId, shipment as IShipment, id)
      navigate(rootEnum.SHIPMENTS)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateSuccess), NotiStackOptions.success)
      setIsSubmitting(false)
    } catch (e: any) {
      logger.warn('shipment saving error', e.response?.data?.data?.errors)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateError), NotiStackOptions.error)
    }
  }, [navigate, id, shipment])

  const handleRecall = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await recallShipment(tenantId, shipment as IShipment, id, customerId)
      await refetch()
      enqueueSnackbar(formatMessage(messages.shipmentUpdateSuccess), NotiStackOptions.success)
      setIsSubmitting(false)
    } catch (e: any) {
      setIsSubmitting(false)

      logger.warn('shipment saving error', e.response?.data?.data?.errors)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateError), NotiStackOptions.error)
    }
  }, [navigate, id, shipment])

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await submitShipment(tenantId, shipment as IShipment, id, customerId)
      await refetch()
      enqueueSnackbar(formatMessage(messages.shipmentUpdateSuccess), NotiStackOptions.success)
      setIsSubmitting(false)
    } catch (e: any) {
      logger.warn('shipment saving error', e.response?.data?.data?.errors)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateError), NotiStackOptions.error)
      setIsSubmitting(false)
    }
  }, [navigate, id, shipment])

  const handleReject = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await rejectShipment(tenantId, shipment as IShipment, id)
      navigate(rootEnum.SHIPMENTS)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateSuccess), NotiStackOptions.success)
      setIsSubmitting(false)
    } catch (e: any) {
      logger.warn('shipment saving error', e.response?.data?.data?.errors)
      enqueueSnackbar(formatMessage(messages.shipmentUpdateError), NotiStackOptions.error)
      setIsSubmitting(false)
    }
  }, [navigate, id, shipment])

  const [countdown, setCountdown] = useState<number | undefined>() // 10min
  const [isUnderReview, setIsUnderReview] = useState<boolean>(false) // 10min

  useEffect(() => {
    if (!countdown) {
      if (isUnderReview) {
        if (isTenantUser) {
          resetReviewShipment(tenantId, shipment as IShipment, id).finally(() => {
            setIsUnderReview(false)
            refetch()
          })
        } else {
          setIsUnderReview(false)
          refetch()
        }
      }
      return
    }
    const timer = setTimeout(() => {
      setCountdown(countdown - 1)
    }, 1000)
    return () => clearTimeout(timer)
  }, [countdown])

  const isUnderReviewOrDifferentUser = useCallback(() => {
    return isUnderReview && underReviewUserId !== loggedInUser.id
  }, [isUnderReview, underReviewUserId, loggedInUser])
  if (!shipmentLoaded || shipmentSelectorFetching) return <SpinnerBlock />

  return (
    <Grid container spacing={1} pt={4}>
      <Grid container spacing={1} pt={2} pb={2} pl={'10px'}>
        <Grid item xs>
          <Box display='flex' justifyContent='flex-start'>
            <Button color='secondary' variant='contained' onClick={handleBackToShipment}>
              {formatMessage(messages.backToShipmentstBtn)}
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Grid container justifyContent='flex-end' spacing={1}>
            <Grid item>
              <ConditionalRender
                condition={isTenantUser && hasAcceptSecurityPrincipal && isSubmitted && !isAccepted}
              >
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleLockForReview}
                  disabled={isUnderReview || isSubmitting}
                >
                  {countdown && countdown > 0
                    ? formatMessage(btnMessages.countdownTitle, {
                      value: `[${dayjs().hour(0).minute(0).second(countdown).format('mm:ss')}]`,
                    })
                    : formatMessage(btnMessages.buttonLockForReview)}
                </Button>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender
                condition={isTenantUser && hasAcceptSecurityPrincipal && !isAccepted}
              >
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleAccept}
                  disabled={isUnderReviewOrDifferentUser() || isSubmitting}
                >
                  {formatMessage(btnMessages.buttonAccept)}
                </Button>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender
                condition={isTenantUser && hasAcceptSecurityPrincipal && isSubmitted && !isAccepted}
              >
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleReject}
                  disabled={isUnderReviewOrDifferentUser() || isSubmitting}
                >
                  {formatMessage(btnMessages.buttonReject)}
                </Button>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender
                condition={
                  isCustomerUser && hasAcceptSecurityPrincipal && isSubmitted && !isAccepted
                }
              >
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleRecall}
                  disabled={isUnderReview || isSubmitting}
                >
                  {formatMessage(btnMessages.buttonRecall)}
                </Button>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender condition={!isSubmitted && !isAccepted}>
                <Button
                  variant='contained'
                  onClick={handleSubmit}
                  disabled={isUnderReview || isSubmitting}
                >
                  {formatMessage(btnMessages.buttonSubmit)}
                </Button>
              </ConditionalRender>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ViewShipmentTabHeader />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', pt: '28px', pb: '28px' }}>
          <ThemeProvider theme={theme}>
            {getStatus(formatMessage, shipment) !== formatMessage(messages.draftStatus) ? (
              <Tabs
                sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                value={activeTab}
                onChange={handleChange}
                centered
                variant='standard'
                scrollButtons={false}
                aria-label='Shipment View Tabs'
              >
                <Tab label='Details' />
                <Tab label='Timeline' />
                <Tab label='Notes' />
                <Tab label='Temperature' />
                <Tab label='Terminal Info' />
              </Tabs>
            ) : (
              <Tabs
                sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                value={activeTab}
                onChange={handleChange}
                centered
                variant='standard'
                scrollButtons={false}
                aria-label='Shipment View Tabs'
              >
                <Tab label='Details' />
              </Tabs>
            )}
          </ThemeProvider>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Grid item xs={12}>
            <ShipmentSummary
              formik={formik}
              isSameValue={true}
              isSameLoadsCurrency={true}
              isSameWithShipmentCurrency={true}
              viewMode={true}
              tabMode={true}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid item xs={12}>
            <Timeline />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Grid item xs={12}>
            <ShipmentNoteTab />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Grid item xs={12}>
            <TemperatureChart data={[]} loadId={0} loadName={''} title={''} />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <Grid item xs={12}>
            <TerminalInfo />
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default ViewShipmentTabs
