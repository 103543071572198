import { Box, FormControlLabel, Link, Switch, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import SideModalWrapper from '../AssetsManagement/modals/SideModalWrapper'
import { fetchAllLocationsByTenantCustomerSelector } from '../Shipments/selectors'
import { ILocationByTenantCustomer } from '../Shipments/types'
import EditLocation from './EditLocation'
import LocationForm from './LocationForm'
import messages from './messages'

export interface ILocationsTableProps {
  columns: MRTColumnDef<ILocationByTenantCustomer>[]
  showLocations: boolean
  setShowLocations: (showLocations: boolean) => void
  locations: ILocationByTenantCustomer[]
  refetch?: () => void
}

const LocationsTable = ({
  columns,
  showLocations,
  setShowLocations,
  locations,
  refetch,
}: ILocationsTableProps): ReactElement<any, any> => {
  const isLoading = useAppSelector(fetchAllLocationsByTenantCustomerSelector.isFetching)
  const { formatMessage } = useIntl()
  const allLocations =
    locations?.filter((d) => {
      if (showLocations) {
        return true
      } else if (d.locationTypeId !== 255) {
        return false
      }
      return true
    }) ?? []

  const handleLocations = (e: any) => {
    setShowLocations(e?.target.checked)
  }
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedLocationId, setSelectedLocation] = useState<number>(-1)

  return (
    <>
      <SideModalWrapper
        open={isCreateModalOpen}
        headerLabel={formatMessage(messages.createLocationTitle)}
        onClose={() => {
          refetch && refetch()
          setIsCreateModalOpen((pv) => !pv)
        }}
      >
        <LocationForm />
      </SideModalWrapper>
      <SideModalWrapper
        open={isEditModalOpen}
        headerLabel={formatMessage(messages.editLocationTitle)}
        onClose={() => {
          refetch && refetch()
          setIsEditModalOpen((pv) => !pv)
        }}
      >
        <EditLocation id={selectedLocationId} />
      </SideModalWrapper>
      <SideModalWrapper
        open={isViewModalOpen}
        headerLabel={formatMessage(messages.viewLocationTitle)}
        onClose={() => {
          setIsViewModalOpen((pv) => !pv)
        }}
      >
        <EditLocation id={selectedLocationId} isDisabled={true} />
      </SideModalWrapper>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          columnPinning: { left: ['name'] },
          sorting: [
            {
              id: 'name',
              desc: false,
            }]
        }
        }
        state={{ isLoading: isLoading }}
        leftHeadingComponent={
          <Box sx={{ display: 'inline-flex' }}>
            <TableTitleTypography>{formatMessage(messages.allLocations)}</TableTitleTypography>
            <FormControlLabel
              sx={{
                marginLeft: '20px',
                marginTop: '2px',
                '& .MuiTypography-root': {
                  color: '#636363',
                },
              }}
              control={
                <Switch
                  size='small'
                  id='showLocations'
                  name='showLocations'
                  checked={showLocations}
                  onChange={handleLocations}
                />
              }
              label='Show all locations'
            />
          </Box>
        }
        rightHeadingComponent={
          <AddButton text={'New Location'} onClick={() => setIsCreateModalOpen(true)} />
        }
        columns={columns as any}
        data={allLocations || []}
        getRowId={(row) => row.id as any}
        enableEditing
        enableColumnPinning={true}
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              {row?.original.locationType.id === 255 && (
                <>
                  <Link
                    underline='none'
                    color='secondary'
                    onClick={() => {
                      setSelectedLocation(row?.original.id)
                      setIsEditModalOpen(true)
                    }}
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    {formatMessage(messages.editButtonLbl)}
                  </Link>
                  <Typography color='secondary'>/</Typography>
                </>
              )}
              <Link
                underline='none'
                color='secondary'
                onClick={() => {
                  setSelectedLocation(row?.original.id)
                  setIsViewModalOpen(true)
                }}
                sx={{
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                {formatMessage(messages.viewButtonLbl)}
              </Link>
            </Box>
          )
        }}
      />
    </>
  )
}

export default LocationsTable
