import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik, FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useAddContactCategoryMutation,
  useUpdateContactCategoryMutation,
} from '../../../../app/redux-fetch/apiCoreQuery'
import RequiredAsterisk from '../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../components/Typographies/styles'
import { IContactCategory } from '../../../Contacts/types'
import messages from '../messages'
import { contactCategoryValidationSchema } from './contactCategoryValidationSchema'

type ContactCategoryFormProps = {
  category: IContactCategory
  handleClose: () => void
  isOpen: boolean
}

const ContactCategoryForm = ({
  category: category,
  handleClose,
  isOpen,
}: ContactCategoryFormProps) => {
  const { formatMessage } = useIntl()

  const isNew = !category?.id || category?.id === 0

  const [addContactCategory] = useAddContactCategoryMutation()
  const [updateContactcategory] = useUpdateContactCategoryMutation()

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const onSubmit = async (data: IContactCategory) => {
    if (isNew) {
      await addContactCategory(data)
        .then((res: any) => {
          if (res.error) {
            console.log('🚀 ~ await addContactCategory ~ res:', res)
          } else {
            enqueueSuccess(messages.contactCategoryCreated)
          }
        })
        .catch((err: any) => {
          enqueueFailure(messages.contactCategoryNotCreated)
        })
    } else {
      await updateContactcategory(data)
        .then(() => {
          enqueueSuccess(messages.contactCategoryUpdated)
        })
        .catch(() => {
          enqueueFailure(messages.contactCategoryNotUpdated)
        })
    }
    handleClose()
  }

  return (
    <Formik
      initialValues={category}
      validationSchema={contactCategoryValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, submitForm, validateForm, setTouched }) => {
        return (
          <Form>
            <Dialog open={isOpen} onClose={handleClose}>
              <DialogTitle>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '20px',
                  }}
                >
                  {formatMessage(messages.createEditContactType)}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{ minWidth: '450px' }}>
                <Box sx={{ mb: '16px' }}>
                  <Box sx={{ mb: '16px' }}>
                    <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                      {formatMessage(messages.contactTypeName)}
                      <RequiredAsterisk />
                    </SectionLabelTypography>
                    <TextField
                      fullWidth
                      id={'name'}
                      name={'name'}
                      value={values?.name ?? ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleChange}
                      helperText={touched.name && (errors?.name as React.ReactNode)}
                      error={touched.name && Boolean(errors?.name)}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  {formatMessage(messages.cancelBtn)}
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    const handleSubmit = () => {
                      validateForm().then((errors: FormikErrors<any>) => {
                        submitForm().finally(() => {
                          setTouched(setNestedObjectValues(errors, true))
                        })
                      })
                    }
                    handleSubmit()
                  }}
                >
                  {isNew ? 'Create' : 'Update'}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContactCategoryForm
