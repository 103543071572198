import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { useGetDriverOperationsQuery, useGetRoutesCountQuery } from '../../app/redux-fetch/apiQuery';
import SpinnerBoxBlock from '../../components/Spinner/SpinnerBoxBlock';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';
import SolidChartComponent from './Components/SolidChartComponent';
import { DashboardTile } from './DashboardTile';
import { useDashboard } from './hooks/useDashboard';

const OperationDashboard = () => {
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { timeFrame } = useDashboard()
    const { data: driverOperation, isSuccess: isSuccessDriverOperation } = useGetDriverOperationsQuery({
        tenantId: loggedInUser.tenantId,
        startDate: timeFrame.startDate.toISOString(),
        endDate: timeFrame.endDate.toISOString(),
    })

    const { data: routesOperation, isSuccess: isSuccessRoutesOperation } = useGetRoutesCountQuery({
        tenantId: loggedInUser.tenantId
    })

    if (!isSuccessDriverOperation || !isSuccessRoutesOperation) return (
        <DashboardTile sx={{ width: '760px' }} >
            <SpinnerBoxBlock />
        </DashboardTile >
    )

    return (
        <DashboardTile sx={{ width: '760px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        paddingLeft: '20px',
                        paddingTop: '20px',
                    }}>
                        <Typography sx={{
                            fontSize: '20px',
                            color: '#666666',
                        }}>Operations</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        height: '400px',
                        overflowY: 'auto',
                        borderRadius: '8px'

                    }}>
                        <SolidChartComponent data={driverOperation} title={'Active Drivers'} />
                        <SolidChartComponent data={{
                            total: routesOperation.count,
                            value: routesOperation.unassignedCount
                        }} title={'Routes'} />
                    </Box>
                </Grid>
            </Grid>
        </DashboardTile >
    );
}

export default OperationDashboard