import dayjs from 'dayjs'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery'
import { useGetUnassignedLoadsQuery } from '../../app/redux-fetch/apiShipment'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { IJourneyLegLoad } from '../Transport/types'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import useJourneyStepperContext from './hooks/useJourneyStepperContext'
import { messages } from './messages'

type UnassignedLoadsTableProps = {
  isAddLoadMode?: boolean
}

const UnassignedLoadsTable = ({ isAddLoadMode = false }: UnassignedLoadsTableProps) => {
  const { formatMessage } = useIntl()

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: loads, isSuccess: isLoadsLoaded } = useGetUnassignedLoadsQuery({ tenantId })
  const { data: customers = [], isSuccess: isCustomersLoaded } = useGetCustomersQuery(tenantId)

  const { setSelectedLoads, selectedLoads, goToStep, setMode } = useJourneyStepperContext()

  const [selectedMembers, setSelectedMembers] = useState<MRTRowSelectionState>(
    selectedLoads.reduce((acc, curr) => {
      const rowId = loads?.findIndex((load) => load.loadId === curr) ?? -1
      acc[rowId] = true
      return acc
    }, {} as MRTRowSelectionState)
  )

  useEffect(() => {
    const selectedRowIds = Object.keys(selectedMembers)
    const loadIds =
      loads
        ?.filter((load, idx) => selectedRowIds.includes(idx.toString()))
        ?.map((load) => load.loadId) ?? []
    setSelectedLoads(loadIds)
  }, [selectedMembers])

  const columns = useMemo<MRTColumnDef<IJourneyLegLoad>[]>(() => {
    return [
      {
        accessorFn: (row) => row.trackingNumber,
        header: formatMessage(messages.trackingNumber),
      },
      {
        accessorFn: (row) => {
          return customers.find((c) => c.id === row.customerId)?.name ?? ''
        },
        header: formatMessage(messages.customerName),
      },
      {
        accessorKey: 'pickupLocationName',
        header: formatMessage(messages.pickupLocation),
      },
      {
        accessorKey: 'pickupDate',
        header: formatMessage(messages.pickupDate),
        Cell: ({ cell }: any) => {
          return dayjs(cell.getValue()).format('YYYY-MM-DD HH:mm')
        },
      },
      {
        accessorFn: (row) => row.consignee,
        header: formatMessage(messages.consigneeName),
      },
      {
        accessorFn: (row) => row.deliveryDate,
        header: formatMessage(messages.deliveryDate),
        Cell: ({ cell }: any) => {
          return dayjs(cell.getValue()).format('YYYY-MM-DD HH:mm')
        },
      },
      {
        accessorFn: (row) =>
          row.containerDetails && row.containerDetails.length > 0
            ? row.containerDetails.reduce((acc: any, curr: any) => {
                return `${acc ? `${acc}, ` : ''}${curr.containerNumber}`
              }, '')
            : '',
        header: formatMessage(messages.containerNumber),
      },
      {
        accessorKey: 'description',
        header: formatMessage(messages.description),
      },
      {
        accessorFn: (row) => row.temperatureRange,
        header: formatMessage(messages.temperatureRange),
      },
    ]
  }, [customers])

  return (
    <>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          sorting: [
            {
              id: 'pickupDate',
              desc: false,
            },
            {
              id: 'pickupLocationName',
              desc: false,
            },
            {
              id: 'description',
              desc: false,
            },
          ],
        }}
        state={{ isLoading: !(isLoadsLoaded && isCustomersLoaded), rowSelection: selectedMembers }}
        columns={columns}
        data={loads || []}
        getRowId={(row) => row.id as any}
        enableSorting={true}
        enableFilters={false}
        enableColumnActions={false}
        enableExpandAll={true}
        enableRowActions={false}
        enableRowSelection={true}
        onRowSelectionChange={setSelectedMembers}
        positionActionsColumn='last'
        {...(!isAddLoadMode
          ? {
              rightHeadingComponent: (
                <AddButton
                  text={formatMessage(messages.newJourney)}
                  onClick={() => {
                    setMode('create')
                    goToStep(2)
                  }}
                />
              ),
            }
          : {})}
      />
    </>
  )
}

export default UnassignedLoadsTable
