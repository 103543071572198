import { Box, MenuItem, Select } from '@mui/material';

type ColoredLoadDropdownProps = {
    values: any[];
    value: string;
    onChange: (event: any) => void;
}

const ColoredLoadDropdown = ({ values, value, onChange }: ColoredLoadDropdownProps) => {
    return (
        <Select value={value} onChange={onChange} displayEmpty sx={{ m: '10px' }}>
            <MenuItem value="" disabled>
                Choose a Load
            </MenuItem>
            {values.map((val) => (
                <MenuItem key={val.loadName} value={val.color}>
                    <Box component="span" sx={{
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        backgroundColor: val.color,
                        display: 'inline-block',
                        marginRight: 1
                    }} />
                    {val.loadName}
                </MenuItem>
            ))}
        </Select>
    );
};

export default ColoredLoadDropdown;
