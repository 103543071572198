import { Box, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useGetShipmentsReportByPickupCountryQuery } from '../../app/redux-fetch/apiQuery';
import SpinnerBoxBlock from '../../components/Spinner/SpinnerBoxBlock';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';
import { DashboardInsideTile, DashboardTile } from './DashboardTile';
import { useDashboard } from './hooks/useDashboard';


const MapDashboard = () => {
    const [selectedCountry, setSelectedCountry] = useState({ name: '', value: 0 })
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { timeFrame } = useDashboard()
    const { data, isSuccess, isFetching } = useGetShipmentsReportByPickupCountryQuery({
        tenantId: loggedInUser.tenantId,
        startDate: timeFrame.startDate.toISOString(),
        endDate: timeFrame.endDate.toISOString(),
    })
    const containerId = '2'
    const mapRef = useRef<any>(null);

    useEffect(() => {
        if (window.anychart && isSuccess && !isFetching) {
            mapRef.current = anychart.map();
            mapRef.current.container(containerId);

            console.log('Updating map data...')
            const series = mapRef.current.choropleth(
                data.map((x: any) => ({ id: x.countryCode, name: x.countryName, value: x.totalShipments }))
            );

            mapRef.current.geoData(anychart.maps['world']);
            mapRef.current.interactivity().selectionMode('none');
            mapRef.current.padding(20);
            mapRef.current.interactivity().zoomOnMouseWheel(true);

            series.tooltip().format((data: any) => 'Shipments: ' + data.value.toLocaleString());

            series.listen('pointClick', (e: any) => {
                const clickedCountryId = e.point.get('id');
                mapRef.current.zoomToFeature(clickedCountryId);
                setSelectedCountry({ name: e.point.get('name'), value: e.point.get('value') });
            });

            mapRef.current.draw();
        }
    }, [data, isSuccess, isFetching]);

    if (!isSuccess || isFetching) {
        return (
            <DashboardTile sx={{ width: '760px' }}>
                <SpinnerBoxBlock />
            </DashboardTile>
        )
    }
    const totalShipmentsSum = data.reduce((sum: any, item: any) => sum + item.totalShipments, 0);

    const getPercent = (value: number) => {
        const percentage = (value / totalShipmentsSum) * 100;
        return `${percentage.toFixed(0)}%`;
    };

    return (
        <DashboardTile sx={{ width: '760px' }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{
                        paddingLeft: '20px',
                        paddingTop: '20px',
                    }}>
                        <Typography sx={{
                            fontSize: '20px',
                            color: '#666666',
                        }}>Total shipments</Typography>
                    </Box>
                    <Box sx={{
                        paddingLeft: '20px',
                    }}>
                        <Typography sx={{
                            fontSize: '20px',
                            color: '#666666',
                            fontWeight: '700',
                        }}>{totalShipmentsSum}</Typography>
                    </Box>
                    <DashboardInsideTile sx={{ borderColor: '#FFFFFF', margin: '20px', padding: '20px', filter: 'drop-shadow(2px 3px 6px rgba(0, 0, 0, 0.16))' }}>
                        {selectedCountry.name ? (
                            <>
                                <Box sx={{ marginBottom: '10px' }}>
                                    <Typography variant="body1" sx={{ fontSize: '20px', color: '#666666', fontWeight: '700' }}>
                                        {selectedCountry.name}
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '25px' }}>
                                    <Box sx={{ flex: 1, textAlign: 'right', paddingRight: '10px' }}>
                                        <Typography variant="body1" sx={{ fontSize: '24px', color: '#666666', fontWeight: '700' }}>
                                            {getPercent(selectedCountry.value)}
                                        </Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem sx={{ height: '40px', mx: 2, mt: 1 }} />
                                    <Box sx={{ flex: 1, textAlign: 'left', paddingLeft: '10px' }}>
                                        <Typography variant="body1" sx={{ fontSize: '20px', color: '#666666', fontWeight: '400' }}>
                                            Shipments
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontSize: '20px', color: '#666666', fontWeight: '400' }}>
                                            {selectedCountry.value.toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <Typography variant="body1" sx={{ fontSize: '20px', color: '#666666', fontWeight: '700' }}>
                                Click on a country to see details
                            </Typography>
                        )}
                    </DashboardInsideTile>
                </Grid>
                <Grid item xs={6}>
                    <div id={containerId} style={{ width: '100%', height: '400px' }} />
                </Grid>
            </Grid>
        </DashboardTile >
    )
}

export default MapDashboard
