import DeleteIcon from '@mui/icons-material/Delete'
import { AccordionDetails, AccordionSummary, Box, Button, Fab, Grid, TextField, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { useAppSelector } from '../../../app/hooks'
import { useGetCarriersQuery } from '../../../app/redux-fetch/apiShipment'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import { ICarrier } from '../../Shipments/types'
import { TransportLegAccordion } from '../../Transport/components/Common/style'
import { ILeg, TransportationMode } from '../../Transport/types'
import { fetchLoggedInUserSelector } from '../../selectors'
import { useJourneyFormContext } from '../hooks/useJourneyFormContext'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import { messages } from '../messages'
import Route from './Route'

interface ILegProps {
    leg: ILeg
    legIdx: number
}

const Leg = ({ leg, legIdx }: ILegProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const { values, setFieldValue, handleChange } = useJourneyFormContext()
    const { mode } = useJourneyStepperContext()

    const { formatMessage } = useIntl()

    const getAccordionDetails = () => {
        return `${formatMessage(messages.legName)} ${legIdx + 1}`
    }
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
    const { data: carries = [] as ICarrier[] } = useGetCarriersQuery(loggedInUser?.tenantId ?? -1)

    const handleRemoveTransportLeg = () => {
        const legs = values.legs
        legs.splice(legIdx, 1)
        setFieldValue('legs', legs)
    }
    const handleAddRoute = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        const id = uuidv4()

        const newRoute = {
            uId: id,
            locations: [],
            loads: [],
            name: `${formatMessage(messages.routeName)} #${id}`,
        }

        setFieldValue(`legs.${legIdx}.routes`, [...values.legs[legIdx].routes, newRoute])
    }
    const handleSelectCarrier = (event: any, name: string, value: any) => {
        setFieldValue(`legs.${legIdx}.carrierWaybillMaster`, undefined)
        setFieldValue(name, value)
    }
    const getCarrierWaybillValue = useCallback(() => {
        return isCarrierWaybillAllowed(leg.carrierId) ? leg.carrierWaybillMaster ?? '' : ''
    }, [leg.carrierId, leg.carrierWaybillMaster])

    const isCarrierWaybillAllowed = (carrierId: number | undefined) => {
        if (!carrierId) return false
        const carrier = carries.find((c) => c.id === carrierId)
        return carrier?.transportModeId !== TransportationMode.Road // 3 is Road mode
    }
    return (
        <TransportLegAccordion
            expanded={isExpanded}
            onChange={(e: any, expanded: boolean) => {
                setIsExpanded(expanded)
            }}
        >
            <AccordionSummary>
                <Grid container>
                    <Grid item xs={8}>
                        <Box display='flex' justifyContent='flex-start' alignItems={'center'}>
                            <Typography sx={{ pr: '16px' }}>{getAccordionDetails()}</Typography>
                            {isExpanded && (
                                <>
                                    <Button
                                        aria-label='add'
                                        color='secondary'
                                        disabled={mode === 'view'}
                                        onClick={handleAddRoute}
                                        size='small'
                                        variant='contained'
                                        sx={{ margin: '0 2px', pl: '16px' }}
                                    >
                                        {formatMessage(messages.addRoute)}
                                    </Button>
                                    <FormDropdown
                                        sx={{ maxWidth: '200px', pl: '16px' }}
                                        id={`legs.${legIdx}.carrierId`}
                                        items={carries}
                                        disabled={mode === 'view'}
                                        label={formatMessage(messages.legCarrierLabel)}
                                        onChange={handleSelectCarrier}
                                        value={leg.carrierId}
                                    />
                                    {isCarrierWaybillAllowed(leg.carrierId) && (
                                        <Box sx={{ maxWidth: '200px', pl: '16px', mb: '3px' }}>
                                            <TextField
                                                id={`legs.${legIdx}.carrierWaybillMaster`}
                                                name={`legs.${legIdx}.carrierWaybillMaster`}
                                                inputProps={{ maxLength: 50 }}
                                                label={formatMessage(messages.legCarrierWaybillMaster)}
                                                variant='outlined'
                                                disabled={mode === 'view'}
                                                onChange={handleChange}
                                                value={getCarrierWaybillValue()}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display='flex' justifyContent='flex-end' sx={{ marginRight: '16px' }}>
                            <Fab
                                aria-label='remove'
                                color='info'
                                disabled={mode === 'view'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleRemoveTransportLeg()
                                }}
                                size='small'
                                sx={{ margin: '0 2px' }}
                            >
                                <DeleteIcon />
                            </Fab>
                        </Box>
                        <Box display='flex' justifyContent='flex-end'></Box>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {values.legs[legIdx].routes.map((route, rIdx) => {
                    return (
                        <Route
                            key={rIdx}
                            route={route}
                            rIdx={rIdx}
                            legIdx={legIdx}
                        />
                    )
                })}
            </AccordionDetails>
        </TransportLegAccordion>
    )
}

export default Leg
