import { Box, Grid, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { a11yProps } from '../../../app/utils'
import TabPanel from '../../../components/TabPanel/TabPanel'
import AssetCategoryTable from './components/AssetCategory/AssetCategoryTable'
import AssetClassTable from './components/AssetClass/AssetClassTable'
import AssetConfigurationTable from './components/AssetConfiguration/AssetConfigurationTable'
import AssetTypeTable from './components/AssetType/AssetTypeTable'
import { messages } from './messages'


const AssetSettings = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { formatMessage } = useIntl()

  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  return (
    <>
      <Box sx={{ width: '100%', justifyContent: 'start' }}>
        <Tabs
          sx={(theme) => {
            return { '& .MuiTabs-indicator': { backgroundColor: theme.palette.secondary.main } }
          }}
          value={activeTab}
          onChange={handleChange}
          variant='standard'
          scrollButtons={false}
          textColor='secondary'
          aria-label='Asset settings scrollable tabs'
        >
          <Tab label={formatMessage(messages.assetCategories)} {...a11yProps(0)} />
          <Tab label={formatMessage(messages.assetTypes)} {...a11yProps(1)} />
          <Tab label={formatMessage(messages.assetClasses)} {...a11yProps(2)} />
          <Tab label={formatMessage(messages.assetConfigurations)} />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Grid item xs={12}>
          <AssetCategoryTable />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Grid item xs={12}>
          <AssetTypeTable />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <Grid item xs={12}>
          <AssetClassTable />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <Grid item xs={12}>
          <AssetConfigurationTable />
        </Grid>
      </TabPanel>
    </>
  )
}

export default AssetSettings
