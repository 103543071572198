import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useDeleteContactTypeMutation,
  useGetContactTypesByCustomerIdQuery,
  useGetContactTypesByTenantIdQuery,
} from '../../../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../../../components/Buttons/AddButton'
import ConditionalRender from '../../../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../../../components/YesNoTypograpphy/YesNoTypography'
import { IContactType } from '../../../Contacts/types'
import messages from '../messages'
import ContactTypeForm from './ContactTypeForm'

const ContactTypeTab = () => {
  const { formatMessage } = useIntl()

  const { tenantId, customerId } = useLoggedInUser()

  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [selectedContactId, setSelectedContact] = useState(-1)

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const [deleteContactType] = useDeleteContactTypeMutation()

  const {
    data: contactTypes = [],
    isFetching: contactsLoading,
    isSuccess: contactsLoaded,
  } = customerId
    ? useGetContactTypesByCustomerIdQuery(customerId)
    : useGetContactTypesByTenantIdQuery(tenantId)

  const columns = useMemo<MRTColumnDef<IContactType>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }: any) => {
          return <Typography>{cell.getValue()}</Typography>
        },
      },
      {
        header: 'Category Name',
        accessorFn: (row) => row.contactCategory?.name ?? '',
        Cell: ({ cell }: any) => {
          return <Typography>{cell.getValue()}</Typography>
        },
      },
      {
        accessorFn: (row) => {
          return row.inUse ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.inUse),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
    ]
  }, [contactTypes])

  return (
    <>
      <ConditionalRender condition={createOpen}>
        <ContactTypeForm
          contactType={{
            tenantId: +tenantId,
            customerId: customerId,
            id: 0,
            name: '',
            contactCategoryId: 0,
          }}
          handleClose={() => setCreateOpen(false)}
          isOpen={createOpen}
        />
      </ConditionalRender>
      <ConditionalRender condition={editOpen}>
        <ContactTypeForm
          contactType={
            contactTypes.find((c) => c.id === selectedContactId) ?? {
              tenantId: +tenantId,
              customerId: customerId,
              id: 0,
              name: '',
              contactCategoryId: 0,
            }
          }
          handleClose={() => setEditOpen(false)}
          isOpen={editOpen}
        />
      </ConditionalRender>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          density: 'comfortable',
          sorting: [
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
        leftHeadingComponent={
          <Box sx={{ display: 'inline-flex' }}>
            <TableTitleTypography>{formatMessage(messages.contacts)}</TableTitleTypography>
          </Box>
        }
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newContactBtn)}
            onClick={() => setCreateOpen(true)}
          />
        }
        state={{
          isLoading: contactsLoading || !contactsLoaded,
        }}
        data={contactTypes}
        columns={columns}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedContact(row.id)
                    setEditOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editBtn)}
                </Link>
                <ConditionalRender condition={row.original.inUse == false}>
                  <CancelIcon
                    color='secondary'
                    sx={{
                      cursor: 'pointer',
                      pl: '4px',
                    }}
                    onClick={() => {
                      deleteContactType(row.original.id)
                        .then(() => {
                          enqueueSuccess(messages.contactTypeDeleted)
                        })
                        .catch(() => {
                          enqueueFailure(messages.contactTypeNotDeleted)
                        })
                    }}
                  />
                </ConditionalRender>
              </Box>
            </>
          )
        }}
      />
    </>
  )
}

export default ContactTypeTab
