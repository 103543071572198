import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'tenantSettings.roleManagement.title',
    description: 'Role table title',
    defaultMessage: 'Roles',
  },
  roleName: {
    id: 'tenantSettings.roleManagement.roleName',
    description: 'Role Name',
    defaultMessage: 'Name',
  },
  name: {
    id: 'tenantSettings.roleManagement.name',
    description: 'Name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'tenantSettings.roleManagement.description',
    description: 'Description',
    defaultMessage: 'Description',
  },
  lastUpdated: {
    id: 'tenantSettings.roleManagement.lastUpdated',
    description: 'Last Updated',
    defaultMessage: 'Last Updated',
  },
  securityPrincipals: {
    id: 'tenantSettings.roleManagement.securityPrincipals',
    description: 'Security Principals',
    defaultMessage: 'Security Principals',
  },
  securityPrincipalAllocations: {
    id: 'tenantSettings.roleManagement.securityPrincipalAllocations',
    description: 'Security Principal Allocations',
    defaultMessage: 'Security Principal Allocations',
  },
  addRole: {
    id: 'tenantSettings.roleManagement.addRole',
    description: 'Add Role',
    defaultMessage: 'Add Role',
  },
  editRole: {
    id: 'tenantSettings.roleManagement.editRole',
    description: 'Edit Role',
    defaultMessage: 'Edit',
  },
  deleteRole: {
    id: 'tenantSettings.roleManagement.deleteRole',
    description: 'Delete Role',
    defaultMessage: 'Delete',
  },
  viewRole: {
    id: 'tenantSettings.roleManagement.viewRole',
    description: 'View Role',
    defaultMessage: 'View',
  },
  mainInfoTitle: {
    id: 'tenantSettings.roleManagement.mainInfoTitle',
    description: 'Main Information Title',
    defaultMessage: 'Main Information',
  },
  nameIsRequired: {
    id: 'tenantSettings.roleManagement.nameIsRequired',
    description: 'Name is required',
    defaultMessage: 'Name is required',
  },
  descriptionIsRequired: {
    id: 'tenantSettings.roleManagement.descriptionIsRequired',
    description: 'Description is required',
    defaultMessage: 'Description is required',
  },
  editButton: {
    id: 'tenantSettings.roleManagement.editButton',
    description: 'Edit',
    defaultMessage: 'Edit',
  },
  cancelButton: {
    id: 'tenantSettings.roleManagement.cancelButton',
    description: 'Cancel',
    defaultMessage: 'Cancel',
  },
  roleApiError: {
    id: 'tenantSettings.roleManagement.roleApiError',
    description: 'Role API Error',
    defaultMessage: 'Role API Error',
  },
  roleCreated: {
    id: 'tenantSettings.roleManagement.roleCreated',
    description: 'Role Created',
    defaultMessage: 'Role Created',
  },
  roleUpdated: {
    id: 'tenantSettings.roleManagement.roleUpdated',
    description: 'Role Updated',
    defaultMessage: 'Role Updated',
  }
})

export default messages
