import { Box, Typography } from '@mui/material'
import { Formik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import logger from '../../../app/middleware/log'
import {
  useApproveJourneyMutation,
  useGetUnassignedLoadsQuery,
  useSaveDraftJourneyMutation,
  useUpdateJourneyMutation,
} from '../../../app/redux-fetch/apiShipment'
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import { DestinationAction, IJourneyLegRoutesLoad, ITransportJourney } from '../../Transport/types'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import { messages } from '../messages'
import { transformAndAppendLoads } from '../utils'
import JourneyLoadsTable from './JourneyLoadsTable'
import Legs from './Legs'
import SubmitButtons from './components/SubmitButtons'
import { checkLoadsMismatch, validateLoadInLegs, validateLoadsInLegRoutes } from './utils'
import { getJourneyValidationSchema } from './validationSchema'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'

const JourneyForm = () => {
  const { formatMessage } = useIntl()

  const validationSchema = getJourneyValidationSchema(formatMessage)

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: loads = [], isSuccess: isLoadsLoaded } = useGetUnassignedLoadsQuery({ tenantId })

  const { selectedLoads, withLoadJourney, selectedJourney, mode } = useJourneyStepperContext()

  const isNew = !selectedJourney

  const [approveJourney] = useApproveJourneyMutation()
  const [saveDraftJourney] = useSaveDraftJourneyMutation()
  const [updateJourney] = useUpdateJourneyMutation()

  const { onClose } = useSideModalContext()

  const initialValues = (): ITransportJourney => {
    if (!isNew) {
      return transformAndAppendLoads(selectedJourney)
    }
    const initialValues: ITransportJourney = {
      tenantId: tenantId,
      loads: withLoadJourney
        ? loads
          .filter((load) => selectedLoads.includes(load.loadId))
          .map((item) => ({ ...item, id: item.loadId, isSelected: false }))
        : [],
      assetConfigurationAllocations: [],
      assetEmployeeAllocations: [],
      assetSealAllocations: [],
      assetConfigurations: [],
      legs: [],
    }
    return initialValues
  }

  const handleSubmit = async (values: ITransportJourney) => {
    let wrongRouteIdx = 1
    const isInvalidLocationCount = values.legs.some((tl) =>
      tl.routes.some((tlr, tlrIdx) => {
        if (tlr.destinations.length < 2) {
          wrongRouteIdx += tlrIdx
          return true
        }
        return false
      })
    )
    if (isInvalidLocationCount) {
      enqueueSnackbar(formatMessage(messages.invalidRouteLocationCount, { wrongRouteIdx }), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }

    const legs = values.legs.map((tl, tIdx) => {
      const routes = tl.routes.map((route) => {
        return {
          transportDetail: {
            pickupLocationId: route.destinations.length > 0 ? route.destinations[0].locationId : -1,
            deliveryLocationId:
              route.destinations.length > 0
                ? route.destinations[route.destinations.length - 1].locationId
                : -1,
            pickupDate: '2023-07-10T12:30:32.935Z', // TODO: What is the time there
            deliveryDate: '2023-07-10T12:30:32.935Z', // TODO: What is the time there
            isCrossBorder: true, // TODO: Calculate in BE
          },
          subcontractedTenantId: 0,
          subSubcontractedTenantId: 0,
          assetGroupAllocations: [],
          destinations: route.destinations.map((rloc) => {
            const loadActions = route.loads.map((rl) => {
              let actionId = DestinationAction.Nothing // Do nothing
              let notifyPartyId
              if (rl.pickupRouteLocationId === rloc.locationId) {
                actionId = DestinationAction.Pickup // Pick up
                notifyPartyId = rl.pickupNotifyPartyId
              }
              if (rl.dropoffRouteLocationId === rloc.locationId) {
                actionId = DestinationAction.Dropoff // Drop off
                notifyPartyId = rl.deliveryNotifyPartyId
              }
              return {
                loadId: rl.id,
                destinationActionTypeId: actionId,
                notifyPartyId: notifyPartyId,
                ...(rl.id && selectedJourney ? { id: rl.id } : {}),
              }
            })
            return {
              locationId: rloc.locationId,
              sequence: rloc.sequence,
              scheduledDate: rloc.scheduledDate,
              loadActions: loadActions,
              ...(rloc.id && selectedJourney ? { id: rloc.id } : {}),
              ...(route.id && selectedJourney ? { routeId: route.id } : {}),
            }
          }),
          ...(route.id && selectedJourney ? { id: route.id } : {}),
        }
      })

      return {
        carrierId: tl.carrierId,
        carrierWaybillMaster: tl.carrierWaybillMaster,
        routes: routes,
        sequence: tIdx + 1,
        ...(selectedJourney?.id ? { id: tl.id } : {}),
      }
    })

    const loads: IJourneyLegRoutesLoad[] = []

    values.legs.forEach((tl) => tl.routes.forEach((tlr) => tlr.loads.forEach((l) => loads.push(l))))

    const isPickupOrDroppoffLocationNotMissing = selectedJourney?.id
      ? true
      : checkLoadsMismatch(values)
    const isPickupOrDroppoffLocationWrongForLegs = selectedJourney?.id
      ? true
      : validateLoadInLegs(values)
    const pickupDropoffErrorLoads = validateLoadsInLegRoutes(values)

    if (pickupDropoffErrorLoads.length > 0) {
      enqueueSnackbar(formatMessage(messages.loadPickupDropoffValidation), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return false
    }

    if (!isPickupOrDroppoffLocationWrongForLegs) {
      enqueueSnackbar(formatMessage(messages.loadPickupDeliveryLegsValidation), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }

    if (!isPickupOrDroppoffLocationNotMissing) {
      enqueueSnackbar(formatMessage(messages.loadPickupDeliveryValidation), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }

    const newPayload = {
      tenantId: tenantId,
      legs: legs,
      ...(selectedJourney?.id ? { id: selectedJourney?.id } : {}),
    }
    if (!selectedJourney) {
      await saveDraftJourney({ tenantId, body: newPayload })
        .then(async (res: any) => {
          if (!values.isDraft) {
            await approveJourney({
              tenantId: tenantId,
              journeyId: res.data.id ?? 0,
            })
              .then((e) => {
                logger.error(e)
                enqueueSnackbar(formatMessage(messages.journeyApprovedSuccessfully), {
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                })
                onClose()
              })
              .catch((e) => {
                logger.error(e)
                enqueueSnackbar(formatMessage(messages.journeyNotApproved), {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                })
              })
          }
          else {
            enqueueSnackbar(formatMessage(messages.journeySavedSuccessfully), {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })
            onClose()
          }
        })
        .catch(() => {
          enqueueSnackbar(formatMessage(messages.journeyNotSaved), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
    } else {
      await updateJourney({ tenantId, journeyId: selectedJourney?.id ?? -1, body: newPayload })
        .then(async (res: any) => {
          if (values.isDraft) {
            enqueueSnackbar(formatMessage(messages.journeySavedSuccessfully), {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })
            onClose()
            return
          }
          await approveJourney({
            tenantId: tenantId,
            journeyId: selectedJourney.id ?? 0,
          })
            .then((e) => {
              logger.error(e)
              enqueueSnackbar(formatMessage(messages.journeyApprovedSuccessfully), {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              })
              onClose()
            })
            .catch((e) => {
              logger.error(e)
              enqueueSnackbar(formatMessage(messages.journeyNotApproved), {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              })
            })
        })
        .catch((e) => {
          logger.error(e)
          enqueueSnackbar(formatMessage(messages.journeyNotSaved), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        })
    }
  }

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues() || {}}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          await handleSubmit(values)
          setSubmitting(false)
        }}
      >
        <Box
          sx={{
            p: '32px',
          }}
        >
          <ConditionalRender condition={withLoadJourney}>
            <JourneyLoadsTable />
          </ConditionalRender>
          <ConditionalRender condition={!withLoadJourney}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                p: '16px',
              }}
            >
              <TableTitleTypography>{formatMessage(messages.loadsName)}</TableTitleTypography>
              <Typography variant='body2' sx={{ pt: '16px' }}>
                {formatMessage(messages.noLoadsSelected)}
              </Typography>
            </Box>
          </ConditionalRender>
          <Legs />
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              padding: '16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #e0e0e0',
              zIndex: 999,
            }}
          >
            <SubmitButtons disabled={!isLoadsLoaded || mode === 'view'} />
          </Box>
        </Box>
      </Formik>
    </>
  )
}

export default JourneyForm
