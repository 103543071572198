import { Box, TextField } from '@mui/material'
import { DatePicker } from '../../components/DatePicker/DatePicker'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../components/Typographies/styles'
import { useWorkforceFormContext } from './hooks/useDriverFormContext'

type DriverInfoProps = {
    isDisabled?: boolean
    isNew: boolean
}

const DriverInfo = ({ isDisabled = false, isNew }: DriverInfoProps) => {

    const { values, setFieldValue, errors, touched } = useWorkforceFormContext()

    const handleTextFieldChange = ({
        target,
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(target.id, target.value)
    }

    const {
        driverDetail
    } = values

    return (
        <Box >
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography>Driver Info</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    License Number
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                    fullWidth
                    id='driverDetail.licenceNumber'
                    value={driverDetail?.licenceNumber}
                    defaultValue={driverDetail?.licenceNumber}
                    disabled={isDisabled}
                    onChange={handleTextFieldChange}
                    error={(touched.driverDetail as any)?.licenceNumber && !!(errors.driverDetail as any)?.licenceNumber}
                    helperText={(touched.driverDetail as any)?.licenceNumber && (errors.driverDetail as any)?.licenceNumber}
                />
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                    License Expiry Date
                    <RequiredAsterisk />
                </SectionLabelTypography>
                <DatePicker
                    name={'driverDetail.licenceExpiry'}
                    value={driverDetail?.licenceExpiry}
                    minDate={new Date('1900-01-01')}
                    disablePast={false}
                    disabled={isDisabled}
                    onChange={(date) => setFieldValue('driverDetail.licenceExpiry', date)}
                    errorText={(touched.driverDetail as any)?.licenceExpiry && (errors.driverDetail as any)?.licenceExpiry}
                />
            </Box>

        </Box>
    )
}

export default DriverInfo

