import { useAppSelector } from '../../app/hooks'
import { useGetUserTypesQuery } from '../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../components/Spinner/SpinnerModalBlock'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import WorkforceForm from './WorkforceForm'

const CreateWorkforce = () => {
    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { isSuccess } = useGetUserTypesQuery({ tenantId })

    const newWorkforce = {
        tenantId,
        userTypeId: 1
    }

    if (!isSuccess) return <SpinnerModalBlock />

    return <WorkforceForm workforce={newWorkforce} />

}

export default CreateWorkforce