/* eslint-disable camelcase */
import { Box, Button, Grid, IconButton } from '@mui/material'
import { useCallback, useEffect, useMemo, type ReactElement } from 'react'
// eslint-disable-next-line
import { Edit, Visibility } from '@mui/icons-material'
import dayjs from 'dayjs'
import {
  MRT_Cell,
  MRT_Row,
  MRT_TableInstance,
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import MRTDataGrid from '../../../../components/MRTDataGrid/MRTDataGrid'
import { default as RoutesEnum, default as rootEnum } from '../../../../components/Routes/rootEnum'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import {
  fetchAllCustomersByTenantSelector,
  fetchAllShipmentsSelector,
  fetchAllTenantCurrenciesSelector,
} from '../../selectors'
import {
  fetchAllCurrenciesByTenantIdThunk,
  fetchAllCustomersByTenantIdThunk,
  fetchShipmentsThunk,
} from '../../slice'
import { ILoad, IShipment } from '../../types'
import { messages } from './messages'

const Shipments = (): ReactElement<any, any> => {
  const { formatMessage } = useIntl()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  const shipments = useAppSelector(fetchAllShipmentsSelector.data) ?? []
  const isFetchingShipments = useAppSelector(fetchAllShipmentsSelector.isFetching) ?? true
  const customers = useAppSelector(fetchAllCustomersByTenantSelector.data) ?? []
  const isFetchingCustomers = useAppSelector(fetchAllCustomersByTenantSelector.isFetching) ?? true
  const isCurrencyFetching = useAppSelector(fetchAllTenantCurrenciesSelector.isFetching)
  const currencies = useAppSelector(fetchAllTenantCurrenciesSelector.data) ?? []
  const currentTime = dayjs(new Date())

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleCreateShipment = useCallback(() => {
    navigate(rootEnum.SHIPMENT_STEPPER)
  }, [navigate])

  useEffect(() => {
    dispatch(fetchShipmentsThunk(tenantId, customerId))
    if (currencies.length === 0) dispatch(fetchAllCurrenciesByTenantIdThunk(tenantId))
    if (!customerId) dispatch(fetchAllCustomersByTenantIdThunk(tenantId))
  }, [dispatch, loggedInUser])

  const renderMoneyValue = (value: number, currencyId: number) => {
    return `${currencies.find((c) => c.id === currencyId)?.iso3} ${value
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }
  // eslint-disable-next-line
  const shipmentColumns = useMemo<Array<MRT_ColumnDef<IShipment>>>(() => {
    let columns: Array<MRT_ColumnDef<IShipment>> = [
      {
        header: formatMessage(messages.shipmentStatus),
        accessorFn: (row) => {
          if (row.underReviewUntil) {
            const localLockedTime = dayjs.utc(row.underReviewUntil)
            const diffTime = localLockedTime.diff(currentTime, 'second')
            if (diffTime > 0) {
              return formatMessage(messages.lockForReviewBtn)
            }
          }
          if (row.acceptedDate) {
            return formatMessage(messages.acceptedStatus)
          }
          if (row.submittedDate) {
            return formatMessage(messages.submittedStatus)
          }
          return formatMessage(messages.draftStatus)
        },
      },
      {
        id: 'submittedDate',
        header: formatMessage(messages.submittedDate),
        accessorFn: (row) => {
          if (row.submittedDate !== undefined) {
            return dayjs(row.submittedDate).format('YYYY-MM-DD')
          }
        },
      },

      {
        header: formatMessage(messages.shipperIdHeader),
        accessorFn: (row) => row.shipper?.name,
        filterVariant: 'text', // default
      },
      {
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        accessorFn: (row) => renderMoneyValue(row.value, row.currencyId ?? 0),
        header: formatMessage(messages.cargoValueHeader),
      },
      {
        accessorKey: 'trackingNumber',
        header: formatMessage(messages.trackingNumberHeader),
      },
      {
        accessorKey: 'customerReferenceNumber',
        header: formatMessage(messages.customerReferenceNumberHeader),
      },
      {
        accessorKey: 'description',
        header: formatMessage(messages.descriptionHeader),
      },
    ]
    if (!customerId) {
      columns = [
        {
          accessorFn: (row) => customers.find((c) => c.id === row.customerId)?.name,
          header: formatMessage(messages.customerIdHeader),
          filterVariant: 'text', // default
        },
        ...columns,
      ]
    }
    return columns
  }, [customers, customerId, currencies])

  const loadColumns = useMemo<Array<MRT_ColumnDef<ILoad>>>(() => {
    return [
      {
        accessorKey: 'quantity',
        header: formatMessage(messages.loadQuantityHeader),
        Cell: ({ cell }) => `${cell.getValue()}`,
        filterVariant: 'text',
      },
      {
        header: formatMessage(messages.loadDescriptionHeader),
        accessorFn: (row) => (row.product ? row.product.name : row.description),
        Cell: ({ cell }) => `${cell.getValue()}`,
        filterVariant: 'text',
      },
      {
        accessorFn: (row) => renderMoneyValue(row.value ?? 0, row.currencyId ?? 0),
        header: formatMessage(messages.loadValueHeader),
      },
      {
        accessorFn: (row) => row.transportDetail?.pickupLocation?.name,
        header: formatMessage(messages.loadPickupLocationHeader),
      },
      {
        accessorFn: (row) => dayjs(row.transportDetail?.pickupDate).format('DD/MM/YYYY'),
        header: formatMessage(messages.loadPickupLocationDateHeader),
      },
      {
        accessorFn: (row) => row.transportDetail?.deliveryLocation?.name,
        header: formatMessage(messages.loadDeliveryLocationHeader),
      },
      {
        accessorFn: (row) =>
          row.transportDetail?.deliveryDate
            ? dayjs(row.transportDetail?.deliveryDate).format('DD/MM/YYYY')
            : '',
        header: formatMessage(messages.loadDeliveryLocationDateHeader),
      },
    ]
  }, [currencies])

  const createRowActions = ({
    row,
  }: {
    cell: MRT_Cell<IShipment>
    row: MRT_Row<IShipment>
    table: MRT_TableInstance<IShipment>
  }) => {
    const { submittedDate } = row.original
    const isShipmentSubmitted = submittedDate !== undefined

    return (
      <Box sx={{ display: 'flex' }}>
        <IconButton
          color={'info'}
          onClick={() =>
            navigate(`${RoutesEnum.VIEW_SHIPMENT.replace(':id', String(row.original.id))}`)
          }
        >
          <Visibility />
        </IconButton>
        {!isShipmentSubmitted ? (
          <IconButton
            color={'info'}
            onClick={() =>
              navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(row.original.id))}`)
            }
          >
            <Edit />
          </IconButton>
        ) : null}
      </Box>
    )
  }
  return (
    <Grid container spacing={2} pt={4}>
      <Grid item xs={6}>
        <TableTitleTypography>{formatMessage(messages.title)}</TableTitleTypography>
      </Grid>
      <Grid item xs={6}>
        <Box display='flex' justifyContent='flex-end'>
          <Button color='secondary' variant='contained' onClick={handleCreateShipment}>
            {formatMessage(messages.createShipmentBtn)}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MRTDataGrid
          columns={shipmentColumns}
          data={shipments}
          initialState={{
            density: 'compact',
            pagination: { pageSize: 100, pageIndex: 0 },
            sorting: [
              {
                id: 'submittedDate',
                desc: false,
              },
            ],
          }}
          state={{
            isLoading:
              isFetchingShipments || (isFetchingCustomers && !customerId) || isCurrencyFetching,
          }}
          enableRowActions
          enableExpanding
          enableEditing
          renderRowActions={createRowActions}
          renderDetailPanel={({ row }) => {
            const loadData = row.original.loads.filter((l) => l.isDefined)
            const loadTable = (loads: ILoad[]) => {
              return (
                <MaterialReactTable
                  columns={loadColumns}
                  data={loads}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enablePagination={false}
                  enableSorting={false}
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  muiTableBodyRowProps={{ hover: false }}
                />
              )
            }
            return loadData.length > 0
              ? loadTable(loadData)
              : `${row.original.assetRequests.length} Asset Request(s)`
          }}
        />
      </Grid>
    </Grid>
  )
}

export default Shipments
