import { useGetRoleByIdQuery } from '../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../components/Spinner/SpinnerModalBlock'
import RoleForm from './RoleForm'
import { IRole } from './types'

type RoleModalProps = {
    roleId?: number
    isDisabled: boolean
}

const RoleModal = ({ isDisabled, roleId }: RoleModalProps) => {

    const { data: role, isSuccess } = useGetRoleByIdQuery({ roleId: roleId ?? 0 }, { refetchOnMountOrArgChange: true })

    if (!isSuccess) return <SpinnerModalBlock />

    return (<RoleForm isDisabled={isDisabled} role={role ?? {} as IRole} />)
}

export default RoleModal