import { Box, Typography, alpha, styled } from '@mui/material'
import { ITableItemProps } from './types'

export const TableItem = styled(Box)<ITableItemProps>(({ theme, isOdd }) => ({
  flex: 1,
  padding: theme.spacing(2),
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '14.28%',
  backgroundColor:
    isOdd || isOdd === undefined ? alpha(theme.palette.secondary.main, 0.08) : 'transparent',
  borderBottom: isOdd || (isOdd && isOdd === 0) ? `1px solid ${theme.palette.divider}` : 'none',
}))

export const TableItemTypography = styled(Typography)(({ theme }) => ({
  color: '#757575',
  fontWeight: 700,
}))

export const TableColumn = styled(Box)(({ theme }) => ({
  flex: 1,
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '14.28%',
  padding: theme.spacing(2),
}))
