import * as yup from 'yup'

import dayjs from 'dayjs'
import { validateContainerNumber } from '../../api'
import messages from './messages'

export const getShipmentValidationSchema = (formatMessage: any): any => {
  const validationErrorPickupDateNotInPast = formatMessage(
    messages.validationErrorPickupDateNotInPast
  )
  const validationErrorPickupDateNotBeforeRequested = formatMessage(
    messages.validationErrorPickupDateNotBeforeRequested
  )
  const validationErrorAtLeastOneLoad = formatMessage(messages.validationErrorAtLeastOneLoad)

  return yup.object({
    shipperId: yup
      .number()
      .min(1, formatMessage(messages.validationErrorCreateShipment_shipperRequired))
      .required(formatMessage(messages.validationErrorCreateShipment_shipperRequired)),
    customerId: yup
      .number()
      .min(1, formatMessage(messages.validationErrorCreateShipment_customerId))
      .required(formatMessage(messages.validationErrorCreateShipment_customerId)),
    customerReferenceNumber: yup
      .string()
      .max(50, formatMessage(messages.validationErrorCreateShipment_fieldMaxLength))
      .required(formatMessage(messages.validationErrorCreateShipment_customerReferenceNumber)),
    description: yup
      .string()
      .max(50, formatMessage(messages.validationErrorCreateShipment_fieldMaxLength))
      .required(formatMessage(messages.validationErrorCreateShipment_shippingDescriptionRequired)),
    value: yup
      .number()
      .min(0, formatMessage(messages.validationErrorCreateShipment_cargoValueMinimum))
      .max(999999999999999, formatMessage(messages.validationErrorCreateShipment_cargoValueMaximum))
      .required(formatMessage(messages.validationErrorCreateShipment_cargoValueRequired)),
    currencyId: yup
      .number()
      .min(1, 'Value and Currency are required')
      .required('Value and Currency are required'),

    loads: yup.array().of(
      yup.object({
        isContainer: yup.boolean().nullable().notRequired(),
        isInternational: yup.boolean().nullable().notRequired(),
        isProduct: yup.boolean().nullable().notRequired(),
        productId: yup.number().when('isProduct', {
          is: true,
          then: yup
            .number()
            .required(formatMessage(messages.validationErrorCreateShipment_productRequired)),
        }),
        quantity: yup
          .number()
          .typeError(formatMessage(messages.validationErrorCreateShipment_quantityRequired))
          .min(1, formatMessage(messages.validationErrorCreateShipment_fieldMinimumLengthOne))
          .required(formatMessage(messages.validationErrorCreateShipment_quantityRequired)),
        description: yup.string().when('isProduct', {
          is: (isProduct: boolean) => !isProduct,
          then: yup
            .string()
            .typeError(
              formatMessage(messages.validationErrorCreateShipment_shippingDescriptionRequired)
            )
            .max(50, formatMessage(messages.validationErrorCreateShipment_fieldMaxLength))
            .required(
              formatMessage(messages.validationErrorCreateShipment_shippingDescriptionRequired)
            ),
        }),
        value: yup
          .number()
          .required(formatMessage(messages.validationErrorCreateLoad_ValueRequired)),
        currencyId: yup
          .number()
          .nullable()
          .required(formatMessage(messages.validationErrorCreateShipment_currencyRequired)),
        countryOfOriginId: yup
          .string()
          .nullable()
          .when('transportDetail.isCrossBorder', {
            is: true,
            then: yup
              .string()
              .required(
                formatMessage(messages.validationErrorCreateShipment_countryOfOriginRequired)
              ),
          }),
        palletDetails: yup
          .array()
          .of(
            yup.object({
              quantity: yup
                .number()
                .min(1, formatMessage(messages.validationErrorCreateShipment_fieldMinimumLengthOne))
                .required(formatMessage(messages.validationErrorCreateShipment_quantityRequired)),
              palletTypeId: yup.number().min(1, 'Required').required('Required'),
            })
          )
          .nullable(),
        temperatureSetting: yup
          .object({
            isTemperatureControlled: yup.boolean(),
            temperatureRangeId: yup.number().when('isTemperatureControlled', {
              is: true,
              then: yup
                .number()
                .required(
                  formatMessage(messages.validationErrorCreateShipment_temperatureRangeRequired)
                ),
            }),
            setPoint: yup.number().when('isTemperatureControlled', {
              is: true,
              then: yup
                .number()
                .required(formatMessage(messages.validationErrorCreateShipment_setPointRequired)),
            }),
            temperatureUnitId: yup.number().when('isTemperatureControlled', {
              is: true,
              then: yup.number().min(1, 'Required').required('Required'),
            }),
          })
          .nullable(),
        transportDetail: yup.object({
          pickupLocationId: yup
            .number()
            .test(
              'pickupLocationId',
              formatMessage(messages.validationErrorCreateShipment_pickUpLocationRequired),
              (value, context: any) => {
                if (
                  context.from.length > 1 &&
                  context.from[2]?.value.loads.some((l: any) => l.isDefined) &&
                  !context.from[1]?.value.isDefined
                ) {
                  return true
                }
                return value && value > 0 ? true : false
              }
            ),
          // .required(formatMessage(messages.validationErrorCreateShipment_pickUpLocationRequired)),
          deliveryLocationId: yup
            .number()
            .test(
              'deliveryLocationId',
              formatMessage(messages.validationErrorCreateShipment_deliveryLocationRequired),
              (value, context: any) => {
                if (
                  context.from.length > 1 &&
                  context.from[2]?.value.loads.some((l: any) => l.isDefined) &&
                  !context.from[1]?.value.isDefined
                ) {
                  return true
                }
                return value && value > 0 ? true : false
              }
            ),

          pickupDate: yup
            .date()
            .typeError('Must be a date')
            .test('pickupDatePast', validationErrorPickupDateNotInPast, (value) => {
              if (value) {
                const currentDate = dayjs()
                const setUpDate = dayjs(value)
                const diff = setUpDate.diff(currentDate, 'day')
                return diff >= 0
              } else {
                return true
              }
            })
            .test(
              'pickupDateRequired',
              'Requested Pickup Date is required',
              (value, context: any) => {
                if (
                  context.from[2]?.value.loads.some((l: any) => l.isDefined) &&
                  !context.from[1]?.value.isDefined
                ) {
                  return true
                }
                return value ? true : false
              }
            )
            .nullable(),
          deliveryDate: yup
            .date()
            .typeError('Must be a date')
            .test('deliveryDatePast', validationErrorPickupDateNotInPast, (value) => {
              if (value) {
                const currentDate = dayjs()
                const setUpDate = dayjs(value)
                const diff = setUpDate.diff(currentDate, 'day')
                return diff >= 0
              }
              return true
            })
            .test(
              'deliveryDateGreterOrEqualPickupDate',
              validationErrorPickupDateNotBeforeRequested,
              (value, context) => {
                const pickUpDate = context.parent.pickupDate
                if (value) {
                  const setUpDate = dayjs(value)
                  const diff = setUpDate.diff(pickUpDate, 'day')
                  return diff >= 0
                }
                return true
              }
            )
            .nullable(),
        }),
        loadAssetConfiguration: yup.array().of(
          yup
            .object({
              containerNumber: yup
                .string()
                .required(
                  formatMessage(messages.validationErrorCreateShipment_containerNumberRequired)
                )
                .when('isContainer', {
                  is: true,
                  then: yup
                    .string()
                    .required(
                      formatMessage(messages.validationErrorCreateShipment_containerNumberRequired)
                    ),
                }),
            })
            .nullable()
        ),
      })
    ),
    assetRequests: yup.array().of(
      yup.object({
        loadsId: yup
          .array()
          .min(1, validationErrorAtLeastOneLoad)
          .required(validationErrorAtLeastOneLoad),
        quantity: yup
          .number()
          .typeError(formatMessage(messages.validationErrorCreateShipment_quantityRequired))
          .min(1, formatMessage(messages.validationErrorCreateShipment_fieldMinimumLengthOne))
          .required(formatMessage(messages.validationErrorCreateShipment_quantityRequired)),
      })
    ),
    containerDetails: yup
      .array()
      .of(
        yup.object({
          loadsId: yup
            .array()
            .min(1, validationErrorAtLeastOneLoad)
            .required(validationErrorAtLeastOneLoad),
          containerNumber: yup
            .string()
            .test('containerNumber', 'Invalid container number', async (value): Promise<any> => {
              if (value) {
                const result = await (await validateContainerNumber(value)).data.data
                return result
              } else {
                return false
              }
            }),
        })
      )
      .nullable(),
  })
}
