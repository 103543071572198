import { Typography } from '@mui/material'


type YesNoTypographyProps = {
    isYes: boolean
}

const YesNoTypography = ({ isYes }: YesNoTypographyProps) => {
    return isYes ? (
        <Typography sx={{ fontWeight: 700, color: '#0A9D33' }}>
            Yes
        </Typography>
    ) : (
        <Typography sx={{ fontWeight: 700, color: '#D40808' }}>
            No
        </Typography>
    )
}

export default YesNoTypography
